	import React, {Component, Fragment} from 'react';
import ListaTabla from './Includes/ListaTabla';

import {EstadoCuentasService} from '../../../services/EstadoCuentasService/EstadoCuentasService';
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {BotonActualizarLista, CabeceraTituloPdfExcelLista} from "../../Include/MiniComponents/GlobalComponent";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from "../../../settings/General/General";
import {DateRangeOutlined, EventNote, Save, SearchOutlined, PictureAsPdf, ShoppingCart, SwapVerticalCircle} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import {Chart} from "react-google-charts";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {CatService} from "../../../services/_Cat/CatService/CatService";
import MDDatePicker from "../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import Sync from "@material-ui/icons/Sync";
import ModalFondeoCuenta from "./Includes/ModalFondeoCuenta";
import ModalVentas from "../Compras/Includes/ModalVentas";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import ModalVerCompras from "../Compras/Includes/ModalVerCompras";


const TabPanel = ({content}) =>
	<div className="TabPanel">
		<p>{content}</p>
	</div>;

class Caja extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let fecha_inicio = new Date(moment().subtract(7, 'd'));
		
		this.state = {
			id_cat_tipo_cuenta: '',
			minimo: '',
			maximo: '',
			
			grafica: [],
			listar_retiros: [],
			listar_retiros_save: [],
			resumen: [],
			
			lista_cat_tipo_cuenta: [],
			loadding: false,
			
			fecha_inicio: fecha_inicio,
			fecha_fin: new Date(),
			
			total_saldo: 0,
			value_tabs: 0,
			
			numero_referencia: ''
			
		};
		
		this.Listar();
		this.ListaTipoCuenta();
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	buscarReferencia = () => {
		let lista = FieldsJs.Copy(this.state.listar_retiros);
		this.setState({
			listar_retiros: this.state.listar_retiros_save
		});
		let obj = lista.filter(ref => ref.referencia === this.state.numero_referencia);
		console.log("OBJ:::", obj);
		if (obj.length === 0) {
			this.showSnackBars('error', "La referencia no se encuentra en la lista.");
		} else {
			this.setState({
				listar_retiros: obj
			});
		}
	};
	
	Listar = () => {
		EstadoCuentasService.Listar(this.state).then((response) => {
			this.setState({
				listar_retiros: response.data,
				listar_retiros_save: response.data,
				resumen: response.resumen,
				total_saldo: response.total_saldo,
				grafica: response.grafica
			});
		}).catch((error) => {
			this.setState({
				listar_retiros: [{
					numero_retiro: '00000001',
					usuario_nombre_completo: 'Carlos A. Medina Arias',
					descripcion: 'Retiro del mes de Marzo',
					fecha_retiro: '2020-04-03',
					total_retiro: 3300,
					saldo: 700,
					compra_comprobante: ''
				}],
				
				value_tabs: 0
			});
			//this.showSnackBars('error', error.mensaje);
		});
	};
	
	changeTabs = (index) => {
		this.setState({
			value_tabs: index
		})
	};
	
	ListaTipoCuenta = () => {
		CatService.ListTipoCuenta().then((response) => {
			this.setState({
				lista_cat_tipo_cuenta: response.data
			});
		}).catch((error) => {
			this.setState({
				lista_cat_tipo_cuenta: []
			});
			this.showSnackBars('error', error.mensaje);
		})
	};
	
	changeSaldoCuenta = () => {
		EstadoCuentasService.ModificarSaldo(this.state).then((response) => {
			this.setState({
				id_cat_tipo_cuenta: '',
				minimo: '',
				maximo: '',
			});
			this.ListaTipoCuenta();
			this.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		})
	};
	
	exportar = (item, archivo, auth) => {
		let fecha_inicio = (this.state.fecha_inicio || null) ? DateFormat.FormatSql(this.state.fecha_inicio) : null;
		let fecha_fin = (this.state.fecha_fin || null) ? DateFormat.FormatSql(this.state.fecha_fin) : null;
		
		let filtro = {
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
		};
		
		HttpRequest.export('ec', ['estadocuentas'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL");
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-340-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Estado de cuenta
						</Typography>
					</div>
					<div className={'w-340-px text-right vertical-inline'}>
						<div className={'v-center'}>
							<ModalFondeoCuenta
								tipo={'add'}
								item={{}}
								componente={
									<Fab variant="extended" size="small" style={{backgroundColor: '#2884a5', color: 'white'}} aria-label="Fondeo Cuenta" className={'margin-10-L'}>
										<EventNote className={'margin-5-R px-14'}/>
										Fondeo Cuenta
									</Fab>
								}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
						</div>
						<div className={'v-center'}>
							<Fab variant="extended" size="small" color="primary" aria-label="Add"
							     className={'margin-10-L'} onClick={this.RefreshList}>
								<Sync className={'margin-5-R px-14'}/>
								Actualizar
							</Fab>
						</div>
					</div>
				</div>
				
				<Grid spacing={0} container>
					{/*{FieldsJs.inArray([1, 2, 13], this.Usr.id_cat_tipo_usuario) === true ? (
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid spacing={4} container>
									
									<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
										<TextField
											select
											fullWidth
											margin="dense"
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_cat_tipo_cuenta"
											label="Tipo Cuenta"
											value={this.state.id_cat_tipo_cuenta}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_cat_tipo_cuenta.map(option => (
												<option key={option.id_cat_tipo_cuenta}
												        value={option.id_cat_tipo_cuenta}>
													{option.tipo_cuenta}
												</option>
											))}
										</TextField>
									</Grid>
									
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<TextField
											autoFocus
											margin="dense"
											name="minimo"
											label="Mínimo"
											type="text"
											fullWidth
											onKeyPress={EnteroSolo}
											defaultValue={this.state.minimo}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
										/>
									
									</Grid>
									
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<TextField
											autoFocus
											margin="dense"
											name="maximo"
											label="Máximo"
											type="text"
											fullWidth
											onKeyPress={EnteroSolo}
											defaultValue={this.state.maximo}
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'left'}>
										<div className={'vertical-inline h-100 w-100'}>
											<Fab className={'v-center'} variant="extended" size="small" color="primary" style={{backgroundColor: "#3f50b6"}} onClick={() => this.changeSaldoCuenta()}>
												<Save style={{fontSize: 14, marginRight: 5}}/> Guardar
											</Fab>
										</div>
									</Grid>
									
									
								</Grid>
							</Grid>
						) : null}*/}
					
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid spacing={4} container>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								<p style={{textAlign: 'left', margin: '10px 0px', fontStyle: 'italic'}}>Saldos para mantener en caja:</p>
								<Table className={'desing-caja-mark'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'}>Tipo</TableCell>
											<TableCell component="th" align={'left'}>Mínimo</TableCell>
											<TableCell component="th" align={'left'}>Máximo</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.lista_cat_tipo_cuenta.map((item, key) => {
											return (
												<TableRow key={key}>
													<TableCell align={'left'}>{item.tipo_cuenta}</TableCell>
													<TableCell align={'left'}>${FieldsJs.Currency(item.minimo)}</TableCell>
													<TableCell align={'left'}>${FieldsJs.Currency(item.maximo)}</TableCell>
												</TableRow>
											)
										})}
									</TableBody>
								</Table>
								
								<p style={{textAlign: 'left', margin: '25px 0px 10px 0px', fontStyle: 'italic'}}>Resumen de saldos:</p>
								<Table className={'desing-caja-mark'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'}>Tipo</TableCell>
											<TableCell component="th" align={'left'}>No. de Retiros</TableCell>
											<TableCell component="th" align={'left'}>No. de Depositos</TableCell>
											<TableCell component="th" align={'left'}>Saldo</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.resumen.map((item, key) => {
											return (
												<TableRow key={key}>
													<TableCell align={'left'}>{item.tipo_cuenta}</TableCell>
													<TableCell align={'left'}>{item.retiros}</TableCell>
													<TableCell align={'left'}>{item.depositos}</TableCell>
													<TableCell align={'left'}>${FieldsJs.Currency(item.saldo)}</TableCell>
												</TableRow>
											)
										})}
									</TableBody>
								</Table>
							
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<div className={'vertical-inline h-80-px w-100'}>
									<div className={'v-center w-100'} align={'center'}>
										<MDDatePicker
											type={'range'}
											component={(
												<TextField
													fullWidth
													label="Movimientos a partir de:"
													value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
													readOnly
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton aria-label="Fecha de alta">
																	<DateRangeOutlined/>
																</IconButton>
															</InputAdornment>
														)
													}}
												/>
											)}
											startDate={this.state.fecha_inicio}
											endDate={this.state.fecha_fin}
											onChange={(range) => {
												this.setState({
													fecha_inicio: range.startDate,
													fecha_fin: range.endDate,
												});
												setTimeout(() => this.RefreshList());
											}}
										/>
									</div>
								</div>
								
								<div className={'vertical-inline h-100-80-px w-100'}>
									<div className={'v-center w-100'} align={'center'}>
										<h2 style={{color: 'rgb(163, 0, 6)', textAlign: 'center', margin: '10px 0px', fontStyle: 'italic'}}>Saldo Total:</h2>
										<h1 style={{fontSize: '2.5em', textAlign: 'center', margin: '10px 0px', fontStyle: 'italic'}}>${FieldsJs.Currency(this.state.total_saldo)}</h1>
										
										<div className={'vertical-inline'}>
											<div className={'v-center margin-10-R'}>
												<div className={'padding-20-T text-center'}>
													<Fab variant="extended" size="small" color="primary" style={{backgroundColor: "#006300"}} onClick={() => this.exportar({}, 'EXCEL', true)}>
														<EventNote style={{fontSize: 14, marginRight: 5}}/> Excel
													</Fab>
												</div>
											</div>
											<div className={'v-center margin-10-L'}>
												<div className={'padding-20-T text-center'}>
													<Fab variant="extended" size="small" color="primary" aria-label="PDF" className={'margin-10-L bg-danger'} onClick={() => this.exportar({}, 'PDF', true)}>
														<PictureAsPdf className={'margin-5-R px-14'}/>
														PDF
													</Fab>
												</div>
											</div>
										</div>
									</div>
								</div>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<div
									style={{
										backgroundColor: 'transparent',
										height: 'calc(100% - 20px)',
										padding: '10px',
										overflow: 'auto',
										borderRadius: '7px',
										border: '1px solid #c3c3c3',
										borderStyle: 'dashed',
										marginTop: '10px'
									}}>
									
									{/*<div style={{height: 0, width: '100%'}} align={'right'}>
										<Fab size="small" color="secondary" onClick={() => this.InitComponent(true)} style={{height: '40px', width: '40px'}}>
											{this.state.loadding ? (
												<CircularProgress size={40} style={{
													color: 'white',
													zIndex: 1,
												}}/>
											) : (
												<Replay/>
											)}
										</Fab>
									</div>*/}
									
									<Fragment>
										<div align={'center'} style={{width: '100%'}} className={'fill-transparent'}>
											
											<Typography component={'h2'} className={'ff-arial'} style={{margin: '0px'}}>
												Proporción en estado de cuenta
											</Typography>
											
											{this.state.grafica.length > 0 ? (
												<Chart
													width={'100%'}
													height={'250px'}
													chartType="PieChart"
													loader={
														<Typography component={'h2'} className={'ff-arial'}>
															Cargando gráficas...
														</Typography>
													}
													data={this.state.grafica}
													options={{
														title: '',
														legend: {
															alignment: 'center',
															position: 'bottom',
															textStyle: {
																color: 'black',
																fontSize: 10,
															},
														},
														pieHole: 0,
														animation: {
															duration: 1000,
															easing: 'out',
															startup: true,
														},
													}}
													rootProps={{'data-testid': '3'}}
												/>
											) : (
												<Fragment>
													<Typography component={'h2'} className={'ff-arial'}>
														Esperando datos...
													</Typography>
												</Fragment>
											)}
										</div>
									</Fragment>
								
								</div>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<TextField
									margin="dense"
									name="numero_referencia"
									label="Número de referencia"
									type="text"
									fullWidth
									onKeyPress={EnteroSolo}
									defaultValue={this.state.numero_referencia}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9} xl={9} align={'left'}>
								<div className={'vertical-inline h-100 w-100'}>
									<Fab className={'v-center'} variant="extended" size="small" color="primary" style={{backgroundColor: "#3f50b6"}} onClick={() => this.buscarReferencia()}>
										<SearchOutlined style={{fontSize: 14, marginRight: 5}}/> Buscar
									</Fab>
								
								
										<div className={'v-center margin-15-L margin-15-R'}>
											<ModalVentas
												tipo={'add'}
												item={{}}
												component={
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Lista Ventas"
														children={(
															<Fab className={'v-center'} variant="extended" size="small" color="primary" style={{backgroundColor: "#196fc1"}}>
																<ShoppingCart style={{fontSize: 14, marginRight: 5}}/> Ventas
															</Fab>
														)}
													/>
												}
												RefreshList={this.RefreshListTipoCompra}
												showSnackBars={this.showSnackBars}
											/>
										</div>
										
								<div className={'v-center'}>
											<ModalVerCompras
												tipo={'add'}
												item={{}}
												component={
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Lista Ventas"
														children={(
															<Fab className={'v-center'} variant="extended" size="small" color="primary" style={{backgroundColor: "#0c92ba"}}>
																<SwapVerticalCircle style={{fontSize: 14, marginRight: 5}}/> Compras
															</Fab>
														)}
													/>
												}
												RefreshList={this.RefreshListTipoCompra}
												showSnackBars={this.showSnackBars}
											/>
										</div>
								</div>
								
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<div style={{padding: '10px 0px 0px 0px'}}>
							
							<Tabs
								value={this.state.value_tabs}
								indicatorColor="primary"
								textColor="primary"
								onChange={this.handleChange}
								aria-label="disabled tabs example"
								style={{marginTop: '15px'}}
							>
								{this.state.resumen.map((item, key) => (
									<Tab key={key} label={item.tipo_cuenta} onClick={() => this.changeTabs(key)}/>
								))}
							</Tabs>
							
							
							<Fragment>
								<div className={'min-h-200-px'} style={{border: '1px solid #c3c3c3', borderStyle: 'dashed'}}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<div className={'vertical-inline h-100 w-100'}>
												<div className={'v-center w-100'} align={'center'}>
													<p style={{textAlign: 'left', margin: '10px 0px', fontStyle: 'italic'}}>Movimientos del periodo:</p>
												</div>
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<div className={'vertical-inline h-100 w-100'}>
												<div className={'v-center w-100'} align={'right'}>
													<h3 style={{color: 'rgb(163, 0, 6)', margin: '10px 10px', fontStyle: 'italic'}}>Saldo {(this.state.resumen[this.state.value_tabs] || '').tipo_cuenta}:</h3>
												</div>
												<div className={'v-center w-100'} align={'right'}>
													<p style={{color: '#333', fontSize: '1.5em', textAlign: 'center', margin: '10px 10px', fontStyle: 'italic'}}>${FieldsJs.Currency((this.state.resumen[this.state.value_tabs] || 0).total_saldo)} </p>
												</div>
											</div>
										</Grid>
									</Grid>
									
									<ListaTabla
										ids_cat_forma_pago={Number(this.state.value_tabs) === 0 ? [1] : [2, 3, 4, 18, 21, 23, 24]}
										value_tabs={this.state.value_tabs}
										lista={this.state.listar_retiros}
										RefreshList={this.RefreshList}
										showSnackBars={this.showSnackBars}
									/>
								</div>
							</Fragment>
						
						</div>
					</Grid>
				</Grid>
				{/*<div className={'form margin-30-B margin-20-T'}>
					
					{this.state.listar_retiros.length > 0 ? (
						<ListaTabla
							lista={this.state.listar_retiros}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>*/}
				
				{/*<ModalEstadoCuentas
					tipo={'add'}
					item={{}}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>*/}
			
			</div>
		);
	}
}

Caja.propTypes = {};

export default IntegrationNotistack(Caja);
