import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/Typography/index";
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import {BalancesService} from '../../../services/BalancesService/BalancesService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_1 from "../../../assets/img/icons/carro-de-la-compra-1.svg";
import FiltrosBalances from "./Includes/FiltrosBalances";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import {CONFIG} from "../../../settings/Config/Config";
import moment from "moment";
import {CatService} from "../../../services/_Cat/CatService/CatService";

class Balances extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			
			filtro: {
				id_cat_tipo_gasto_compra: '',
				tipo_gasto_compra: '',
				fecha_inicio: new Date(moment().startOf('month')),
				fecha_fin: new Date(moment()),
			},
			filtro2: {
				id_cat_tipo_gasto_compra: '',
				tipo_gasto_compra: '',
				fecha_inicio: new Date(moment().startOf('month')),
				fecha_fin: new Date(moment()),
			},
			
			listar_balances: [],
			lista_cat_tipo_gasto_compra: [],
			listar_balances_detalle: {
				ingresos: [],
				compras: []
			}
		};
		
		this.ListaTipoGastoCompra();
		this.RefreshList();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	AplicarFiltros = () => {
		setTimeout(() => this.Listar());
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	
	ListaTipoGastoCompra = () => {
		CatService.ListTipoGastoCompra().then(value => {
			this.setState({
				lista_cat_tipo_gasto_compra: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_tipo_gasto_compra: []
			});
		});
	};
	
	
	Listar = () => {
		BalancesService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_balances = response.data;
			let filtro = this.state.filtro
			let lista_cat_tipo_gasto_compra = FieldsJs.Copy(this.state.lista_cat_tipo_gasto_compra);
			
			for (let i = 0; i < lista_cat_tipo_gasto_compra.length; i++) {
				if (Number(lista_cat_tipo_gasto_compra[i].id_cat_tipo_gasto_compra) === Number(filtro.id_cat_tipo_gasto_compra)) {
					filtro.tipo_gasto_compra = lista_cat_tipo_gasto_compra[i].tipo_gasto_compra.toUpperCase();
				}
			}
			
			this.setState({
				filtro: filtro,
				filtro2: filtro,
				listar_balances: listar_balances,
				ver_detalle: false
			});
			
		}).catch((error) => {
			
			this.setState({listar_balances: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	verDetalle = () => {
		
		BalancesService.Detalles(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_balances_detalle = response.data;
			
			this.setState({
				listar_balances_detalle: listar_balances_detalle,
				ver_detalle: true
			});
			
		}).catch((error) => {
			
			this.setState({
				listar_balances_detalle: {
					ingresos: [],
					compras: []
				}
			});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	}
	
	exportar = (item, archivo, auth) => {
		if (item.id_compra > 0) {
			let tipo = 2;
			HttpRequest.export('cxid', ['compras'], {
				id_compra: item.id_compra,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_inicio = (this.state.filtro.fecha_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_inicio) : null;
			let fecha_fin = (this.state.filtro.fecha_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_fin) : null;
			
			let filtro = {
				id_cat_tipo_gasto_compra: this.state.filtro.id_cat_tipo_gasto_compra || null,
				fecha_inicio: fecha_inicio,
				fecha_fin: fecha_fin,
			};
			HttpRequest.export('c', ['compras'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Balances
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						{/*<Fab variant="extended" size="small" color="primary" aria-label="PDF"
						     className={'margin-10-L bg-danger'} onClick={() => this.exportar({}, 'PDF', true)}>
							<PictureAsPdf className={'margin-5-R px-14'}/>
							PDF
						</Fab>
						<Fab variant="extended" size="small" color="primary" aria-label="Excel"
						     className={'margin-10-L bg-green'} onClick={() => this.exportar({}, 'EXCEL', false)}>
							<EventNote className={'margin-5-R px-14'}/>
							Excel
						</Fab>*/}
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.RefreshList}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				<FiltrosBalances
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B'}>
					
					<Fragment>
						<ListaTabla
							lista={this.state.listar_balances}
							lista_detalle={this.state.listar_balances_detalle}
							filtro2={this.state.filtro2}
							mostrar_detalle={this.state.ver_detalle}
							verDetalle={this.verDetalle}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					</Fragment>
				
				</div>
			
			</div>
		);
	}
}

Balances.propTypes = {};

export default IntegrationNotistack(Balances);
