import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import {ProductoService} from "../../../../services/ProductoService/ProductoService";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import {CONFIG} from "../../../../settings/Config/Config";
import Typography from "@material-ui/core/Typography/index";
import Table from "@material-ui/core/Table";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import FiltrosProductos from "../../Producto/Includes/FiltrosProductos";
import Paginacion from "../../../Include/Paginacion/Paginacion";


class ModalProductos extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
			id_producto: '',
			lista_producto: [],
			lista_producto_original: [],
			
			filtro: {
				clave: '',
				producto: '',
				activo: '',
			},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const {item} = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, item);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	AplicarFiltro = () => {
		this.init();
	}
	
	init = () => {
		return new Promise((resolve, reject) => {
			this.Listar();
			resolve(true);
		});
	};
	
	Listar = () =>{
		ProductoService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				lista_producto: response.data,
				lista_producto_original: response.data,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({
				lista_producto: [],
				lista_producto_original: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		}, () => {
			console.log("CONTENT:::: ", files);
		});
	};
	
	
	save = () => {
		
		console.log(this.state);
		
		/*CotizadorService.Nube(formData).then((response) => {
			this.closeModal();
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});*/
	};
	
	render() {
		const is_root = this.is_root();
		let {item} = this.props;
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogTitle>
						Productos
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} className={'cotizador_fondo'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FiltrosProductos
									AplicarFiltros={this.AplicarFiltros}
									HandleFiltro={this.HandleFiltro}
									showSnackBars={this.props.showSnackBars}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{this.state.lista_producto.length > 0 ? (
									<Fragment>
										<Table className={'desing-productos'}>
											<TableHead>
												<TableRow>
													<TableCell component="th" align={'left'}>Imagen</TableCell>
													<TableCell component="th" align={'left'}>Clave</TableCell>
													<TableCell component="th" align={'left'}>Producto</TableCell>
													<TableCell component="th" align={'left'}>Grupo</TableCell>
													<TableCell component="th" align={'left'}>Categoría</TableCell>
													<TableCell component="th" align={'left'}>Descripción</TableCell>
													<TableCell component="th" align={'left'}>Cantidad</TableCell>
													<TableCell component="th" align={'center'}>Precio unitario</TableCell>
													<TableCell component="th" align={'center'}>Acción</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.state.lista_producto.map((item, key) => (
													<TableRow key={key}>
														
														<TableCell align={'left'}>
															<Avatar
																style={{
																	width: 34,
																	height: 34
																}}
																alt={item.imagen}
																src={CONFIG.src + item.imagen}
															/>
														</TableCell>
														
														<TableCell align={'left'}>
															{item.clave}
														</TableCell>
														
														<TableCell align={'left'}>
															{item.producto}
														</TableCell>
														
														<TableCell align={'left'}>
															{item.grupo}
														</TableCell>
														
														<TableCell align={'left'}>
															{item.categoria}
														</TableCell>
														
														<TableCell align={'center'}>
															{item.descripcion}
														</TableCell>
														
														<TableCell align={'center'}>
															{item.cantidad}
														</TableCell>
														
														<TableCell align={'center'}>
															${FieldsJs.Currency(item.precio_unitario)}
														</TableCell>
														
														<TableCell align={'center'}>
															<Button
																fullWidth
																variant="contained"
																onClick={() => {
																	this.props.selectProducto(item);
																	this.close();
																}}
																style={{
																	backgroundColor: "#3f51b5",
																	color: "white",
																	textTransform: "none",
																	padding: "5px",
																	fontSize: "12px",
																}}
															>
																{"Seleccionar"}
															</Button>
														</TableCell>
													
													</TableRow>
												))}
											</TableBody>
										</Table>
										<Paginacion
											total={this.state.paginacion.total}
											page={this.state.paginacion.page}
											limit={this.state.paginacion.limit}
											rangos={this.state.paginacion.rangos}
											onClick={(data) => this.AplicarPaginacion(data)}
										/>
									</Fragment>
								) : (
									<VistaVacia
										numero={0}
										mensaje={'No se encontraron datos.'}
									/>
								)}
							
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalProductos.propTypes = {
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	selectProducto: PropTypes.func.isRequired,
};

export default ModalProductos;
