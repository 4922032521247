import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {DateRangeOutlined, SearchOutlined, PersonOutlined, CardTravel} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";

import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {CatService} from "../../../../services/_Cat/CatService/CatService";


class FiltrosVerCompras extends Component {
	state = {};
	
	constructor() {
		super();
		this.state = {
			numero_compra: '',
			usaurio: '',
			proveedor: '',
			producto: '',
			fecha_alta_inicio: null,
			fecha_alta_fin: null,
			fecha_compra_inicio: null,
			fecha_compra_fin: null,
			ordenar_fecha_alta: false,
			ordenar_fecha_compra: false,
			
			filtro_cat_usuario_disenador: [],
		};
		
		this.ListaUsuarioDisenador();
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.numero_compra = this.state.numero_compra || undefined;
		filtro.producto = this.state.producto || undefined;
		filtro.usaurio = this.state.usaurio || undefined;
		filtro.proveedor = this.state.proveedor || undefined;
		filtro.fecha_alta_inicio = this.state.fecha_alta_inicio && this.state.fecha_alta_fin ? this.state.fecha_alta_inicio : undefined;
		filtro.fecha_alta_fin = this.state.fecha_alta_inicio && this.state.fecha_alta_fin ? this.state.fecha_alta_fin : undefined;
		filtro.fecha_compra_inicio = this.state.fecha_compra_inicio && this.state.fecha_compra_fin ? this.state.fecha_compra_inicio : undefined;
		filtro.fecha_compra_fin = this.state.fecha_compra_inicio && this.state.fecha_compra_fin ? this.state.fecha_compra_fin : undefined;
		filtro.ordenar_fecha_alta = this.state.ordenar_fecha_alta ? 1 : undefined;
		filtro.ordenar_fecha_compra = this.state.ordenar_fecha_compra ? 1 : undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	ListaUsuarioDisenador = () => {
		CatService.ListUsuarioDisenador().then((cat) => {
			this.setState({
				filtro_cat_usuario_disenador: cat.data
			})
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={1} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<TextField
							type={'text'}
							fullWidth
							name="numero_compra"
							onChange={this.handleChange}
							label="No. compra"
							autoComplete={'off'}
							value={this.state.numero_compra}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="usaurio"
									onChange={this.handleChange}
									label="Usuario"
									autoComplete={'off'}
									value={this.state.usaurio}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="proveedor"
									onChange={this.handleChange}
									label="Proveedor"
									autoComplete={'off'}
									value={this.state.proveedor}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<CardTravel className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="producto"
									onChange={this.handleChange}
									label="Producto"
									autoComplete={'off'}
									value={this.state.producto}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha de alta"
									value={(this.state.fecha_alta_inicio && this.state.fecha_alta_fin) ? (DateFormat.FormatText(this.state.fecha_alta_inicio) + " al " + DateFormat.FormatText(this.state.fecha_alta_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha de alta">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_alta_inicio}
							endDate={this.state.fecha_alta_fin}
							onChange={(range) => {
								this.setState({
									fecha_alta_inicio: range.startDate,
									fecha_alta_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha de compra"
									value={(this.state.fecha_compra_inicio && this.state.fecha_compra_fin) ? (DateFormat.FormatText(this.state.fecha_compra_inicio) + " al " + DateFormat.FormatText(this.state.fecha_compra_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha de compra">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_compra_inicio}
							endDate={this.state.fecha_compra_fin}
							onChange={(range) => {
								this.setState({
									fecha_compra_inicio: range.startDate,
									fecha_compra_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
			
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
					
				</Grid>
			</Fragment>
		);
	}
}

FiltrosVerCompras.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosVerCompras;
