import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography";
import Fab from '@material-ui/core/Fab';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import EventNote from '@material-ui/icons/EventNote';
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';
import ModalProveedor from './Includes/ModalProveedor';

import {ProveedorService} from '../../../services/ProveedorService/ProveedorService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import FiltrosCliente from "../Cliente/Includes/FiltrosCliente";
import FiltrosProveedor from "./Includes/FiltrosProveedor";
import Paginacion from "../../Include/Paginacion/Paginacion";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";


class Proveedor extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor() {
		super();
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			filtro: {
				nombre_comercial: '',
				razon_social: '',
				rfc: '',
			},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_proveedor: []
		};
		
		this.Listar();
	}
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	Listar = () => {
		ProveedorService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			this.setState({
				listar_proveedor: response.data,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_proveedor: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	exportar = (item, archivo, auth) => {
		let filtro = {};
		HttpRequest.export('proveedores', ['proveedores'], {
			auth: auth,
			archivo: archivo,
			filtro: filtro
		}, false, false, false, archivo === "EXCEL")
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							Proveedores
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="PDF"
							     className={'margin-10-L bg-danger'}>
								<PictureAsPdf className={'margin-5-R px-14'}/>
								PDF
							</Fab>
						) : ''}*/}
						{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
							<Fab variant="extended" size="small" color="primary" aria-label="Excel"
							     className={'margin-10-L bg-green'} onClick={() => this.exportar({}, 'EXCEL', false)}>
								<EventNote className={'margin-5-R px-14'}/>
								Excel
							</Fab>
						) : ''}
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.Listar}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				<FiltrosProveedor
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-30-B'}>
					
					<Typography variant={'h6'} className={'margin-30-T margin-30-B'}>
					
					</Typography>
					
					{this.state.listar_proveedor.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_proveedor}
								RefreshList={this.RefreshList}
								showSnackBars={this.showSnackBars}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
					<ModalProveedor
						id_proveedor={null}
						tipo={'add'}
						item={{}}
						RefreshList={this.RefreshList}
						showSnackBars={this.showSnackBars}
					/>
				) : ''}
			
			</div>
		);
	}
}

Proveedor.propTypes = {};

export default IntegrationNotistack(Proveedor);
