import React, {Fragment, Component} from 'react';
import PropTypes from "prop-types";

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import {FieldsJs} from "../../../../settings/General/General";
import {OrdenDeVentaService} from "../../../../services/OrdenDeVentaService/OrdenDeVentaService";
import {KeyboardDatePicker} from "@material-ui/pickers";
import moment from "moment";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import requiere_factura from '../../../../assets/img/icons/requiere_factura.svg';
import requiere_interno from '../../../../assets/img/icons/requiere_interno.svg';
import requiere_nota_sencilla from '../../../../assets/img/icons/requiere_nota_sencilla.svg';


class ModalAprobarCotizacion extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: props.open || false
			},
			
			id_venta: null,
			item: props.item || {},
		};
		
		if (props.open) {
			this.openModal();
		}
	}
	
	openModal = () => {
		const {id_venta, item} = this.props;
		
		this.setState({
			modal: {
				open: true
			},
			
			id_venta: id_venta,
			item: item,
			fecha_entrega: moment(item.fecha_entrega),
			interno: item.interno === 1,
			nota_sencilla: item.nota_sencilla === 1,
			factura: item.factura === 1,
			cotizacion: item.cotizacion === 1,
		});
		console.log("openModa:::", item);
	};
	
	closeModal = () => {
		this.props.selectCloseModal();
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	handleChange = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	save = () => {
		
		try {
			if (!(this.state.item.factura === true) && !(this.state.item.nota_sencilla === true) && !(this.state.item.interno === true)) {
				throw Object({
					status: false,
					mensaje: "Selecciona una opción"
				});
			}
			OrdenDeVentaService.AprobarCotizacion(this.state.item.id_venta, this.state.item, this.props.tipo_vista).then((response) => {
				this.closeModal();
				this.props.watchAprobarCotizacion();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
		
	};
	
	render() {
		
		return (
			<div>
				
				{/*<Fragment>
					{this.props.open ? () => this.openModal():null}
				</Fragment>*/}
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						Aprobar {this.props.tipo_vista === "cotizacion" ? "cotización" : "orden de venta"} como
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								<br/>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'center'}>
								<div style={{
									width: "100px",
									height: "100px",
									background: this.state.factura ? "#3f51b5" : (this.state.item.factura ? "#7fc6e6" : "gainsboro"),
									color: this.state.factura ? "white" : (this.state.item.factura ? "black" : "black"),
									padding: "10px",
									borderRadius: "100%"
								}} className={'vertical-inline cursor-pointer'} onClick={() => {
									if (!this.state.factura) {
										this.handleChange('item', 'factura', !this.state.item.factura);
									}
								}}>
									<div className={'w-100 v-center text-center'} align={'center'}>
										<div style={{width: '100%'}} align={'center'}>
											<DivSvg img={requiere_factura} height={40} width={40}/>
										</div>
										Factura
									</div>
								</div>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'center'}>
								<div style={{
									width: "100px",
									height: "100px",
									background: this.state.nota_sencilla ? "#3f51b5" : (this.state.item.nota_sencilla ? "#7fc6e6" : "gainsboro"),
									color: this.state.nota_sencilla ? "white" : (this.state.item.nota_sencilla ? "black" : "black"),
									padding: "10px",
									borderRadius: "100%"
								}} className={'vertical-inline cursor-pointer'} onClick={() => {
									if (!this.state.nota_sencilla) {
										this.handleChange('item', 'nota_sencilla', !this.state.item.nota_sencilla);
									}
								}}>
									<div className={'w-100 v-center text-center'} align={'center'}>
										<div style={{width: '100%'}} align={'center'}>
											<DivSvg img={requiere_nota_sencilla} height={40} width={40}/>
										</div>
										Nota sencilla
									</div>
								</div>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'center'}>
								<div style={{
									width: "100px",
									height: "100px",
									background: this.state.interno ? "#3f51b5" : (this.state.item.interno ? "#7fc6e6" : "gainsboro"),
									color: this.state.interno ? "white" : (this.state.item.interno ? "black" : "black"),
									padding: "10px",
									borderRadius: "100%"
								}} className={'vertical-inline cursor-pointer'} onClick={() => {
									if (!this.state.interno) {
										this.handleChange('item', 'interno', !this.state.item.interno);
									}
								}}>
									<div className={'w-100 v-center text-center'} align={'center'}>
										<div style={{width: '100%'}} align={'center'}>
											<DivSvg img={requiere_interno} height={40} width={40}/>
										</div>
										Interno
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								<br/>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Numero de cotización:
									<div className={'vertical-inline v-center'} style={{
										padding: "0px 5px",
										borderRadius: "10px",
										marginLeft: "5px",
										background: "#3a3aa0",
										color: "white",
									}}>{this.state.item.numero_cotizacion || 'Sin numero'}</div>
								</Typography>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Numero de orden:
									<div className={'vertical-inline v-center'} style={{
										padding: "0px 5px",
										borderRadius: "10px",
										marginLeft: "5px",
										background: "#3a3aa0",
										color: "white",
									}}>{this.state.item.numero_pedido || 'Por asignar'}</div>
								</Typography>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Observaciones: {this.state.item.observaciones || 'Sin observaciones'}
								</Typography>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Cliente:
									<div className={'vertical-inline v-center'} style={{
										padding: "0px 5px",
										borderRadius: "10px",
										marginLeft: "5px",
										background: "#3a3aa0",
										color: "white",
									}}>{this.state.item.cliente_nombre_completo || ''}</div>
								</Typography>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Vendedor: {this.state.item.usuario_nombre_completo}
								</Typography>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Total:
									<div className={'vertical-inline v-center'} style={{
										padding: "0px 5px",
										borderRadius: "10px",
										marginLeft: "5px",
										background: "#3a3aa0",
										color: "white",
									}}>$ {FieldsJs.Currency(this.state.item.total)}</div>
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
								<Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
									
									{(this.state.item.venta_producto || []).map((item, key) => (
										<Grid key={key} item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
											<Card>
												<CardContent className={'padding-10'}>
													<Typography component={'div'}
													            className={'margin-0 padding-0 px-10'}>
														{key + 1}.- {item.venta_producto} {item.venta_descripcion} ({item.requerimientos})
													</Typography>
												</CardContent>
											</Card>
										</Grid>
									))}
								
								</Grid>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
								<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
									Fecha registro: {DateFormat.FechaHoraFormatText(this.state.item.created_at)}<br/>
									{this.state.cotizacion ? "Fecha cotización autorizado" : "Fecha alta"}: {DateFormat.FormatText(this.state.item.fecha_alta)}
								</Typography>
							</Grid>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'left'}>
								{this.props.tipo_vista === "cotizacion" ? (
									<KeyboardDatePicker
										format={'dd/MM/yyyy'} fullWidth clearable
										inputProps={{readOnly: true}}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										label="Fecha entrega"
										value={this.state.item.fecha_entrega}
										onChange={(date) => {
											this.handleChange('item', 'fecha_entrega', date);
										}}
										clearLabel={'Limpiar'}
										okLabel={'Aceptar'}
										cancelLabel={'Cancelar'}
									/>
								) : (
									<Typography component={'div'} className={'margin-0 padding-0 px-12'}>
										Fecha entrega: {DateFormat.FormatText(this.state.item.fecha_entrega)}
									</Typography>
								)}
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.closeModal()} color="primary">
							Cerrar
						</Button>
						
						<Button onClick={() => this.save()} color="primary">
							Aprobar
						</Button>
					
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalAprobarCotizacion.propTypes = {
	id_venta: PropTypes.number.isRequired,
	open: PropTypes.bool,
	item: PropTypes.object.isRequired,
	icono: PropTypes.element.isRequired,
	watchAprobarCotizacion: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	selectCloseModal: PropTypes.func.isRequired,
	tipo_vista: PropTypes.string.isRequired,
};

export default ModalAprobarCotizacion;
