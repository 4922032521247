import React, {Component, Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import icon_xml from '../../../../assets/img/icons/xml.svg';
import icon_pdf from '../../../../assets/img/icons/pdf.svg';
import icon_logo from '../../../../assets/img/logo.svg';

import ModalCargaMasivaCFDI from "./ModalCargaMasivaCFDI";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {Description, PictureAsPdf} from "@material-ui/icons";
import * as PropTypes from "prop-types";
import ModalCargaMasivoPDFCFDI from "./ModalCargaMasivoPDFCFDI";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";


class ModalMasivoCFDI extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: props.open || false
			},
			archivos_nube: (props.item || []).archivos,
		};
		
		if (props.open) {
			this.open();
		}
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.props.selectCloseModal();
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
			archivos_nube: (props.item || []).archivos,
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	RefreshClose = () => {
		this.closeModal();
		this.props.RefreshList();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path, null, null, null, null, 2).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		
		const bg_carta = {
			backgroundImage: `url(${icon_xml})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		const bg_ticket = {
			backgroundImage: `url(${icon_pdf})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
			const icon_ = {
			backgroundImage: `url(${icon_logo})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		
		return (
			<div>
				
				{/*{
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.open()}
						/>
					</Fragment>
				}*/}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Typography align={'left'}>Subir CFDI's</Typography>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={3}>
							
							<Grid item xs={12}>
								<Typography align={'center'}>Selecciona una opción:</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								<ModalCargaMasivaCFDI
									tipo={'add'}
									item={this.props.item}
									project={this.state.project}
									listDropbox={this.props.listDropbox}
									componente={
										<div className={'borde-punteado-gray-2 br-10 cursor-pointer text-center'} style={{borderRadius: '5%'}}>
											<div style={bg_carta} className={'h-70-px margin-15'}/>
											<Typography className={'margin-10'}>
												Subir masivo xml
											</Typography>
										</div>
									}
									RefreshList={this.RefreshClose}
									showSnackBars={this.props.showSnackBars}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<div className={'vertical-inline w-100 h-100'}>
									<div className={'w-100'} style={icon_}>
									
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								<ModalCargaMasivoPDFCFDI
									tipo={'add'}
									item={this.props.item}
									project={this.state.project}
									listDropbox={this.props.listDropbox}
									componente={
										<div className={'borde-punteado-gray-2 br-10 cursor-pointer text-center'} style={{borderRadius: '5%'}}>
											<div style={bg_ticket} className={'h-70-px margin-15'}/>
											<Typography className={'margin-10'}>
												Subir masivo pdf
											</Typography>
										</div>
									}
									RefreshList={this.RefreshClose}
									showSnackBars={this.props.showSnackBars}
								/>
							</Grid>
							
							{this.state.archivos_nube.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<Table className={' desing-acresco'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'left'}>ID</TableCell>
												<TableCell component="th" align={'center'}>Archivo(s)</TableCell>
												<TableCell component="th" align={'left'}>Uuid</TableCell>
												<TableCell component="th" align={'left'}>Folio</TableCell>
												<TableCell component="th" align={'left'}>Serie</TableCell>
												<TableCell component="th" align={'left'}>Fecha XML</TableCell>
												<TableCell component="th" align={'left'}>Fecha PDF</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.archivos_nube.map((item, key) => (
												<Fragment key={key}>
													<TableRow key={key}>
														<TableCell align={'left'}>
															<Typography style={{fontWeight: 'bold'}}>
																{item.id_venta}
															</Typography>
														</TableCell>
														<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Archivo">
																<IconButton aria-label="Ver Archivo" onClick={() => this.exportar(item.venta_archivo)}>
																	<Description style={{color: '#e18c02'}}/>
																</IconButton>
															</Tooltip>
															{item.venta_archivo_pdf ? (
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Archivo">
																	<IconButton aria-label="Ver Archivo" onClick={() => this.exportar(item.venta_archivo_pdf)}>
																		<PictureAsPdf style={{color: '#901C30'}}/>
																	</IconButton>
																</Tooltip>
															) : null }
														</TableCell>
														<TableCell align={'left'}>
															{item.uuid}
														</TableCell>
														<TableCell align={'left'}>
															{item.folio}
														</TableCell>
														<TableCell align={'left'}>
															{item.serie}
														</TableCell>
														<TableCell align={'left'}>
															{item.fecha_xml}
														</TableCell>
														<TableCell align={'left'}>
															{item.fecha_pdf ? item.fecha_pdf:'Sin registrar'}
														</TableCell>
													</TableRow>
												</Fragment>
											))}
										</TableBody>
									</Table>
								</Grid>
							) : null}
						
						</Grid>
						
						<br/>
					
					</DialogContent>
					
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{'Cancelar'}
							</Button>
						</Fragment>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalMasivoCFDI.propTypes = {
	componente: PropTypes.element,
	listDropbox: PropTypes.array,
	item: PropTypes.object.isRequired,
	open: PropTypes.bool,
	RefreshList: PropTypes.element,
	showSnackBars: PropTypes.func.isRequired,
	selectCloseModal: PropTypes.func.isRequired,
};

export default ModalMasivoCFDI;
