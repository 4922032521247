import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import {EnteroSolo} from "../../../../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";


class ModalAtributoRroducto extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			modal: {
				open: false
			},
			index: null,
			requerimiento_adicional: '',
			costo_adicional: ''
		};
	}
	
	openModal = () => {
		const {index} = this.props;
		this.setState({
			modal: {
				open: true
			},
			index: index,
			requerimiento_adicional: '',
			costo_adicional: ''
		});
		console.log(this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	save = () => {
		try {
			
			if (!this.state.requerimiento_adicional) {
				throw Object({
					status: false,
					mensaje: "Escribe el requerimiento adicional"
				});
			}
			
			this.props.agregar_producto_atributo(this.state.index, {
				requerimiento_adicional: this.state.requerimiento_adicional,
				costo_adicional: this.state.costo_adicional ? Number(this.state.costo_adicional) : 0
			});
			
			this.closeModal();
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	render() {
		
		return (
			<Fragment>
				
				<div className={'vertical-inline v-center'} onClick={() => this.openModal()}>
					{this.props.icono}
				</div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						Requerimiento
					</DialogTitle>
					
					<DialogContent>
						
						<TextField
							required
							fullWidth
							type="text"
							margin="none"
							onChange={(e) => {
								this.setState({
									requerimiento_adicional: e.target.value
								});
							}}
							label={'Requerimiento adicional'}
							value={this.state.requerimiento_adicional}
						/>
						
						<br/>
						<br/>
						
						<TextField
							fullWidth
							type="text"
							margin="none"
							onKeyPress={EnteroSolo}
							onChange={(e) => {
								this.setState({
									costo_adicional: e.target.value
								});
							}}
							label={'Costo adicional'}
							value={this.state.costo_adicional}
						/>
					
					</DialogContent>
					
					<DialogActions>
						<Button onClick={() => this.closeModal()} color="primary">
							Cerrar
						</Button>
						<Button onClick={() => this.save()} color="primary">
							Aceptar
						</Button>
					</DialogActions>
				
				</Dialog>
			
			
			</Fragment>
		);
	}
}


ModalAtributoRroducto.propTypes = {
	index: PropTypes.number.isRequired,
	icono: PropTypes.element.isRequired,
	agregar_producto_atributo: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired
};

export default ModalAtributoRroducto;
