import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const IngresosService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ingresos_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_producto: form.id_producto,
			id_cat_categoria: form.id_cat_categoria,
			clave: form.clave,
			producto: form.producto,
			descripcion: form.descripcion,
			cantidad: form.cantidad,
			precio_unitario: form.precio_unitario,
			codigo_descuento: form.codigo_descuento,
			imagen_archivo: form.imagen_archivo,
			imagen_formato: form.imagen_formato,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ingresos_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_producto: form.id_producto,
			id_cat_categoria: form.id_cat_categoria,
			clave: form.clave,
			producto: form.producto,
			descripcion: form.descripcion,
			cantidad: form.cantidad,
			precio_unitario: form.precio_unitario,
			codigo_descuento: form.codigo_descuento,
			imagen_archivo: form.imagen_archivo,
			imagen_formato: form.imagen_formato,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ingresos_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_producto) => {
		let params = {
			id_producto: id_producto
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ingresos_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
