import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {ReactLocalStorageService} from "../../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../../settings/Config/Config";
import Card from "@material-ui/core/Card";
import pakageIcon from "../../../../../assets/img/icon-catalogo/lista_paquete.svg";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {DateFormat} from "../../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../../../../settings/General/General";
import ModalPaquetes from "./ModalPaquetes";
import Button from "@material-ui/core/Button";
import {PaqueteService} from "../../../../../services/_Cat/PaqueteService/PaqueteService";


class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el paquete ${item.nombre}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				PaqueteService.Eliminar(item.id_cat_paquete).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Grid container spacing={2}>
				
				{lista.map((item, key) => (
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={'columnContainer'}>
						<div className={'w-100 h-100'}>
							<Card className={item.selected ? 'border-selected h-100' : 'h-100'}>
								<div className={'padding-10 h-100-70-px'}>
									<div className={'vertical-inline w-100'}>
										<div className={'v-center w-30 text-left'}>
											<p className={'px-13'} style={{margin: '0px', color: 'gray', marginTop: '3px'}}><strong>#{item.codigo}</strong></p>
										</div>
										<div className={'v-center w-70 text-right'}>
											<p className={'px-13'} style={{margin: '0px', color: '#b9996f', marginTop: '3px'}}>{DateFormat.FormatText(item.fecha_periodo_inicio) + ' | ' + DateFormat.FormatText(item.fecha_periodo_fin)}</p>
										</div>
									</div>
									
									<div className={'padding-15-T'}>
										<p className={'px-18'} style={{margin: 0, color: '#961f1f', fontWeight: 'bold', textAlign: 'center'}}>{item.nombre}</p>
									</div>
									
									<img className={'v-center'} src={pakageIcon} alt="add" width={100} height={100} style={{opacity: 0.7}}/>
									
									<div className={'padding-10 padding-15-T'}>
										<p className={'px-20'} style={{margin: '0px', color: 'red', fontWeight: 'bold', marginTop: '3px'}}>${FieldsJs.Currency(item.precio_total)}</p>
									</div>
									
									
									<div className={'padding-10 padding-15-B'}>
										<div className={'vertical-inline w-100 padding-10-T'}>
											<div className={'v-center w-30 text-left'}>
												<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Cantidad</strong></p>
											</div>
											<div className={'v-center w-40 text-left'}>
												<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Producto</strong></p>
											</div>
											<div className={'v-center w-30 text-right'}>
												<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Precio</strong></p>
											</div>
										</div>
										{item.venta_producto.map((pro, keyp) => (
											<Fragment>
												<Divider/>
												<div className={'vertical-inline w-100 padding-5-T padding-5-B'}>
													<div className={'v-center w-30 text-left'}>
														<p className={'px-13'} style={{margin: '0px'}}>{pro.venta_cantidad}</p>
													</div>
													<div className={'v-center w-40 text-left'}>
														<p className={'px-13'} style={{margin: '0px'}}>{pro.venta_producto}</p>
													</div>
													<div className={'v-center w-30 text-right'}>
														<p className={'px-13'} style={{margin: '0px'}}>${FieldsJs.Currency(pro.venta_precio)}</p>
													</div>
												</div>
											</Fragment>
										))}
									</div>
								</div>
								
								<div className={'padding-10 h-40-px'}>
									<div className={'vertical-inline w-100 text-right'}>
										<div className={'w-100 text-right'}>
											<ModalPaquetes
												tipo={'edit'}
												item={item}
												componente={
													<Button
														variant="contained"
														style={{
															backgroundColor: "#3f51b5",
															color: "white",
															textTransform: "none",
															padding: "5px",
															fontSize: "12px",
														}}
													>
														{"Editar"}
													</Button>
												}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</div>
								
								{/*{item.venta_producto.map((pro, keyp) => (
										<Fragment>
											<Divider/>
											
											<div className={'vertical-inline w-100 padding-10-T'}>
												<div className={'v-center w-40 text-left'}>
													<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Producto: </strong></p>
												</div>
												<div className={'v-center w-60 text-left'}>
													<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}>{pro.venta_producto}</p>
												</div>
											</div>
											
											<div className={'vertical-inline w-100'}>
												<div className={'v-center w-40 text-left'}>
													<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Descripción: </strong></p>
												</div>
												<div className={'v-center w-60 text-left'}>
													<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}>{pro.venta_descripcion}</p>
												</div>
											</div>
											
											<div className={'vertical-inline w-100'}>
												<div className={'v-center w-40 text-left'}>
													<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Cantidad: </strong></p>
												</div>
												<div className={'v-center w-60 text-left'}>
													<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}>{pro.venta_cantidad}</p>
												</div>
											</div>
											
											<div className={'vertical-inline w-100'}>
												<div className={'v-center w-40 text-left'}>
													<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Tipo Requerimiento: </strong></p>
												</div>
												<div className={'v-center w-60 text-center'}>
													{pro.venta_producto_tipo_requerimiento.map((adi, keya) => (
														<p className={'px-13'} style={{backgroundColor: '#a0ccc7', padding: '0px 5px 0px 5px', borderRadius: 10, margin: '0px', marginTop: '3px'}}>
															{adi.tipo_requerimiento}
														</p>
													))}
												</div>
											</div>
											
											<div className={'vertical-inline w-100 padding-10-B'}>
												<div className={'v-center w-40 text-left'}>
													<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Requerimiento Adicional: </strong></p>
												</div>
												<div className={'v-center w-60 text-center'}>
													{pro.venta_producto_requerimiento_adicional.map((adi, keya) => (
														<p className={'px-13'} style={{backgroundColor: '#ccc', padding: '0px 5px 0px 5px', borderRadius: 10, margin: '0px', marginTop: '3px'}}>
															{adi.requerimiento_adicional} ${FieldsJs.Currency(adi.costo_adicional)}
														</p>
													))}
												</div>
											</div>
										
										</Fragment>
									))}*/}
							</Card>
						</div>
					</Grid>
				))}
				
				{/*<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>
								ID
							</TableCell>
							<TableCell component="th" align={'left'}>Nombre</TableCell>
							<TableCell component="th" align={'left'}>Código</TableCell>
							<TableCell component="th" align={'left'}>Periodo</TableCell>
							<TableCell component="th" align={'center'}>Activo</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<TableRow key={key}>
								<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
									{item.id_cat_paquete}
								</TableCell>
								<TableCell align={'left'}>{item.nombre}</TableCell>
								<TableCell align={'left'}>{item.codigo ? item.codigo:'Sin registrar'}</TableCell>
								<TableCell align={'left'}>{item.fecha_periodo_inicio ? DateFormat.FormatText(item.fecha_periodo_inicio) +' | '+ DateFormat.FormatText(item.fecha_periodo_fin):'Sin registrar'}</TableCell>
								<TableCell align={'center'}>
									{item.activo === 1 ? (
										<div style={{
											width: "50px",
											color: "white",
											borderRadius: "3px",
											padding: "2px 10px",
											background: "darkgreen",
											display: "inline-grid",
										}}>Activo</div>
									) : (
										<div style={{
											width: "50px",
											color: "white",
											borderRadius: "3px",
											padding: "2px 10px",
											background: "#901C30",
											display: "inline-grid",
										}}>Inactivo</div>
									)}
								</TableCell>
								<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
									<div className={'w-auto vertical-inline'}>
										<div className={'v-center'}>
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
												<IconButton aria-label="Eliminar"
												            onClick={() => this.eliminar(item)}>
													<DeleteOutlined/>
												</IconButton>
											</Tooltip>
										</div>
										<div className={'v-center'}>
											<ModalPromocion
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalPromocion
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>*/}
			</Grid>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
