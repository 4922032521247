import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {DateRangeOutlined, SearchOutlined, PersonOutlined, LocalParking, Description, AttachMoney} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";

import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {CatService} from "../../../../services/_Cat/CatService/CatService";


class FiltrosCotizador extends Component {
	state = {};
	
	constructor() {
		super();
		this.state = {
			producto: '',
			descripcion: '',
			costo_unitario: '',
			precio_individual: '',
			costo_mayoreo: '',
			precio_mayoreo: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.producto = this.state.producto || undefined;
		filtro.descripcion = this.state.descripcion || undefined;
		filtro.costo_unitario = this.state.costo_unitario || undefined;
		filtro.precio_individual = this.state.precio_individual || undefined;
		filtro.costo_mayoreo = this.state.costo_mayoreo || undefined;
		filtro.precio_mayoreo = this.state.precio_mayoreo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<LocalParking className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="producto"
									onChange={this.handleChange}
									label="Producto"
									autoComplete={'off'}
									value={this.state.producto}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Description className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="descripcion"
									onChange={this.handleChange}
									label="Descripción"
									autoComplete={'off'}
									value={this.state.descripcion}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<AttachMoney className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="costo_unitario"
									onChange={this.handleChange}
									label="Costo Unitario"
									autoComplete={'off'}
									value={this.state.costo_unitario}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<AttachMoney className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="precio_individual"
									onChange={this.handleChange}
									label="Precio Individual"
									autoComplete={'off'}
									value={this.state.precio_individual}
								/>
							</Grid>
						</Grid>
					</Grid>
				
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<AttachMoney className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="costo_mayoreo"
									onChange={this.handleChange}
									label="Costo Mayoreo"
									autoComplete={'off'}
									value={this.state.costo_mayoreo}
								/>
							</Grid>
						</Grid>
					</Grid>
				
					<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<AttachMoney className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="precio_mayoreo"
									onChange={this.handleChange}
									label="Precio Mayoreo"
									autoComplete={'off'}
									value={this.state.precio_mayoreo}
								/>
							</Grid>
						</Grid>
					</Grid>
				
					
					{/*<Grid item xs={6} sm={6} md={4} lg={2} xl={2} align={'left'}>
						<FormControlLabel
							control={
								<Checkbox type="checkbox" name='ordenar_fecha_alta'
								          checked={this.state.ordenar_fecha_alta}
								          onChange={this.handleChange} value="activo" color="primary"
								/>
							}
							label={'Ordenar por fecha de alta'}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<FormControlLabel
							control={
								<Checkbox type="checkbox" name='ordenar_fecha_compra'
								          checked={this.state.ordenar_fecha_compra}
								          onChange={this.handleChange} value="activo" color="primary"
								/>
							}
							label={'Ordenar por fecha de compra'}
						/>
					</Grid>*/}
					
					<Grid item xs={6} sm={6} md={4} lg={10} xl={10} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
					
				</Grid>
			</Fragment>
		);
	}
}

FiltrosCotizador.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosCotizador;
