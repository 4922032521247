import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import {Folder} from "@material-ui/icons";


class ModalFichaTecnica extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	
	render() {
		const is_root = this.is_root();
		let {item} = this.props;
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogTitle>
						Ficha Técnica
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={0} className={'cotizador_fondo'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'} style={{backgroundColor: 'rgba(71,71,71,0.4)'}}>
								<p className={'margin-0 text-white'}>PRODUCTO</p>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<p className={'margin-0 padding-10-T'} style={{fontWeight: 'bold'}}>{item.cotizador_producto} <span style={{fontWeight: '300'}}>{item.descripcion ? ' - ' + item.descripcion : ''}</span></p>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'} style={{backgroundColor: 'rgba(71,71,71,0.4)', marginTop: '15px'}}>
								<p className={'margin-0 text-white'}>INSUMOS</p>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 vertical-inline'}>
									<div className={'v-center w-45'} style={{fontWeight: 'bold'}}>
										<p className={'margin-0 px-12'}>Insumo</p>
									</div>
									<div className={'v-center w-15'} style={{fontWeight: 'bold'}}>
										<p className={'margin-0 px-12'}>Cantidad</p>
									</div>
									<div className={'v-center w-20'} style={{fontWeight: 'bold'}}>
										<p className={'margin-0 px-12'}>Unidad Medida</p>
									</div>
									<div className={'v-center w-20'} style={{fontWeight: 'bold'}}>
										<p className={'margin-0 px-12'}>Precio Unitario</p>
									</div>
								</div>
								{item.insumos.map((insumo, key) => (
									<div className={'w-100 vertical-inline padding-10-T padding-5-B'} style={{borderBottom: '1px solid rgba(71,71,71,0.4)'}}>
										<div className={'w-45 text-left'}>
											<p className={'margin-0'}>{insumo.insumo}</p>
										</div>
										<div className={'w-15 text-left'}>
											<p className={'margin-0'}>{insumo.cantidad}</p>
										</div>
										<div className={'w-20 text-left'}>
											<p className={'margin-0'}>{insumo.unidad_medida ? insumo.unidad_medida: '-'}</p>
										</div>
										<div className={'w-20 text-left'}>
											<p className={'margin-0'}>${FieldsJs.Currency(insumo.precio_unitario)}</p>
										</div>
									</div>
								))}
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<p className={'margin-0 padding-10-T'}>Total: <span style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.total)}</span></p>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'} style={{backgroundColor: 'rgba(71,71,71,0.4)', marginTop: '15px'}}>
								<p className={'margin-0 text-white'}>COSTOS</p>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 vertical-inline padding-10-T padding-5-B'} style={{borderBottom: '1px solid rgba(71,71,71,0.4)'}}>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>Porcentaje | Importe</p>
									</div>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>{item.porcentaje === 1 ? '%' + item.cantidad : '$' + FieldsJs.Currency(item.cantidad)}</p>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 vertical-inline padding-10-T padding-5-B'} style={{borderBottom: '1px solid rgba(71,71,71,0.4)'}}>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>Costo Unitario:</p>
									</div>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.total)}</p>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 vertical-inline padding-10-T padding-5-B'} style={{borderBottom: '1px solid rgba(71,71,71,0.4)'}}>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>Costo Mayoreo:</p>
									</div>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.costo_mayoreo)}</p>
									</div>
								</div>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'} style={{backgroundColor: 'rgba(71,71,71,0.4)', marginTop: '15px'}}>
								<p className={'margin-0 text-white'}>PRECIOS SUGERIDOS</p>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 vertical-inline padding-10-T padding-5-B'} style={{borderBottom: '1px solid rgba(71,71,71,0.4)'}}>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>Porcentaje | Importe:</p>
									</div>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>{item.porcentaje_individual === 1 ? '%' + item.cantidad_individual : '$' + FieldsJs.Currency(item.cantidad_individual)}</p>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 vertical-inline padding-10-T padding-5-B'} style={{borderBottom: '1px solid rgba(71,71,71,0.4)'}}>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>Precio Individual:</p>
									</div>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.precio_individual)}</p>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 vertical-inline padding-10-T padding-5-B'} style={{borderBottom: '1px solid rgba(71,71,71,0.4)'}}>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>Porcentaje | Importe:</p>
									</div>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>{item.porcentaje_mayoreo === 1 ? '%' + item.cantidad_mayoreo : '$' + FieldsJs.Currency(item.cantidad_mayoreo)}</p>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100 vertical-inline padding-10-T padding-5-B'}>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'}>Precio Mayoreo:</p>
									</div>
									<div className={'w-50 text-left'}>
										<p className={'margin-0'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.precio_mayoreo)}</p>
									</div>
								</div>
							</Grid>
							
							{item.archivos ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<p className={'margin-0 margin-15-T'}>Archivos: </p>
									<div className={'w-100 vertical-inline padding-10-T padding-5-B text-center borde-punteado-gray-1'}>
										{item.archivos.map((item2, key) => (
											<div className={'w-25 text-center cursor-pointer'}>
												{item2.thumbnail ? <img src={`data:image/jpeg;base64,${item2.thumbnail}`} width={64} alt={'imagen'}/> : <Folder style={{fontSize: '64px', color: 'rgb(142, 30, 50)'}}/>}
											</div>
										))}
									</div>
								</Grid>
							) : null}
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalFichaTecnica.propTypes = {
	item: PropTypes.object.isRequired,
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalFichaTecnica;
