import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {CloudUploadOutlined, DeleteOutlined, EditOutlined, SearchOutlined} from '@material-ui/icons';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../../../settings/General/General";
import ModalCompras from "./ModalCompras";
import ModalComprobantes from "./ModalComprobantes";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-mark'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>No. compra</TableCell>
							<TableCell component="th" align={'left'}>Usuario</TableCell>
							<TableCell component="th" align={'left'}>Proveedor</TableCell>
							<TableCell component="th" align={'left'}>Descripción</TableCell>
							<TableCell component="th" align={'left'}>Forma de pago</TableCell>
							<TableCell component="th" align={'left'}>Fecha compra</TableCell>
							<TableCell component="th" align={'left'}>Fecha alta</TableCell>
							<TableCell component="th" align={'left'}>Total</TableCell>
							<TableCell component="th" align={'center'}>Subir comprobante</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
							return (
								<TableRow key={key}>
									<TableCell align={'center'}
									           className={'padding-20-L padding-20-R'}>{item.numero_compra}</TableCell>
									<TableCell align={'left'}>{item.usuario_nombre_completo}</TableCell>
									<TableCell align={'left'}>{item.proveedor_nombre_comercial}</TableCell>
									<TableCell align={'left'}>{item.descripcion}</TableCell>
									<TableCell align={'left'}>{item.forma_pago}</TableCell>
									<TableCell align={'left'}>{DateFormat.FormatText(item.fecha_compra)}</TableCell>
									<TableCell align={'left'}>{DateFormat.FormatText(item.fecha_alta)}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.total)}</TableCell>
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										<div className={'w-auto vertical-inline'}>
											<div className={'v-center'}>
												<ModalComprobantes
													id_compra={item.id_compra}
													item={item}
													icono={(
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title={(item.compra_comprobante.length > 0 ? "Ver o subir comprobantes" : "Subir comprobante")}>
															<IconButton aria-label={(item.compra_comprobante.length > 0 ? "Ver o subir comprobantes" : "Subir comprobante")}>
																<CloudUploadOutlined style={{color: (item.compra_comprobante.length > 0 ? "green" : "gray")}}/>
															</IconButton>
														</Tooltip>
													)}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
										</div>
									</TableCell>
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										
										<div className={'w-auto vertical-inline'}>
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
													<IconButton aria-label="Eliminar"
													            onClick={() => this.props.delete(item)}>
														<DeleteOutlined/>
													</IconButton>
												</Tooltip>
											</div>
											<div className={'v-center'}>
												<ModalCompras
													id_compra={item.id_compra}
													item={item}
													tipo={'edit'}
													icono={(
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title="Editar">
															<IconButton aria-label="Editar">
																<EditOutlined/>
															</IconButton>
														</Tooltip>
													)}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
											<div className={'v-center'}>
												<ModalCompras
													id_compra={item.id_compra}
													item={item}
													tipo={'view'}
													icono={(
														<Tooltip TransitionComponent={Zoom} placement={"top"}
														         title="Detalles">
															<IconButton aria-label="Detalles">
																<SearchOutlined/>
															</IconButton>
														</Tooltip>
													)}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
										</div>
									
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	delete: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
