import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from "@material-ui/core/Button";

import {EnteroSolo, FieldsJs, FloatSolo, MinMax} from '../../../../../settings/General/General';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import {DateRangeOutlined, DeleteOutlined} from '@material-ui/icons';

import agregar_producto_requerimiento_adicional from '../../../../../assets/img/icons/agregar-producto-atributo.svg';
import Input from "@material-ui/core/Input";
import ModalAtributoRroducto from "./ModalAtributoRroducto";
import BtnMasAdd from "../../../../Include/MiniComponents/BtnMasAdd"
import VistaVacia from "../../../../Include/VistaVacia/VistaVacia";
import NativeSelect from "@material-ui/core/NativeSelect";
import MDDatePicker from "../../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../../settings/DateFormat/DateFormat";
import IconButton from "@material-ui/core/IconButton";

const styles = {
	root: {
		background: "transparent",
		color: "black"
	},
	input: {
		color: "black"
	},
	input_number: {
		color: "black",
		textAlign: "right",
		alignText: "right",
	}
};


class ContenidoOrdenVenta extends Component {
	
	getAtrubutoOpcionRequerimineto = (itemTR) => {
		let info = ((itemTR.cat_opcion_requerimiento || []).find(value => value.id_cat_opcion_requerimiento === (Number(itemTR.id_cat_opcion_requerimiento))) || {})
		return !!info.opcion_requerimiento ? `(${info.opcion_requerimiento})` : ``
	}
	
	render() {
		
		const btn_agregar_producto_requerimiento_adicional = {
			backgroundImage: `url(${agregar_producto_requerimiento_adicional})`,
			opacity: 1
		};
		
		const desglose = this.props.updateDesglose();
		
		const {classes} = this.props;
		
		let disabledInputVenta = !!this.props.id_venta;
		
		let disabledInputVentaEditar = !!this.props.id_venta && !this.props.editar;
		
		return (
			<Fragment>
				
				<section className={'w-auto padding-20-L padding-20-R'}>
					
					<Grid container spacing={3} direction="row" justify="flex-start" alignItems="flex-start" className={'h-100 margin-0-B margin-0-T'}>
						
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-100'}>
							
							<Grid container spacing={2}>
								
								
								<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
									<TextField
										multiline
										fullWidth
										type="text"
										margin="none"
										className={classes.root}
										label={"Código"}
										name={'codigo'}
										onChange={this.props.handleChange}
										value={this.props.form.codigo}
										disabled
									/>
								</Grid>
								
								<Grid item xs={12} sm={12} md={8} lg={8} xl={8} align={'left'}>
									<div>
										<MDDatePicker
											type={'range'}
											component={(
												<TextField
													fullWidth
													label="Periodo del paquete"
													value={(this.props.form.fecha_periodo_inicio && this.props.form.fecha_periodo_fin) ? (DateFormat.FormatText(this.props.form.fecha_periodo_inicio) + " al " + DateFormat.FormatText(this.props.form.fecha_periodo_fin)) : ''}
													readOnly
													InputProps={{
														endAdornment: (
															<InputAdornment>
																<IconButton aria-label="Periodo del paquete">
																	<DateRangeOutlined/>
																</IconButton>
															</InputAdornment>
														)
													}}
												/>
											)}
											startDate={this.props.form.fecha_periodo_inicio}
											endDate={this.props.form.fecha_periodo_fin}
											onChange={(range) => {
												this.props.updatePeriodo(range.startDate, range.endDate);
											}}
										/>
									</div>
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<TextField
										multiline
										fullWidth
										type="text"
										margin="none"
										className={classes.root}
										label={"Nombre del paquete"}
										name={'nombre'}
										onChange={this.props.handleChange}
										value={this.props.form.nombre}
										disabled={disabledInputVentaEditar}
									/>
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'padding-13-B'}>
									
									<div className={'borde-punteado-gray-1 h-100-2-px'}>
										<Card className={'padding-0 h-100'}>
											<CardContent className={'padding-0 h-100'}>
												
												<div style={{
													overflowY: "auto",
													overflowX: "hidden",
													height: "calc(100% - 10px)",
													padding: "15px 15px 5px 15px"
												}}>
													<Fragment>
														<Grid container spacing={1} justify="flex-start"
														      alignItems="flex-start">
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<TextField
																	multiline
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Producto"
																	}}
																	
																	name={'_producto'}
																	onChange={this.props.handleChange}
																	value={this.props.form._producto}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<TextField
																	multiline
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Descripción"
																	}}
																	
																	name={'_descripcion'}
																	onChange={this.props.handleChange}
																	value={this.props.form._descripcion}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																<TextField
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Cantidad"
																	}}
																	onKeyPress={EnteroSolo}
																	
																	name={'_cantidad'}
																	onChange={this.props.handleChange}
																	value={this.props.form._cantidad}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																<TextField
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	InputProps={{
																		inputProps: {
																			className: classes.input,
																			placeholder: "0"
																		},
																		startAdornment: <InputAdornment
																			position="start">$</InputAdornment>
																	}}
																	onKeyPress={FloatSolo}
																	
																	name={'_precio_unitario'}
																	onChange={this.props.handleChange}
																	value={this.props.form._precio_unitario}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																<NativeSelect
																	name="_id_cat_grupo"
																	value={this.props.form._id_cat_grupo}
																	onChange={(e) => {
																		this.props.handleChange(e);
																		this.props.getCatTipoRequerimiento(e)
																	}}
																	inputProps={{
																		'aria-label': '_id_cat_grupo',
																	}}
																	disabled={disabledInputVentaEditar}
																>
																	<option value="">Selecciona un grupo
																	</option>
																	{this.props.cat_grupo.map(option => (
																		<option
																			key={option.id_cat_grupo}
																			value={option.id_cat_grupo}
																			children={option.grupo}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															{this.props.cat_tipo_requerimiento.length > 0 ? (
																<Fragment>
																	{this.props.cat_tipo_requerimiento.map((item, key) => (
																		<Grid key={key} item xs={6} sm={6} md={6} lg={6} xl={6}>
																			
																			<Grid container spacing={0} justify="flex-end" alignItems="flex-end">
																				<Grid item className={(item.con_costo_adicional === 1 ? 'w-100-50-px' : 'w-100')}>
																					<FormGroup row>
																						<FormControlLabel
																							className={'h-30-px px-12 text-left'}
																							control={
																								<Checkbox
																									fontSize="small"
																									type="checkbox"
																									color="primary"
																									name={'tipo_requerimiento__' + key}
																									onChange={(e) => this.props.handleChange(e, 'cat_tipo_requerimiento', 'checked')}
																									checked={!!item.checked}
																									disabled={disabledInputVentaEditar}
																								/>
																							}
																							label={item.tipo_requerimiento}
																							labelPlacement="end"
																						/>
																					</FormGroup>
																				</Grid>
																				{item.con_costo_adicional === 1 ? (
																					<Grid item className={'w-50-px'}>
																						<Input placeholder="0.00"
																						       className={'px-14 text-right'}
																						       inputProps={{
																							       'aria-label': 'Costo adicional',
																						       }}
																						       name={'costo_adicional__' + key}
																						       onChange={(e) => this.props.handleChange(e, 'cat_tipo_requerimiento', 'costo_adicional')}
																						       disabled={disabledInputVentaEditar || !item.checked}
																						       value={item.costo_adicional}
																						/>
																					</Grid>
																				) : ""}
																				{FieldsJs.Array(item.cat_opcion_requerimiento) ? (
																					<Grid item xs={12}>
																						<RadioGroup
																							row
																							className={'h-25-px'}
																							aria-label="position"
																							name={'id_cat_opcion_requerimiento__' + key}
																							value={Number(item.id_cat_opcion_requerimiento)}
																							onChange={(e) => this.props.handleChange(e, 'cat_tipo_requerimiento', 'id_cat_opcion_requerimiento')}
																						>
																							{item.cat_opcion_requerimiento.map((corItem, corkey) => (
																								<FormControlLabel
																									className={'h-25-px px-10'}
																									key={corkey}
																									labelPlacement="end"
																									control={<Radio color="primary"/>}
																									value={corItem.id_cat_opcion_requerimiento}
																									label={corItem.opcion_requerimiento}
																									disabled={!!!item.checked || disabledInputVentaEditar}
																								/>
																							))}
																						</RadioGroup>
																					</Grid>
																				) : ""}
																			</Grid>
																		
																		</Grid>
																	))}
																</Fragment>
															) : (
																<Fragment>
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																		<VistaVacia
																			padding={'20px'}
																			height={'60px'}
																			width={'80px'}
																			numero={0}
																			mensaje={'Selecciona un grupo para mostrar los requerimintos'}
																		/>
																	</Grid>
																</Fragment>
															)}
															
															{this.props.producto_requerimiento_adicional.map((item, key) => (
																<Grid key={key} item xs={12} sm={12} md={12} lg={12}
																      xl={12}>
																	
																	<Grid container spacing={0}
																	      justify="center"
																	      alignItems="center">
																		
																		<Grid item className={'w-30-px'}
																		      align={'left'}>
																			<div className={'cursor-pointer'}
																			     style={{
																				     padding: '6px',
																				     background: 'red',
																				     height: '6px',
																				     width: '6px',
																				     lineHeight: '6px',
																				     color: 'white',
																				     borderRadius: '100%',
																				     fontSize: '12px',
																			     }}
																			     onClick={() => this.props.eliminar_producto_requerimiento_adicional(key)}>x
																			</div>
																		</Grid>
																		
																		<Grid item className={'w-100-80-px'}>
																			<Typography
																				className={'text-left px-14'}>
																				{item.requerimiento_adicional}
																			</Typography>
																		</Grid>
																		
																		<Grid item className={'w-50-px'}>
																			<Input placeholder="0.00"
																			       className={'px-14 text-right'}
																			       inputProps={{
																				       'aria-label': 'Costo adicional',
																			       }}
																			       value={item.costo_adicional}
																			       name={'costo_adicional__' + key}
																			       onChange={(e) => this.props.handleChange(e, 'producto_requerimiento_adicional', 'costo_adicional')}
																			       disabled={disabledInputVentaEditar}
																			/>
																		</Grid>
																	
																	</Grid>
																
																</Grid>
															))}
															
															{!disabledInputVentaEditar ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																	
																	<div className={'row-flex'}>
																		<div className="w-100-40-px">
																			<TextField
																				fullWidth
																				type="text"
																				margin="none"
																				className={classes.root}
																				inputProps={{
																					className: classes.input,
																					placeholder: "Otros requerimientos",
																				}}
																				
																				name={'_otro_requerimiento'}
																				onChange={this.props.handleChange}
																				value={this.props.form._otro_requerimiento}
																				disabled={disabledInputVentaEditar}
																			/>
																		</div>
																		<div className="w-40-px vertical-inline">
																			<div className={'w-100 v-end'}
																			     align={'right'}>
																				<div
																					className={'w-30-px h-30-px b-r-100 bg-img-cover-x-center-y-center cursor-pointer'}
																					onClick={this.props.agregar_producto_requerimiento_adicional}
																					style={btn_agregar_producto_requerimiento_adicional}/>
																			</div>
																		</div>
																	</div>
																
																</Grid>
															) : ''}
															
															{!disabledInputVentaEditar ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
																      align={'center'} className={'padding-15-T'}>
																	<Button variant="contained" color="primary"
																	        onClick={this.props.mas}
																	        className={'btn-default-primary text-transform-none'}>
																		Agregar
																	</Button>
																</Grid>
															) : ''}
														
														</Grid>
													</Fragment>
												</div>
											</CardContent>
										</Card>
									</div>
								</Grid>
							</Grid>
						
						</Grid>
						
						<Grid item xs={12} sm={12} md={8} lg={8} xl={8} className={'h-100'}>
							
							<Grid container spacing={0} className={'h-100'}>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100'}>
									
									<article className={'borde-punteado-gray-1 bg-white'} style={{
										overflowX: "auto",
										height: "calc(100% - 15px)"
									}}>
										
										<table width={'100%'}>
											<thead>
											<tr>
												<th className={'padding-10-T padding-10-B px-14'}>Eliminar</th>
												<th className={'padding-10-T padding-10-B px-14'}>Grupo</th>
												<th className={'padding-10-T padding-10-B px-14'}>Producto</th>
												<th className={'padding-10-T padding-10-B px-14'}>Descripción</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={75}>Cantidad
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>P.
													Unitario
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>Costo adicional
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={75}>% Descuento
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>Precio
												</th>
											</tr>
											</thead>
											<tbody>
											{this.props.productos.map((item, key) => (
												<Fragment key={key}>
													<tr className={key % 2 === 0 ? 'bg-lightgray' : 'bg-white'}>
														<td className={'padding-4'} align={'center'}>
															{!disabledInputVentaEditar ? (
																<div className={'cursor-pointer'} style={{
																	padding: '6px',
																	background: 'red',
																	height: '6px',
																	width: '6px',
																	lineHeight: '6px',
																	color: 'white',
																	borderRadius: '100%',
																	fontSize: '12px',
																}}
																     onClick={() => this.props.menos(item, key)}>x</div>
															) : ''}
														</td>
														<td className={'padding-4'}>
															<NativeSelect
																name={'id_cat_grupo__' + key}
																value={item.id_cat_grupo}
																onChange={(ev) => {
																	let data = {
																		target: {
																			value: ev.target.value,
																			name: ev.target.name,
																			checked: ev.target.checked,
																			type: ev.target.type
																		}
																	}
																	this.props.resetTipoRequerimiento(data, item, key).then((value) => {
																		if (value.r) {
																			this.props.handleChange(value.e, 'productos', 'id_cat_grupo');
																		}
																	});
																}}
																inputProps={{
																	'aria-label': 'id_cat_grupo',
																}}
																disabled={disabledInputVentaEditar}
															>
																<option value="">Selecciona un grupo
																</option>
																{this.props.cat_grupo.map(option => (
																	<option
																		key={option.id_cat_grupo}
																		value={option.id_cat_grupo}
																		children={option.grupo}
																	/>
																))}
															</NativeSelect>
														</td>
														<td className={'padding-4'}>
															<TextField
																multiline
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "Producto"
																}}
																
																name={'venta_producto__' + key}
																onChange={(e) => this.props.handleChange(e, 'productos', 'venta_producto')}
																value={item.venta_producto}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																multiline
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "Descripción"
																}}
																
																name={'venta_descripcion__' + key}
																onChange={(e) => this.props.handleChange(e, 'productos', 'venta_descripcion')}
																value={item.venta_descripcion}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "0"
																}}
																onKeyPress={EnteroSolo}
																
																name={'venta_cantidad__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_cantidad');
																	this.props.updatePrecio();
																}}
																value={item.venta_cantidad}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																InputProps={{
																	inputProps: {
																		className: classes.input,
																		placeholder: "0"
																	},
																	startAdornment: <InputAdornment
																		position="start">$</InputAdornment>
																}}
																
																onKeyPress={FloatSolo}
																
																name={'venta_precio_unitario__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_precio_unitario');
																	this.props.updatePrecio();
																}}
																value={item.venta_precio_unitario}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4 text-right'}>
															$ {FieldsJs.Currency(item.venta_costo_adicional)}
														</td>
														<td className={'padding-4 text-right'}>
															<TextField
																multiline
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "0",
																	maxLength: 10
																}}
																onKeyPress={(e) => {
																	MinMax(e, 0, 100);
																	EnteroSolo(e);
																}}
																
																name={'venta_porcentaje_descuento__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_porcentaje_descuento');
																	this.props.updatePrecio();
																}}
																value={item.venta_porcentaje_descuento}
																// disabled={disabledInputVentaEditar}
																disabled={true}
															/>
														</td>
														<td className={'padding-4 text-right'}>
															<Fragment>
																<p className={'padding-0 margin-0 px-14 ff-arial'}>
																	${FieldsJs.Currency(item.venta_precio)}
																</p>
																{item.venta_descuento > 0 ? (
																	<p className={'padding-0 margin-0 px-9 text-blue ff-arial'}>
																		Descuento.
																		${FieldsJs.Currency(item.venta_descuento)}
																	</p>
																) : ''}
															</Fragment>
														</td>
													</tr>
													<tr className={key % 2 === 0 ? 'bg-lightgray' : 'bg-white'}>
														<td className={'padding-4'} colSpan={9}>
															
															{(item.venta_producto_tipo_requerimiento || []).map((itemTR, keyTR) => (
																<div key={keyTR}
																     className={'vertical-inline b-r-20 margin-5 ' + (disabledInputVentaEditar ? 'bg-gray' : 'bg-danger')}>
																	<div
																		className={'vertical-inline padding-3 padding-10-L padding-10-R v-center text-white'}>
																		{itemTR.tipo_requerimiento || 'N/A'} {this.getAtrubutoOpcionRequerimineto(itemTR)} {itemTR.costo_adicional > 0 ? '$' + FieldsJs.Currency(itemTR.costo_adicional) : ''}
																	</div>
																	<div
																		className={'vertical-inline padding-3 padding-10-R v-center text-white'}>
																		{!disabledInputVentaEditar ? (
																			<DeleteOutlined className={'px-18'}
																			                onClick={() => this.props.eliminar_requerimiento(key, keyTR)}/>
																		) : ''}
																	</div>
																</div>
															))}
															
															{(item.venta_producto_requerimiento_adicional || []).map((itemTR, keyTR) => (
																<div key={keyTR}
																     className={'vertical-inline b-r-20 margin-5 ' + (disabledInputVentaEditar ? 'bg-gray' : 'bg-danger')}>
																	<div
																		className={'vertical-inline padding-3 padding-10-L padding-10-R v-center text-white'}>
																		{itemTR.requerimiento_adicional || 'N/A'} {itemTR.costo_adicional > 0 ? '$' + FieldsJs.Currency(itemTR.costo_adicional) : ''}
																	</div>
																	<div
																		className={'vertical-inline padding-3 padding-10-R v-center text-white'}>
																		{!disabledInputVentaEditar ? (
																			<DeleteOutlined className={'px-18'}
																			                onClick={() => this.props.eliminar_producto_atributo(key, keyTR)}/>
																		) : ''}
																	</div>
																</div>
															))}
															
															{!disabledInputVentaEditar ? (
																<ModalAtributoRroducto
																	index={key}
																	icono={(
																		<BtnMasAdd/>
																	)}
																	agregar_producto_atributo={this.props.agregar_producto_atributo}
																	showSnackBars={this.props.showSnackBars}
																/>
															) : ''}
														
														</td>
													</tr>
												</Fragment>
											))}
											{this.props.productos.length <= 0 ? (
												<tr className={'bg-white'}>
													<td className={'padding-0 text-center'} colSpan={9}>
														<VistaVacia numero={2} height={'100px'} width={'100px'}
														            padding={'30px'}
														            mensaje={'Sin productos agregados'}/>
													</td>
												</tr>
											) : null}
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={6} rowSpan={5}
												    align={'center'}>
												</td>
												<td className={'padding-4'} colSpan={2}>Subtotal</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.subtotal)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={2}>IVA {desglose.iva} %</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.iva_total)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={2}>Descuento</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.descuento)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={2}>Total sin descuento</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.total)}
													<hr/>
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={2}>TOTAL</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.total - desglose.descuento)}
												</td>
											</tr>
											</tbody>
										</table>
									
									</article>
								
								</Grid>
							
							</Grid>
						
						</Grid>
					
					</Grid>
				
				</section>
			
			</Fragment>
		);
	}
}

ContenidoOrdenVenta.propTypes = {
	item: PropTypes.object.isRequired,
	
	OrdenDeVentaDatos: PropTypes.func,
	classes: PropTypes.object.isRequired,
	
	handleChange: PropTypes.func.isRequired,
	updatePrecio: PropTypes.func.isRequired,
	
	mas: PropTypes.func.isRequired,
	menos: PropTypes.func.isRequired,
	
	eliminar_requerimiento: PropTypes.func.isRequired,
	eliminar_producto_atributo: PropTypes.func.isRequired,
	agregar_producto_atributo: PropTypes.func.isRequired,
	
	producto_requerimiento_adicional: PropTypes.array.isRequired,
	
	agregar_producto_requerimiento_adicional: PropTypes.func.isRequired,
	eliminar_producto_requerimiento_adicional: PropTypes.func.isRequired,
	
	cat_tipo_requerimiento: PropTypes.array.isRequired,
	productos: PropTypes.array.isRequired,
	
	cat_grupo: PropTypes.array.isRequired,
	updateDesglose: PropTypes.func.isRequired,
	getCatTipoRequerimiento: PropTypes.func.isRequired,
	resetTipoRequerimiento: PropTypes.func.isRequired,
	apply_search: PropTypes.func.isRequired,
	
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	OrdenDeVentaGuardar: PropTypes.func.isRequired,
	OrdenDeVentaActualizar: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	updatePeriodo: PropTypes.func.isRequired,
};

export default withStyles(styles)(ContenidoOrdenVenta);
