import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const GiroEmpresaService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Giro_Empresa_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			giro_empresa: form.giro_empresa,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Giro_Empresa_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_giro_empresa: form.id_cat_giro_empresa,
			giro_empresa: form.giro_empresa,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Giro_Empresa_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_giro_empresa) => {
		let params = {
			id_cat_giro_empresa: id_cat_giro_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Giro_Empresa_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
