import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";


class ModalEnvio extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
			id_cat_tipo_mensajeria: '',
			otra_mensajeria: '',
			id_cat_envio_estatus: '',
			numero_guia: '',
			numero_rastreo: '',
			listar_cat_envio_estatus: [],
			listar_cat_tipo_mensajeria: [],
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		let hc = new Promise((resolve, reject) => {
			FieldsJs.HandleChange(e, variable, campo, date, input, (r) => {
				this.setState({
					[r.name]: r.value,
				}, resolve(true) );
			});
		});
		
		hc.then(r => {
			this.props.selectEnvio(this.state);
		}).catch(e => {
			console.log(e);
		});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const {form} = this.props;
		this.setState({
			modal: {
				open: true
			},
			id_cat_tipo_mensajeria: form._id_cat_tipo_mensajeria || '',
			otra_mensajeria: form._otra_mensajeria|| '',
			id_cat_envio_estatus: form._id_cat_envio_estatus || '',
			numero_guia: form._numero_guia || '',
			numero_rastreo: form._numero_rastreo || '',
		});
		console.log(this.state, form);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		}, () => {
			console.log("CONTENT:::: ", files);
		});
	};
	
	
	save = () => {
		
		console.log(this.state);
		
		/*CotizadorService.Nube(formData).then((response) => {
			this.closeModal();
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});*/
	};
	
	render() {
		let {item} = this.props;
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogTitle>
						Envío
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} className={'cotizador_fondo'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormControl component="fieldset" required>
									<FormLabel component="legend">Mensajería:</FormLabel>
									<RadioGroup row aria-label="position"
									            name="id_cat_tipo_mensajeria"
									            value={this.state.id_cat_tipo_mensajeria}
									            onChange={(e) => this.handleChange(e)}
									>
										{this.props.cat_tipo_mensajeria.map((item, key) => (
											<FormControlLabel
												key={key}
												className={'px-12'}
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value={item.id_cat_tipo_mensajeria.toString()}
												label={item.tipo_mensajeria}
											/>
										))}
									</RadioGroup>
								</FormControl>
							</Grid>
							
							{Number(this.state.id_cat_tipo_mensajeria) === 7 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<TextField
										margin="none"
										name="otra_mensajeria"
										label="Nombre de la mensajería"
										type="text"
										fullWidth
										value={this.state.otra_mensajeria}
										onChange={(e) => {
											this.handleChange(e);
										}}
										required
									/>
								</Grid>
							) : null}
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<TextField
									margin="none"
									name="numero_guia"
									label="Número de guía"
									type="text"
									fullWidth
									value={this.state.numero_guia}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<TextField
									margin="none"
									name="numero_rastreo"
									label="Número de rastreo"
									type="text"
									fullWidth
									value={this.state.numero_rastreo}
									onChange={(e) => {
										this.handleChange(e);
									}}
									required
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Estatus de envío:</FormLabel>
									<RadioGroup row aria-label="position"
									            label={'Estatus de envío'}
									            name="id_cat_envio_estatus"
									            value={this.state.id_cat_envio_estatus}
									            onChange={(e) => {
									            	this.handleChange(e);
									            }}
									>
										{this.props.cat_envio_estatus.map((item, key) => (
											<FormControlLabel
												key={key}
												className={'px-12'}
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value={item.id_cat_envio_estatus.toString()}
												label={item.envio_estatus}
											/>
										))}
									</RadioGroup>
								</FormControl>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEnvio.propTypes = {
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	selectEnvio: PropTypes.func.isRequired,
	cat_tipo_mensajeria: PropTypes.array.isRequired,
	cat_envio_estatus: PropTypes.array.isRequired,
};

export default ModalEnvio;
