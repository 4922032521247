import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import Grid from "@material-ui/core/Grid";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from '../../../../settings/General/General';

import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from "@material-ui/core/Fab";
import {EventNote, SearchOutlined, PersonOutlined} from "@material-ui/icons";
import dinero_vacio from "../../../../assets/img/icons/dinero.svg";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

const styles = theme => ({
	root: {
		width: '150%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	
	render() {
		
		const {classes, lista, lista_detalle, filtro2} = this.props;
		
		return (
			<div className={'margin-15-T'}>
				<Grid container spacing={2} alignContent={"flex-start"} alignItems={"flex-start"}>
					
					{FieldsJs.Field(filtro2.id_cat_tipo_gasto_compra) ? (
						<Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={'left'}>
						</Grid>
					) : null}
					
					<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
						<Card>
							<Typography className={'text-center margin-15-T text-bold px-14'}>
								{'ESTADO DE CUENTA ' + (!filtro2.tipo_gasto_compra ? 'OPERACIÓN':filtro2.tipo_gasto_compra)}
							</Typography>
							
							<CardContent>
								<Table className={classes.table + ' desing-mark'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'} className={'w-40-px padding-15-L padding-15-R'}>Periodo:</TableCell>
											<TableCell component="th" align={'right'}>{DateFormat.FormatText(filtro2.fecha_inicio) + ' - ' + DateFormat.FormatText(filtro2.fecha_fin)}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell align={'left'} className={'padding-15-L padding-15-R'}>Ingresos</TableCell>
											<TableCell align={'right'} className={'padding-15-R'}>${lista.ingreso_general}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align={'left'} className={'padding-15-L padding-15-R'}>Gastos</TableCell>
											<TableCell align={'right'} className={'padding-15-R'}>${lista.compra_operacion}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align={'left'} className={'padding-15-L padding-15-R'}>Utilidad</TableCell>
											<TableCell align={'right'} className={'padding-15-R'}>${lista.utilidad_operacion}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={2} align={'right'} className={'padding-15-L padding-15-R'}>
												<div className={'vertical-inline text-right text-bold'}>
													<div className={'v-center margin-20-R'}> Total:</div>
													<div className={'v-center'}>${lista.utilidad_operacion}</div>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</CardContent>
						</Card>
					</Grid>
					
					{!FieldsJs.Field(filtro2.id_cat_tipo_gasto_compra) ? (
						<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
							<Card>
								<Typography className={'text-center margin-15-T text-bold px-14'}>
									ESTADO DE CUENTA GENERAL
								</Typography>
								
								<CardContent>
									<Table className={classes.table + ' desing-mark'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'left'} className={'w-40-px padding-15-L padding-15-R'}>Periodo:</TableCell>
												<TableCell component="th" align={'right'}>{DateFormat.FormatText(filtro2.fecha_inicio) + ' - ' + DateFormat.FormatText(filtro2.fecha_fin)}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell align={'left'} className={'padding-15-L padding-15-R'}>Ingresos</TableCell>
												<TableCell align={'right'} className={'padding-15-R'}>${lista.ingreso_general}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align={'left'} className={'padding-15-L padding-15-R'}>Gastos</TableCell>
												<TableCell align={'right'} className={'padding-15-R'}>${lista.compra_general}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align={'left'} className={'padding-15-L padding-15-R'}>Utilidad</TableCell>
												<TableCell align={'right'} className={'padding-15-R'}>${lista.utilidad_general}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell colSpan={2} align={'right'} className={'padding-15-L padding-15-R'}>
													<div className={'vertical-inline text-right text-bold'}>
														<div className={'v-center margin-20-R'}> Total:</div>
														<div className={'v-center'}>${lista.utilidad_general}</div>
													</div>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</CardContent>
							</Card>
						</Grid>
					) : null}
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
						<Fab variant="extended" size="small" color="primary" aria-label="Excel"
						     className={'margin-15-L bg-green'} onClick={() => this.props.verDetalle()}>
							<EventNote className={'margin-5-R px-14'}/>
							Ver detalle
						</Fab>
					</Grid>
					
					{this.props.mostrar_detalle ? (
						<Fragment>
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Card>
									<Typography className={'text-center margin-15-T text-bold px-14'}>
										INGRESOS
									</Typography>
									
									<div className={'table-scroll'}>
										<CardContent>
											<Table className={classes.table + ' desing-mark'}>
												<TableHead>
													<TableRow>
														<TableCell component="th" align={'left'} className={'w-115-px padding-15-L padding-15-R'}>Orden de Venta</TableCell>
														<TableCell component="th" align={'center'} width={100}>Fecha</TableCell>
														<TableCell component="th" align={'left'}>Cliente</TableCell>
														<TableCell component="th" align={'center'}>Importe</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{lista_detalle.ingresos.length > 0 ? (
														<Fragment>
															{lista_detalle.ingresos.map((item, key) => (
																<TableRow>
																	<TableCell align={'left'} className={'padding-15-L padding-15-R'}>{item.numero_pedido}</TableCell>
																	<TableCell align={'center'} className={'padding-15-R'}>{DateFormat.FormatText(item.fecha_alta)}</TableCell>
																	<TableCell align={'left'} className={'padding-15-R'}>{item.nombre_completo}</TableCell>
																	<TableCell align={'right'} className={'padding-15-R'}>${FieldsJs.Currency(item.monto)}</TableCell>
																</TableRow>
															))}
														</Fragment>
													) : (
														<TableRow>
															<TableCell colSpan={4} align={'center'} className={'padding-15-L padding-15-R'}>
																<VistaVacia
																	numero={null}
																	mensaje={'No se encontraron datos.'}
																	image={dinero_vacio}
																	// padding={'20px'}
																	// paddingText={'20px'}
																	// height={'80px'}
																	// width={'80px'}
																/>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</CardContent>
									</div>
								</Card>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'left'}>
								<Card>
									<Typography className={'text-center margin-15-T text-bold px-14'}>
										GASTOS
									</Typography>
									
									<div className={'table-scroll'}>
										<CardContent>
											<Table className={classes.table + ' desing-mark'}>
												<TableHead>
													<TableRow>
														<TableCell component="th" align={'left'} className={'w-40-px padding-15-L padding-15-R'}>Id</TableCell>
														<TableCell component="th" align={'left'} width={100}>Fecha</TableCell>
														<TableCell component="th" align={'left'}>Tipo Gasto</TableCell>
														<TableCell component="th" align={'left'}>Concepto</TableCell>
														<TableCell component="th" align={'center'}>Importe</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{lista_detalle.compras.length > 0 ? (
														<Fragment>
															{lista_detalle.compras.map((item, key) => (
																<TableRow>
																	<TableCell align={'left'} className={'padding-15-L padding-15-R'}>{item.id_compra}</TableCell>
																	<TableCell align={'left'} className={'padding-15-R'}>{DateFormat.FormatText(item.fecha_alta)}</TableCell>
																	<TableCell align={'left'} className={'padding-15-R'}>{item.tipo_gasto_compra}</TableCell>
																	<TableCell align={'left'} className={'padding-15-R'}>{item.descripcion}</TableCell>
																	<TableCell align={'right'} className={'padding-15-R'}>${FieldsJs.Currency(item.total)}</TableCell>
																</TableRow>
															))}
														</Fragment>
													) : (
														<TableRow>
															<TableCell colSpan={5} align={'center'} className={'padding-15-L padding-15-R'}>
																<VistaVacia
																	numero={null}
																	mensaje={'No se encontraron datos.'}
																	image={dinero_vacio}
																	// padding={'20px'}
																	// paddingText={'20px'}
																	// height={'80px'}
																	// width={'80px'}
																/>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</CardContent>
									</div>
								</Card>
							</Grid>
						</Fragment>
					) : null}
					
				</Grid>
			
			</div>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	lista_detalle: PropTypes.array.isRequired,
	filtro2: PropTypes.array.isRequired,
	verDetalle: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
