import React, {Component, Fragment} from 'react';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import icon_xml from '../../../../assets/img/icons/xml.svg';
import icon_pdf from '../../../../assets/img/icons/pdf.svg';
import icon_logo from '../../../../assets/img/logo.svg';
import {Description, PictureAsPdf, DeleteOutlined, Visibility} from "@material-ui/icons";
import * as PropTypes from "prop-types";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Tab from "@material-ui/core/Tab";
import Zoom from '@material-ui/core/Zoom/index';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab/index";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_3 from "../../../../assets/img/icons/facturacion.svg";
import moment from "moment";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import {TimbradoFacturacionExtService} from "../../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import ModalCargaMasivaCFDI from "./ModalCargaMasivaCFDI";
import ModalCargaMasivoPDFCFDI from "./ModalCargaMasivoPDFCFDI";
import ModalFormaPagoEdit from "../../OrdenDeVenta/Includes/ModalFormaPagoEdit";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ver_menos from "../../../../assets/img/icons/ver-menos.svg";
import ver_mas from "../../../../assets/img/icons/ver-mas.svg";
import {OrdenDeVentaService} from "../../../../services/OrdenDeVentaService/OrdenDeVentaService";
import IconButton from "@material-ui/core/IconButton";
import {EditOutlined} from "@material-ui/icons";
import icon_ticket from '../../../../assets/img/icons/icon-ticket.svg';
import {VentasService} from "../../../../services/VentasService/VentasService";
import ModalCambiarClienteCFDI from "./ModalCambiarClienteCFDI";
import ModalCliente from "../../Cliente/Includes/ModalCliente";


class ModalTimbrarCFDI extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: props.open || false,
				open2: false
			},
			archivos_nube: (props.item || []).archivos,
			id_venta: (props.item || []).id_venta,
			numero_pedido: (props.item || []).numero_pedido,
			venta: props.item || '',
			listar_cat_tipo_cfdi: [],
			listar_cat_uso_cfdi: [],
			listar_cat_metodo_pago: [],
			listar_cat_forma_pago: [],
			listar_cat_moneda: [],
			listar_empresa_sucursal_serie: [],
			partidas: [],
			listar_cat_impuestos: [],
			listar_cat_impuestos_retenidos: [],
			listar_cat_impuestos_trasladados: [],
			listar_unidad_medida: [],
			listar_cliente: [],
			listar_cat_objeto_impuesto: [],
			listar_cat_tipo_factor: [],
			listar_empresa: [],
			
			nombre_razon_social: '',
			fecha: '1',
			
			rfc_emisor: '',
			sucursal_emisor: '',
			observaciones_emisor: '',
			id_cat_uso_cfdi: '',
			id_cat_metodo_pago: '',
			id_cat_tipo_cfdi: '',
			tipo_decimales_redondear: '',
			
			id_cat_forma_pago: '',
			id_cat_moneda: '',
			tipo_cambio: 1,
			id_empresa_sucursal_serie: '',
			folio: '',
			id_cliente: (props.item.cliente || '').id_cliente,
			enviar_a: (props.item.cliente || '').correo_electronico || '',
			enviar_cc: (props.item.cliente || '').copia_factura || '',
			cliente_regimen_fiscal: (props.item.cliente || '').id_cat_regimen_fiscal,
			cliente_numero_registro_if: (props.item.cliente || '').registro_identidad_fiscal,
			cliente_codigo_postal: (props.item.cliente || '').codigo_postal,
			cliente_rfc: (props.item.cliente || '').rfc,
			cliente_nombre_razon_social: props.item.cliente_nombre_completo || '',
			
			ver: false,
			ws_lista_pagos: [],
			ws_por_pagar: 0,
			ws_total_pagado: 0,
			ws_total_venta: 0,
			ws_decsuento_venta: 0,
			edit_cliente: false,
			is_exhibiciones: false,
			
			retimbrar: props.retimbrar || false,
		};
		
		if (props.open) {
			this.open();
		}
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.props.selectCloseModal();
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	closeModal2 = () => {
		this.setState({
			modal: {
				open2: false
			}
		});
	};
	
	close2 = () => {
		this.closeModal2();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
			archivos_nube: (props.item || []).archivos,
		});
		console.log(this.state, props);
	};
	
	openModal2 = () => {
		const props = this.props;
		this.setState({
			modal: {
				open2: true
			},
		});
		console.log(this.state, props);
	};
	
	handleChangeEmpresa = (id_empresa) => {
		console.log(id_empresa);
		this.setState({
			id_empresa: id_empresa
		});
		this.ListaEmpresaSucursal(id_empresa);
		this.ListaEmpresaRegimenFiscal(id_empresa);
		this.ListaConcepto(id_empresa);
		this.ListaUnidadMedida(id_empresa);
		this.ListaMoneda(id_empresa);
		
	}
	
	ListaMoneda = (id_empresa) => {
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListMonedaFac(id_empresa, {}).then((response) => {
				this.setState({
					listar_cat_moneda: response.data,
					id_cat_moneda: response.data[0].id_cat_moneda,
				});
			}).catch((error) => {
				this.setState({
					listar_cat_moneda: []
				});
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_cat_moneda: []
			});
		}
	};
	
	ListaEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresaFac({}, {}).then((response) => {
				this.setState({
					listar_empresa: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_empresa: []
				});
				reject(error);
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListaEmpresaSucursal = (id_empresa) => {
		
		let empresas = FieldsJs.Copy(this.state.listar_empresa);
		for (let i = 0; i < empresas.length; i++) {
			if (Number(id_empresa) === Number(empresas[i].id_empresa)) {
				this.setState({
					rfc_emisor: empresas[i].rfc,
				});
			}
		}
		
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaSucursalFac(id_empresa, {}).then((response) => {
				if (response.data.length > 0) {
					this.setState({
						listar_empresa_sucursal: response.data,
						id_empresa_sucursal: response.data[0].id_empresa_sucursal,
						lugar_expedicion: response.data[0].codigo_postal,
						sucursal_emisor: response.data[0].nombre,
					});
					this.ListaEmpresaSucursalSerie(response.data[0].id_empresa_sucursal);
				} else {
					this.setState({
						listar_empresa_sucursal: response.data,
					});
				}
			}).catch((error) => {
				this.setState({
					listar_empresa_sucursal: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_sucursal: []
			});
		}
	};
	
	handleChangeSerie = (id_empresa_sucursal_serie, valor) => {
		let series = FieldsJs.Copy(this.state.listar_empresa_sucursal_serie);
		for (let i = 0; i < series.length; i++) {
			if (Number(id_empresa_sucursal_serie) === Number(series[i].id_empresa_sucursal_serie)) {
				this.setState({
					serie: series[i].serie
				});
			}
		}
		
		EmpresaFacturacionExtService.SerieFolio(id_empresa_sucursal_serie).then((response) => {
			this.setState({
				folio: response.data
			});
		}).catch((error) => {
			this.setState({listar_facturacion: []});
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	ListaEmpresaSucursalSerie = (id_empresa_sucursal) => {
		EmpresaFacturacionExtService.Series(id_empresa_sucursal, {}).then((response) => {
			if (response.data.length > 0) {
				this.setState({
					listar_empresa_sucursal_serie: response.data,
					id_empresa_sucursal_serie: response.data[0].id_empresa_sucursal_serie,
					serie: response.data[0].serie,
				});
				this.handleChangeSerie(response.data[0].id_empresa_sucursal_serie);
			} else {
				this.setState({
					listar_empresa_sucursal_serie: response.data,
					id_empresa_sucursal_serie: '',
				});
			}
		}).catch((error) => {
			this.setState({
				listar_cat_moneda: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaEmpresaRegimenFiscal = (id_empresa) => {
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaRegimenFiscalFac(id_empresa, {}).then((response) => {
				console.log("REGIMEN FISCAL::: ", response.data);
				if (response.data.length > 0) {
					this.setState({
						listar_empresa_regimen_fiscal: response.data,
						id_empresa_regimen_fiscal: response.data[0].id_empresa_regimen_fiscal,
						id_cat_regimen_fiscal: response.data[0].id_cat_regimen_fiscal,
					});
				} else {
					this.setState({
						listar_empresa_regimen_fiscal: response.data,
					});
				}
			}).catch((error) => {
				this.setState({
					listar_empresa_regimen_fiscal: []
				});
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_regimen_fiscal: []
			});
		}
	};
	
	ListaConcepto = (id_empresa) => {
		return new Promise((resolve, reject) => {
			let params = {
				id_empresa: id_empresa,
				valuetab: '3'
			};
			EmpresaFacturacionExtService.Catalogos(params).then((response) => {
				this.setState({
					listar_concepto: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_concepto: []
				});
				this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	ListaUnidadMedida = (id_empresa) => {
		return new Promise((resolve, reject) => {
			let params = {
				id_empresa: id_empresa,
				valuetab: '2'
			};
			EmpresaFacturacionExtService.Catalogos(params).then((response) => {
				this.setState({
					listar_unidad_medida: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					listar_unidad_medida: []
				});
				this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	ConsultaCFDIProyecto = () => {
		return new Promise((resolve, reject) => {
			TimbradoFacturacionExtService.ConsultaCFDIProyecto(this.state).then((response) => {
				resolve(response);
				//this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.setState({listar_facturacion: []});
				//this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	open = () => {
		const {item} = this.props;
		
		this.setState({
			venta: item || '',
			edit_cliente: false,
			rfc_emisor: '',
			sucursal_emisor: '',
			observaciones_emisor: '',
			id_cat_uso_cfdi: '',
			id_cat_metodo_pago: '',
			id_cat_tipo_cfdi: '',
			tipo_decimales_redondear: '',
			
			id_cat_forma_pago: '',
			nombre_razon_social: '',
			id_cat_moneda: '',
			tipo_cambio: 1,
			id_empresa_sucursal_serie: '',
			folio: '',
			fecha: '1',
			id_cliente: (item.cliente || '').id_cliente,
			enviar_a: (item.cliente || '').correo_electronico || '',
			enviar_cc: (item.cliente || '').copia_factura || '',
			cliente_regimen_fiscal: (item.cliente || '').id_cat_regimen_fiscal,
			cliente_numero_registro_if: (item.cliente || '').registro_identidad_fiscal,
			cliente_codigo_postal: (item.cliente || '').codigo_postal,
			cliente_rfc: (item.cliente || '').rfc,
			cliente_nombre_razon_social: item.cliente_nombre_completo || '',
			partidas: []
		});
		
		console.log("item.cliente :::::", item.cliente);
		
		if (!item.cliente.receptor_factura) {
			let msg = `El cliente no cuenta con los datos suficientes para realizar la factura. Favor de completar sus datos.
			Si desea facturar a otro receptor dé click en siguiente.`;
			
			PopupService.ConfirmMultiple(['Cancelar', 'Actualizar Cliente', 'Siguiente'], 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === 'Actualizar Cliente') {
					this.setState({
						edit_cliente: true
					});
					//this.props.history.push('/clientes');
				} else if (r.button === 'Siguiente') {
					this.setState({
						nombre_razon_social: Number(item.cliente.id_cat_tipo_persona) === 1 ? (FieldsJs.Field(item.cliente.rfc) ? item.cliente.rfc + " - " : "") + (FieldsJs.Field(item.cliente.nombre) ? item.cliente.nombre + " " : "") + (FieldsJs.Field(item.cliente.apellido_paterno) ? item.cliente.apellido_paterno + " " : "") + (FieldsJs.Field(item.cliente.apellido_materno) ? item.cliente.apellido_materno : "") : (FieldsJs.Field(item.cliente.razon_social) ? item.cliente.razon_social : ""),
						partidas: [],
					})
					
					let peticiones = new Promise((resolve) => {
						this.ListaEmpresa().then((response) => {
							this.handleChangeEmpresa(11);
						});
						this.ListaTipoFactor();
						this.ListaTipoCFDI();
						this.ListaUsoCFDI();
						this.ListaMetodoPago();
						this.ListaFormaPago();
						this.ListaObjetoImpuesto();
						this.ListaImpuestos();
						this.ListaImpuestosRetenidos();
						this.ListaImpuestosTraslados();
						this.ListaCliente();
						
						setTimeout(() => {
							resolve(true);
						}, 1300);
					});
					
					peticiones.then((response) => {
						this.addPartidas();
						this.OrdenDeVentaDatos(item.id_venta);
					});
					
					
					this.init().then(r => {
						setTimeout(() => this.openModal());
					}).catch(e => {
						console.log(e);
					});
				} else {
					this.props.selectCloseModal();
				}
				
			});
		} else {
			
			this.setState({
				nombre_razon_social: Number(item.cliente.id_cat_tipo_persona) === 1 ? item.cliente.rfc + " - " + item.cliente.nombre + " " + item.cliente.apellido_paterno + " " + item.cliente.apellido_materno : item.cliente.razon_social,
				partidas: [],
			})
			
			this.ConsultaCFDIProyecto().then((response) => {
				console.log("RESPONSE::: ", response);
				
				this.setState({
					archivo_xml: response.data.archivo_xml || '',
					id_facturacion_cfdi: response.data.id_facturacion_cfdi || '',
				});
				if (!response.data.cancelado && !this.state.retimbrar) {
					this.init().then(r => {
						setTimeout(() => this.openModal2());
					}).catch(e => {
						console.log(e);
					});
					this.OrdenDeVentaDatos(item.id_venta);
				} else {
					let peticiones = new Promise((resolve) => {
						this.ListaEmpresa().then((response) => {
							this.handleChangeEmpresa(11);
						});
						this.ListaTipoFactor();
						this.ListaTipoCFDI();
						this.ListaUsoCFDI();
						this.ListaMetodoPago();
						this.ListaFormaPago();
						this.ListaObjetoImpuesto();
						this.ListaImpuestos();
						this.ListaImpuestosRetenidos();
						this.ListaImpuestosTraslados();
						this.ListaCliente();
						
						setTimeout(() => {
							resolve(true);
						}, 1300);
					});
					
					peticiones.then((response) => {
						this.addPartidas();
						this.OrdenDeVentaDatos(item.id_venta);
					});
					
					
					this.init().then(r => {
						setTimeout(() => this.openModal());
					}).catch(e => {
						console.log(e);
					});
				}
				
			}).catch((error) => {
				let peticiones = new Promise((resolve) => {
					this.ListaEmpresa().then((response) => {
						this.handleChangeEmpresa(11);
					});
					this.ListaTipoFactor();
					this.ListaTipoCFDI();
					this.ListaUsoCFDI();
					this.ListaMetodoPago();
					this.ListaFormaPago();
					this.ListaObjetoImpuesto();
					this.ListaImpuestos();
					this.ListaImpuestosRetenidos();
					this.ListaImpuestosTraslados();
					this.ListaCliente();
					
					setTimeout(() => {
						resolve(true);
					}, 1300);
				});
				
				peticiones.then((response) => {
					this.addPartidas();
					this.OrdenDeVentaDatos(item.id_venta);
				});
				
				
				this.init().then(r => {
					setTimeout(() => this.openModal());
				}).catch(e => {
					console.log(e);
				});
			});
			
			
		}
	};
	
	ListaImpuestos = () => {
		CatService.ListImpuestoFac().then((response) => {
			this.setState({
				listar_cat_impuestos: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaImpuestosRetenidos = () => {
		CatService.ListImpuestoRetenidoFac().then((response) => {
			this.setState({
				listar_cat_impuestos_retenidos: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos_retenidos: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaImpuestosTraslados = () => {
		CatService.ListImpuestoTrasladoFac().then((response) => {
			this.setState({
				listar_cat_impuestos_trasladados: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_impuestos_trasladados: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	/*ListaUnidadMedida = (id_empresa) => {
		return new Promise((resolve, reject) => {
			let params = {
				id_empresa: id_empresa,
				valuetab: '2'
			};
			EmpresaFacturacionExtService.Catalogos(params).then((response) => {
				this.setState({
					listar_unidad_medida: response.data,
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					listar_unidad_medida: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}*/
	
	ListaCliente = () => {
		CatService.ListClienteFac({}, {}).then((response) => {
			this.setState({
				listar_cliente: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cliente: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaObjetoImpuesto = () => {
		CatService.ListObjetoImpuestoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_objeto_impuesto: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_objeto_impuesto: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaTipoCFDI = () => {
		CatService.ListTipoCFDIFac({}, {}).then((response) => {
			this.setState({
				listar_cat_tipo_cfdi: response.data,
				id_cat_tipo_cfdi: 1,
				tipo_decimales_redondear: 2,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_cfdi: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaUsoCFDI = () => {
		CatService.ListUsoCFDIFac({}, {}).then((response) => {
			this.setState({
				listar_cat_uso_cfdi: response.data,
				id_cat_uso_cfdi: 3
			});
		}).catch((error) => {
			this.setState({
				listar_cat_uso_cfdi: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaFormaPago = () => {
		return new Promise((resolve, reject) => {
			CatService.ListFormaPagoFac({}, {}).then((response) => {
				this.setState({
					listar_cat_forma_pago: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					listar_cat_forma_pago: []
				});
				this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	ListaMetodoPago = () => {
		return new Promise((resolve, reject) => {
			CatService.ListMetodoPagoFac({}, {}).then((response) => {
				if (response) {
					this.setState({
						listar_cat_metodo_pago: response.data
					});
				}
				resolve(response);
			}).catch((error) => {
				this.setState({
					listar_cat_metodo_pago: []
				});
				this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	ListaTipoFactor = () => {
		CatService.ListTipoFactorFac().then((response) => {
			this.setState({
				listar_cat_tipo_factor: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_tipo_factor: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	OrdenDeVentaDatos = (id_venta) => {
		OrdenDeVentaService.OrdenDeVentaDatos(id_venta).then((response) => {
			console.log("OV DATOS>>>> ", response);
			let venta = response.data.venta;
			let info = response.data.pagos;
			this.setState({
				ws_lista_pagos: info.pagos || [],
				ws_por_pagar: info.por_pagar || 0,
				ws_total_pagado: info.total_pagado || 0,
				ws_total_venta: info.total_venta || 0,
				ws_decsuento_venta: info.total_descuento || 0,
				numero_pedido: venta.numero_pedido || 0,
			});
			
			let band_fp = true;
			if (info.pagos.length > 0) {
				let band_pagado = false;
				for (let i = 0; i < info.pagos.length; i++) {
					if (info.por_pagar <= 0) {
						band_pagado = true;
					}
				}
				if (band_pagado) {
					for (let x = 0; x < info.pagos.length; x++) {
						if (Number(info.pagos[0].id_cat_forma_pago) !== Number(info.pagos[x].id_cat_forma_pago)) {
							band_fp = false;
						}
						if (info.pagos.length > 1) {
							for (let p = 0; p < info.pagos.length; p++) {
								if (DateFormat.FormatSql(info.pagos[x].fecha_alta) !== DateFormat.FormatSql(info.pagos[p].fecha_alta)) {
									band_fp = false;
								}
							}
						}
					}
				} else {
					band_fp = false;
				}
			} else {
				band_fp = false;
			}
			
			if (band_fp) {
				this.setState({
					id_cat_forma_pago: info.pagos[0].id_cat_forma_pago,
					id_cat_metodo_pago: 1
				})
			} else {
				this.setState({
					id_cat_forma_pago: 22,
					id_cat_metodo_pago: 2
				})
			}
			
		}).catch((error) => {
			this.setState({
				ws_lista_pagos: [],
				ws_por_pagar: 0,
				ws_total_pagado: 0,
				ws_total_venta: 0,
			});
		})
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	RefreshClose = () => {
		this.closeModal();
		this.props.RefreshList();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Open(path, null, null, null, null, 2).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	exportarFacturacion = (link, tipo) => {
		if (tipo === 'PDF') {
			try {
				let params = {
					id_proyecto: 2,
					archivo: tipo,
					archivo_xml: link
				};
				HttpRequest.exportFacturacion('xmltopdf', ['xmltopdf'], params, false, false, false, true);
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}
		} else {
			let url = CONFIG.src_facturacion + link;
			window.open(url, '', 'width=900, height=600');
		}
	};
	
	calcularDesgoseTotales = () => {
		let desglose = {
			descuento: 0,
			subtotal: 0,
			trasladados: 0,
			retenidos: 0,
			total: 0,
		};
		let partidas = FieldsJs.Copy(this.state.partidas);
		let venta = FieldsJs.Copy(this.state.venta);
		
		/*function round(num, decimales = 2) {
			var signo = (num >= 0 ? 1 : -1);
			num = num * signo;
			if (decimales === 0) //con 0 decimales
				return signo * Math.round(num);
			num = num.toString().split('e');
			num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
			num = num.toString().split('e');
			return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
		}*/
		
		
		for (let i = 0; i < partidas.length; i++) {
			let item = partidas[i];
			desglose.descuento += Number(item.descuento);
			desglose.subtotal += Number(item.importe);
			desglose.trasladados += Number(item.trasladados);
			desglose.retenidos += Number(item.retenidos);
		}
		console.log(desglose.subtotal, desglose.subtotal * 0.16);
		
		/*if(venta.desglosar_iva) {
			desglose.subtotal = venta.total;
			 desglose.subtotal = desglose.subtotal + (desglose.subtotal * 0.16);
		}*/
		
		desglose.descuento = parseFloat(desglose.descuento.toFixed(2));
		desglose.subtotal = Number(this.redondeo_trucar(desglose.subtotal));
		desglose.trasladados = Number(this.redondeo_trucar(desglose.trasladados));
		desglose.retenidos = Number(this.redondeo_trucar(desglose.retenidos));
		
		console.log("calcularDesgoseTotales >>>>>>>", venta, venta.desglosar_iva, desglose.subtotal);
		
		//desglose.total += ((desglose.subtotal - desglose.descuento) + desglose.trasladados) - desglose.retenidos;
		desglose.total = (((desglose.subtotal - desglose.descuento) + desglose.trasladados) - desglose.retenidos);
		
		desglose.total = Number((desglose.total));
		
		return desglose;
	};
	
	timbradoFacturacion = () => {
		try {
			
			if (!this.state.id_cat_tipo_cfdi) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tipo de CFDI.'
				})
			}
			if (!this.state.id_cat_uso_cfdi) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Uso CFDI.'
				})
			}
			if (!this.state.id_cat_metodo_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Metodo de Pago.'
				})
			}
			if (!this.state.id_cat_forma_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Forma de Pago.'
				})
			}
			if (!this.state.id_cat_moneda) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tipo Moneda.'
				})
			}
			if (!this.state.serie) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Serie.'
				})
			}
			if (!this.state.folio) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Folio.'
				})
			}
			if (!this.state.fecha) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha.'
				})
			}
			if (this.state.partidas.length === 0) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Por lo menos debe seleccionar un concepto.'
				})
			}
			
			console.log("Generar pdf");
			let msg = `¿Seguro que desea facturar?`;
			
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === 'Aceptar') {
					TimbradoFacturacionExtService.Agregar(this.state).then((response) => {
						if (Number(response.data.code) === 200 || Number(response.data.code) === 307) {
							
							VentasService.Timbrado_CFDI(this.state).then((response) => {
								this.props.RefreshList();
								this.closeModal();
								this.props.showSnackBars('success', response.mensaje);
							}).catch((error) => {
								this.setState({listar_facturacion: []});
								this.props.showSnackBars('error', error.mensaje);
							});
						}
						
						let msg = `${response.data.code} - ${response.data.message}`;
						PopupService.Confirm(['Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
							if (r.button === 'Aceptar') {
								this.setState({
									ruta: response.ruta,
									open_modal_xml: true
								});
							}
						});
						//this.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.setState({listar_facturacion: []});
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			});
		} catch (e) {
			console.log(this.state);
			this.props.showSnackBars('error', e.mensaje, e);
		}
	};
	
	redondeo_trucar = (valor) => {
		if (!(valor % 1 === 0)) {
			let num = valor.toString().split('.');
			
			if (num.length > 1) {
				console.log("sssss::::: ", num, num[1].toString().length);
				
				if (num[1].toString().length > 2) {
					let dec = num[1].substr(0, 2);
					console.log("dec", dec);
					if (Number(dec) % 2 !== 0) {
						console.log("redondeoooooo::::: ",valor, Math.round(valor, -2));
						valor = valor.toFixed(2);
						
					} else {
						console.log("par",valor);
						valor = valor.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
						
					}
				}
			}
		}
		return valor;
	}
	
	addPartidas = () => {
		
		let items = FieldsJs.Copy(this.props.item.venta_producto) || [];
		
		console.log("PARTIDAS::: ", this.props.item.venta_producto, items);
		
		
		
		
		try {
			let partidas = FieldsJs.Copy(this.state.partidas);
			
			for (let i = 0; i < items.length; i++) {
				let iva_aplicado = false;
				let iva_incluido = true;
				let concepto = items[i].venta_producto || '';
				let numero_identificacion = items[i].numero_identificacion || '';
				let clave_concepto = items[i].clave_producto_servicio_sat || '';
				let precio_unitario = items[i].venta_precio_unitario || '';
				let cantidad = items[i].venta_cantidad || '';
				let tipo_descuento = items[i].venta_precio_unitario || '';
				let descuento_ini = 0;
				let id_empresa_clave_producto = 36;
				let id_empresa_clave_unidad = 17;
				let id_cat_objeto_impuesto = 2;
				
				if (!clave_concepto) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Clave Concepto.'
					})
				}
				if (!id_empresa_clave_producto) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Concepto.'
					})
				}
				if (!id_empresa_clave_unidad) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Unidad de Medida.'
					})
				}
				if (!cantidad) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Cantidad.'
					})
				}
				if (!precio_unitario) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Valor Unitario.'
					})
				}
				if (FieldsJs.inArray([2, 3], Number(tipo_descuento)) && !descuento_ini) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Descuento.'
					})
				}
				if (!id_cat_objeto_impuesto) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Objeto Impuesto.'
					})
				}
				
				function round(num, decimales = 2) {
					var signo = (num >= 0 ? 1 : -1);
					num = num * signo;
					if (decimales === 0) //con 0 decimales
						return signo * Math.round(num);
					num = num.toString().split('e');
					num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
					num = num.toString().split('e');
					return signo * (num[0] + 'e' + (num[1] ? (+num[1] - decimales) : -decimales));
				}
				
				
				let productos = FieldsJs.Copy(this.state.listar_concepto);
				let unidades_medida = FieldsJs.Copy(this.state.listar_unidad_medida);
				let impuestos_t = [];
				let impuestos_r = [];
				let impuestos_trasladados_total = 0;
				let impuestos_retenidos_total = 0;
				let descripcion = '';
				let clave = '';
				let unidad_medida = '';
				let importe = 0;
				
				
				// let num = (precio_unitario / 1.16).toString();
				
				if (iva_incluido) {
					// num = num.split('.');
					// if (num.length > 1) {
					// 	if (num[1].length > 2) {
					// 		num = num[1].toString();
					// 		num = num.substring(2, 3);
					// 	}
					// }
					
					// if (Number(num) !== 5) {
					// 	precio_unitario = round(precio_unitario / 1.16);
					// } else {
					// 	let val = Math.floor((precio_unitario / 1.16) * 100) / 100;
					// 	precio_unitario = val.toFixed(2);
					// }
					
					precio_unitario = round((precio_unitario), 6);
					
					
					importe = (Number(precio_unitario) * Number(cantidad));
					let cat_impuesto_t = FieldsJs.Copy(this.state.listar_cat_impuestos_trasladados);
					let cat_tipo_factor = FieldsJs.Copy(this.state.listar_cat_tipo_factor);
					let cat_impuesto = FieldsJs.Copy(this.state.listar_cat_impuestos);
					
					let it = '';
					let ifc = '';
					let im = '';
					
					for (let i = 0; i < cat_impuesto_t.length; i++) {
						if (Number(2) === Number(cat_impuesto_t[i].id_cat_impuesto_trasladado)) {
							it = cat_impuesto_t[i].impuesto_trasladado;
						}
					}
					for (let i = 0; i < cat_tipo_factor.length; i++) {
						if (Number(1) === Number(cat_tipo_factor[i].id_cat_tipo_factor)) {
							ifc = cat_tipo_factor[i].tipo_factor;
						}
					}
					for (let i = 0; i < cat_impuesto.length; i++) {
						if (Number(3) === Number(cat_impuesto[i].id_cat_impuesto)) {
							im = cat_impuesto[i].impuesto;
						}
					}
					
					if (impuestos_t.length <= 0) {
						let item = {
							id_cat_impuesto_trasladado: 2,
							impuesto_trasladado: it,
							tipo_impuesto: 1,
							tipo_factor: ifc,
							id_cat_impuesto: 3,
							tasa_cuota: im,
						};
						
						impuestos_t.push(item);
					}
				} else if (this.state.iva_aplicado) {
					importe = Number(cantidad) * Number(precio_unitario);
				} else {
					importe = Number(cantidad) * Number(precio_unitario);
				}
				
				let descuento = Number(tipo_descuento) === 2 ? importe * (Number(descuento_ini) / 100) : Number(descuento_ini);
				
				
				for (let i = 0; i < impuestos_t.length; i++) {
					impuestos_t[i].base = importe - descuento;
					impuestos_t[i].importe = (importe - descuento) * impuestos_t[i].tasa_cuota;
					impuestos_trasladados_total = impuestos_trasladados_total + ((importe - descuento) * (impuestos_t[i].tasa_cuota));
					
					
					/*if (Number(num) !== 5) {
						
						impuestos_t[i].importe = round(((importe - descuento) * impuestos_t[i].tasa_cuota));
						impuestos_trasladados_total = impuestos_trasladados_total + ((importe - descuento) * (impuestos_t[i].tasa_cuota));
						impuestos_trasladados_total = round(impuestos_trasladados_total);
						
					} else {
						let imp = Math.floor(((importe - descuento) * (impuestos_t[i].tasa_cuota)) * 100) / 100;
						impuestos_t[i].importe = imp.toFixed(2);
						
						let val = Math.floor((impuestos_trasladados_total + ((importe - descuento) * (impuestos_t[i].tasa_cuota))) * 100) / 100;
						impuestos_trasladados_total = val.toFixed(2);
					}*/
					
				}
				for (let i = 0; i < impuestos_r.length; i++) {
					impuestos_r[i].base = importe - descuento;
					impuestos_r[i].importe = (importe - descuento) * (impuestos_r[i].tasa_cuota);
					impuestos_retenidos_total = impuestos_retenidos_total + ((importe - descuento) * (impuestos_r[i].tasa_cuota));
				}
				
				for (let i = 0; i < productos.length; i++) {
					if (Number(id_empresa_clave_producto) === Number(productos[i].id_empresa_clave_producto)) {
						clave = productos[i].clave_producto_servicio;
						descripcion = productos[i].descripcion;
					}
				}
				
				for (let i = 0; i < unidades_medida.length; i++) {
					if (Number(id_empresa_clave_unidad) === Number(unidades_medida[i].id_empresa_clave_unidad)) {
						unidad_medida = unidades_medida[i].concepto ? unidades_medida[i].clave_unidad + ' - ' + unidades_medida[i].concepto : unidades_medida[i].clave_unidad;
					}
				}
				
				let item = {
					id_empresa_clave_producto: id_empresa_clave_producto,
					numero_identificacion: numero_identificacion,
					id_empresa_clave_unidad: id_empresa_clave_unidad,
					unidad_medida: unidad_medida,
					descripcion: descripcion,
					cantidad: cantidad,
					precio_unitario: precio_unitario.toFixed(2),
					importe: importe.toFixed(2),
					concepto: concepto,
					clave_concepto: clave_concepto,
					tipo_descuento: tipo_descuento,
					descuento: descuento,
					numero_pedimento: '',
					numero_predial: '',
					id_cat_objeto_impuesto: id_cat_objeto_impuesto,
					trasladados: impuestos_trasladados_total.toFixed(2),
					retenidos: impuestos_retenidos_total.toFixed(2),
					impuestos_trasladados: impuestos_t,
					impuestos_retenidos: impuestos_r,
					iva_incluido: iva_incluido ? 1 : 0,
					iva_aplicado: iva_aplicado ? 1 : 0,
				};
				
				partidas.push(item);
				
			}
			
			this.setState({
				partidas: partidas
			});
			
			setTimeout(() => {
				console.log("PARTIDAS>>>>>>", partidas);
				let desglose = this.calcularDesgoseTotales();
				this.setState({
					desglose: desglose
				});
			}, 800);
		} catch (e) {
			console.log(this.state);
			this.props.showSnackBars('error', e.mensaje, e);
		}
	};
	
	ticket = (item) => {
		HttpRequest.export('txid', ['ticket'], {
			id_venta_pago: item.id_venta_pago
		}, true, 450, 680)
	};
	
	visualizacionPDF = () => {
		try {
			if (!this.state.id_cat_tipo_cfdi) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tipo de CFDI.'
				})
			}
			if (!this.state.id_cat_uso_cfdi) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Uso CFDI.'
				})
			}
			if (!this.state.id_cat_metodo_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Metodo de Pago.'
				})
			}
			if (!this.state.id_cat_forma_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Forma de Pago.'
				})
			}
			if (!this.state.id_cat_moneda) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Tipo Moneda.'
				})
			}
			if (!this.state.serie) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Serie.'
				})
			}
			if (!this.state.folio) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Folio.'
				})
			}
			if (!this.state.fecha) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha.'
				})
			}
			if (this.state.partidas.length === 0) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Por lo menos debe seleccionar un concepto.'
				})
			}
			
			
			let params = {
				id_proyecto: 2,
				rfc_emisor: this.state.rfc_emisor || '',
				sucursal_emisor: this.state.sucursal_emisor || '',
				certificado_emisor: this.state.certificado_emisor || '',
				observaciones_emisor: this.state.observaciones_emisor || '',
				enviar_a: this.state.correo_electronico || '',
				enviar_cc: this.state.enviar_cc || '',
				enviar_cco: this.state.enviar_cco || '',
				id_empresa: this.state.id_empresa || '',
				id_cliente: this.state.id_cliente || '',
				id_empresa_sucursal: this.state.id_empresa_sucursal || '',
				id_cat_regimen_fiscal: this.state.id_cat_regimen_fiscal || '',
				lugar_expedicion: this.state.lugar_expedicion || '',
				cliente_regimen_fiscal: this.state.id_cat_regimen_fiscal || '',
				cliente_numero_registro_if: this.state.cliente_numero_registro_if || '',
				cliente_codigo_postal: this.state.cliente_codigo_postal || '',
				cliente_rfc: this.state.cliente_rfc || '',
				cliente_nombre_razon_social: this.state.cliente_nombre_razon_social || '',
				partidas: this.state.partidas || [],
				desglose: this.state.desglose || '',
				id_cat_tipo_cfdi: this.state.id_cat_tipo_cfdi || '',
				id_cat_metodo_pago: this.state.id_cat_metodo_pago || '',
				id_cat_forma_pago: this.state.id_cat_forma_pago || '',
				id_cat_moneda: this.state.id_cat_moneda || '',
				id_cat_uso_cfdi: this.state.id_cat_uso_cfdi || '',
				id_empresa_sucursal_serie: this.state.id_empresa_sucursal_serie || '',
				fecha: this.state.fecha || '',
				serie: this.state.serie || '',
				folio: this.state.folio || '',
				condiciones_pago: this.state.condiciones_pago || '',
				clave_confirmacion: this.state.clave_confirmacion || '',
				tipo_cambio: this.state.tipo_cambio || '',
				produccion: this.state.produccion ? 1 : 0,
				activo: this.state.activo ? 1 : 0
			};
			
			console.log("Generar pdf", params);
			
			HttpRequest.exportFacturacion('pdfvisualizacion', ['pdfvisualizacion'], params, true, 800, 1000, true);
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	SubTabla = (item, key) => {
		let partidas = FieldsJs.Copy(this.state.partidas);
		for (let i = 0; i < partidas.length; i++) {
			if (i === key) {
				partidas[i].expandir = !partidas[i].expandir;
			}
		}
		
		this.setState({
			partidas: partidas
		})
	}
	
	RefreshCliente = (cliente) => {
		console.log(cliente);
		this.setState({
			//id_cliente: (cliente || '').id_cliente,
			enviar_a: (cliente || '').correo_electronico,
			enviar_cc: (cliente || '').copia_factura,
			cliente_regimen_fiscal: (cliente || '').id_cat_regimen_fiscal,
			cliente_numero_registro_if: (cliente || '').registro_identidad_fiscal,
			cliente_codigo_postal: (cliente || '').codigo_postal,
			cliente_rfc: (cliente || '').rfc,
			cliente_nombre_razon_social: cliente.razon_social || cliente.nombre_completo || '',
			nombre_razon_social: Number(cliente.id_cat_tipo_persona) === 1 ? cliente.rfc + " - " + cliente.nombre + " " + cliente.apellido_paterno + " " + cliente.apellido_materno : cliente.razon_social,
		})
		
	}
	
	handleChangeModalCliente = (valor) => {
		
		this.setState({
			edit_cliente: valor
		});
		
		this.props.RefreshList();
	}
	
	render() {
		
		const bg_icon_ticket = {
			backgroundImage: `url(${icon_ticket})`
		};
		
		const bg_carta = {
			backgroundImage: `url(${icon_xml})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		const bg_ticket = {
			backgroundImage: `url(${icon_pdf})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		const icon_ = {
			backgroundImage: `url(${icon_logo})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain',
			opacity: '0.4'
		};
		
		const desglose = this.calcularDesgoseTotales();
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		const bg_ver = {
			backgroundImage: `url(${(this.state.ver ? ver_menos : ver_mas)})`
		};
		
		return (
			<div>
				{this.state.edit_cliente ? (
					<ModalCliente
						tipo={'edit'}
						edit={this.state.edit_cliente}
						componente={(<div></div>)}
						item={this.props.item.cliente}
						RefreshList={this.props.RefreshList}
						showSnackBars={this.props.showSnackBars}
						handleChangeModalCliente={this.handleChangeModalCliente}
						history={this.props.history}
					/>
				) : null}
				
				{
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => this.open()}
						/>
					</Fragment>
				}
				
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'left'}>
								<Typography align={'left'}>Timbrar</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'center'}>
								<Typography className={'text-center px-16'} style={{opacity: 0.8, color: '#3f51b5', fontWeight: 'bold'}}>
									{this.state.nombre_razon_social}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'right'}>
								<div className={'vertical-inline'}>
									<Typography className={'v-center text-center px-16 margin-10-R'} style={{opacity: 0.8}}>
										{'No. Pedido: '}
									</Typography>
									<b className={'v-center px-18 ff-arial'}
									   style={{
										   background: "#e4e4e4",
										   padding: '1px 5px',
										   borderRadius: '4px',
										   color: "black"
									   }}>
										{this.state.numero_pedido}
									</b>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								<ModalCambiarClienteCFDI
									tipo={'add'}
									componente={(
										<Button color="primary" size={'small'} variant="contained">
											{'Timbrar a otro receptor'}
										</Button>
									)}
									item={this.props.item}
									RefreshList={this.RefreshCliente}
									showSnackBars={this.props.showSnackBars}
									history={this.props.history}
								/>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={3}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
								<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
								<Typography className={'text-left px-16'} style={{opacity: 0.8, color: 'black'}}>
									{"Datos del comprobante"}
								</Typography>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									required
									disabled
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_tipo_cfdi"
									label="Tipo de CDFI"
									value={this.state.id_cat_tipo_cfdi}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_tipo_cfdi.map(option => (
										<option key={option.id_cat_tipo_cfdi} value={option.id_cat_tipo_cfdi}>
											{option.tipo_cfdi}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_uso_cfdi"
									label="Uso CFDI"
									value={this.state.id_cat_uso_cfdi}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_uso_cfdi.map(option => (
										<option key={option.id_cat_uso_cfdi} value={option.id_cat_uso_cfdi}>
											{option.descripcion ? option.uso_cfdi + " - " + option.descripcion : option.uso_cfdi}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_metodo_pago"
									label="Método de Pago"
									value={this.state.id_cat_metodo_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_metodo_pago.map(option => (
										<option key={option.id_cat_metodo_pago} value={option.id_cat_metodo_pago}>
											{option.descripcion ? option.metodo_pago + " - " + option.descripcion : option.metodo_pago}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_forma_pago"
									label="Forma de Pago"
									value={this.state.id_cat_forma_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_forma_pago.map(option => (
										<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
											{option.descripcion ? option.forma_pago + " - " + option.descripcion : option.forma_pago}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									required
									disabled
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
										this.changeTipoMoneda(e.target.value);
									}}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_moneda"
									label="Tipo Moneda"
									value={this.state.id_cat_moneda}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_moneda.map(option => (
										<option key={option.id_cat_moneda} value={option.id_cat_moneda}>
											{option.descripcion ? option.moneda + " - " + option.descripcion : option.moneda}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									margin="none"
									name="tipo_cambio"
									label="Tipo Cambio"
									type="text"
									fullWidth
									disabled
									value={this.state.tipo_cambio}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
										this.handleChangeSerie(e.target.value);
									}}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_empresa_sucursal_serie"
									label="Serie"
									value={this.state.id_empresa_sucursal_serie}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_empresa_sucursal_serie.map(option => (
										<option key={option.id_empresa_sucursal_serie} value={option.id_empresa_sucursal_serie}>
											{option.serie}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={1} lg={1} xl={1}>
								<TextField
									margin="none"
									name="folio"
									label="Folio"
									type="text"
									fullWidth
									value={this.state.folio}
									onChange={this.handleChange}
									disabled
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
									}}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="fecha"
									label="Fecha de CFDI"
									value={this.state.fecha}
								>
									<option value={'1'}>Timbrar con fecha actual</option>
									<option value={'2'}>Fecha de ayer</option>
									<option value={'3'}>Fecha de hace dos días</option>
									<option value={'4'}>Fecha de hace tres días</option>
								</TextField>
								{/*<TextField
									margin="none"
									name="fecha"
									label="Fecha"
									type="text"
									fullWidth
									value={this.state.fecha}
									onChange={this.handleChange}
									disabled
								/>*/}
							</Grid>
							
							{this.state.ver && this.state.ws_lista_pagos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<div className={'padding-10 bg-lightgray'}>
										<table className={'w-100'}>
											<thead>
											<tr>
												<th className={'text-center px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Ticket
												</th>
												<th className={'text-left px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Forma de pago
												</th>
												<th className={'text-left px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Fecha de pago
												</th>
												<th className={'text-right px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Monto
												</th>
											</tr>
											</thead>
											<tbody>
											{this.state.ws_lista_pagos.map((item, key) => (
												<tr key={key}>
													<td align={'center'}>
														<div
															className={'bg-img-contain-x-center-y-center h-25-px w-25-px cursor-pointer'}
															style={bg_icon_ticket}
															onClick={() => this.ticket(item)}/>
													</td>
													<td>
														<Typography className={'px-12 text-left'}>
															{item.forma_pago}
														</Typography>
													</td>
													<td>
														<Typography className={'px-12 text-left'}>
															{DateFormat.FormatText(item.fecha_alta, true)}
														</Typography>
													</td>
													<td>
														<Typography className={'px-12 text-right'}>
															${FieldsJs.Currency(item.monto)}
														</Typography>
													</td>
												</tr>
											))}
											<tr>
												<td className={'border-desglose-t padding-10-T'}/>
												<td className={'border-desglose-t padding-10-T'}/>
												<td className={'border-desglose-t padding-10-T'}>
													<Typography className={'px-12 text-right'}>
														TOTAL PAGADO:
													</Typography>
												</td>
												<td className={'border-desglose-t padding-10-T'}>
													<Typography className={'px-12 text-right'}>
														${FieldsJs.Currency(this.state.ws_total_pagado)}
													</Typography>
												</td>
											</tr>
											<tr>
												<td/>
												<td/>
												<td>
													<Typography className={'px-12 text-right'}>
														SALDO:
													</Typography>
												</td>
												<td>
													<Typography className={'px-12 text-right'}>
														${FieldsJs.Currency(this.state.ws_por_pagar)}
													</Typography>
												</td>
											</tr>
											<tr>
												<td/>
												<td/>
												<td>
													<Typography className={'px-12 text-right'}>
														TOTAL VENTA:
													</Typography>
												</td>
												<td>
													<Typography className={'px-12 text-right'}>
														
														${FieldsJs.Currency(Number(this.state.ws_total_venta) - Number(this.state.ws_decsuento_venta))}
													</Typography>
												</td>
											</tr>
											</tbody>
										</table>
									</div>
								
								</Grid>
							) : ''}
							
							{this.state.ws_lista_pagos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"}>
									<div className={'cursor-pointer'} onClick={() => {
										this.setState({ver: !this.state.ver})
									}}>
										{!this.state.ver ? <Typography className={'px-12 text-center text-gray'}>Ver
											Pagos</Typography> : ''}
										<div className={'bg-img-contain-x-center-y-center h-20-px w-40-px'}
										     style={bg_ver}/>
										{this.state.ver ? <Typography className={'px-12 text-center text-gray'}>Ocultar
											Pagos</Typography> : ''}
									</div>
								</Grid>
							) : ''}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<table className={'desing-mark'}>
									<thead>
									<tr>
										<th align={"center"}>...</th>
										{/*<th align={"center"}>Número de identificación</th>*/}
										<th align={"center"}>Cantidad</th>
										<th align={"center"}>Unidad</th>
										<th align={"center"}>Descripción</th>
										<th align={"right"}>Valor Unitario</th>
										<th align={"right"}>Importe</th>
										<th align={"right"}>Trasladados</th>
										<th align={"right"}>Retenidos</th>
										<th align={"right"}>Descuentos</th>
										{/*<th align={"right"}>Partes</th>
															<th align={"right"}>Complemento Concepto</th>*/}
										<th align={"right"}>Acciones</th>
									</tr>
									</thead>
									<tbody>
									{this.state.partidas.map((item, index) => (
										<Fragment key={index}>
											<tr>
												<td align={'center'} className={'padding-20-L padding-20-R'}>
													<div className={'w-100'} align={'center'}>
														<div style={!!item.expandir ? bg_menos : bg_mas}
														     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
														     onClick={() => this.SubTabla(item, index)}
														/>
													</div>
												</td>
												{/*<td>{item.numero_identificacion}</td>*/}
												<td align={"center"}>{item.cantidad}</td>
												<td align={"center"}>{item.unidad_medida}</td>
												<td align={"center"}>{item.concepto}</td>
												<td align={"center"}>${item.precio_unitario}</td>
												<td align={"center"}>${item.importe}</td>
												<td align={"center"}>${item.trasladados}</td>
												<td align={"center"}>${item.retenidos}</td>
												<td align={"center"}>${item.descuento}</td>
												{/*<td align={"center"}>{item.partes}</td>
																<td align={"center"}>{item.complemento}</td>*/}
												<td align={'right'}>
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Eliminar"
														children={(
															<Fab
																size="small"
																aria-label="Eliminar"
																onClick={() => this.deletePartida(item, index)}
																style={{
																	marginLeft: "10px",
																	backgroundColor: "transparent",
																	color: "#808080",
																	boxShadow: "none",
																}}
																children={(
																	<DeleteOutlined/>
																)}
															/>
														)}
													/>
												</td>
											</tr>
											{item.expandir ? (
												<Fragment>
													<tr>
														<td></td>
														<td colSpan={10}>
															<Typography className={'text-left px-14'} style={{opacity: 0.8, color: 'black'}}>
																{item.impuestos_trasladados.length > 0 ? "Detalle de Impuestos Trasladados" : "La partida no tiene impuestos trasladados."}
															</Typography>
															{item.impuestos_trasladados.length > 0 ? (
																<table className={'desing-mark'}>
																	<thead>
																	<tr>
																		<th align={"center"}># Base</th>
																		<th align={"center"}>Impuesto</th>
																		<th align={"center"}>Tipo Factor</th>
																		<th align={"center"}>Tasa Cuota</th>
																		<th align={"right"}>Importe</th>
																	</tr>
																	</thead>
																	<tbody>
																	{item.impuestos_trasladados.map((itemt, keyt) => (
																		<tr key={keyt}>
																			<td align={"center"}>${itemt.base}</td>
																			<td align={"center"}>{itemt.impuesto_trasladado}</td>
																			<td align={"center"}>{itemt.tipo_factor}</td>
																			<td align={"center"}>{itemt.tasa_cuota}</td>
																			<td align={"right"}>${itemt.importe}</td>
																		</tr>
																	))}
																	
																	</tbody>
																</table>
															) : null}
														</td>
													</tr>
													<tr>
														<td></td>
														<td colSpan={10}>
															<Typography className={'text-left px-14'} style={{opacity: 0.8, color: 'black'}}>
																{item.impuestos_retenidos.length > 0 ? "Detalle de Impuestos Retenidos" : "La partida no tiene impuestos retenidos."}
															</Typography>
															{item.impuestos_retenidos.length > 0 ? (
																<table className={'desing-mark'}>
																	<thead>
																	<tr>
																		<th align={"center"}># Base</th>
																		<th align={"center"}>Impuesto</th>
																		<th align={"center"}>Tipo Factor</th>
																		<th align={"center"}>Tasa Cuota</th>
																		<th align={"right"}>Importe</th>
																	</tr>
																	</thead>
																	<tbody>
																	{item.impuestos_retenidos.map((itemr, keyr) => (
																		<tr key={keyr}>
																			<td>${FieldsJs.Currency(itemr.base)}</td>
																			<td>{itemr.impuesto_retenido}</td>
																			<td>{itemr.tipo_factor}</td>
																			<td>{itemr.tasa_cuota}</td>
																			<td>${FieldsJs.Currency(itemr.importe)}</td>
																		</tr>
																	))}
																	</tbody>
																</table>
															) : null}
														</td>
													</tr>
												</Fragment>
											) : null}
										</Fragment>
									
									))}
									
									
									{!(this.state.partidas.length > 0) ? (
										<tr>
											<td colSpan={12}
											    align={'center'}>
												<VistaVacia
													numero={null}
													mensaje={'Ningún concepto agregado.'}
													image={carrito_de_compra_3}
													padding={'20px'}
													paddingText={'20px'}
													height={'80px'}
													width={'80px'}
												/>
											</td>
										</tr>
									) : null}
									<tr>
										<td colSpan={7}/>
										<td colSpan={2} align={"right"}>Subtotal</td>
										<td colSpan={2} align={"right"}>${desglose.subtotal}</td>
									</tr>
									<tr>
										<td colSpan={7}/>
										<td colSpan={2} align={"right"}>Descuentos</td>
										<td colSpan={2} align={"right"}>${desglose.descuento}</td>
									</tr>
									{desglose.trasladados > 0 ? (
										<tr>
											<td colSpan={7}/>
											<td colSpan={2} align={"right"}>Impuestos Trasladados</td>
											<td colSpan={2} align={"right"}>${desglose.trasladados}</td>
										</tr>
									) : null}
									{desglose.retenidos > 0 ? (
										<tr>
											<td colSpan={7}/>
											<td colSpan={2} align={"right"}>Impuestos Retenidos</td>
											<td colSpan={2} align={"right"}>${desglose.retenidos}</td>
										</tr>
									) : null}
									<tr>
										<td colSpan={7}/>
										<td colSpan={2} align={"right"}>Total</td>
										<td colSpan={2} align={"right"}>${desglose.total}</td>
									</tr>
									</tbody>
								</table>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
							</Grid>
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<div className={'vertical-inline w-100 h-100'}>
									<div className={'w-100 h-100-px'} style={icon_}></div>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<fieldset className={'borde-punteado-gray-2 text-left h-92'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Verificación</legend>
									
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												required
												name="rfc_emisor"
												label="RFC Emisor"
												type="text"
												fullWidth
												value={this.state.rfc_emisor}
												onChange={this.handleChange}
												disabled
											/>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												required
												name="sucursal_emisor"
												label="Sucursal"
												type="text"
												fullWidth
												value={this.state.sucursal_emisor}
												onChange={this.handleChange}
												disabled
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											
											<TextField
												multiline
												name="observaciones_emisor"
												label="Observaciones"
												type="text"
												fullWidth
												rows={3}
												value={this.state.observaciones_emisor}
												onChange={this.handleChange}
											/>
										
										</Grid>
									
									</Grid>
								
								</fieldset>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<fieldset className={'borde-punteado-gray-2 text-left'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Datos de envío</legend>
									
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												required
												name="cliente_nombre_razon_social"
												label="Cliente: "
												type="text"
												fullWidth
												value={this.state.cliente_nombre_razon_social}
												onChange={this.handleChange}
												disabled
											/>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												required
												name="enviar_a"
												label="Enviar a: "
												type="text"
												fullWidth
												value={this.state.enviar_a}
												onChange={this.handleChange}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<TextField
												required
												name="enviar_cc"
												label="Cc:"
												type="text"
												fullWidth
												value={this.state.enviar_cc}
												onChange={this.handleChange}
											/>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
											<Button onClick={() => this.visualizacionPDF()} color="primary" size={'small'} variant="contained" startIcon={<Visibility/>}>
												{'Previsualizar'}
											</Button>
										</Grid>
									
									
									</Grid>
								
								</fieldset>
							
							</Grid>
						
						</Grid>
						
						<br/>
					
					</DialogContent>
					
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{'Cancelar'}
							</Button>
							{FieldsJs.Field(this.state.enviar_a) && FieldsJs.Field(this.state.cliente_rfc) ? (
								<Button color="primary" size={'small'} variant="contained" onClick={() => this.timbradoFacturacion()}>
									{'Timbrar'}
								</Button>
							) : (
								<Button color="primary" size={'small'} variant="contained" disabled>
									{'Timbrar'}
								</Button>
							)}
						
						</Fragment>
					</DialogActions>
				
				</Dialog>
				
				<Dialog open={this.state.modal.open2} onClose={() => this.close2()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Typography align={'left'}>Factura Realizada</Typography>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={3}>
							
							<Grid item xs={12}>
								<Typography align={'center'}>Selecciona una opción:</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								
								<div className={'borde-punteado-gray-2 br-10 cursor-pointer text-center'} style={{borderRadius: '5%'}} onClick={() => this.exportarFacturacion(this.state.archivo_xml)}>
									<div style={bg_carta} className={'h-70-px margin-15'}/>
									<Typography className={'margin-10'}>
										Descargar XML
									</Typography>
								</div>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<div className={'vertical-inline w-100 h-100'}>
									<div className={'w-100 h-100-px'} style={icon_}></div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								
								<div className={'borde-punteado-gray-2 br-10 cursor-pointer text-center'} style={{borderRadius: '5%'}} onClick={() => this.exportarFacturacion(this.state.archivo_xml, 'PDF')}>
									<div style={bg_ticket} className={'h-70-px margin-15'}/>
									<Typography className={'margin-10'}>
										Descargar PDF
									</Typography>
								</div>
							
							</Grid>
						
						</Grid>
						
						<br/>
					
					</DialogContent>
					
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close2} color="primary">
								{'Cerrar'}
							</Button>
						</Fragment>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTimbrarCFDI.propTypes = {
	componente: PropTypes.element,
	listDropbox: PropTypes.array,
	open: PropTypes.bool,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.element,
	showSnackBars: PropTypes.func.isRequired,
	selectCloseModal: PropTypes.func.isRequired,
};

export default ModalTimbrarCFDI;
