import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {Badge, Popper} from "@material-ui/core";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import Typography from "@material-ui/core/Typography";
import {FieldsJs, str_search} from "../../../../settings/General/General";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ModalAccionPDFVentas from "./ModalAccionPdfVentas";
import Grid from "@material-ui/core/Grid";

import {
	AccountBalanceOutlined,
	AttachMoneyOutlined,
	CallMadeOutlined,
	CancelOutlined,
	CheckCircleOutlineOutlined,
	CheckOutlined,
	EditOutlined,
	ForwardOutlined,
	HowToRegOutlined,
	ImageAspectRatioOutlined,
	LocalShipping,
	MailOutline,
	PictureAsPdfOutlined,
	PostAdd,
	SearchOutlined,
	WatchLaterOutlined,
	Web,
	PlayForWork,
	AttachMoney,
	MoreVert,
	MonetizationOn,
	Close
} from "@material-ui/icons";
import ModalPagoCFDI from "./ModalPagoCFDI";
import ModalTimbrarCFDI from "./ModalTimbrarCFDI";
import ModalMasivoCFDI from "./ModalMasivoCFDI";
import ModalSeleccionarCliente from "./ModalSeleccionarCliente";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import ModalAprobarCotizacion from "./ModalAprobarCotizacion";
import ModalTipoTicket from "./ModalTipoTicket";
import ModalInsumos from "./ModalInsumos";

import ModalEnviarAlmacen from "./ModalEnviarAlmacen";
import ModalHorasTrabajadas from "./ModalHorasTrabajadas";
import ModalEnviarCliente from "./ModalEnviarCliente";
import ModalEnviarTodoAlmacen from "./ModalEnviarTodoAlmacen";
import ModalEnviarTodoCliente from "./ModalEnviarTodoCliente";
import ModalVerTimbradoCFDI from "./ModalVerTimbradoCFDI";
import ModalTimbrarxPartidasCFDI from "./ModalTimbrarxPartidasCFDI";


class PopoverMenu extends Component {
	Usr = {};
	state = {};
	
	constructor(props, context) {
		super(props, context);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {open_popover: false, anchorEl: null, go_to_notificaciones: false};
	}
	
	handleToggle = (event) => {
		this.setState({
			open_popover: !this.state.open_popover,
			anchorEl: this.state.anchorEl ? null : event.currentTarget
		});
	};
	
	show = (event) => {
		this.setState({
			open_popover: true,
			anchorEl: event.currentTarget
		});
	};
	
	hide = (event) => {
		this.setState({
			open_popover: false,
			anchorEl: null
		});
	};
	
	handleClose = (tipo) => {
		this.setState({open: false, anchorEl: null});
		if (this.props.open_popover) {
			this.setState({open_popover: false});
		}
	};
	
	handleListKeyDown = (event) => {
		/*if (event.key === "Tab") {
			event.preventDefault();
			this.setState({open_popover: false});
		}*/
		//this.setState({open_popover: false});
	};
	
	go = (u) => {
		let isno = str_search(window.location.pathname, 'notificaciones') ? 1 : 0;
		if (!isno) {
		
		} else {
			this.setState({go_to_notificaciones: false});
		}
	};
	
	is_cotizacion = (item) => {
		return item.cotizacion === 1 && item.factura === 0 && item.nota_sencilla === 0 && item.interno === 0;
	};
	
	handleOutsideClick = (event) => {
		if (this.box && !this.box.current.contains(event.target)) {
			alert('you just clicked outside of box!');
		}
	}
	
	render() {
		
		return (
			<div style={{
				marginLeft: "0px",
				marginTop: "0px",
			}}
			     className="popover-menu"
				//onMouseEnter={this.show}
				//onMouseLeave={this.hide}
			
			>
				<Button
					aria-controls={this.state.open ? "menu-list-grow" : null}
					aria-haspopup="true"
					onClick={this.handleToggle}
					style={{minWidth: '31px'}}
				
				>
					<Badge badgeContent={this.props.badgecount} color="primary" style={{color: 'gray'}}>
						{this.props.icon}
					</Badge>
				</Button>
				
				<Popper
					placement="bottom"
					className="tooltip"
					anchorEl={this.state.anchorEl}
					open={this.state.open_popover}
					role={undefined}
					transition
					disablePortal={false}
				>
					{({TransitionProps, placement}) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === "bottom" ? "center top" : "center bottom"
							}}
						>
							<Paper>
								<Grid container spacing={1}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'left'}>
										<Typography variant={'h5'} className={'margin-5 margin-10-L text-black px-13 text-left'} style={{fontWeight: 'bold'}}>
											{this.props.title}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6} align={'right'}>
										<IconButton aria-label="Cerrar" onClick={() => this.hide()} className={'padding-3 margin-5-R'} style={{color: 'gray'}}>
											<Close/>
										</IconButton>
									</Grid>
								</Grid>
								<ClickAwayListener onClickAway={this.handleClose}>
									{/*<div>*/}
									<MenuList
										style={{maxWidth: '300px'}}
										autoFocusItem={this.state.open_popover}
										id="menu-list-grow"
										onKeyDown={this.handleListKeyDown}
									>
										<Fragment>
											
											{(this.props.item.id_cat_venta_status_pago >= 3 || this.is_cotizacion(this.props.item) || this.props.item.interno === 1) ? (
												<div className={'v-center'}>
													{this.is_cotizacion(this.props.item) ? (
														<MenuItem onClick={() => {
															this.handleClose();
															this.props.selectModal(this.props.item, 'componenteModalVerOrdenVenta')
														}}
														          style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
															<ListItemIcon>
																<PictureAsPdfOutlined fontSize="small"/>
															</ListItemIcon>
															<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Ver cotización</Typography>
														</MenuItem>
													) : (
														<MenuItem onClick={() => {
															this.handleClose();
															this.props.selectModal(this.props.item, 'componenteModalVerOrdenVenta')
														}}
														          style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
															<ListItemIcon>
																<PictureAsPdfOutlined fontSize="small"/>
															</ListItemIcon>
															<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Ver orden de venta</Typography>
														</MenuItem>
													)
													}
												</div>
											) : ''}
											
											<div className={'v-center'}>
												<MenuItem onClick={() => {
													this.handleClose();
													this.props.selectModal(this.props.item, 'componenteModalAprobarCotizacion')
												}}
												          style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
													<ListItemIcon>
														<ImageAspectRatioOutlined fontSize="small"/>
													</ListItemIcon>
													<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>{this.props.tipo_vista === "cotizacion" ? "Aprobar cotización" : "Cambiar tipo de orden"}</Typography>
												</MenuItem>
											</div>
											
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<div className={'v-center'}>
													<MenuItem onClick={() => {
														this.handleClose();
														this.props.selectModal(this.props.item, 'componenteModalSeleccionarCliente')
													}} style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
														<ListItemIcon>
															<HowToRegOutlined fontSize="small" color={'primary'}/>
														</ListItemIcon>
														<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Seleccionar o cambiar cliente</Typography>
													</MenuItem>
												</div>
											) : ''}
											
											
											{!this.is_cotizacion(this.props.item) ? (
												<MenuItem onClick={() => {
													this.handleClose();
													this.props.selectModal(this.props.item, 'componenteModalRealizarPagos');
													this.props.OrdenDeVentaContinuar(this.props.item);
												}} style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
													<ListItemIcon>
														<AttachMoneyOutlined fontSize="small" className={this.props.item.porpagar > 0 ? 'text-danger' : ''}/>
													</ListItemIcon>
													<Typography variant="inherit" style={{fontWeight: 'bold'}}
													            className={'text-left text-black px-13'}>{this.props.item.porpagar > 0 ? (this.props.item.pagado > 0 ? 'Ver ticket o realizar pagos' : 'Realizar pagos') : 'Ver ticket o desglose de pagos'}</Typography>
												</MenuItem>
											) : ""}
											
											<MenuItem onClick={() => {
												this.handleClose();
												this.props.selectModal(this.props.item, 'componenteModalEnviarNota');
											}} style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
												<ListItemIcon>
													<MailOutline fontSize="small"/>
												</ListItemIcon>
												<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Enviar nota de venta</Typography>
											</MenuItem>
											
											<MenuItem onClick={() => {
												this.handleClose();
												this.props.selectModal(this.props.item, 'componenteModalInsumo');
											}} style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
												<ListItemIcon>
													<Web fontSize="small" style={{color: 'rgba(0,120,24,0.7)'}}/>
												</ListItemIcon>
												<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Agregar Insumo</Typography>
											</MenuItem>
											
											
											{this.props.item.factura === 1 ? (
												<Fragment>
													<MenuItem onClick={() => {
														this.handleClose();
														this.props.selectModal(this.props.item, 'componenteModalMasivoCFDI');
													}} style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
														<ListItemIcon>
															<PostAdd fontSize="small" style={{color: this.props.item.archivos.length > 0 ? 'rgba(0,120,24,0.7)' : 'rgba(0, 0, 0, 0.54)'}}/>
														</ListItemIcon>
														<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Subir Factura</Typography>
													</MenuItem>
													
													{!this.props.item.timbrado_cfdi ? (
														<MenuItem onClick={() => {
															this.handleClose();
															this.props.selectModal(this.props.item, 'componenteModalTimbrarCFDI');
														}} style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
															<ListItemIcon>
																<PlayForWork fontSize="small" style={{color: this.props.item.archivos.length > 0 ? 'rgba(0,120,24,0.7)' : 'rgba(0, 0, 0, 0.54)'}}/>
															</ListItemIcon>
															<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Timbrar</Typography>
														</MenuItem>
													) : null}
													
													{this.props.item.timbrado_cfdi ? (
														<MenuItem onClick={() => {
															this.handleClose();
															this.props.selectModal(this.props.item, 'componenteModalVerTimbradoCFDI');
														}} style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
															<ListItemIcon>
																<MonetizationOn fontSize="small" style={{color: '#3f51b5'}}/>
															</ListItemIcon>
															<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Ver CFDI Timbrado</Typography>
														</MenuItem>
													) : null}
													
													<MenuItem onClick={() => {
														this.handleClose();
														this.props.selectModal(this.props.item, 'componenteModalTimbrarxPartidasCFDI');
													}} style={{zIndex: `${15000} ' !important'`, maxWidth: '300px', whiteSpace: 'pre', textOverflow: 'ellipsis', overflow: 'hidden'}}>
														<ListItemIcon>
															<PlayForWork fontSize="small" style={{color: '#bf8937'}}/>
														</ListItemIcon>
														<Typography variant="inherit" style={{fontWeight: 'bold'}} className={'text-left text-black px-13'}>Timbrar en exhibiciones</Typography>
													</MenuItem>
												
												
												</Fragment>
											) : null}
										
										
										</Fragment>
									</MenuList>
								</ClickAwayListener>
								{/*</div>*/}
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		);
	}
	
	/*render() {
		return (
			<div className="login-badges" style={{display: "flex", flexDirection: "row"}}>
				<PopOverMenu
					icon={this.props.icon}
					badgecount={this.props.cant}
					title={this.props.title}
					item={this.props.item}
				/>
			</div>
		);
	}*/
}


PopoverMenu.propTypes = {
	icon: PropTypes.element,
	item: PropTypes.object,
	cant: PropTypes.number,
	title: PropTypes.string,
	watchHorasTrabajadas: PropTypes.func.isRequired,
	watchAsignarCliente: PropTypes.func.isRequired,
	watchEnviarTodoAlmacen: PropTypes.func.isRequired,
	watchEnviarTodoCliente: PropTypes.func.isRequired,
	watchEnviarAlmacen: PropTypes.func.isRequired,
	watchEnviarCliente: PropTypes.func.isRequired,
	watchAprobarCotizacion: PropTypes.func.isRequired,
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	is_cotizacion: PropTypes.func.isRequired,
	openModal: PropTypes.func.isRequired,
	history: PropTypes,
	open_popover: PropTypes.bool,
	selectModal: PropTypes.func,
	
	componenteModalAprobarCotizacion: PropTypes.element,
	componenteModalSeleccionarCliente: PropTypes.element,
};

export default IntegrationNotistack(PopoverMenu);
