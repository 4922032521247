import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {EstadoCuentasService} from "../../../../services/EstadoCuentasService/EstadoCuentasService";
import {CONFIG} from "../../../../settings/Config/Config";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../../../settings/General/General";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from '@material-ui/core/Fab';
import ModalVerOrdenVenta from "../../OrdenDeVenta/Includes/ModalVerOrdenVenta";
import ModalCompras from "../../Compras/Includes/ModalCompras";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el sexo ${item.sexo}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				EstadoCuentasService.Eliminar(item.id_cat_sexo).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	sumTotales = (tipo) => {
		let lista = FieldsJs.Copy(this.props.lista);
		let total = 0;
		switch (tipo) {
			case 0:
				for (let i = 0; i < lista.length; i++) {
					if (FieldsJs.inArray(this.props.ids_cat_forma_pago, lista[i].id_cat_forma_pago)) {
						if (lista[i].id_compra > 0) {
							total = total + Number(lista[i].total);
						}
					}
				}
				break;
			case 1:
				for (let i = 0; i < lista.length; i++) {
					if (FieldsJs.inArray(this.props.ids_cat_forma_pago, lista[i].id_cat_forma_pago)) {
						if (lista[i].id_venta > 0 || lista[i].id_fondeo_cuenta > 0) {
							total = total + Number(lista[i].total);
						}
					}
				}
				break;
			case 2:
				for (let i = 0; i < lista.length; i++) {
					if (FieldsJs.inArray(this.props.ids_cat_forma_pago, lista[i].id_cat_forma_pago)) {
						if (lista[i].saldo > 0) {
							total = total + Number(lista[i].saldo);
						}
					}
				}
				break;
			case 3:
				for (let i = 0; i < lista.length; i++) {
					total = total + Number(lista[i].comision);
				}
				break;
		}
		
		return total;
	};
	
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-mark'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>Fecha</TableCell>
							<TableCell component="th" align={'left'}>Concepto</TableCell>
							<TableCell component="th" align={'left'}>Referencia</TableCell>
							{this.props.value_tabs === 1 ? (
								<TableCell component="th" align={'left'}>Forma de pago</TableCell>
							) : null}
							<TableCell component="th" align={'left'}>Cargo</TableCell>
							{this.props.value_tabs === 1 ? (
								<TableCell component="th" align={'left'}>Comisión</TableCell>
							) : null}
							<TableCell component="th" align={'left'}>Abono</TableCell>
							<TableCell component="th" align={'left'}>Saldo</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
							return (
								<Fragment>
									{FieldsJs.inArray(this.props.ids_cat_forma_pago, item.id_cat_forma_pago) ?
										<Fragment>
											<TableRow key={key}>
												<TableCell align={'left'} className={'w-90-px'}>{DateFormat.FormatText(item.fecha_alta)}</TableCell>
												<TableCell align={'left'}>{item.concepto || 'Sin registrar'}</TableCell>
												<TableCell align={'left'} className={'w-90-px'}>
													{item.id_compra ? (
														<ModalCompras
															id_compra={item.id_compra}
															item={item.item}
															tipo={'view'}
															icono={item.referencia}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													) : (
														<ModalVerOrdenVenta
															tipo={'ver'}
															item={item}
															id_venta={item.id_venta}
															id_compra={item.id_compra}
															componente={item.referencia}
															RefreshList={this.props.RefreshList}
															showSnackBars={this.props.showSnackBars}
														/>
													)
													}
												</TableCell>
												{this.props.value_tabs === 1 ? (
													<TableCell align={'left'}>{item.forma_pago}</TableCell>
												) : null}
												<TableCell align={'left'}>
													{item.id_compra > 0 ? '$' + FieldsJs.Currency(item.total) : '$' + FieldsJs.Currency(0)}
												</TableCell>
												{this.props.value_tabs === 1 ? (
													<TableCell align={'left'}>
														{/*{item.id_cat_forma_pago === 24 ? (
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Comisión por SPEI">
																<span style={{color: 'white', background: 'rgb(163, 0, 6)', padding: '2px 5px', borderRadius: '6px'}}>
																	{FieldsJs.Currency(item.comision)}
																</span>
															</Tooltip>
														) : FieldsJs.Currency(item.comision)
														}*/}
														{FieldsJs.Currency(item.comision)}
													</TableCell>
												) : null}
												<TableCell align={'left'}>
													{item.id_fondeo_cuenta > 0 || item.id_venta > 0 ? item.comision > 0 ? (
															<Tooltip TransitionComponent={Zoom} placement={"top"} title={FieldsJs.Currency(item.total - item.comision)}>
															<span style={{color: 'white', background: 'rgb(163, 0, 6)', padding: '2px 5px', borderRadius: '6px'}}>
																{FieldsJs.Currency(item.total)}
															</span>
															</Tooltip>
														)
														: '$' + FieldsJs.Currency(item.total)
														: '$' + FieldsJs.Currency(0)}
												</TableCell>
												<TableCell align={'left'}>${FieldsJs.Currency(item.saldo)}</TableCell>
											</TableRow>
										</Fragment>
										: null
									}
								</Fragment>
							)
						})}
						<TableRow>
							<TableCell colSpan={this.props.value_tabs === 1 ? 4 : 3} align={'left'} className={'text-right'}><strong>TOTAL</strong></TableCell>
							<TableCell align={'left'}><strong>${FieldsJs.Currency(this.sumTotales(0))}</strong></TableCell>
							{this.props.value_tabs === 1 ? (
								<TableCell align={'left'}><strong>${FieldsJs.Currency(this.sumTotales(3))}</strong></TableCell>
							) : null}
							<TableCell colSpan={2} align={'left'}><strong>${FieldsJs.Currency(this.sumTotales(1))}</strong></TableCell>
							{/*<TableCell align={'left'}><strong>${FieldsJs.Currency(this.sumTotales(2))}</strong></TableCell>*/}
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	ids_cat_forma_pago: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	value_tabs: PropTypes.number
};

export default ListaTabla;
