import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {VentasService} from "../../../../services/VentasService/VentasService";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";


class ModalHorasTrabajadas extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			id_venta: null,
			horas: null,
			item: {},
		};
	}
	
	openModal = () => {
		const {id_venta, item} = this.props;
		this.setState({
			modal: {
				open: true
			},
			
			id_venta: id_venta,
			horas: '',
			item: item,
		});
		console.log(this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	save = () => {
		try {
			
			if (!(this.state.horas > 0)) {
				throw Object({
					status: false,
					mensaje: "Agrega las horas trabajadas"
				});
			}
			
			VentasService.VentaGuardarHorasTrabajadas(this.state.id_venta, this.state.item.id_venta_producto, this.state.horas).then((response) => {
				this.closeModal();
				this.props.watchHorasTrabajadas();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	render() {
		
		return (
			<div>
				
				<div onClick={() => this.openModal()}>
					{this.props.icono}
				</div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						Horas trabajadas
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={3} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								
								<Typography
									component="span"
									variant="body2"
									style={{
										display: 'inline',
									}}
									color="textPrimary"
									className={'px-16'}
								>
									Agrega las horas trabajadas
								</Typography>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								
								<Typography
									component="span"
									variant="body2"
									style={{
										display: 'inline',
									}}
									color="textPrimary"
									className={'px-12'}
								>
									{this.state.item.venta_producto} <br/>{this.state.item.venta_descripcion}
								</Typography>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								
								<TextField
									type="text"
									margin="none"
									inputProps={{
										style: {
											width: "100px",
											fontSize: "35px"
										},
										className: "text-center padding-0 margin-0",
										placeholder: "0"
									}}
									// onKeyPress={EnteroSolo}
									onChange={(e) => {
										this.setState({
											horas: e.target.value
										});
									}}
									value={this.state.horas}
								/>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Button onClick={() => this.closeModal()} color="primary">
							Cerrar
						</Button>
						<Button onClick={() => this.save()} color="primary">
							Aceptar
						</Button>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}


ModalHorasTrabajadas.propTypes = {
	id_venta: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
	icono: PropTypes.element.isRequired,
	watchHorasTrabajadas: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalHorasTrabajadas;
