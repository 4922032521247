import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import TextField from "@material-ui/core/TextField";
import {TimbradoFacturacionExtService} from "../../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import {DialogActions} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {Send, Visibility, AddOutlined} from "@material-ui/icons";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import icon_xml from "../../../../assets/img/icons/xml.svg";
import icon_pdf from "../../../../assets/img/icons/pdf.svg";
import icon_logo from "../../../../assets/img/logo.svg";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {VentasService} from "../../../../services/VentasService/VentasService";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import FiltrosCliente from "../../Cliente/Includes/FiltrosCliente";
import ListaTabla from "../../Cliente/Includes/ListaTabla";
import Paginacion from "../../../Include/Paginacion/Paginacion";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import ModalCliente from "../../Cliente/Includes/ModalCliente";
import BotonFlotante from "../../../../includes/BotonFlotante";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";


class ModalVentasClientes extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_cat_clientes: [],
		};
	}
	
	RefreshList = () => {
		this.Listar();
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	Listar = () => {
		ClienteService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_cat_clientes = response.data;
			
			this.setState({
				listar_cat_clientes: listar_cat_clientes,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_cat_clientes: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
			filtro: {}
		});
		console.log(this.state, props);
		this.Listar();
		
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.setState({
			filtro: {},
		})
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	
	add = () => {
		let {item} = this.props;
		
		this.setState({
			filtro: {},
		})
		
		this.open();
	};
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Clientes
						</Fab>
					</Fragment>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Clientes
					</DialogTitle>
					
					<DialogContent>
						
						<div className='Catalogos'>
							<FiltrosCliente
								AplicarFiltros={this.AplicarFiltros}
								HandleFiltro={this.HandleFiltro}
								showSnackBars={this.props.showSnackBars}
							/>
							
							<div className={'form margin-30-B'}>
								
								{this.state.listar_cat_clientes.length > 0 ? (
									<Fragment>
										<ListaTabla
											lista={this.state.listar_cat_clientes}
											RefreshList={this.RefreshList}
											showSnackBars={this.props.showSnackBars}
										/>
										<Paginacion
											total={this.state.paginacion.total}
											page={this.state.paginacion.page}
											limit={this.state.paginacion.limit}
											rangos={this.state.paginacion.rangos}
											onClick={(data) => this.AplicarPaginacion(data)}
										/>
									</Fragment>
								) : (
									<VistaVacia
										numero={0}
										mensaje={'No se encontraron datos.'}
									/>
								)}
							
							</div>
							
							<ModalCliente
								tipo={'add'}
								item={{}}
								RefreshList={this.RefreshList}
								showSnackBars={this.props.showSnackBars}
								componente={<BotonFlotante icono={<AddOutlined/>}/>}
							/>
						</div>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={12} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalVentasClientes.propTypes = {
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	item: PropTypes.object,
	pago: PropTypes.object,
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalVentasClientes;
