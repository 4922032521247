import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const PaqueteService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Paquete_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalle: (id_cat_paquete) => {
		let params = {
			id_cat_paquete: id_cat_paquete
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Paquete_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_periodo_inicio = (form.fecha_periodo_inicio || null) ? DateFormat.FormatSql(form.fecha_periodo_inicio) : null;
		let fecha_periodo_fin = (form.fecha_periodo_fin || null) ? DateFormat.FormatSql(form.fecha_periodo_fin) : null;
		
		let params = {
			id_cat_paquete: null,
			nombre: form.nombre,
			codigo: form.codigo,
			fecha_periodo_inicio: fecha_periodo_inicio,
			fecha_periodo_fin: fecha_periodo_fin,
			productos: form.productos,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Paquete_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_periodo_inicio = (form.fecha_periodo_inicio || null) ? DateFormat.FormatSql(form.fecha_periodo_inicio) : null;
		let fecha_periodo_fin = (form.fecha_periodo_fin || null) ? DateFormat.FormatSql(form.fecha_periodo_fin) : null;
		
		let params = {
			id_cat_paquete: form.id_cat_paquete,
			nombre: form.nombre,
			codigo: form.codigo,
			fecha_periodo_inicio: fecha_periodo_inicio,
			fecha_periodo_fin: fecha_periodo_fin,
			productos: form.productos,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Paquete_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_paquete) => {
		let params = {
			id_cat_paquete: id_cat_paquete
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Paquete_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ObtenerCodigo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Paquete_Codigo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
