import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import EditOutlined from "@material-ui/icons/EditOutlined";
import SearchOutlined from "@material-ui/icons/SearchOutlined";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<table className={classes.table + ' desing-acresco'}>
					{lista.map((item0, key1) => (
						<Fragment key={key1}>
							<thead>
							<tr>
								<th style={{textAlign: 'center', height: '40px'}}>{item0.grupo}</th>
							</tr>
							</thead>
							<tr>
								<td style={{background: 'gray'}}>
									<div style={{padding: '10px'}}>
										<table className={classes.table + ' desing-acresco'}>
											<thead>
												<tr>
													<td
														component="th"
														align={'center'}
														className={'w-40-px padding-20-L padding-20-R'}>
														ID
													</td>
													<td component="th" align={'left'}>Tipo de requerimiento</td>
													<td component="th" align={'center'}>Con consto adicional</td>
													<td component="th" align={'center'}>Radio selección</td>
													<td component="th" align={'center'}>Activo</td>
													<td component="th" align={'center'} className={'w-110-px'}>Acciones</td>
												</tr>
											</thead>
											<tbody>
												{item0.cat_tipo_requerimiento.map((item, key) => {
													return (
														<tr key={key}>
															<td align={'center'} className={'padding-20-L padding-20-R'}>
																{item.id_cat_tipo_requerimiento}
															</td>
															<td align={'left'}>{item.tipo_requerimiento}</td>
															<td align={'center'}>
																{item.con_costo_adicional === 1 ? 'Si' : 'No'}
															</td>
															<td align={'center'}>
																{item.cat_opcion_requerimiento.map(value => (
																	<Fragment>
																		<div>{value.opcion_requerimiento}</div>
																	</Fragment>
																))}
															</td>
															<td align={'center'}>
																{item.activo === 1 ? (
																	<div style={{
																		width: "50px",
																		color: "white",
																		borderRadius: "3px",
																		padding: "2px 10px",
																		background: "darkgreen",
																		display: "inline-grid",
																	}}>Activo</div>
																) : (
																	<div style={{
																		width: "50px",
																		color: "white",
																		borderRadius: "3px",
																		padding: "2px 10px",
																		background: "#901C30",
																		display: "inline-grid",
																	}}>Inactivo</div>
																)}
															</td>
															<td align={'center'} className={'padding-5-L padding-5-R'}>
																
																<div className={'w-auto vertical-inline'}>
																	<div className={'v-center'}>
																		<Tooltip TransitionComponent={Zoom} placement={"top"}
																		         title="Eliminar">
																			<IconButton aria-label="Eliminar"
																			            onClick={() => this.props.delete(item)}>
																				<DeleteOutlined/>
																			</IconButton>
																		</Tooltip>
																	</div>
																	<div className={'v-center'}>
																		<Tooltip TransitionComponent={Zoom} placement={"top"}
																		         title="Editar">
																			<IconButton aria-label="Editar"
																			            onClick={() => this.props.edit(item)}>
																				<EditOutlined/>
																			</IconButton>
																		</Tooltip>
																	</div>
																	<div className={'v-center'}>
																		<Tooltip TransitionComponent={Zoom} placement={"top"}
																		         title="Detalles">
																			<IconButton aria-label="Detalles"
																			            onClick={() => this.props.view(item)}>
																				<SearchOutlined/>
																			</IconButton>
																		</Tooltip>
																	</div>
																</div>
															
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</Fragment>
					))}
				</table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
};

export default withStyles(styles)(ListaTabla);
