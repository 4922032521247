import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const CotizadorProductoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Producto_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cotizador_producto: form.id_cotizador_producto,
			cotizador_producto: form.cotizador_producto,
			descripcion: form.descripcion,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Producto_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cotizador_producto: form.id_cotizador_producto,
			cotizador_producto: form.cotizador_producto,
			descripcion: form.descripcion,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Producto_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cotizador_producto) => {
		let params = {
			id_cotizador_producto: id_cotizador_producto
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Producto_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
