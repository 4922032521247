import React, {Component, Fragment} from 'react';
import './Monitor.css';
import {MonitorService} from "../../../services/MonitorService/MonitorService";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {Replay} from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import icon_i from "../../../assets/img/icons/icon-interno.svg";
import icon_f from "../../../assets/img/icons/icon-factura.svg";
import icon_c from "../../../assets/img/icons/icon-cotizacion.svg";
import icon_n from "../../../assets/img/icons/icon-nota-sencilla.svg";
import {BarraProgreso} from "../../Include/MiniComponents/BarraProgreso";
import {$cError, $cInfo, $cSuccess, FieldsJs} from '../../../settings/General/General';
import {Chart} from "react-google-charts";

import PusherWatchChannelEvent, {PusherSubscribe, PusherUnSubscribe} from "../../../settings/Pusher/Pusher";
import ModalVerOrdenVenta from "../OrdenDeVenta/Includes/ModalVerOrdenVenta";

const ItemOrden = ({item}) => {
	
	const textoClaseIcono = "bg-img-contain-x-center-y-center h-15-px w-15-px cursor-pointer margin-2-R margin-2-L";
	
	const bg_i = {
		backgroundImage: `url(${icon_i})`
	};
	
	const bg_f = {
		backgroundImage: `url(${icon_f})`
	};
	
	const bg_c = {
		backgroundImage: `url(${icon_c})`
	};
	
	const bg_n = {
		backgroundImage: `url(${icon_n})`
	};
	
	let total_partidas = item.venta_producto.length;
	
	let total_productos_en_partidas = 0;
	
	for (let i = 0; i < item.venta_producto.length; i++) {
		let info = item.venta_producto[i];
		total_productos_en_partidas += Number(info.venta_cantidad);
	}
	
	return (
		<Fragment>
			<ListItem button style={{padding: 0, width: '100%', height: '100%'}}>
				
				<Card style={{width: '100%', height: '100%'}}>
					
					<CardContent style={{padding: '12px'}}>
						
						<Typography variant="h5" component="h2" align={'center'} style={{fontSize: '18px'}}>
							<ModalVerOrdenVenta
								tipo={'ver'}
								item={item}
								id_venta={item.id_venta}
								componente={item.numero_pedido}
								RefreshList={{}}
								showSnackBars={{}}
							/>
							{/*{item.numero_pedido}*/}
						</Typography>
						
						<Typography color="textSecondary" gutterBottom style={{fontSize: '14px', lineHeight: '14px'}}>
							{DateFormat.FechaFormatString(item.fecha_entrega)}
						</Typography>
						
						<div className={'row-flex'}>
							<div className="w-100-34-px vertical-inline">
								<div className={'v-center w-100'} align={'left'}>
									<Typography component={'div'} style={{marginBottom: '5px', marginTop: '5px'}}>
										<BarraProgreso progreso={item.progreso || 0} labelText={''}/>
									</Typography>
									<Typography component={'div'} style={{marginBottom: '5px', marginTop: '5px'}}>
										<BarraProgreso progreso={(item.progreso_pagado || 0)} labelText={'Pagado'}
										               color={'#3F51B5'}/>
									</Typography>
								</div>
							</div>
							<div className="w-34-px vertical-inline">
								<div className={'v-center w-100'} align={'right'}>
									{item.cotizacion === 1 ? (
										<Tooltip TransitionComponent={Zoom} placement={"left"}
										         title={'Cotización: ' + item.numero_cotizacion}>
											<div style={bg_c} className={textoClaseIcono}/>
										</Tooltip>
									) : ''}
									{item.interno === 1 ? (
										<div style={bg_i} className={textoClaseIcono}/>
									) : ''}
									{item.nota_sencilla === 1 ? (
										<div style={bg_n} className={textoClaseIcono}/>
									) : ''}
									{item.factura === 1 ? (
										<div style={bg_f} className={textoClaseIcono}/>
									) : ''}
								</div>
							</div>
						</div>
						
						<Typography color="textSecondary" gutterBottom style={{fontSize: '12px', lineHeight: '12px'}}>
							{total_partidas || 0} partidas
							/ {Number(FieldsJs.Currency(total_productos_en_partidas)) || 0} productos
						</Typography>
					
					</CardContent>
				
				</Card>
			
			</ListItem>
		
		</Fragment>
	)
};

const ItemGrafica = ({state}) => {
	let grafica = [
		['Progreso de ventas', 'Porcentaje']
	];
	grafica.push([state.venta_creado_disenador_asignado_texto, state.venta_creado_disenador_asignado.length]);
	grafica.push([state.venta_en_produccion_texto, state.venta_en_produccion.length]);
	grafica.push([state.venta_en_almacen_texto, state.venta_en_almacen.length]);
	return (
		<Fragment>
			<div align={'center'} style={{width: '100%'}} className={'fill-transparent'}>
				
				<Typography component={'h2'} className={'ff-arial'} style={{}}>
					Gráfica porcentaje progreso de ventas
				</Typography>
				
				{grafica.length > 0 ? (
					<Chart
						width={'100%'}
						height={'270px'}
						chartType="PieChart"
						loader={
							<Typography component={'h2'} className={'ff-arial'}>
								Cargando gráficas...
							</Typography>
						}
						data={grafica}
						options={{
							title: '',
							legend: {
								alignment: 'center',
								position: 'bottom',
								textStyle: {
									color: 'black',
									fontSize: 10,
								},
							},
							pieHole: 0,
							animation: {
								duration: 1000,
								easing: 'out',
								startup: true,
							},
						}}
						rootProps={{'data-testid': '3'}}
					/>
				) : (
					<Fragment>
						<Typography component={'h2'} className={'ff-arial'}>
							Esperando datos...
						</Typography>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

class Monitor extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			venta_creado_disenador_asignado_texto: 'Órdenes nuevas / Enviado al diseñador',
			venta_en_almacen_texto: 'En almacén',
			venta_en_produccion_texto: 'En producción',
			
			venta_status_proceso: [],
			venta: [],
			venta_creado_disenador_asignado: [],
			venta_en_produccion: [],
			venta_en_almacen: [],
			
			loadding: false
		};
		
		this.InitComponent(true);
	};
	
	componentDidMount() {
		$cSuccess("componentDidMount");
		PusherSubscribe(null, info => {
			$cSuccess(info);
			this.InitPusher();
		});
	}
	
	componentWillUnmount() {
		$cInfo("componentWillUnmount");
		PusherUnSubscribe(null, info => {
			$cInfo(info);
		});
	}
	
	InitPusher = () => {
		PusherWatchChannelEvent(null, (data) => {
			$cError(JSON.stringify(data));
			FieldsJs.TimePromise(1000, () => this.InitComponent(false), () => console.warn('Tiempo cancelado por FieldsJs.TimePromise para actualizar los datos de la vista.'))
		});
	};
	
	InitComponent = (spinner) => {
		this.setState({loadding: true});
		MonitorService.Datos(spinner).then((response) => {
			let data = response.data || [];
			this.setState({
				venta_status_proceso: data.venta_status_proceso,
				venta: data.venta,
				venta_creado_disenador_asignado: data.venta_creado_disenador_asignado,
				venta_en_produccion: data.venta_en_produccion,
				venta_en_almacen: data.venta_en_almacen,
			});
			setTimeout(() => this.setState({loadding: false}), 2000);
		}).catch((error) => {
			this.setState({
				venta_status_proceso: [],
				venta: [],
				venta_creado_disenador_asignado: [],
				venta_en_produccion: [],
				venta_en_almacen: [],
			});
			setTimeout(() => this.setState({loadding: false}), 2000);
		});
	};
	
	render() {
		
		const bg_logo_svg = {
			// backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundAttachment: 'fixed',
			backgroundSize: '300px auto',
			// opacity: '0.2',
		};
		
		return (
			<div className='Monitor ContaineViewComponet' style={bg_logo_svg}>
				
				<div style={{height: '100%', width: '100%', backgroundColor: 'transparent'}}>
					
					<Grid container spacing={2} style={{
						minHeight: 'calc(100vh - 150px)',
						backgroundColor: 'transparent'
					}} alignItems={"stretch"} alignContent={"stretch"}>
						
						<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{height: 'calc(50vh - 75px)',}}>
							<div
								style={{
									backgroundColor: 'rgba(51, 102, 204, 0.20)',
									height: 'calc(100% - 20px)',
									padding: '10px',
									overflow: 'auto',
									borderRadius: '7px',
								}}>
								<Badge color="primary" badgeContent={this.state.venta_creado_disenador_asignado.length}>
									<Typography style={{padding: '5px'}}>
										{this.state.venta_creado_disenador_asignado_texto}
									</Typography>
								</Badge>
								<Grid container spacing={1}>
									{this.state.venta_creado_disenador_asignado.map((item, key) => (
										<Grid key={key} item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
											<ItemOrden item={item}/>
										</Grid>
									))}
								</Grid>
							</div>
						</Grid>
						
						<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{height: 'calc(50vh - 75px)',}}>
							<div
								style={{
									backgroundColor: 'rgba(0, 0, 0, 0.20)',
									height: 'calc(100% - 20px)',
									padding: '10px',
									overflow: 'auto',
									borderRadius: '7px',
								}}>
								
								<div style={{height: 0, width: '100%'}} align={'right'}>
									<Fab size="small" color="secondary" onClick={() => this.InitComponent(true)} style={{height: '40px', width: '40px'}}>
										{this.state.loadding ? (
											<CircularProgress size={40} style={{
												color: 'white',
												zIndex: 1,
											}}/>
										) : (
											<Replay/>
										)}
									</Fab>
								</div>
								
								<ItemGrafica state={this.state}/>
							
							</div>
						</Grid>
						
						<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{height: 'calc(50vh - 75px)',}}>
							<div
								style={{
									backgroundColor: 'rgba(51, 102, 204, 0.20)',
									height: 'calc(100% - 20px)',
									padding: '10px',
									overflow: 'auto',
									borderRadius: '7px',
								}}>
								<Badge color="primary" badgeContent={this.state.venta_en_almacen.length}>
									<Typography
										style={{padding: '5px'}}>{this.state.venta_en_almacen_texto}</Typography>
								</Badge>
								<Grid container spacing={1}>
									{this.state.venta_en_almacen.map((item, key) => (
										<Grid key={key} item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'}>
											<ItemOrden item={item}/>
										</Grid>
									))}
								</Grid>
							</div>
						</Grid>
						
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{height: 'calc(50vh - 75px)',}}>
							<div
								style={{
									backgroundColor: 'rgba(0, 0, 0, 0.1)',
									height: 'calc(100% - 20px)',
									padding: '10px',
									overflow: 'auto',
									borderRadius: '7px',
								}}>
								<Badge color="primary" badgeContent={this.state.venta_en_produccion.length}>
									<Typography
										style={{padding: '5px'}}>{this.state.venta_en_produccion_texto}</Typography>
								</Badge>
								<Grid container spacing={1}>
									{this.state.venta_en_produccion.map((item, key) => (
										<Grid key={key} item xs={3} sm={3} md={3} lg={3} xl={3} align={'center'}>
											<ItemOrden item={item}/>
										</Grid>
									))}
								</Grid>
							</div>
						</Grid>
					
					</Grid>
				
				</div>
			
			</div>
		);
	}
}

export default Monitor;
