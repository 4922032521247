import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {DialogActions} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {DateRangeOutlined, Send, Visibility} from "@material-ui/icons";
import moment from "moment";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {VentasService} from "../../../../services/VentasService/VentasService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";


class ModalTimbrarPublicoGeneral extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false,
			},
			
			tipo: props.tipo,
			id_cat_periodicidad: '',
			meses: '',
			mes: '',
			anio: '',
			fecha_inicio: null,
			fecha_fin: null,
			timbrar: 0,
			pagos: [],
			listar_periodicidad: [],
			rfc_emisor: '',
			listar_empresa_sucursal: [],
			id_empresa_sucursal: '',
			lugar_expedicion: '',
			sucursal_emisor: '',
			folio: '',
			serie: '',
			listar_empresa_sucursal_serie: [],
			id_empresa_sucursal_serie: '',
			listar_cat_moneda: '',
			listar_empresa: [],
			enviar_a: '',
			fecha: '1',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	add = () => {
		let {item} = this.props;
		
		
		this.setState({
			enviar_a: '',
			id_cat_periodicidad: '',
			meses: '',
			mes: '',
			anio: '',
			fecha: '1',
			fecha_inicio: null,
			fecha_fin: null,
			timbrar: 0,
			pagos: [],
			listar_periodicidad: []
		});
		
		this.periodicidad();
		this.ListaEmpresa().then(() => {
			this.ListaEmpresaSucursal(11);
		});
		
		this.open();
	};
	
	ListaEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresaFac({}, {}).then((response) => {
				this.setState({
					listar_empresa: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_empresa: []
				});
				reject(error);
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	periodicidad = () => {
		CatService.ListPeriodicidadCFDI(this.state).then((response) => {
			this.setState({
				listar_periodicidad: response.data,
				id_cat_periodicidad: '1',
			})
		});
	};
	
	refreshFolioTimbrado = () => {
		this.ListaEmpresaSucursalSerie(11);
		this.verificar();
	};
	
	verificar = (item) => {
		VentasService.ListarTodo(this.state, item).then((response) => {
			this.setState({
				pagos: response.data,
				timbrar: response.data.length > 0 ? 1:0
			})
			if (item) {
				this.refreshFolioTimbrado();
			}
		}).catch((error) => {
			this.setState({
				pagos: []
			})
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaEmpresaSucursal = (id_empresa) => {
		
		let empresas = FieldsJs.Copy(this.state.listar_empresa);
		for (let i = 0; i < empresas.length; i++) {
			if (Number(id_empresa) === Number(empresas[i].id_empresa)) {
				this.setState({
					rfc_emisor: empresas[i].rfc,
				});
			}
		}
		
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaSucursalFac(id_empresa, {}).then((response) => {
				if (response.data.length > 0) {
					this.setState({
						listar_empresa_sucursal: response.data,
						id_empresa_sucursal: response.data[0].id_empresa_sucursal,
						lugar_expedicion: response.data[0].codigo_postal,
						sucursal_emisor: response.data[0].nombre,
					});
					this.ListaEmpresaSucursalSerie(response.data[0].id_empresa_sucursal);
				} else {
					this.setState({
						listar_empresa_sucursal: response.data,
					});
				}
			}).catch((error) => {
				this.setState({
					listar_empresa_sucursal: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_sucursal: []
			});
		}
	};
	
	handleChangeSerie = (id_empresa_sucursal_serie, valor) => {
		let series = FieldsJs.Copy(this.state.listar_empresa_sucursal_serie);
		for (let i = 0; i < series.length; i++) {
			if (Number(id_empresa_sucursal_serie) === Number(series[i].id_empresa_sucursal_serie)) {
				this.setState({
					serie: series[i].serie
				});
			}
		}
		
		EmpresaFacturacionExtService.SerieFolio(id_empresa_sucursal_serie).then((response) => {
			this.setState({
				folio: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_facturacion: [],
				folio: ''
			});
			//this.props.showSnackBars('error', error.mensaje);
		});
	}
	
	ListaEmpresaSucursalSerie = (id_empresa_sucursal) => {
		EmpresaFacturacionExtService.Series(id_empresa_sucursal, {}).then((response) => {
			if (response.data.length > 0) {
				this.setState({
					listar_empresa_sucursal_serie: response.data,
					id_empresa_sucursal_serie: response.data[0].id_empresa_sucursal_serie,
					serie: response.data[0].serie,
				});
				this.handleChangeSerie(response.data[0].id_empresa_sucursal_serie);
			} else {
				this.setState({
					listar_empresa_sucursal_serie: response.data,
					id_empresa_sucursal_serie: '',
				});
			}
		}).catch((error) => {
			this.setState({
				listar_cat_moneda: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.add()} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Timbrar PG
						</Fab>
					</Fragment>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Timbrar Público General
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_periodicidad"
									label="Periodicidad"
									value={this.state.id_cat_periodicidad}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_periodicidad.map(option => (
										<option key={option.id_cat_periodicidad}
										        value={option.id_cat_periodicidad}>
											{option.periodicidad + ' - ' + option.descripcion}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
								<MDDatePicker
									type={'range'}
									component={(
										<TextField
											fullWidth
											label="Rango para timbrar"
											value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
											readOnly
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton aria-label="Fecha de entrega">
															<DateRangeOutlined/>
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									)}
									startDate={this.state.fecha_inicio}
									endDate={this.state.fecha_fin}
									onChange={(range) => {
										let dia = new Date(range.startDate);
										let mes = dia.getMonth();
										let meses = [
											{"mes": "01", "descripcion": "Enero"},
											{"mes": "02", "descripcion": "Febrero"},
											{"mes": "03", "descripcion": "Marzo"},
											{"mes": "04", "descripcion": "Abril"},
											{"mes": "05", "descripcion": "Mayo"},
											{"mes": "06", "descripcion": "Junio"},
											{"mes": "07", "descripcion": "Julio"},
											{"mes": "08", "descripcion": "Agosto"},
											{"mes": "09", "descripcion": "Septiembre"},
											{"mes": "10", "descripcion": "Octubre"},
											{"mes": "11", "descripcion": "Nombiembre"},
											{"mes": "12", "descripcion": "Diciembre"}
										];
										let anio = dia.getFullYear();
										this.setState({
											fecha_inicio: range.startDate,
											fecha_fin: range.endDate,
											meses: meses[mes].mes + " - " + meses[mes].descripcion,
											mes: meses[mes].mes,
											anio: anio,
										});
									}}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
								<TextField
									margin="none"
									name="meses"
									label="Meses"
									type="text"
									fullWidth
									value={this.state.meses}
									onChange={this.handleChange}
									required
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
								<TextField
									margin="none"
									name="anio"
									label="Año"
									type="text"
									fullWidth
									value={this.state.anio}
									onChange={this.handleChange}
									required
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
										this.handleChangeSerie(e.target.value);
									}}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_empresa_sucursal_serie"
									label="Serie"
									value={this.state.id_empresa_sucursal_serie}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_empresa_sucursal_serie.map(option => (
										<option key={option.id_empresa_sucursal_serie} value={option.id_empresa_sucursal_serie}>
											{option.serie}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									margin="none"
									name="folio"
									label="Folio"
									type="text"
									fullWidth
									value={this.state.folio}
									onChange={this.handleChange}
									disabled
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
									}}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="fecha"
									label="Fecha de CFDI"
									value={this.state.fecha}
								>
									<option value={'1'}>Timbrar con fecha actual</option>
									<option value={'2'}>Fecha de ayer</option>
									<option value={'3'}>Fecha de hace dos días</option>
									<option value={'4'}>Fecha de hace tres días</option>
								</TextField>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="enviar_a"
									label="Enviar a: "
									type="text"
									fullWidth
									value={this.state.enviar_a}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="enviar_cc"
									label="Cc:"
									type="text"
									fullWidth
									value={this.state.enviar_cc}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
								<div className={'margin-15-B'}></div>
							</Grid>
							
							{this.state.pagos.length > 0 ? (
								this.state.pagos.map((itemp, keyp) => (
									<Fragment>
										{itemp.total_pagos > 0 ? (
											<Grid key={keyp} item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Table className={'desing-acresco'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'}>Forma de Pago</TableCell>
															<TableCell component="th" align={'left'}>Total Pagos</TableCell>
															<TableCell component="th" align={'left'}>Subtotal</TableCell>
															<TableCell component="th" align={'left'}>Iva</TableCell>
															<TableCell component="th" align={'left'}>Total</TableCell>
															<TableCell component="th" align={'right'}>Acciones</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														<TableRow>
															<TableCell align={'left'}>{itemp.forma_pago}</TableCell>
															<TableCell align={'left'}>{itemp.total_pagos}</TableCell>
															<TableCell align={'left'}>${FieldsJs.Currency(itemp.subtotal)}</TableCell>
															<TableCell align={'left'}>${FieldsJs.Currency(itemp.iva)}</TableCell>
															<TableCell align={'left'}>${FieldsJs.Currency(itemp.total)}</TableCell>
															<TableCell align={'right'}>
																<Button onClick={() => this.verificar(itemp)} color="primary" size={'small'} variant={"contained"}>
																	Timbrar
																</Button>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</Grid>
										):null}
									</Fragment>
								))
							) : (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<VistaVacia numero={2} mensaje={'No se han encontraron pagos'}/>
								</Grid>
							)}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{this.state.pagos.length === 0 && this.state.timbrar === 0 ? (
									<Button onClick={() => this.verificar()} color="primary" variant={"contained"}>
										Verificar Ordenes de Venta
									</Button>
								) : null}
								{/*{this.state.pagos.length > 0 && this.state.timbrar === 1 ? (
									<Button onClick={() => this.verificar()} color="primary" variant={"contained"}>
										Timbrar
									</Button>
								) : null}*/}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalTimbrarPublicoGeneral.propTypes = {
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	item: PropTypes.object,
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalTimbrarPublicoGeneral;
