import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from 'dropbox';
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import Typography from "@material-ui/core/Typography";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {TimbradoFacturacionExtService} from "../../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {PopupService} from "../../../../settings/PoPup/PoPup";


class ModalCambiarClienteCFDI extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo,
			id_cliente: '',
			cliente_ac: '',
			lista_clientes: [],
		};
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			if (!FieldsJs.Field(this.state.cliente_ac)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cliente/Receptor CFDI.'
				})
			}
			
			if (!this.state.cliente_ac.receptor_factura) {
				let msg = `El cliente no cuenta con los datos suficientes para realizar la factura. Favor de completar sus datos.`;
				
				PopupService.Confirm(['Cancelar', 'Ir a Clientes'], 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
					
					if (r.button === 'Ir a Clientes') {
						this.closeModal();
						this.props.history.push('/clientes');
					}
					
				});
			} else {
				this.closeModal();
				this.props.RefreshList(this.state.cliente_ac);
				
			}
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	add = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: true,
		});
		this.ListaClientes().then(() => {
			let cliente = '';
			for (let i = 0; i < this.state.lista_clientes.length; i++) {
				if (Number(item.cliente.id_cliente) === Number(this.state.lista_clientes[i].id_cliente)){
					cliente = this.state.lista_clientes[i];
				}
			}
			
			
			this.setState({
				id_cliente: (item.cliente || '').id_cliente,
				cliente_ac: cliente || '',
			});
		});
		this.open();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	ListaClientes = () => {
		return new Promise((resolve) => {
			CatService.ListCliente().then((response) => {
				this.setState({
					lista_clientes: response.data,
				});
				resolve(true);
			}).catch((error) => {
				this.setState({
					lista_clientes: []
				});
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	handleChangeCliente = (e, valor) => {
		console.log("SELECT:::: handleChangeCliente ",e, valor);
		
		let val = FieldsJs.Copy(valor);
		
		if (valor) {
			this.setState({
				id_cliente: val.id_cliente,
				cliente_ac: valor
			});
		}
	}
	
	render() {
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Cambiar Cliente
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
									Selecciona al cliente/receptor de la factura.
								</Typography>
							</Grid>
							
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Autocomplete
									id="autocomplete_cliente"
									options={this.state.lista_clientes}
									value={this.state.cliente_ac}
									//inputValue={this.state.descripcion}
									getOptionLabel={(option) => option.razon_social ?? option.nombre_completo}
									noOptionsText={'No se encontron datos.'}
									renderInput={(params) => <TextField {...params} label="Cliente/Receptor CFDI" margin="none"/>}
									onChange={(e, value) => {
										this.handleChangeCliente(e, value);
									}}
								/>
								{/*<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cliente"
									label="Cliente/Receptor CFDI"
									value={this.state.id_cliente}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_clientes.map(option => (
										<option key={option.id_cliente} value={option.id_cliente}>
											{option.razon_social ?? option.nombre_completo}
										</option>
									))}
								</TextField>*/}
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
								<br/>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								Cerrar
							</Button>
							<Button onClick={this.save} color="primary">
								Confirmar
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCambiarClienteCFDI.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCambiarClienteCFDI;
