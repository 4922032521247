import {Component} from "react";
import {CONFIG} from "./settings/Config/Config";

var getUserId = (resolve) => {
	resolve(null)
};
var OneSignal = window.OneSignal || [
	getUserId,
];

export const OneSignalGetUserId = (timeout) => {
	timeout = timeout > 0 ? timeout : 1000;
	return new Promise((resolve) => {
		let time = setTimeout(() => resolve(null), timeout);
		OneSignal.getUserId((userId) => {
			clearTimeout(time);
			resolve(userId);
		});
	});
};

export const OneSignalListenerForNotificationOpened = () => {
	OneSignal.push(["addListenerForNotificationOpened", function (listenerForNotificationOpened) {
		console.log('addListenerForNotificationOpened', listenerForNotificationOpened);
		// OneSignalListenerForNotificationOpened();
	}]);
};

export const OneSignalNotificationPermissionChange = () => {
	OneSignal.on('notificationPermissionChange', function (permissionChange) {
		console.log('notificationPermissionChange', permissionChange);
	});
};

export const OneSignalNotificationDismiss = () => {
	OneSignal.on('notificationDismiss', function (dismiss) {
		console.log('notificationDismiss', dismiss);
	});
};

export const OneSignalNotificationDisplay = () => {
	OneSignal.on('notificationDisplay', function (display) {
		console.log('notificationDisplay', display);
	});
};

class OneSignalService extends Component {
	
	static UserId = OneSignalGetUserId;
	
	static ListenerForNotificationOpened = OneSignalListenerForNotificationOpened;
	
	static OneSignalNotificationPermissionChange = OneSignalNotificationPermissionChange;
	
	static OneSignalNotificationDismiss = OneSignalNotificationDismiss;
	
	static OneSignalNotificationDisplay = OneSignalNotificationDisplay;
	
	static Init = () => {
		return new Promise((resolve) => {
			
			let time = setTimeout(() => resolve(false), 5000);
			
			if (typeof OneSignal.push === "function") {
				OneSignal.push(function () {
					
					var appId;
					
					if (window.location.hostname === CONFIG.onesignal_hostname_prod) {
						appId = CONFIG.onesignal_app_id_prod;
					} else {
						appId = CONFIG.onesignal_app_id_test;
					}
					
					OneSignal.init({
						appId: appId,
						notifyButton: {
							enable: true,
						},
					});
					
					// OneSignalListenerForNotificationOpened();
					
					OneSignalNotificationPermissionChange();
					
					OneSignalNotificationDismiss();
					
					OneSignalNotificationDisplay();
					
					clearTimeout(time);
					
					resolve(true);
				})
			} else {
				clearTimeout(time);
				
				resolve(false);
			}
			
		})
	};
	
	render() {
		return null;
	}
	
}


export default OneSignalService;
