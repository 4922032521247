import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const ProveedorService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				nombre_comercial: filtro.nombre_comercial || null,
				razon_social: filtro.razon_social || null,
				rfc: filtro.rfc || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_proveedor: form.id_proveedor,
			nombre_comercial: form.nombre_comercial,
			razon_social: form.razon_social,
			telefono: form.telefono,
			correo_electronico: form.correo_electronico,
			pagina_web: form.pagina_web,
			rfc: form.rfc,
			domicilio: form.domicilio,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_proveedor: form.id_proveedor,
			nombre_comercial: form.nombre_comercial,
			razon_social: form.razon_social,
			telefono: form.telefono,
			correo_electronico: form.correo_electronico,
			pagina_web: form.pagina_web,
			domicilio: form.domicilio,
			rfc: form.rfc,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_proveedor) => {
		let params = {
			id_proveedor: id_proveedor
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Proveedor_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
