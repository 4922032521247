import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {EnteroSolo} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {AccountBalanceWallet, CompareArrows} from '@material-ui/icons';
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";

class FormTransferenciaBancaria extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			value: 0
		};
	};
	
	handleChangeTab = (e, tab) => {
		this.setState({
			value: tab
		});
	};
	
	render() {
		
		
		return (
			<Fragment>
				
				<AppBar position="static">
					<Tabs
						className={'bg-white text-black'}
						value={this.state.value}
						onChange={this.handleChangeTab}
						variant="fullWidth"
						aria-label="Transferencia / Depósito bancario"
					>
						<Tab icon={<CompareArrows/>} label="Transferencia"/>
						<Tab icon={<AccountBalanceWallet/>} label="Depósito bancario"/>
					</Tabs>
				</AppBar>
				
				<TabPanel value={this.state.value} index={0}>
					
					<div className={'borde-punteado-gray-1 padding-15'}>
						<Grid container spacing={1}>
							
							<Grid item className={'text-left'} xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center'}>
											Clave de rastreo
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
										           onChange={this.props.handleChangePago}
										           name="clave_rastreo"
										           value={this.props.form.clave_rastreo}
										           inputProps={{
											           maxLength: 100,
											           style: {
												           paddingTop: "8px"
											           }
										           }}
										/>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={6} sm={6} md={6} lg={6} xl={6}>
								
								<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center'}>
											Numero de referencia
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
										           onChange={this.props.handleChangePago}
										           name="numero_referencia"
										           value={this.props.form.numero_referencia}
										           inputProps={{
											           maxLength: 100,
											           style: {
												           paddingTop: "8px"
											           }
										           }}
										/>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center'}>
											Monto pagado
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
										           onChange={this.props.handleChangePago}
										           name="monto"
										           value={this.props.form.monto}
										           onKeyPress={EnteroSolo}
										           inputProps={{
											           maxLength: 15,
											           style: {
												           paddingTop: "8px"
											           }
										           }}
										/>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Button variant="contained" color="primary"
								        onClick={() => this.props.Pagar(this.state.value)}
								        className={'btn-default-primary'}>
									Guardar pago
								</Button>
							</Grid>
						
						</Grid>
					</div>
				
				</TabPanel>
				
				<TabPanel value={this.state.value} index={1}>
					
					<div className={'borde-punteado-gray-1 padding-15'}>
						<Grid container spacing={1}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'px-14 text-center'}>
											No. folio, Recibo, Voucher
										</Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
										           onChange={this.props.handleChangePago}
										           name="folio"
										           value={this.props.form.folio}
										           inputProps={{
											           maxLength: 100,
											           style: {
												           paddingTop: "8px"
											           }
										           }}
										/>
									</Grid>
								</Grid>
								
								<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<Typography className={'px-14 text-center'}>
												Monto pagado
											</Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
											<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
											           onChange={this.props.handleChangePago}
											           name="monto"
											           value={this.props.form.monto}
											           onKeyPress={EnteroSolo}
											           inputProps={{
												           maxLength: 15,
												           style: {
													           paddingTop: "8px"
												           }
											           }}
											/>
										</Grid>
									</Grid>
								
								</Grid>
							
							</Grid>
							
							<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Button variant="contained" color="primary"
								        onClick={() => this.props.Pagar(this.state.value)}
								        className={'btn-default-primary'}>
									Guardar pago
								</Button>
							</Grid>
						
						</Grid>
					</div>
				
				</TabPanel>
			
			</Fragment>
		);
	}
}

FormTransferenciaBancaria.propTypes = {
	handleChangePago: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	Pagar: PropTypes.func.isRequired,
};

export default FormTransferenciaBancaria;


function TabPanel(props) {
	const {children, value, index, ...other} = props;
	
	return (
		<Typography
			className={'padding-0 margin-0'}
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			aria-labelledby={`nav-tab-${index}`}
			{...other}
		>
			<Box p={3} className={'padding-0 margin-0'}>{children}</Box>
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};
