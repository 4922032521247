import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const CatService = {
	Cfg: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/config').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMunicipio: (id_estado) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/municipio/' + id_estado).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMenuPertenece: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/menu_pertenece').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSexo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/sexo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGiroEmpresa: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/giro_empresa').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioDisenador: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_disenador').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioCompra: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_compra').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGrupo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/grupo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListComoTeEnteraste: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/como_te_enteraste').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioTipoReporte: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_tipo_reporte').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoReporte: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_reporte').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCategoria: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/categoria', {}, {spinner: spinner || false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoComprobante: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_comprobante').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProducto: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/producto').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProveedor: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/proveedor').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFormaPago: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/forma_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoRequerimiento: (id_cat_grupo) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_requerimiento/' + id_cat_grupo).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGrupoCompra: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/grupo_compra').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoCompra: (spinner, id_cat_grupo_compra) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_compra/'+id_cat_grupo_compra).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMedidaLargoCompra: (spinner, id_cat_grupo_compra) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/medida_largo_compra/'+id_cat_grupo_compra).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMedidaAnchoCompra: (spinner, id_cat_grupo_compra) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/medida_ancho_compra/'+id_cat_grupo_compra).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoGastoCompra: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_gasto_compra').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUnidadMedida: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/unidad_medida').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoCuenta: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_cuenta').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoIngresoFondeo: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_ingreso_fondeo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFormaPagoFondeo: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/forma_pago_fondeo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEnvioEstatus: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/envio_estatus').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoMensajeria: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_mensajeria').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListBanco: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/banco').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCliente: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cliente').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCompraDescripcion: (descripcion) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/compra_descripcion/'+descripcion).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCompraProducto: (producto) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/compra_producto/'+producto).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCompraProductoDescripcion: (producto_descripcion) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/compra_producto_descripcion/'+producto_descripcion).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPaisFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/pais', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListRegimenFiscalFac: (id_cat_tipo_persona = 1) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/regimen_fiscal/'+id_cat_tipo_persona, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClaveUnidadFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/clave_unidad', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClaveProductoServicioFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/clave_producto_servicio', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMonedaFac: (id_empresa) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa_moneda/'+id_empresa, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresaFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresaSucursalFac: (id_empresa) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa_sucursal/'+id_empresa, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresaRegimenFiscalFac: (id_empresa) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa_regimen_fiscal/'+id_empresa, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClienteFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cliente', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListImpuestoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/impuesto', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListImpuestoRetenidoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/impuesto_retenido', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListImpuestoTrasladoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/impuesto_traslado', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoFactorFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_factor', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoCFDIFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_cfdi', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsoCFDIFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/uso_cfdi', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMetodoPagoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/metodo_pago', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFormaPagoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/forma_pago', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresaMonedaFac: (id_empresa) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa_moneda/'+id_empresa, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListObjetoImpuestoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/objeto_impuesto', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCancelacionCFDIFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cancelacion_cfdi', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPeriodicidadCFDI: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/periodicidad', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCatProducto: (id_cat_producto_categoria) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cat_producto/'+id_cat_producto_categoria).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCatProductoGrupo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cat_producto_grupo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCatProductoCategoria: (id_cat_producto_grupo) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cat_producto_categoria/'+id_cat_producto_grupo).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCatProductoDetalle: (id_cat_producto) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cat_producto_detalle/'+id_cat_producto).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
