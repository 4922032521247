import React, {Component, Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {FieldsJs} from "../../../settings/General/General";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import PropTypes from 'prop-types';


class AutoCompletado extends Component {
	state = {};
	
	constructor() {
		super();
		this.state = {
			open: false,
			options: [],
			loading: false
		}
	}
	
	obtenerProductos = () => {
		FieldsJs.TimePromise(500, () => {
			this.setState({loading: true})
			CatService.ListProducto().then((response) => {
				let options = FieldsJs.Copy(response.data);
				for (let i = 0; i < options.length; i++) {
					options[i].name = options[i].producto
					options[i].id = options[i].id_producto
				}
				this.setState({
					options: options
				})
				this.setState({loading: false})
			}).catch((error) => {
				this.setState({
					options: []
				})
				this.setState({loading: false})
			})
		}, () => console.warn('Tiempo cancelado por FieldsJs.TimePromise para actualizar los los productos.'))
	}
	
	render() {
		return (
			<div id={'input-search'}>
				<Autocomplete
					style={{width: '100%'}}
					open={this.state.open}
					size="small"
					onOpen={() => {
						this.setState({open: true})
					}}
					onClose={() => {
						this.setState({open: false})
					}}
					getOptionSelected={(option, value) => option.name === value.name}
					getOptionLabel={(option) => option.name}
					onChange={(option, value) => {
						this.props.onChange({option, value})
					}}
					options={this.state.options}
					loading={this.state.loading}
					renderInput={(params) => (
						<TextField
							{...params}
							style={{border: '0px'}}
							placeholder={'Buscar productos...'}
							margin="dense"
							onKeyDown={this.obtenerProductos}
							variant="standard"
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<InputAdornment position="start">
										<IconButton aria-label="Search">
											<SearchOutlined/>
										</IconButton>
									</InputAdornment>
								),
								endAdornment: (
									<Fragment>
										{this.state.loading ? <CircularProgress color="inherit" size={20}/> : null}
										{params.InputProps.endAdornment}
									</Fragment>
								),
							}}
						/>
					)}
				/>
			</div>
		);
	}
}


AutoCompletado.propTypes = {
	onChange: PropTypes.func.isRequired,
};

export default AutoCompletado;
