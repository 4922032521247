import React, {Component, Fragment} from 'react';
import './Tablero.css';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {TableroService} from "../../../services/TableroService/TableroService";
import {FieldsJs} from '../../../settings/General/General';
import {Chart} from 'react-google-charts';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

class Tablero extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			total_cliente: 0,
			total_ventas: 0,
			total_cotizaciones: 0,
			total_usuarios: 0,
			desglose: {
				pagado: 0,
				porpagar: 0,
				totalventa: 0,
				total: 0,
			},
			grafica_como_te_enteraste: [],
			grafica_semanas_ventas: [],
			grafica_semanas_pagos_compras: [],
			grafica_forma_pago: [],
			grafica_grupos_1: [],
			grafica_grupos_2: [],
		};
		this.ComponentInit();
	}
	
	ComponentInit = () => {
		TableroService.Datos().then((response) => {
			let data = response.data || {};
			
			let grafica_como_te_enteraste = [
				['¿Como te enteraste?', 'Porcentaje']
			];
			for (let i = 0; i < data.grafica_como_te_enteraste.length; i++) {
				let item = data.grafica_como_te_enteraste[i];
				grafica_como_te_enteraste.push([item.como_te_enteraste, Number(item.porcentaje)]);
			}
			console.log(grafica_como_te_enteraste);
			
			let grafica_forma_pago = [
				['Formas de pago', 'Importe']
			];
			for (let i = 0; i < data.grafica_forma_pago.length; i++) {
				let item = data.grafica_forma_pago[i];
				grafica_forma_pago.push([item.forma_pago, Number(item.pagos_total)]);
			}
			console.log(grafica_forma_pago);
			
			let grafica_grupos_1 = [
				['Grupos', 'Importe']
			];
			let grafica_grupos_2 = [
				['Grupos', 'Numero de productos']
			];
			for (let i = 0; i < data.grafica_grupos.length; i++) {
				let item = data.grafica_grupos[i];
				grafica_grupos_1.push([item.grupo, Number(item.importe_total)]);
				grafica_grupos_2.push([item.grupo, Number(item.numero_de_productos)]);
			}
			console.log(grafica_grupos_1, grafica_grupos_2);
			
			let grafica_semanas_ventas = [
				['x', 'Total de ventas', 'Pagado', 'Por pagar']
			];
			for (let i = 0; i < data.grafica_semanas_ventas.length; i++) {
				let item = data.grafica_semanas_ventas[i];
				grafica_semanas_ventas.push([this.semana_fecha_string(item), item.totales.totalventa, item.totales.pagado, item.totales.porpagar]);
			}
			console.log(grafica_semanas_ventas);
			
			let grafica_semanas_pagos_compras = [
				['x', 'Ingresos', 'Compras']
			];
			for (let i = 0; i < data.grafica_semanas_pagos_compras.length; i++) {
				let item = data.grafica_semanas_pagos_compras[i];
				grafica_semanas_pagos_compras.push([this.semana_fecha_string(item), item.totales.pagado, item.totales.comprado]);
			}
			console.log(grafica_semanas_pagos_compras);
			
			this.setState({
				total_cliente: data.total_cliente,
				total_ventas: data.total_ventas,
				total_cotizaciones: data.total_cotizaciones,
				total_usuarios: data.total_usuarios,
				desglose: data.desglose,
				grafica_como_te_enteraste: grafica_como_te_enteraste,
				grafica_semanas_ventas: grafica_semanas_ventas,
				grafica_semanas_pagos_compras: grafica_semanas_pagos_compras,
				grafica_forma_pago: grafica_forma_pago,
				grafica_grupos_1: grafica_grupos_1,
				grafica_grupos_2: grafica_grupos_2,
			})
		}).catch((error) => {
		
		});
	};
	
	semana_fecha_string = (item) => {
		return 'Semana ' + item.week + ' del ' + item.year + ', ' + DateFormat.FormatText(item.interval.start) + ' al ' + DateFormat.FormatText(item.interval.end);
	};
	
	render() {
		
		return (
			<div className='Tablero ContaineViewComponet'>
				
				<Grid container spacing={3}>
					
					<Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
						<Card style={{height: '100%'}}>
							<CardContent>
								<Typography component={'h2'}>
									Total ventas
								</Typography>
								<Typography component={'h1'} style={{fontSize: '35px'}}>
									{this.state.total_ventas || 0}
								</Typography>
								<Grid container spacing={1}>
									<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
										<Typography component={'p'} style={{fontSize: '10px', color: '#0000ff'}}>
											Pagado
										</Typography>
										<Typography component={'p'}
										            style={{fontWeight: 700, fontSize: '12px', color: '#0000ff'}}>
											${FieldsJs.Currency(this.state.desglose.pagado || 0)}
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
										<Typography component={'p'} style={{fontSize: '10px', color: '#ff0000'}}>
											Por pagar
										</Typography>
										<Typography component={'p'}
										            style={{fontWeight: 700, fontSize: '12px', color: '#ff0000'}}>
											${FieldsJs.Currency(this.state.desglose.porpagar || 0)}
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
										<Typography component={'p'} style={{fontSize: '10px', color: '#008000'}}>
											Total
										</Typography>
										<Typography component={'p'}
										            style={{fontWeight: 700, fontSize: '12px', color: '#008000'}}>
											${FieldsJs.Currency(this.state.desglose.totalventa || 0)}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
						<Card style={{height: '100%'}}>
							<CardContent>
								<Typography component={'h2'}>
									Total cotizaciones
								</Typography>
								<Typography component={'h1'} style={{fontSize: '35px'}}>
									{this.state.total_cotizaciones || 0}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
						<Card style={{height: '100%'}}>
							<CardContent>
								<Typography component={'h2'}>
									Total clientes
								</Typography>
								<Typography component={'h1'} style={{fontSize: '35px'}}>
									{this.state.total_cliente || 0}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
						<Card style={{height: '100%'}}>
							<CardContent>
								<Typography component={'h2'}>
									Total usuarios
								</Typography>
								<Typography component={'h1'} style={{fontSize: '35px'}}>
									{this.state.total_usuarios || 0}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
						<Card style={{height: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
							<CardContent style={{paddingLeft: '0px', paddingRight: '0px'}}>
								{this.state.grafica_como_te_enteraste.length > 0 ? (
									<Fragment>
										{/*<Typography component={'h2'}>
											¿Como te enteraste?
										</Typography>*/}
										<Chart
											width={'100%'}
											height={'120px'}
											chartType="PieChart"
											loader={
												<Typography component={'h2'} className={'ff-arial'}>
													Cargando gráficas...
												</Typography>
											}
											data={this.state.grafica_como_te_enteraste}
											options={{
												// title: 'Gráfica porcentaje ¿Como te enteraste?',
												legend: {
													alignment: 'center',
													position: 'left',
													textStyle: {
														color: '#0000ff',
														fontSize: 8
													},
												},
												pieHole: 0.4,
												animation: {
													duration: 1000,
													easing: 'out',
													startup: true,
												},
											}}
											rootProps={{'data-testid': '3'}}
										/>
									</Fragment>
								) : (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial'}>
											Esperando datos...
										</Typography>
									</Fragment>
								)}
							
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
						<Card style={{height: '100%'}}>
							<CardContent>
								{this.state.grafica_semanas_ventas.length > 0 ? (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial text-center'}>
											Gráfica ingreso por ordenes de venta
										</Typography>
										<Chart
											width={'100%'}
											height={'400px'}
											chartType="LineChart"
											loader={
												<Typography component={'h2'} className={'ff-arial'}>
													Cargando gráficas...
												</Typography>
											}
											data={this.state.grafica_semanas_ventas}
											options={{
												colors: [
													'#008000',
													'#0000ff',
													'#ff0000'
												],
												hAxis: {
													title: 'Semanas',
												},
												vAxis: {
													title: 'Importe',
												},
												series: {
													0: {curveType: 'function'},
													1: {curveType: 'function'},
													2: {curveType: 'function'},
												},
												animation: {
													duration: 1000,
													easing: 'out',
													startup: true,
												},
											}}
											rootProps={{'data-testid': '1'}}
										/>
									</Fragment>
								) : (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial'}>
											Esperando datos...
										</Typography>
									</Fragment>
								)}
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
						<Card style={{height: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
							<CardContent style={{paddingLeft: '0px', paddingRight: '0px'}}>
								{this.state.grafica_forma_pago.length > 0 ? (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial text-center'}>
											Gráfica formas de pago
										</Typography>
										<Chart
											width={'100%'}
											height={'300px'}
											chartType="PieChart"
											loader={
												<Typography component={'h2'} className={'ff-arial'}>
													Cargando gráficas...
												</Typography>
											}
											data={this.state.grafica_forma_pago}
											options={{
												// title: 'Gráfica formas de pago',
												legend: {
													alignment: 'center',
													position: 'left',
													textStyle: {
														color: '#0000ff',
														fontSize: 8
													},
												},
												pieHole: 0.4,
												animation: {
													duration: 1000,
													easing: 'out',
													startup: true,
												},
											}}
											rootProps={{'data-testid': '3'}}
										/>
									</Fragment>
								) : (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial'}>
											Esperando datos...
										</Typography>
									</Fragment>
								)}
							
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
						<Card style={{height: '100%'}}>
							<CardContent>
								{this.state.grafica_semanas_pagos_compras.length > 0 ? (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial text-center'}>
											Gráfica ingresos/compras
										</Typography>
										<Chart
											width={'100%'}
											height={'400px'}
											chartType="LineChart"
											loader={
												<Typography component={'h2'} className={'ff-arial'}>
													Cargando gráficas...
												</Typography>
											}
											data={this.state.grafica_semanas_pagos_compras}
											options={{
												colors: [
													'#008000',
													'#ff0000'
												],
												hAxis: {
													title: 'Semanas',
												},
												vAxis: {
													title: 'Importe',
												},
												series: {
													0: {curveType: 'function'},
													1: {curveType: 'function'},
												},
												animation: {
													duration: 1000,
													easing: 'out',
													startup: true,
												},
											}}
											rootProps={{'data-testid': '1'}}
										/>
									</Fragment>
								) : (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial'}>
											Esperando datos...
										</Typography>
									</Fragment>
								)}
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
						<Card style={{height: '100%', paddingLeft: '0px', paddingRight: '0px'}}>
							<CardContent style={{paddingLeft: '0px', paddingRight: '0px'}}>
								{(this.state.grafica_grupos_1.length > 0 && this.state.grafica_grupos_2.length > 0) ? (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial text-center'}>
											Gráfica por grupos / Importe
										</Typography>
										<Chart
											width={'100%'}
											height={'200px'}
											chartType="PieChart"
											loader={
												<Typography component={'h2'} className={'ff-arial'}>
													Cargando gráficas...
												</Typography>
											}
											data={this.state.grafica_grupos_1}
											options={{
												legend: 'none',
												pieSliceText: 'label',
												// title: 'Gráfica por grupos / Importe',
												pieStartAngle: 100,
											}}
											rootProps={{ 'data-testid': '4' }}
										/>
										<Typography component={'h2'} className={'ff-arial text-center'}>
											Gráfica por grupos / Número de productos
										</Typography>
										<Chart
											width={'100%'}
											height={'200px'}
											chartType="PieChart"
											loader={
												<Typography component={'h2'} className={'ff-arial'}>
													Cargando gráficas...
												</Typography>
											}
											data={this.state.grafica_grupos_2}
											options={{
												legend: 'none',
												pieSliceText: 'label',
												// title: 'Gráfica por grupos / Número de productos',
												pieStartAngle: 100,
											}}
											rootProps={{ 'data-testid': '4' }}
										/>
									</Fragment>
								) : (
									<Fragment>
										<Typography component={'h2'} className={'ff-arial'}>
											Esperando datos...
										</Typography>
									</Fragment>
								)}
							
							</CardContent>
						</Card>
					</Grid>
					
				</Grid>
			
			</div>
		);
	}
}

export default Tablero;
