import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const AnchoCompraProductoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Medida_Ancho_Compra_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_medida_ancho_compra: null,
			id_cat_grupo_compra: form.id_cat_grupo_compra,
			medida_ancho_compra: form.medida_ancho_compra,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Medida_Ancho_Compra_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_medida_ancho_compra: form.id_cat_medida_ancho_compra,
			id_cat_grupo_compra: form.id_cat_grupo_compra,
			medida_ancho_compra: form.medida_ancho_compra,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Medida_Ancho_Compra_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_medida_ancho_compra) => {
		let params = {
			id_cat_medida_ancho_compra: id_cat_medida_ancho_compra
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Medida_Ancho_Compra_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
