import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {PromocionService} from "../../../../../services/_Cat/PromocionService/PromocionService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../../Include/MiniComponents/GlobalComponent";
import ContenidoOrdenVenta from "./ContenidoOrdenVenta";
import {PopupService} from "../../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../../settings/Config/Config";
import {OrdenDeVentaService} from "../../../../../services/OrdenDeVentaService/OrdenDeVentaService";
import {DateFormat} from "../../../../../settings/DateFormat/DateFormat";
import moment from "moment";
import {CatService} from "../../../../../services/_Cat/CatService/CatService";
import {PaqueteService} from "../../../../../services/_Cat/PaqueteService/PaqueteService";


let productos = [];

class ModalPaquetes extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cat_paquete: (props.item || {}).id_cat_paquete,
			promocion: '',
			codigo: '',
			nombre: '',
			activo: true,
			
			fecha_periodo_inicio: null,
			fecha_periodo_fin: null,
			
			productos: FieldsJs.Copy(productos),
			
			cat_tipo_requerimiento: [],
			cat_grupo: [],
			producto_requerimiento_adicional: [],
			porcentaje_descuento: '',
			
		};
		
	}
	
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				let key = Number(name.split('__')[1]);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		console.log("OrdenDeVentaGuardar", this.state);
		
		if (this.state.id_cat_paquete > 0) {
			PaqueteService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			PaqueteService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	obtenerCodigo = () => {
		PaqueteService.ObtenerCodigo(this.state).then((response) => {
			this.setState({codigo: response.data});
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cat_paquete: '',
			promocion: '',
			nombre: '',
			fecha_periodo_inicio: null,
			fecha_periodo_fin: null,
			codigo: '',
			
			productos: FieldsJs.Copy(productos),
			cat_grupo: [],
			cat_tipo_requerimiento: [],
			producto_requerimiento_adicional: [],
			porcentaje_descuento: 0,
			
			activo: true,
		});
		
		this.open();
		this.OrdenDeVentaDatos(this.state.id_venta);
		this.obtenerCodigo();
		
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_paquete: item.id_cat_paquete || '',
			promocion: item.promocion || '',
			nombre: item.nombre || '',
			fecha_periodo_inicio: item.fecha_periodo_inicio || '',
			fecha_periodo_fin: item.fecha_periodo_fin || '',
			codigo: item.codigo || '',
			productos: item.venta_producto || [],
			cat_grupo: item.cat_grupo || [],
			cat_tipo_requerimiento: item.cat_tipo_requerimiento || [],
			activo: (item.activo === 1),
		});
		this.open();
		
		this.OrdenDeVentaDatos(this.state.id_venta);
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_paquete: item.id_cat_paquete || '',
			promocion: item.promocion || '',
			fecha_periodo_inicio: item.fecha_periodo_inicio || '',
			fecha_periodo_fin: item.fecha_periodo_fin || '',
			codigo: item.codigo || '',
			productos: item.venta_producto || [],
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	
	agregar_producto_requerimiento_adicional = () => {
		
		console.log(this.state);
		
		let producto_requerimiento_adicional = FieldsJs.Copy(this.state.producto_requerimiento_adicional);
		let requerimiento_adicional = this.state._otro_requerimiento;
		
		try {
			
			if (!requerimiento_adicional) {
				throw Object({
					status: false,
					mensaje: 'Escribe el requerimiento'
				});
			}
			
			producto_requerimiento_adicional.push({
				requerimiento_adicional: requerimiento_adicional,
				costo_adicional: ''
			});
			
			this.setState({
				producto_requerimiento_adicional: producto_requerimiento_adicional,
				_otro_requerimiento: ''
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar_producto_requerimiento_adicional = (key) => {
		
		console.log(this.state);
		
		try {
			
			let producto_requerimiento_adicional = FieldsJs.Copy(this.state.producto_requerimiento_adicional);
			
			producto_requerimiento_adicional.splice(key, 1);
			
			this.setState({
				producto_requerimiento_adicional: producto_requerimiento_adicional
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar_requerimiento = (key, keyTR) => {
		
		console.log(this.state);
		
		try {
			
			let btn = ['Cancelar', 'Aceptar'];
			let msg = '¿Deseas eliminar esta propiedad del producto?';
			
			PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === btn[1]) {
					let product = FieldsJs.Copy(this.state.productos);
					
					for (let i = 0; i < product.length; i++) {
						if (i === key) {
							product[i].venta_producto_tipo_requerimiento.splice(keyTR, 1);
						}
					}
					
					for (let i = 0; i < product.length; i++) {
						let it = product[i];
						let costo_adicional = 0;
						for (let j = 0; j < it.venta_producto_tipo_requerimiento.length; j++) {
							costo_adicional += it.venta_producto_tipo_requerimiento[j].costo_adicional;
						}
						for (let j = 0; j < it.venta_producto_requerimiento_adicional.length; j++) {
							costo_adicional += it.venta_producto_requerimiento_adicional[j].costo_adicional;
						}
						product[i].venta_costo_adicional = costo_adicional;
						product[i].venta_precio = costo_adicional + (it.venta_cantidad * it.venta_precio_unitario);
					}
					
					this.setState({
						productos: product
					})
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar_producto_atributo = (key, keyTR) => {
		
		console.log(this.state);
		
		try {
			
			let btn = ['Cancelar', 'Aceptar'];
			let msg = '¿Deseas eliminar esta propiedad del producto?';
			
			PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === btn[1]) {
					let product = FieldsJs.Copy(this.state.productos);
					
					for (let i = 0; i < product.length; i++) {
						if (i === key) {
							product[i].venta_producto_requerimiento_adicional.splice(keyTR, 1);
						}
					}
					
					for (let i = 0; i < product.length; i++) {
						let it = product[i];
						let costo_adicional = 0;
						for (let j = 0; j < it.venta_producto_tipo_requerimiento.length; j++) {
							costo_adicional += it.venta_producto_tipo_requerimiento[j].costo_adicional;
						}
						for (let j = 0; j < it.venta_producto_requerimiento_adicional.length; j++) {
							costo_adicional += it.venta_producto_requerimiento_adicional[j].costo_adicional;
						}
						product[i].venta_costo_adicional = costo_adicional;
						product[i].venta_precio = costo_adicional + (it.venta_cantidad * it.venta_precio_unitario);
					}
					
					this.setState({
						productos: product
					});
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	agregar_producto_atributo = (key, item) => {
		
		console.log(item, this.state);
		
		try {
			
			let product = FieldsJs.Copy(this.state.productos);
			
			for (let i = 0; i < product.length; i++) {
				if (i === key) {
					product[i].venta_producto_requerimiento_adicional.push({
						requerimiento_adicional: item.requerimiento_adicional,
						costo_adicional: item.costo_adicional,
					});
				}
			}
			
			for (let i = 0; i < product.length; i++) {
				let it = product[i];
				let costo_adicional = 0;
				for (let j = 0; j < it.venta_producto_tipo_requerimiento.length; j++) {
					costo_adicional += it.venta_producto_tipo_requerimiento[j].costo_adicional;
				}
				for (let j = 0; j < it.venta_producto_requerimiento_adicional.length; j++) {
					costo_adicional += it.venta_producto_requerimiento_adicional[j].costo_adicional;
				}
				product[i].venta_costo_adicional = costo_adicional;
				product[i].venta_precio = costo_adicional + (it.venta_cantidad * it.venta_precio_unitario);
			}
			
			this.setState({
				productos: product
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	mas = () => {
		
		try {
			if (!this.state._producto) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Producto."
				});
			}
			if (!this.state._descripcion) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Descripción."
				});
			}
			if (!this.state._id_cat_grupo) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Grupo."
				});
			}
			if (!this.state._cantidad) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Cantidad."
				});
			}
			if (!this.state._precio_unitario) {
				throw Object({
					status: false,
					mensaje: "Campo requerido: Precio unitario."
				});
			}
			let item_opcion_requerimiento = {}
			let arr_temp = FieldsJs.Copy(this.state.cat_tipo_requerimiento);
			for (let i = 0; i < arr_temp.length; i++) {
				if (arr_temp[i].checked) {
					if (arr_temp[i].cat_opcion_requerimiento.length > 0) {
						if (!FieldsJs.Field(arr_temp[i].id_cat_opcion_requerimiento)) {
							item_opcion_requerimiento = arr_temp[i]
							item_opcion_requerimiento.opcion_requerimiento_obligatorio = (arr_temp[i].cat_opcion_requerimiento || []).map(e => e.opcion_requerimiento).join(", ");
						}
					}
				}
			}
			if (FieldsJs.Array(item_opcion_requerimiento)) {
				throw Object({
					status: false,
					mensaje: `Has seleccionado ${item_opcion_requerimiento.tipo_requerimiento}, esta seleccion requiere obligatoriamente seleccionar una de las opciones siguientes (${item_opcion_requerimiento.opcion_requerimiento_obligatorio})`
				});
			}
			if (this.state._otro_requerimiento) {
				throw Object({
					status: false,
					mensaje: "Has escrito en otros requerimientos, para poder agregar el producto, asegúrate de agregar el requerimiento, o limpiar otros requerimientos"
				});
			}
			
			let product = FieldsJs.Copy(this.state.productos);
			let costo_adicional = 0;
			
			let cat_tipo_requerimiento = FieldsJs.Copy(this.state.cat_tipo_requerimiento);
			let venta_producto_tipo_requerimiento = [];
			let item1 = {};
			for (let i = 0; i < cat_tipo_requerimiento.length; i++) {
				
				item1 = FieldsJs.Copy(cat_tipo_requerimiento[i]);
				
				if (item1.checked) {
					
					item1.costo_adicional = (item1.costo_adicional > 0 ? Number(item1.costo_adicional) : 0);
					costo_adicional += Number(item1.costo_adicional);
					
					venta_producto_tipo_requerimiento.push(item1);
					cat_tipo_requerimiento[i].checked = false;
					cat_tipo_requerimiento[i].costo_adicional = '';
					cat_tipo_requerimiento[i].id_cat_opcion_requerimiento = '';
				}
			}
			
			let producto_requerimiento_adicional = FieldsJs.Copy(this.state.producto_requerimiento_adicional);
			let venta_producto_requerimiento_adicional = [];
			let item2 = {};
			for (let i = 0; i < producto_requerimiento_adicional.length; i++) {
				
				item2 = FieldsJs.Copy(producto_requerimiento_adicional[i]);
				
				item2.id_venta_producto_requerimiento_adicional = null;
				item2.costo_adicional = (item2.costo_adicional > 0 ? Number(item2.costo_adicional) : 0);
				costo_adicional += Number(item2.costo_adicional);
				
				venta_producto_requerimiento_adicional.push(item2);
			}
			/*
			let _grupo;
			for (let i = 0; i < this.state.cat_grupo.length; i++) {
				let item = this.state.cat_grupo[i];
				if (item.id_cat_grupo === this.state._id_cat_grupo) {
					_grupo = this.state.grupo;
				}
			}
			*/
			product.push({
				venta_id_producto: this.state._id_producto, // asignar el id de producto 1 pro default
				venta_producto: this.state._producto,
				venta_descripcion: this.state._descripcion,
				id_cat_grupo: this.state._id_cat_grupo,
				venta_cantidad: this.state._cantidad,
				venta_precio_unitario: this.state._precio_unitario,
				venta_costo_adicional: costo_adicional,
				venta_precio: (this.state._cantidad * this.state._precio_unitario) + Number(costo_adicional),
				venta_porcentaje_descuento: 0,
				venta_descuento: 0,
				venta_producto_tipo_requerimiento: venta_producto_tipo_requerimiento,
				venta_producto_requerimiento_adicional: venta_producto_requerimiento_adicional,
			});
			
			this.setState({
				productos: product,
				_id_producto: '',
				_producto: '',
				_descripcion: '',
				_id_cat_grupo: '',
				_cantidad: '',
				_precio_unitario: '',
				_otro_requerimiento: '',
				cat_tipo_requerimiento: [],
				producto_requerimiento_adicional: [],
			});
			
			setTimeout(() => {
				this.updatePrecio();
			}, 1000);
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	menos = (item, key) => {
		
		let btn = ['Cancelar', 'Aceptar'];
		let msg = '¿Deseas eliminar este producto de la orden de venta?';
		
		PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === btn[1]) {
				let product = FieldsJs.Copy(this.state.productos);
				
				product.splice(key, 1);
				
				this.setState({
					productos: product
				});
			}
		});
		
	};
	
	resetTipoRequerimiento = (e, item, key) => {
		return new Promise(resolve => {
			if ((item.venta_producto_tipo_requerimiento || []).length > 0) {
				let grupo_name = ((this.state.cat_grupo || []).find(value => Number(value.id_cat_grupo) === Number(item.id_cat_grupo)) || {}).grupo;
				let btn = ['Cancelar', 'Aceptar'];
				let msg = `¿Al cambiar de grupo se perderan los requerimientos seleccionados en ${grupo_name}?`;
				PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
					if (r.button === btn[1]) {
						this.update_product(key).then((r) => {
							resolve({e, r});
						});
					}
				});
			} else {
				this.update_product(key).then((r) => {
					resolve({e, r});
				});
			}
		})
	};
	
	update_product = (key) => {
		return new Promise(resolve => {
			let productos = this.state.productos;
			for (let i = 0; i < productos.length; i++) {
				if (key === i) {
					productos[i].venta_producto_tipo_requerimiento = []
				}
			}
			this.setState({
				productos: productos
			});
			resolve(true)
		})
	};
	
	total_descuento = (total, porcentaje_descuento_por_producto, porcentaje_descuento_total_venta) => {
		return 0;
		total = Number(total);
		porcentaje_descuento_por_producto = Number(porcentaje_descuento_por_producto);
		porcentaje_descuento_total_venta = Number(porcentaje_descuento_total_venta);
		if (porcentaje_descuento_por_producto > 0) {
			return (porcentaje_descuento_por_producto * total) / 100;
		} else if (porcentaje_descuento_total_venta > 0) {
			return (porcentaje_descuento_total_venta * total) / 100;
		} else {
			return 0;
		}
	};
	
	updateDesglose = (i) => {
		let prod = [];
		if (i) {
			prod = FieldsJs.Copy(this.state.formpagos.productos);
		} else {
			prod = FieldsJs.Copy(this.state.productos);
		}
		let total = 0;
		let descuento = 0;
		let iva = 16;
		for (let i = 0; i < prod.length; i++) {
			let item = prod[i];
			let total_producto_actual = 0;
			if (item.venta_cantidad > 0 && item.venta_precio_unitario > 0) {
				
				total_producto_actual += (item.venta_cantidad * item.venta_precio_unitario);
				
				for (let j = 0; j < item.venta_producto_tipo_requerimiento.length; j++) {
					total_producto_actual += (item.venta_producto_tipo_requerimiento[j].costo_adicional || 0)
				}
				
				for (let j = 0; j < item.venta_producto_requerimiento_adicional.length; j++) {
					total_producto_actual += (item.venta_producto_requerimiento_adicional[j].costo_adicional || 0)
				}
				
				total += total_producto_actual;
				
				prod[i].venta_descuento = this.total_descuento(total_producto_actual, (item.venta_porcentaje_descuento || 0), (this.state.porcentaje_descuento || 0));
				
				descuento += prod[i].venta_descuento;
			}
		}
		console.log(descuento);
		let subtotal = (total / ((iva / 100) + 1));
		return {
			iva: iva,
			subtotal: subtotal,
			iva_total: total - subtotal,
			total: total,
			descuento: descuento,
			productos: prod,
		};
	};
	
	
	OrdenDeVentaDatos = (id_venta) => {
		
		
		OrdenDeVentaService.OrdenDeVentaDatos(id_venta).then((response) => {
			
			if (this.state.id_cat_paquete > 0) {
				this.setState({
					cat_como_te_enteraste: response.data.cat_como_te_enteraste,
					cat_grupo: response.data.cat_grupo || [],
				});
			} else {
				
				let cat_tipo_requerimiento = [];
				
				for (let i = 0; i < response.data.cat_tipo_requerimiento.length; i++) {
					let item = response.data.cat_tipo_requerimiento[i];
					item.checked = false;
					item.costo_adicional = '';
					item.id_cat_opcion_requerimiento = '';
					cat_tipo_requerimiento.push(item);
				}
				
				this.setState({
					cat_como_te_enteraste: response.data.cat_como_te_enteraste,
					cat_tipo_requerimiento: cat_tipo_requerimiento,
					cat_grupo: response.data.cat_grupo || [],
					
					_id_producto: '',
					_producto: '',
					_descripcion: '',
					_id_cat_grupo: '',
					_cantidad: '',
					_precio_unitario: '',
					_otro_requerimiento: '',
					productos: [],
					
					insumo: ''
					
				});
				let venta = response.data.venta || {};
				
				let fecha_alta = DateFormat.FormatText(DateFormat.ConvertTimestamp(venta.fecha_alta, 'fecha', 'MySql'));
				
				if (FieldsJs.Array(venta)) {
					
					let fecha_entrega = moment(venta.fecha_entrega);
					
					let factura_cotizacion_nota_sencilla_interno = '';
					
					if (venta.factura === 1) {
						factura_cotizacion_nota_sencilla_interno = 'factura';
					} else if (venta.nota_sencilla === 1) {
						factura_cotizacion_nota_sencilla_interno = 'nota_sencilla';
					} else if (venta.interno === 1) {
						factura_cotizacion_nota_sencilla_interno = 'interno';
					} else if (venta.cotizacion === 1) {
						factura_cotizacion_nota_sencilla_interno = 'cotizacion';
					}
					
					let productos = this.state.productos;
					
					if (productos.length >= venta.venta_producto.length) {
						
						for (let k = 0; k < venta.venta_producto.length; k++) {
							let item = venta.venta_producto[k];
							
							productos[k].id_venta_producto = item.id_venta_producto;
							productos[k].id_venta = item.id_venta;
							productos[k].id_producto = item.id_producto;
							
							productos[k].venta_producto = item.venta_producto;
							productos[k].venta_descripcion = item.venta_descripcion;
							productos[k].id_cat_grupo = item.id_cat_grupo;
							productos[k].venta_cantidad = item.venta_cantidad;
							productos[k].venta_precio_unitario = item.venta_precio_unitario;
							productos[k].venta_costo_adicional = item.venta_costo_adicional;
							productos[k].venta_porcentaje_descuento = item.venta_porcentaje_descuento;
							productos[k].venta_descuento = item.venta_descuento;
							productos[k].venta_precio = item.venta_precio;
							productos[k].venta_producto_tipo_requerimiento = item.venta_producto_tipo_requerimiento || [];
							productos[k].venta_producto_requerimiento_adicional = item.venta_producto_requerimiento_adicional || [];
						}
						
					} else {
						
						productos = [];
						
						for (let k = 0; k < venta.venta_producto.length; k++) {
							let item = venta.venta_producto[k];
							productos.push({
								id_venta_producto: item.id_venta_producto,
								id_venta: item.id_venta,
								id_producto: item.id_producto,
								
								venta_producto: item.venta_producto,
								venta_descripcion: item.venta_descripcion,
								id_cat_grupo: item.id_cat_grupo,
								venta_cantidad: item.venta_cantidad,
								venta_precio_unitario: item.venta_precio_unitario,
								venta_costo_adicional: item.venta_costo_adicional,
								venta_porcentaje_descuento: item.venta_porcentaje_descuento,
								venta_descuento: item.venta_descuento,
								venta_precio: item.venta_precio,
								venta_producto_tipo_requerimiento: item.venta_producto_tipo_requerimiento || [],
								venta_producto_requerimiento_adicional: item.venta_producto_requerimiento_adicional || [],
							});
						}
					}
					
					/*
					* Recuperar como te enteraste y tipo requerimientos
					* */
					
					let venta_como_te_enteraste = venta.venta_como_te_enteraste || [];
					
					
					let cat_como_te_enteraste = [];
					
					for (let i = 0; i < response.data.cat_como_te_enteraste.length; i++) {
						let item0 = response.data.cat_como_te_enteraste[i];
						item0.checked = false;
						for (let j = 0; j < venta_como_te_enteraste.length; j++) {
							let item1 = venta_como_te_enteraste[j];
							if (item0.id_cat_como_te_enteraste === item1.id_cat_como_te_enteraste) {
								item0.id_venta_como_te_enteraste = item1.id_venta_como_te_enteraste;
								item0.checked = true;
							}
						}
						cat_como_te_enteraste.push(item0);
					}
					
					let cat_tipo_requerimiento = [];
					
					for (let i = 0; i < response.data.cat_tipo_requerimiento.length; i++) {
						let item = response.data.cat_tipo_requerimiento[i];
						item.checked = false;
						item.costo_adicional = '';
						item.id_cat_opcion_requerimiento = '';
						cat_tipo_requerimiento.push(item);
					}
					
					let form = {
						id_cliente: venta.id_cliente || '',
						id_cat_tipo_persona: venta.id_cat_tipo_persona || 1,
						tipo_persona: venta.tipo_persona || 'fisica',
						razon_social: venta.razon_social || '',
						nombre: venta.cliente_nombre || '',
						apellido_paterno: venta.cliente_apellido_paterno || '',
						apellido_materno: venta.cliente_apellido_materno || '',
						correo_electronico: venta.cliente_correo_electronico || '',
						celular: venta.cliente_celular || '',
						telefono: venta.cliente_telefono || '',
						rfc: venta.cliente_rfc || '',
						
						id_cliente_direccion: venta.cliente_id_cliente_direccion || '',
						calle: venta.cliente_calle || '',
						numero_exterior: venta.cliente_numero_exterior || '',
						numero_interior: venta.cliente_numero_interior || '',
						codigo_postal: venta.cliente_codigo_postal || '',
						colonia: venta.cliente_colonia || '',
						id_cat_estado: venta.cliente_id_cat_estado || '',
						id_cat_municipio: venta.cliente_id_cat_municipio || '',
					};
					
					this.setState({
						
						numero_pedido: venta.numero_pedido,
						fecha_alta: fecha_alta,
						fecha_entrega: fecha_entrega,
						porcentaje_descuento: venta.porcentaje_descuento,
						factura_cotizacion_nota_sencilla_interno: factura_cotizacion_nota_sencilla_interno,
						por_hora: venta.por_hora,
						anticipo: venta.anticipo,
						observaciones: venta.observaciones || '',
						
						cliente_deja_anexo: venta.cliente_deja_anexo || '',
						cliente_envia_muestra_por_correo: venta.cliente_envia_muestra_por_correo || '',
						
						id_cliente: venta.id_cliente || '',
						
						form: form,
						
						form_seleccionado: form,
						
						productos: productos,
						
						cat_como_te_enteraste: cat_como_te_enteraste,
						cat_tipo_requerimiento: cat_tipo_requerimiento,
						cat_grupo: response.data.cat_grupo || [],
						
						_id_producto: '',
						_producto: '',
						_descripcion: '',
						_id_cat_grupo: '',
						_cantidad: '',
						_precio_unitario: '',
						_otro_requerimiento: '',
						
						insumo: venta.insumo || ''
					});
				}
			}
			
		}).catch((error) => {
			this.setState({
				cat_tipo_requerimiento: [],
				cat_como_te_enteraste: [],
				cat_grupo: []
			});
		})
	};
	
	OrdenDeVentaContinuar = () => {
		
		console.log(this.state);
		
		let cat_como_te_enteraste = FieldsJs.Copy(this.state.cat_como_te_enteraste);
		let ids = [];
		for (let i = 0; i < cat_como_te_enteraste.length; i++) {
			if (!!cat_como_te_enteraste[i].checked) {
				ids.push(cat_como_te_enteraste[i].id_cat_como_te_enteraste);
			}
		}
		
		try {
			if (!this.state.factura_cotizacion_nota_sencilla_interno) {
				throw Object({
					status: false,
					mensaje: 'Selecciona el tipo de documento (Factura, Cotización, Nota sencilla)'
				});
			}
			if (this.state.factura_cotizacion_nota_sencilla_interno === "factura") {
				if (!this.state.form_seleccionado.rfc) {
					throw Object({
						status: false,
						mensaje: 'Es necesario el RFC del cliente para la factura'
					});
				}
			}
			if (!(this.state.productos.length > 0)) {
				throw Object({
					status: false,
					mensaje: 'Agrega los productos para la orden de venta'
				});
			}
			if (!FieldsJs.Array(ids)) {
				throw Object({
					status: false,
					mensaje: 'Selecciona como te enteraste'
				});
			}
			if (!this.state.fecha_entrega) {
				throw Object({
					status: false,
					mensaje: 'Selecciona la fecha de entrega'
				});
			}
			
			if (this.state.por_hora) {
				if (this.state.productos.length > 1) {
					throw Object({
						status: false,
						mensaje: 'Por el momento solo puedes agregar un solo producto al seleccionar Definir x hrs.'
					});
				}
			}
			
			if (this.state.factura_cotizacion_nota_sencilla_interno === "cotizacion" || this.state.factura_cotizacion_nota_sencilla_interno === "interno" || this.state.por_hora === true) {
				let msg = '';
				if (this.state.factura_cotizacion_nota_sencilla_interno === "cotizacion") {
					msg = '¿Estas seguro de guardar la cotización?';
				}
				if (this.state.factura_cotizacion_nota_sencilla_interno === "interno") {
					msg = '¿Estas seguro de guardar la orden de venta de tipo interno?';
				}
				if (this.state.por_hora === true) {
					msg = '¿Estas seguro de guardar la orden de venta como pago por horas trabajadas?';
				}
				PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
					if (r.button === 'Aceptar') {
						this.OrdenDeVentaGuardar(this.state.factura_cotizacion_nota_sencilla_interno, null, this.state.por_hora);
					}
				});
			} else {
				this.handleChangePagoIdCatFormaPago(this.state.productos, "productos");
				this.openModal(true, 'modalpagos');
			}
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	updatePrecio = () => {
		/*let product = this.state.productos;
		for (let i = 0; i < product.length; i++) {
			if (product[i].venta_cantidad > 0 && product[i].venta_precio_unitario > 0) {
				product[i].venta_precio = (product[i].venta_cantidad * product[i].venta_precio_unitario) + Number(product[i].venta_costo_adicional);
			} else {
				product[i].venta_precio = '';
			}
		}
		this.setState({
			productos: product
		});*/
		let productos = FieldsJs.Copy(this.state.productos);
		for (let i = 0; i < productos.length; i++) {
			let item = productos[i];
			if (item.venta_cantidad > 0 && item.venta_precio_unitario > 0) {
				let total = 0;
				total += (item.venta_cantidad * item.venta_precio_unitario);
				for (let j = 0; j < item.venta_producto_tipo_requerimiento.length; j++) {
					total += (item.venta_producto_tipo_requerimiento[j].costo_adicional || 0)
				}
				for (let j = 0; j < item.venta_producto_requerimiento_adicional.length; j++) {
					total += (item.venta_producto_requerimiento_adicional[j].costo_adicional || 0)
				}
				item.venta_precio = total;
				item.venta_descuento = this.total_descuento(total, (item.venta_porcentaje_descuento || 0), (this.state.porcentaje_descuento || 0));
				productos[i].venta_precio = item.venta_precio;
				productos[i].venta_descuento = item.venta_descuento;
			} else {
				productos[i].venta_precio = '';
				productos[i].venta_descuento = '';
			}
		}
		this.setState({
			productos: productos
		});
	};
	
	getCatTipoRequerimiento = (e) => {
		const {value, name, checked, type} = e.target;
		CatService.ListTipoRequerimiento(value || 0).then((response) => {
			this.setState({
				cat_tipo_requerimiento: response.data
			});
		});
	};
	
	apply_search = (data) => {
		console.log("apply_search", data)
		if (FieldsJs.Array(data.value)) {
			let item = data.value;
			let id_cat_grupo = item.id_cat_grupo || 1
			this.setState({
				_id_producto: item.id_producto,
				_producto: item.producto,
				_descripcion: item.descripcion,
				_id_cat_grupo: id_cat_grupo,
				_cantidad: 1,
				_precio_unitario: item.precio_unitario,
				_otro_requerimiento: '',
				_numero_folio_orden_venta: '',
			});
			this.getCatTipoRequerimiento({target: {value: id_cat_grupo}})
		} else {
			this.setState({
				_id_producto: '',
				_producto: '',
				_descripcion: '',
				_id_cat_grupo: '',
				_cantidad: '',
				_precio_unitario: '',
				_otro_requerimiento: '',
				_numero_folio_orden_venta: '',
				
				cat_tipo_requerimiento: []
			});
		}
	};
	
	OrdenDeVentaActualizar = () => {
		let btn = ['Cancelar', 'Aceptar'];
		let msg = '¿Deseas actualizar la orden de venta?';
		
		PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === btn[1]) {
				this.OrdenDeVentaGuardar();
			}
		});
	};
	
	OrdenDeVentaGuardar = (factura_cotizacion_nota_sencilla_interno, tab, por_hora) => {
		
		console.log("OrdenDeVentaGuardar", this.state);
		
		/*OrdenDeVentaService.OrdenDeVentaGuardar(this.state).then((response) => {
			
			this.props.showSnackBars('success', response.mensaje);
			
			if (this.props.params.editar === 'editar') {
				setTimeout(() => {
					window.history.back();
				}, 2000);
			} else {
				
				this.resetState();
				
				let info = response.data.pagos;
				
				let productos_anticipo = this.state.productos_anticipo;
				
				for (let i = 0; i < info.venta_producto.length; i++) {
					for (let j = 0; j < productos_anticipo.length; j++) {
						if (i === j) {
							productos_anticipo[j].id_venta_producto = info.venta_producto[i].id_venta_producto;
						}
					}
				}
				
				this.setState({
					id_venta_pagar_exportar: response.data.id_venta,
					cliente_pagar_exportar: response.data.cliente,
					
					ws_lista_pagos: info.pagos || [],
					ws_por_pagar: info.por_pagar || 0,
					ws_total_pagado: info.total_pagado || 0,
					ws_total_venta: info.total_venta || 0,
					ws_descuento_venta: info.descuento_venta || 0,
					ws_anticipo_completo: info.anticipo_completo || false,
					ws_numero_pedido: info.numero_pedido || '',
					
					productos_anticipo: productos_anticipo,
				});
				
				if (factura_cotizacion_nota_sencilla_interno === "cotizacion" || factura_cotizacion_nota_sencilla_interno === "interno" || por_hora === true) {
					this.VerExportarOrden();
				} else {
					this.OrdenDeVentaPagar(response.data.id_venta, this.state.formpagos, tab);
				}
				
			}
			
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		})*/
	};
	
	updatePeriodo = (init, end) => {
		this.setState({
			fecha_periodo_inicio:init,
			fecha_periodo_fin: end
		});
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_paquete}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Paquete'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<div className={'w-100'} align={'center'}>
									
									<section style={{maxWidth: '1400px', width: '100%'}}>
										
										<ContenidoOrdenVenta
											
											OrdenDeVentaDatos={this.OrdenDeVentaDatos}
											handleChange={this.handleChange}
											updatePrecio={this.updatePrecio}
											
											mas={this.mas}
											menos={this.menos}
											
											eliminar_requerimiento={this.eliminar_requerimiento}
											eliminar_producto_atributo={this.eliminar_producto_atributo}
											agregar_producto_atributo={this.agregar_producto_atributo}
											
											producto_requerimiento_adicional={this.state.producto_requerimiento_adicional}
											
											agregar_producto_requerimiento_adicional={this.agregar_producto_requerimiento_adicional}
											eliminar_producto_requerimiento_adicional={this.eliminar_producto_requerimiento_adicional}
											
											cat_tipo_requerimiento={this.state.cat_tipo_requerimiento}
											cat_grupo={this.state.cat_grupo}
											productos={this.state.productos}
											form={this.state}
											editar={this.props.tipo === 'edit'}
											id_venta={this.state.id_venta}

											getCatTipoRequerimiento={this.getCatTipoRequerimiento}
											resetTipoRequerimiento={this.resetTipoRequerimiento}
											apply_search={this.apply_search}
											
											OrdenDeVentaContinuar={this.OrdenDeVentaContinuar}
											OrdenDeVentaGuardar={this.OrdenDeVentaGuardar}
											OrdenDeVentaActualizar={this.OrdenDeVentaActualizar}
											updateDesglose={this.updateDesglose}
											
											showSnackBars={this.props.showSnackBars}
											
											updatePeriodo={this.updatePeriodo}
										/>
									
									</section>
								
								</div>
							
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_paquete}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPaquetes.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPaquetes;
