import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ListaTabla from './Includes/ListaTabla';
import ModalIngresos from './Includes/ModalIngresos';

import {IngresosService} from '../../../services/IngresosService/IngresosService';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {
	BotonActualizarLista,
	BotonExportarListaExcel,
	BotonExportarListaPDF,
	CabeceraTituloPdfExcelLista
} from "../../Include/MiniComponents/GlobalComponent";
import Grid from "@material-ui/core/Grid";
import Chart from "react-google-charts";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";


class Ingresos extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			listar_cat_sexo: []
		};
		this.Listar();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	Listar = () => {
		IngresosService.Listar().then((response) => {
			this.setState({
				listar_cat_sexo: response.data
			});
		}).catch((error) => {
			this.setState({
				listar_cat_sexo: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Ingresos'}
					botonPDF={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaPDF onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}
					botonEXCEL={
						<Fragment>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<BotonExportarListaExcel onClick={() => {
								
								}}/>
							) : ''}
						</Fragment>
					}
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				
				<Grid spacing={2} container >
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Card>
							<div>
								<CardContent>
									<Typography component="h5" variant="h5">
										Ingreso Total
									</Typography>
									<Typography variant="subtitle1" color="textSecondary">
										$10000.00
									</Typography>
								</CardContent>
							</div>
							<CardMedia
								image="assets/img/icons/analisis.svg"
								title="Live from space album cover"
							/>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Card>
							<div>
								<CardContent>
									<Typography component="h5" variant="h5">
										Ingreso Total
									</Typography>
									<Typography variant="subtitle1" color="textSecondary">
										$10000.00
									</Typography>
								</CardContent>
							</div>
							<CardMedia
								image="assets/img/icons/analisis.svg"
								title="Live from space album cover"
							/>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Card>
							<div>
								<CardContent>
									<Typography component="h5" variant="h5">
										Ingreso Total
									</Typography>
									<Typography variant="subtitle1" color="textSecondary">
										$10000.00
									</Typography>
								</CardContent>
							</div>
							<CardMedia
								image="assets/img/icons/analisis.svg"
								title="Live from space album cover"
							/>
						</Card>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Chart
							width={'100%'}
							height={'500px'}
							chartType="Bar"
							loader={<div>Loading Chart</div>}
							data={[
								['Year', 'Sales', 'Expenses', 'Profit'],
								['2014', 1000, 400, 200],
								['2015', 1170, 460, 250],
								['2016', 660, 1120, 300],
								['2017', 1030, 540, 350],
							]}
							options={{
								// Material design options
								// backgroundColor: { fill: "#F4F4F4" },
								chart: {
									title: 'Ingresos',
									// subtitle: 'Sales, Expenses, and Profit: 2014-2017',
								},
								colors: ['#ccc', '#bbb'],
								
							}}
							// For tests
							rootProps={{ 'data-testid': '2' }}
						/>
					</Grid>
					
				</Grid>
				
				<div className={'form margin-30-B'}>
					
					{this.state.listar_cat_sexo.length > 0 ? (
						<ListaTabla
							lista={this.state.listar_cat_sexo}
							RefreshList={this.RefreshList}
							showSnackBars={this.showSnackBars}
						/>
					) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				<ModalIngresos
					tipo={'add'}
					item={{}}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>
			
			</div>
		);
	}
}

Ingresos.propTypes = {
};

export default IntegrationNotistack(Ingresos);
