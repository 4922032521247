import React, {Component} from 'react';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import Checkbox from "@material-ui/core/Checkbox/index";
import FormGroup from "@material-ui/core/FormGroup/index";
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import Grid from "@material-ui/core/Grid";

class ModalTipoRequerimiento extends Component {
	
	
	render() {
		
		return (
			<div>
				
				<Dialog open={this.props.modal.open} onClose={() => this.props.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.props.modal.title} tipo de requerimiento
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12}>
								<TextField
									autoFocus
									margin="none"
									name="tipo_requerimiento"
									label="Tipo de requerimientos"
									type="text"
									fullWidth
									defaultValue={this.props.form.tipo_requerimiento}
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.props.handleChange}
									disabled={this.props.modal.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									helperText=""
									name="id_cat_grupo"
									label="Grupo"
									value={this.props.form.id_cat_grupo}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.props.form.list_cat_grupo.map(option => (
										<option key={option.id_cat_grupo} value={option.id_cat_grupo}>
											{option.grupo}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12}>
								<FormGroup row>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='con_costo_adicional' className={'padding-3-T padding-1-B'}
											          checked={this.props.form.con_costo_adicional}
											          onChange={this.props.handleChange} value="con_costo_adicional"
											          color="primary"
											          disabled={this.props.modal.tipo === 'view'}/>
										}
										label={'Con costo adicional'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12}>
								<FormGroup row>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='activo' className={'padding-1-T padding-1-B'}
											          checked={this.props.form.activo}
											          onChange={this.props.handleChange} value="activo" color="primary"
											          disabled={this.props.modal.tipo === 'view'}/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.props.closeModal()} color="primary">
							{this.props.modal.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.props.modal.tipo !== 'view' ? (
							<Button onClick={() => this.props.save()} color="primary">
								{this.props.form.id_cat_tipo_requerimiento > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

export default ModalTipoRequerimiento;
