import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo} from "../../../../settings/General/General";
import {VentasService} from "../../../../services/VentasService/VentasService";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";


class ModalEnviarCliente extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			id_venta: null,
			item: {},
			
			tipo_total: null,
			tipo_parcial: null,
			tipo_cantidad: null
		};
	}
	
	openModal = () => {
		const {id_venta, item} = this.props;
		this.setState({
			modal: {
				open: true
			},
			
			id_venta: id_venta,
			item: item,
			
			tipo_total: null,
			tipo_parcial: null,
			tipo_cantidad: null
		});
		console.log(this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	save = () => {
		try {
			
			if (!(this.state.tipo_total === 1) && !(this.state.tipo_parcial === 1)) {
				throw Object({
					status: false,
					mensaje: "Selecciona una opción"
				});
			}
			
			if (!(this.state.tipo_cantidad > 0)) {
				throw Object({
					status: false,
					mensaje: "Coloca la cantidad a entregar"
				});
			}
			
			let producto_entrega_cliente = [];
			
			producto_entrega_cliente.push({
				id_venta_producto: this.state.item.id_venta_producto,
				cantidad: this.state.tipo_cantidad
			});
			
			VentasService.VentasProductoEntregarCliente(this.state.id_venta, producto_entrega_cliente).then((response) => {
				this.closeModal();
				this.props.watchEnviarCliente();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	render() {
		
		return (
			<div>
				
				<div onClick={() => this.openModal()}>
					{this.props.icono}
				</div>
				
				<Dialog open={this.state.modal.open} onClose={() => this.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						Entrega al cliente
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
							
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'center'}>
								<Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<div style={{
											width: "calc(100% - 20px)",
											height: "70px",
											background: this.state.tipo_total === 1 ? "#7fc6e6" : "gainsboro",
											padding: "10px",
										}} className={'vertical-inline cursor-pointer'} onClick={() => {
											this.setState({
												tipo_total: 1,
												tipo_parcial: 0,
												tipo_cantidad: (this.state.item.venta_cantidad - this.state.item.venta_entregado_cliente)
											})
										}}>
											<div className={'w-100 v-center text-center'}>
												Total
											</div>
										</div>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<div style={{
											width: "calc(100% - 20px)",
											height: "70px",
											background: this.state.tipo_parcial === 1 ? "#7fc6e6" : "gainsboro",
											padding: "10px",
										}} className={'vertical-inline cursor-pointer'} onClick={() => {
											this.setState({
												tipo_total: 0,
												tipo_parcial: 1,
												tipo_cantidad: ''
											})
										}}>
											<div className={'w-100 v-center text-center'}>
												Parcial
											</div>
										</div>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item xs={8} sm={8} md={8} lg={8} xl={8} align={'center'} className={'padding-15'}>
								
								<Typography
									component="span"
									variant="body2"
									style={{
										display: 'inline',
									}}
									color="textPrimary"
								>
									{this.state.item.venta_producto}
								</Typography>
								
								<br/>
								
								<Typography
									component="span"
									variant="body2"
									style={{
										display: 'inline',
									}}
									color="textPrimary"
								>
									{this.state.item.venta_descripcion} {this.state.item.requerimientos ? "(" + this.state.item.requerimientos + ")" : ""}
								</Typography>
								
								<br/>
								
								<Typography component="span" className={"text-center padding-0 margin-0"} style={{
									fontSize: "28px"
								}}>
									{this.state.item.venta_entregado_cliente} / {this.state.item.venta_cantidad}
								</Typography>
								
								<br/>
								
								{this.state.tipo_total === 1 || this.state.tipo_parcial === 1 ? (
									<Fragment>
										<TextField
											type="text"
											margin="none"
											inputProps={{
												style: {
													width: "100px",
													fontSize: "35px"
												},
												className: "text-center padding-0 margin-0",
												placeholder: "0"
											}}
											onKeyPress={EnteroSolo}
											onChange={(e) => {
												this.setState({
													tipo_cantidad: e.target.value
												});
											}}
											value={this.state.tipo_cantidad}
											disabled={this.state.tipo_total === 1 && this.state.tipo_parcial === 0}
										/>
									</Fragment>
								) : (
									<Fragment>
										<div>Selecciona una de las opciones</div>
									</Fragment>
								)}
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Button onClick={() => this.closeModal()} color="primary">
							Cerrar
						</Button>
						<Button onClick={() => this.save()} color="primary">
							Entregar
						</Button>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}


ModalEnviarCliente.propTypes = {
	id_venta: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
	icono: PropTypes.element.isRequired,
	watchEnviarCliente: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalEnviarCliente;
