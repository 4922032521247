import React, {Component, Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import PropTypes from "prop-types";
import {FieldsJs} from "../../../../settings/General/General";
import Card from "@material-ui/core/Card";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import pakageIcon from "../../../../assets/img/icon-catalogo/lista_paquete.svg";
import Divider from "@material-ui/core/Divider";
import {PaqueteService} from "../../../../services/_Cat/PaqueteService/PaqueteService";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import DialogTitle from "@material-ui/core/DialogTitle";


class ModalPaquetes extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			listar_cat_paquetes: [],
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	Listar = () => {
		return new Promise((resolve, reject) => {
			PaqueteService.Listar().then((response) => {
				this.setState({
					listar_cat_paquetes: response.data
				});
				resolve(response);
			}).catch((error) => {
				this.setState({
					listar_cat_paquetes: []
				});
				this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cat_paquete: '',
			activo: true,
		});
		
		this.Listar().then((response) => {
			if (response.data.length > 0) {
				this.open();
			} else {
				this.props.showSnackBars('error', "No hay paquetes registrados.");
			}
		});
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_paquete: item.id_cat_paquete || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_paquete: item.id_cat_paquete || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	selectPaquete = (item) => {
		console.log("SELECT PAQUETE ::::", item);
		
		PaqueteService.Detalle(item.id_cat_paquete).then((response) => {
			this.close();
			this.props.actualizarProductosPaquetes(response.data.venta_producto);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						{'Selecciona un paquete para tu nueva orden de venta:'}
					</DialogTitle>
					
					<DialogContent>
						
						<div className={'padding-10'}>
							<Grid container spacing={2} style={{overflowX: "auto"}}>
								
								{this.state.listar_cat_paquetes.map((item, key) => (
									<Grid key={key} item xs={12} sm={12} md={3} lg={3} xl={3}>
										<div className={'w-100 w-100 margin-10 cursor-pointer '}>
											<Card className={item.selected ? 'border-selected paquetes' : 'paquetes'} onClick={() => {
												this.selectPaquete(item)
											}}>
												<div className={'padding-10'}>
													<div className={'vertical-inline w-100'}>
														<div className={'v-center w-30 text-left'}>
															<p className={'px-13'} style={{margin: '0px', color: 'gray', marginTop: '3px'}}><strong>#{item.codigo}</strong></p>
														</div>
														<div className={'v-center w-70 text-right'}>
															<p className={'px-13'} style={{margin: '0px', color: '#b9996f', marginTop: '3px'}}>{DateFormat.FormatText(item.fecha_periodo_inicio) + ' | ' + DateFormat.FormatText(item.fecha_periodo_fin)}</p>
														</div>
													</div>
												</div>
												
												<div className={'padding-10 padding-15-T'}>
													<p className={'px-18'} style={{margin: 0, color: '#961f1f', fontWeight: 'bold', textAlign: 'center'}}>{item.nombre}</p>
												</div>
												
												<div className={'v-center w-100 text-center'}>
													<img className={'v-center'} src={pakageIcon} alt="add" width={100} height={100} style={{opacity: 0.7}}/>
												</div>
												
												<div className={'padding-10 padding-15-T'}>
													<p className={'px-20'} style={{margin: '0px', color: 'red', fontWeight: 'bold', marginTop: '3px'}}>${FieldsJs.Currency(item.precio_total)}</p>
												</div>
												
												<div className={'padding-10 padding-15-B min-h-100-px'}>
													<div className={'vertical-inline w-100 padding-10-T'}>
														<div className={'v-center w-30 text-left'}>
															<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Cantidad</strong></p>
														</div>
														<div className={'v-center w-40 text-left'}>
															<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Producto</strong></p>
														</div>
														<div className={'v-center w-30 text-right'}>
															<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Precio</strong></p>
														</div>
													</div>
													{item.venta_producto.map((pro, keyp) => (
														<Fragment key={keyp}>
															<Divider/>
															<div className={'vertical-inline w-100 padding-5-T padding-5-B'}>
																<div className={'v-center w-30 text-left'}>
																	<p className={'px-13'} style={{margin: '0px'}}>{pro.venta_cantidad}</p>
																</div>
																<div className={'v-center w-40 text-left'}>
																	<p className={'px-13'} style={{margin: '0px'}}>{pro.venta_producto}</p>
																</div>
																<div className={'v-center w-30 text-right'}>
																	<p className={'px-13'} style={{margin: '0px'}}>${FieldsJs.Currency(pro.venta_precio)}</p>
																</div>
															</div>
														</Fragment>
													))}
												</div>
											</Card>
										</div>
									</Grid>
								))}
							</Grid>
						</div>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item className={'text-right'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Button onClick={() => this.closeModal()} color="primary">Cerrar</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			</div>
		);
	}
}

ModalPaquetes.propTypes = {
	actualizarProductosPaquetes: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};


export default ModalPaquetes;
