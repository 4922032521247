/*

npm i -g npm-check-updates
ncu -u
npm install

*/

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

let a, s, af, sf, pusher_app_key;
switch (hostname) {
	case 'localhost':
	case '127.0.0.1':
		a = 'http://localhost/api_mark/public/api/';
		s = 'http://localhost/api_mark/public/';
		pusher_app_key = 'c83734b103069350dc25';
		af = "https://api-test.acrescotec.com/public/api/";
		sf = "https://api-test.acrescotec.com/public/";
		break;
	case 'mark.conexopen.com':
		a = 'http://mark.conexopen.com/api_integraciones/public/api/';
		s = 'http://mark.conexopen.com/api_integraciones/public/';
		pusher_app_key = 'c83734b103069350dc25';
		break;
	case 'virtual.markdesing.com.mx':
		a = 'http://store.markdesing.com.mx/public/api/';
		s = 'http://store.markdesing.com.mx/public/';
		pusher_app_key = 'c83734b103069350dc25';
		break;
	case 'sistema.markdesing.com.mx':
		a = 'http://apimark.markdesing.com.mx/public/api/';
		s = 'http://apimark.markdesing.com.mx/public/';
		pusher_app_key = 'ff15eef8898f41c63fcf';
		break;
	default:
		a = protocol + '//api' + hostname + '/public/api/';
		s = protocol + '//api' + hostname + '/public/';
		pusher_app_key = 'ff15eef8898f41c63fcf';
		af = "https://api-test.acrescotec.com/public/api/";
		sf = "https://api-test.acrescotec.com/public/";
}
const api = a;
const src = s;
const api_facturacion = af;
const src_facturacion = sf;

const basename = hostname === 'localhost' ? (Number(port) === 3000 || Number(port) === 3001 || Number(port) === 3002 || Number(port) === 3003 ? '' : '/puntov/build/') : '/';

export const CONFIG = {
	prefix: 'puntov',
	id_project: 3,
	api: api,
	src: src,
	api_facturacion: api_facturacion,
	src_facturacion: src_facturacion,
	basename: basename,
	foto_default: src + 'file/perfil/default-perfil.svg',
	portada_default: src + 'file/portada/default-portada.svg',
	menu: 1,
	debug: true,
	pusher_app_key: pusher_app_key,
	pusher_app_cluster: 'us2',
	pusher_app_force_tls: true,
	pusher_app_log_to_console: true,
	segundo_confirmar_token_expirado: 30,
	tipo_menu_contextual: 2,
	onesignal_hostname_prod: 'mark.mlp.mx',
	onesignal_app_id_prod: '879ad787-9142-4e84-acfc-ce411fca10a3',
	onesignal_app_id_test: '812bedc8-849f-4a46-b4a5-c2d18f7af0f3',
	titulo_alert_confirm: 'Mark Desing',
	
	DROPBOX_TOKEN: "Zto1IdH0HsAAAAAAAAAGWnUFdiRZ7CsV5T0Ww7H1jD7yMmebB80RO6qhmb2a89pw",
	DROPBOX_TOKEN_INTEGRACIONES: "Zto1IdH0HsAAAAAAAAAAEf3ZBqVsxJoNqmcp2-zRmCl6GkG7RpIDpAwShiiEY5je",
	DROPBOX_API: "https://api.dropboxapi.com/2/files/",
	DROPBOX_CONTENT: "https://content.dropboxapi.com/2/files/",
};
