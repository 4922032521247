import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const TableroService = {
	Datos: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Tablero_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};

