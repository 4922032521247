import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {EnteroSolo} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";


class FormCheque extends Component {
	
	render() {
		
		return (
			<Fragment>
				<div className={'borde-punteado-gray-1 padding-15'}>
					<Grid container spacing={1}>
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Banco
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField
										select
										fullWidth
										variant={"filled"}
										margin={"none"}
										onChange={this.props.handleChangePago}
										SelectProps={{
											native: true,
											MenuProps: {},
										}}
										required
										name="id_cat_banco"
										//label="Banco"
										value={this.props.form.id_cat_banco}
										inputProps={{
											maxLength: 100,
											style: {
												paddingTop: "8px"
											}
										}}
									>
										<option key={0} value={''}>&nbsp;</option>
										{this.props.cat_banco.map(option => (
											<option key={option.id_cat_banco} value={option.id_cat_banco}>
												{option.banco}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Número de cheque
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField
										type={'text'}
										fullWidth
										variant={"filled"}
										margin={"none"}
										onChange={this.props.handleChangePago}
										name="numero_cheque"
										value={this.props.form.numero_cheque}
										onKeyPress={EnteroSolo}
										inputProps={{
											maxLength: 100,
											style: {
												paddingTop: "8px"
											}
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Nombre del Cuentahabiente
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="nombre_cuentahabiente"
									           value={this.props.form.nombre_cuentahabiente}
									           inputProps={{
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						</Grid>
						
						<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Monto pagado
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="monto"
									           value={this.props.form.monto}
									           onKeyPress={EnteroSolo}
									           inputProps={{
										           maxLength: 15,
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						
						</Grid>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Button variant="contained" color="primary" onClick={this.props.Pagar}
							        className={'btn-default-primary'}>
								Guardar pago
							</Button>
						</Grid>
					
					</Grid>
				
				</div>
			</Fragment>
		);
	}
}

FormCheque.propTypes = {
	handleChangePago: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	Pagar: PropTypes.func.isRequired,
	cat_banco: PropTypes.array.isRequired,
};

export default FormCheque;
