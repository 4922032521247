import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import BoxSelectFile from "../../../Include/MiniComponents/BoxSelectFile";
import Avatar from "@material-ui/core/Avatar";
import {Icono} from "../../../Include/Icono/Icono";
import ModalImage from "../../../Include/ModalImage/ModalImage";
import Box from "@material-ui/core/Box";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {CloudUploadOutlined, DeleteOutlined, EditOutlined, SearchOutlined} from '@material-ui/icons';

import lineas_de_texto_svg from '../../../../assets/img/icons/lineas-de-texto.svg'
import FiltrosVentas from "./FiltrosVentas";
import ListaTabla from "./ListaTablaVentas";
import Paginacion from "../../../Include/Paginacion/Paginacion";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {DropboxApi} from "../../../../settings/DropboxApi/DropboxApi";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {VentasService} from "../../../../services/VentasService/VentasService";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalComprobantes from "./ModalComprobantes";
import ModalCompras from "./ModalCompras";
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {withStyles} from '@material-ui/core/styles/index';
import FiltrosCompras from "./FiltrosCompras";
import FiltrosVerCompras from "./FiltrosVerCompras";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ModalVerCompras extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			filtro: {},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_cat_compra: []
			
		};
		this.RefreshList();
	}
	
	RefreshList = () => {
		this.Listar();
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			if (this.props.id_compra > 0) {
				ComprasService.Detalles(this.props.id_compra).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_compra = item.fecha_compra ? moment(item.fecha_compra) : null;
					this.setState({
						id_compra: item.id_compra,
						
						descripcion: item.descripcion || '',
						fecha_compra: fecha_compra,
						total: item.total || '',
						
						compra_producto: item.compra_producto || [],
						
						compra_comprobante: item.compra_comprobante || [],
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				resolve(true);
			}
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	
	ListBanco = () => {
		CatService.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data
			});
		});
	};
	
	ListarDropbox = () => {
		DropboxApi.List('').then((response) => {
			
			let listar_cfdi = response;
			this.setState({
				listDropbox: listar_cfdi.entries
			});
			console.log("LISTAR CFDI::: ", listar_cfdi);
			
		}).catch((error) => {
			
			this.setState({listar_facturacion: []});
			
			this.props.showSnackBars('error', error.mensaje);
			
		});
	};
	
	
	handleChangeForm = (e) => {
		const {value, name, checked, type} = e.target;
		let form = this.state.form || {};
		form[name] = type === 'checkbox' ? checked : value;
		this.setState({
			form: form
		});
	};
	
	handleChangePago = (e, variable, campo, date, input) => {
		let formpagos = FieldsJs.Copy(this.state.formpagos) || {};
		if ((date && input) || input) {
			formpagos[input] = date;
		} else {
			const {value, name, checked, type} = e.target;
			formpagos[name] = type === 'checkbox' ? checked : value;
		}
		
		this.setState({
			formpagos: formpagos
		});
	};
	
	handleChangePagoIdCatFormaPago = (v, n) => {
		//let desglose = this.updateDesglose(null, {});
		let formpagos = FieldsJs.Copy(this.state.formpagos) || {};
		formpagos[n] = v;
		// formpagos["descuento_venta"] = desglose.descuento;
		// formpagos["total_venta"] = desglose.total;
		// formpagos["productos"] = desglose.productos;
		formpagos["id_venta"] = this.state.id_venta_pagar_exportar;
		this.setState({
			formpagos: formpagos
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		ComprasService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			
			let listar_cat_compra = response.data;
			
			this.setState({
				listar_cat_compra: listar_cat_compra,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_cat_compra: []});
			
			this.props.showSnackBars('error', error.mensaje);
			
		});
	};
	
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_ventas', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_venta: item.id_venta
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_venta) !== Number(item.id_venta)) {
					ids_expandido.push({
						id_venta: ids_temp[i].id_venta
					})
				}
			}
			
		}
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	
	render() {
		const {classes } = this.props;
		
		return (
			<div>
				
				<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
				</Fragment>
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Compras / Gastos
					</DialogTitle>
					
					<DialogContent>
						<FiltrosVerCompras
							AplicarFiltros={this.AplicarFiltros}
							HandleFiltro={this.HandleFiltro}
							showSnackBars={this.props.showSnackBars}
							tipo_vista={"Compra"}
						/>
						
						<div className={'form margin-20-T margin-30-B'}>
							
							{this.state.listar_cat_compra.length > 0 ? (
								<Fragment>
									<Table className={classes.table + ' desing-mark'}>
										<TableHead>
											<TableRow>
												<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>No. compra</TableCell>
												<TableCell component="th" align={'left'}>Usuario</TableCell>
												<TableCell component="th" align={'left'}>Proveedor</TableCell>
												<TableCell component="th" align={'left'}>Descripción</TableCell>
												<TableCell component="th" align={'left'}>Forma de pago</TableCell>
												<TableCell component="th" align={'left'}>Fecha compra</TableCell>
												<TableCell component="th" align={'left'}>Fecha alta</TableCell>
												<TableCell component="th" align={'left'}>Total</TableCell>
												<TableCell component="th" align={'center'}>Subir comprobante</TableCell>
												<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.listar_cat_compra.map((item, key) => {
												return (
													<TableRow key={key}>
														<TableCell align={'center'}
														           className={'padding-20-L padding-20-R'}>{item.numero_compra}</TableCell>
														<TableCell align={'left'}>{item.usuario_nombre_completo}</TableCell>
														<TableCell align={'left'}>{item.proveedor_nombre_comercial}</TableCell>
														<TableCell align={'left'}>{item.descripcion}</TableCell>
														<TableCell align={'left'}>{item.forma_pago}</TableCell>
														<TableCell align={'left'}>{DateFormat.FormatText(item.fecha_compra)}</TableCell>
														<TableCell align={'left'}>{DateFormat.FormatText(item.fecha_alta)}</TableCell>
														<TableCell align={'left'}>${FieldsJs.Currency(item.total)}</TableCell>
														<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
															<div className={'w-auto vertical-inline'}>
																<div className={'v-center'}>
																	<ModalComprobantes
																		id_compra={item.id_compra}
																		item={item}
																		icono={(
																			<Tooltip TransitionComponent={Zoom} placement={"top"}
																			         title={(item.compra_comprobante.length > 0 ? "Ver o subir comprobantes" : "Subir comprobante")}>
																				<IconButton aria-label={(item.compra_comprobante.length > 0 ? "Ver o subir comprobantes" : "Subir comprobante")}>
																					<CloudUploadOutlined style={{color: (item.compra_comprobante.length > 0 ? "green" : "gray")}}/>
																				</IconButton>
																			</Tooltip>
																		)}
																		RefreshList={this.props.RefreshList}
																		showSnackBars={this.props.showSnackBars}
																	/>
																</div>
															</div>
														</TableCell>
														<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
															<div className={'w-auto vertical-inline'}>
																<div className={'v-center'}>
																	<ModalCompras
																		id_compra={item.id_compra}
																		item={item}
																		tipo={'view'}
																		icono={(
																			<Tooltip TransitionComponent={Zoom} placement={"top"}
																			         title="Detalles">
																				<IconButton aria-label="Detalles">
																					<SearchOutlined/>
																				</IconButton>
																			</Tooltip>
																		)}
																		RefreshList={this.props.RefreshList}
																		showSnackBars={this.props.showSnackBars}
																	/>
																</div>
															</div>
														</TableCell>
													</TableRow>
												)
											})}
										</TableBody>
									</Table>
									<Paginacion
										total={this.state.paginacion.total}
										page={this.state.paginacion.page}
										limit={this.state.paginacion.limit}
										rangos={this.state.paginacion.rangos}
										onClick={(data) => this.AplicarPaginacion(data)}
									/>
								</Fragment>
							
							) : (
								<VistaVacia numero={1} mensaje={'No se encontraron ordenes de venta'}/>
							)}
						</div>
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalVerCompras.propTypes = {
	item: PropTypes.object.isRequired,
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ModalVerCompras);

