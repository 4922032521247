import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";
import * as moment from "moment";

export const TimbradoJsonService = {
	Listar: (filtro, paginacion) => {
		console.log('PAGINACION', paginacion);
		let fecha_alta = (filtro.fecha_alta || null) ? DateFormat.FormatSql(filtro.fecha_alta) : null;
		
		let params = {
			filtro: {
				id_facturacion: filtro.id_facturacion || null,
				cliente: filtro.cliente || null,
				id_cat_forma_pago: filtro.id_cat_forma_pago || null,
				id_cat_metodo_pago: filtro.id_cat_metodo_pago || null,
				id_cat_periodo_pago: filtro.id_cat_periodo_pago || null,
				id_cat_facturacion_estatus: filtro.id_cat_facturacion_estatus || null,
				activo: filtro.activo || 1,
				fecha_alta: fecha_alta,
				ordenar_fecha_alta: filtro.ordenar_fecha_alta || null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Estadistica: (id_facturacion) => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Estadistica', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Guardar: (form) => {
		let params = {
			json: form.json,
			enviar_a: form.enviar_a,
			enviar_cc: form.enviar_cc,
			enviar_cco: form.enviar_cco
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Mark', params,  {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Eliminar', params, ).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Cerrar: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Cerrar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AutorizarFactura: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Autorizar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	PorAutorizarFactura: (id_facturacion) => {
		let params = {
			id_facturacion: id_facturacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Por_Autorizar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Timbrado: (item) => {
		let params = {
			id_facturacion_archivo : item.id_facturacion_archivo,
			id_facturacion : item.id_facturacion,
			id_cat_tipo_comprobante : item.id_cat_tipo_comprobante,
			id_cat_forma_pago : item.id_cat_forma_pago,
			id_cat_metodo_pago : item.id_cat_metodo_pago,
			id_cat_periodo_pago : item.id_cat_periodo_pago,
			serie : item.serie,
			folio : item.folio,
			folio_fiscal : item.folio_fiscal,
			uuid : item.uuid,
			observacion : item.observacion_facturacion,
			pagado : item.pagado,
			url_xml_factura : item.url_xml_factura,
			url_pdf_factura : item.url_pdf_factura,
			url_xml_factura_externa : item.url_xml_factura_externa,
			url_pdf_factura_externa : item.url_pdf_factura_externa,
			fecha_emitir_comprobante : item.fecha_emitir_comprobante,
			archivos_facturacion: item.ficha_tecnica_archivo_integracion_expediente,
			id_project: item.id_project,
			id_venta: item.id_venta
		};
		console.log("PARAMS::: ", params);
		
		return new Promise((resolve, reject) => {
			// resolve(true);
			HttpRequest.post('_Timbrado_Json_Timbrar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirComprobante: (form) => {
		let params = {
			id_facturacion: form.id_facturacion,
			comprobante_archivo: form.comprobante_archivo,
			comprobante_formato: form.comprobante_formato,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Subir_Comprobante', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	GuardarArchivoTimbradoXId: (form) => {
		let params = {
			id_facturacion: form.id_facturacion,
			id_ficha_tecnica: form.id_ficha_tecnica,
			id_cat_archivo_integracion_expediente: form.id_cat_archivo_integracion_expediente,
			id_ficha_tecnica_archivo_integracion_expediente: form.id_ficha_tecnica_archivo_integracion_expediente,
			archivo: form.archivo,
			formato: form.formato
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Subir_Archivo', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Cancelacion: (form) => {
		let params = {
			id_facturacion: form.id_facturacion,
			id_cat_facturacion_motivo_cancelacion: form.id_cat_facturacion_motivo_cancelacion,
			motivo: form.motivo,
			autorizar: form.autorizar ? 1:0,
			observacion: form.observacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Cancelacion', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	StatusSat: (form) => {
		let params = {
			id_facturacion: form.id_facturacion,
			id_facturacion_archivo: form.id_facturacion_archivo,
			id_cat_facturacion_status_sat: form.id_cat_facturacion_status_sat,
			observacion: form.observacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Status_Sat', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Comprobante: (form) => {
		let fecha_pago = (form.fecha_pago || null) ? moment(form.fecha_pago).format('YYYY-MM-DD hh:mm:ss') : null;
		
		let params = {
			id_facturacion: form.id_facturacion,
			id_facturacion_archivo: form.id_facturacion_archivo,
			fecha_pago: fecha_pago,
			archivos: form.archivos,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_Comprobante', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendMail: (form) => {
		let params = {
			id_facturacion: form.id_facturacion,
			id_facturacion_archivo: form.id_facturacion_archivo,
			id_usuario_email: form.id_usuario_email,
			otro_usuario: form.otro_usuario,
			correo_electronico: form.correo_electronico,
			correo_electronico_copia: form.correo_electronico_copia,
			text_email: form.text_email,
			check_rocket_chat: form.check_rocket_chat,
			check_email: form.check_email
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Json_SendMail', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
