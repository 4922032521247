import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import OrdenDeVenta from "../OrdenDeVenta";
import ModalCompras from "../../Compras/Includes/ModalCompras";
import Tooltip from "@material-ui/core/Tooltip/index";
import Zoom from "@material-ui/core/Zoom/index";
import IconButton from "@material-ui/core/IconButton/index";
import {CloudUploadOutlined, DeleteOutlined, EditOutlined, SearchOutlined} from '@material-ui/icons';


class ModalVerOrdenVenta extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => {
			this.setState({
				[r.name]: r.value,
			});
		});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const {form} = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, form);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
	
	};
	
	render() {
		let {item} = this.props;
		
		let ventaPago = this.props.id_venta;
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogContent>
						
						<Grid container spacing={2} className={'cotizador_fondo'}>
							<OrdenDeVenta params={{id_venta: this.props.id_venta, ver: "ver"}}/>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={12} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalVerOrdenVenta.propTypes = {
	id_venta: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object,
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func,
	showSnackBars: PropTypes.func,
};

export default ModalVerOrdenVenta;
