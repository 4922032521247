import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const EstadoCuentasService = {
	Listar: (form) => {
		let fecha_inicio = (form.fecha_inicio || null) ? DateFormat.FormatSql(form.fecha_inicio) : null;
		let fecha_fin = (form.fecha_fin || null) ? DateFormat.FormatSql(form.fecha_fin) : null;
		let params = {
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			referencia: form.numero_referencia || null,
			value_tabs: form.value_tabs
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estado_Cuenta_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_estado_cuenta: form.id_estado_cuenta,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estado_Cuenta_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_estado_cuenta: form.id_estado_cuenta,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estado_Cuenta_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_estado_cuenta) => {
		let params = {
			id_estado_cuenta: id_estado_cuenta
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estado_Cuenta_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ModificarSaldo: (form) => {
		let params = {
			id_cat_tipo_cuenta: form.id_cat_tipo_cuenta,
			minimo: form.minimo,
			maximo: form.maximo,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Cuenta_Modificar_Saldo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarFondeo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estado_Cuenta_Fondeo_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarFondeo: (form) => {
		let fecha_operacion = (form.fecha_operacion || null) ? DateFormat.FormatSql(form.fecha_operacion) : null;
		
		let params = {
			id_fondeo_cuenta: form.id_fondeo_cuenta,
			importe: form.importe,
			origen: form.origen,
			observacion: form.observacion,
			id_cat_tipo_ingreso_fondeo: form.id_cat_tipo_ingreso_fondeo,
			id_cat_forma_pago_fondeo: form.id_cat_forma_pago_fondeo,
			id_cat_destino_fondeo: form.id_cat_destino_fondeo,
			fecha_operacion: fecha_operacion,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estado_Cuenta_Fondeo_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ModificarFondeo: (form) => {
		let fecha_operacion = (form.fecha_operacion || null) ? DateFormat.FormatSql(form.fecha_operacion) : null;
		
		let params = {
			id_fondeo_cuenta: form.id_fondeo_cuenta,
			importe: form.importe,
			origen: form.origen,
			observacion: form.observacion,
			id_cat_tipo_ingreso_fondeo: form.id_cat_tipo_ingreso_fondeo,
			id_cat_forma_pago_fondeo: form.id_cat_forma_pago_fondeo,
			id_cat_destino_fondeo: form.id_cat_destino_fondeo,
			fecha_operacion: fecha_operacion,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estado_Cuenta_Fondeo_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EliminarFondeo: (id_fondeo_cuenta) => {
		let params = {
			id_fondeo_cuenta: id_fondeo_cuenta
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Estado_Cuenta_Fondeo_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
