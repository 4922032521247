import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const GrupoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Grupo_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_grupo: null,
			grupo: form.grupo,
			descripcion_sat: form.descripcion_sat,
			clave_producto_servicio_sat: form.clave_producto_servicio_sat,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Grupo_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_grupo: form.id_cat_grupo,
			grupo: form.grupo,
			descripcion_sat: form.descripcion_sat,
			clave_producto_servicio_sat: form.clave_producto_servicio_sat,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Grupo_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_grupo) => {
		let params = {
			id_cat_grupo: id_cat_grupo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Grupo_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
