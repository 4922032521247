import React, {Component} from 'react';


import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';

import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import Checkbox from "@material-ui/core/Checkbox/index";
import FormGroup from "@material-ui/core/FormGroup/index";
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import Grid from "@material-ui/core/Grid";
import {HdrWeakOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

class ModalMenu extends Component {
	
	
	render() {
		
		return (
			<div>
				
				<Dialog open={this.props.modal.open} onClose={() => this.props.closeModal()} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.props.modal.title} Menu
					</DialogTitle>
					
					<DialogContent>
						
						<article>
							
							<TextField
								autoFocus
								margin="dense"
								name="icono"
								label="Icono"
								type="text"
								fullWidth
								defaultValue={this.props.form.icono}
								onChange={this.props.handleChange}
								disabled={this.props.modal.tipo === 'view'}
							/>
							
							<TextField
								margin="dense"
								name="menu"
								label="Menu"
								type="text"
								fullWidth
								defaultValue={this.props.form.menu}
								onChange={this.props.handleChange}
								disabled={this.props.modal.tipo === 'view'}
							/>
							
							<TextField
								margin="dense"
								name="orden"
								label="Orden"
								type="text"
								fullWidth
								defaultValue={this.props.form.orden}
								onChange={this.props.handleChange}
								disabled={this.props.modal.tipo === 'view'}
							/>
							
							<FormControl fullWidth>
								<InputLabel>Pertenece a:</InputLabel>
								<Select
									native
									value={this.props.form.id_cat_menu_pertenece}
									onChange={this.props.handleChange}
									name="id_cat_menu_pertenece"
									input={<Input/>}
									disabled={this.props.tipo === 'view'}
								>
									<option value=""/>
									{this.props.form.listar_menu_pertenece.map(option => (
										<option key={option.id_cat_menu_pertenece}
										        value={option.id_cat_menu_pertenece}>
											{option.menu_pertenece}
										</option>
									))}
								</Select>
							</FormControl>
							
							<FormGroup row className={'margin-3-L'}>
								<FormControlLabel
									control={
										<Checkbox type="checkbox" name='activo' checked={this.props.form.activo}
										          onChange={this.props.handleChange} value="activo" color="primary"
										          disabled={this.props.modal.tipo === 'view'}
										/>
									}
									label={'Activo'}
								/>
							</FormGroup>
						
						</article>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.props.closeModal()} color="primary">
							{this.props.modal.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.props.modal.tipo !== 'view' ? (
							<Button onClick={() => this.props.save()} color="primary">
								{this.props.form.id_menu > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}


export default ModalMenu;
