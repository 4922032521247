import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {DialogActions} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {DateRangeOutlined, Send, Visibility} from "@material-ui/icons";
import moment from "moment";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {VentasService} from "../../../../services/VentasService/VentasService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {TimbradoFacturacionExtService} from "../../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import FiltrosVentas from "./FiltrosVentas";
import ListaTabla from "./ListaTabla";
import Paginacion from "../../../Include/Paginacion/Paginacion";
import FiltrosFacturas from "./FiltrosFacturas";
import ListaTablaFacturas from "./ListaTablaFacturas";


class ModalFacturasPublicoGeneral extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false,
			},
			
			tipo: props.tipo,
			
			filtro: {},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			listar_facturas: [],
			enviar_a: '',
		};
		
		this.Listar();
	}
	
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		return new Promise((resolve, reject) => {
			TimbradoFacturacionExtService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
				this.setState({
					listar_facturas: response.data,
					paginacion: {
						total: response.paginacion.total,
						page: response.paginacion.page,
						limit: response.paginacion.limit,
						rangos: this.DefaultRangos()
					}
				});
				resolve(response);
				//this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.setState({listar_facturacion: []});
				//this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	add = () => {
		let {item} = this.props;
		
		this.setState({
			enviar_a: '',
			id_cat_periodicidad: '',
			meses: '',
			anio: '',
			fecha_inicio: null,
			fecha_fin: null,
			timbrar: 0,
			pagos: [],
			listar_periodicidad: []
		});
		
		this.open();
	};
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.add()} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Timbrar PG
						</Fab>
					</Fragment>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Facturas Público General
					</DialogTitle>
					
					<DialogContent>
						<div className='Catalogos '>
							<div className={'row-flex margin-0-T margin-0-B'}>
								<Grid container spacing={0}>
								<FiltrosFacturas
									AplicarFiltros={this.AplicarFiltros}
									HandleFiltro={this.HandleFiltro}
									showSnackBars={this.showSnackBars}
									tipo_vista={this.props.tipo_vista}
								/>
								</Grid>
								
							</div>
							
								<div className={'margin-30-B'}>
									
									{this.state.listar_facturas.length > 0 ? (
										<Fragment>
											<ListaTablaFacturas
												lista={this.state.listar_facturas}
												exportar={this.exportar}
												exportarTicket={this.exportarTicket}
												modalDisenador={this.modalDisenador}
												produccion={this.produccion}
												// almacen={this.almacen}
												// entregar={this.entregar}
												cancelar={this.cancelar}
												activar={this.activar}
												OrdenDeVentaContinuar={this.OrdenDeVentaContinuar}
												
												watchAsignarCliente={this.watchAsignarCliente}
												watchEnviarTodoAlmacen={this.watchEnviarTodoAlmacen}
												watchEnviarTodoCliente={this.watchEnviarTodoCliente}
												watchHorasTrabajadas={this.watchHorasTrabajadas}
												watchEnviarAlmacen={this.watchEnviarAlmacen}
												watchEnviarCliente={this.watchEnviarCliente}
												watchAprobarCotizacion={this.watchAprobarCotizacion}
												
												handleChange={this.handleChange}
												SubTabla={this.SubTabla}
												showSnackBars={this.showSnackBars}
												RefreshList={this.Listar}
												
												DesactivarFacturaNotaSencillaInterno={this.DesactivarFacturaNotaSencillaInterno}
												listDropbox={this.state.listDropbox}
												history={this.props.history}
											
											/>
											<Paginacion
												total={this.state.paginacion.total}
												page={this.state.paginacion.page}
												limit={this.state.paginacion.limit}
												rangos={this.state.paginacion.rangos}
												onClick={(data) => this.AplicarPaginacion(data)}
											/>
										</Fragment>
									
									) : (
										<VistaVacia numero={1} mensaje={'No se encontraron CFDI´s'}/>
									)}
							</div>
						</div>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={12} align={'right'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalFacturasPublicoGeneral.propTypes = {
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	item: PropTypes.object,
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalFacturasPublicoGeneral;
