import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const BalancesService = {
	Listar: (filtro, paginacion) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			filtro: {
				id_cat_tipo_gasto_compra: filtro.id_cat_tipo_gasto_compra || null,
				fecha_inicio: fecha_inicio,
				fecha_fin: fecha_fin,
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Balances_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (filtro) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			filtro: {
				id_cat_tipo_gasto_compra: filtro.id_cat_tipo_gasto_compra || null,
				fecha_inicio: fecha_inicio,
				fecha_fin: fecha_fin,
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Balances_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
