import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../settings/General/General";

export const OrdenDeVentaService = {
	OrdenDeVentaDatos: (id_venta) => {
		let params = {
			id_venta: id_venta
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Orden_De_Venta_Datos_XId', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	OrdenDeVentaGuardar: (form) => {
		
		console.log(form);
		
		return new Promise((resolve, reject) => {
			
			try {
				
				let fecha_alta = DateFormat.FormatSql(form.fecha_alta);
				let fecha_entrega = DateFormat.FormatSql(form.fecha_entrega);
				
				if (!form.id_cliente) {
					throw Object({
						success: false,
						status: 400,
						mensaje: 'Selecciona o agrega un cliente para poder realizar la orden de venta'
					});
				}
				
				let venta_como_te_enteraste = [];
				let venta_producto = [];
				
				let item1 = {};
				var item2;
				let item3 = {};
				
				for (let i = 0; i < form.cat_como_te_enteraste.length; i++) {
					item1 = form.cat_como_te_enteraste[i];
					if (item1.checked) {
						venta_como_te_enteraste.push({
							id_venta_como_te_enteraste: item1.id_venta_como_te_enteraste || null,
							id_cat_como_te_enteraste: item1.id_cat_como_te_enteraste,
						});
					}
				}
				
				var venta_producto_tipo_requerimiento;
				var venta_producto_requerimiento_adicional;
				
				for (let i = 0; i < form.productos.length; i++) {
					item3 = form.productos[i];
					if (FieldsJs.Field(item3.venta_producto) && FieldsJs.Field(item3.venta_descripcion) && FieldsJs.Field(item3.venta_cantidad) && FieldsJs.Field(item3.venta_precio_unitario) && FieldsJs.Field(item3.venta_precio)) {
						
						venta_producto_tipo_requerimiento = [];
						item2 = {};
						
						for (let i = 0; i < item3.venta_producto_tipo_requerimiento.length; i++) {
							item2 = item3.venta_producto_tipo_requerimiento[i];
							venta_producto_tipo_requerimiento.push({
								id_venta_producto_tipo_requerimiento: item2.id_venta_producto_tipo_requerimiento || null,
								id_cat_tipo_requerimiento: item2.id_cat_tipo_requerimiento,
								costo_adicional: item2.costo_adicional || 0,
								id_cat_opcion_requerimiento: item2.id_cat_opcion_requerimiento || null
							});
						}
						
						
						if (FieldsJs.Array(item3.venta_producto_requerimiento_adicional)) {
							venta_producto_requerimiento_adicional = item3.venta_producto_requerimiento_adicional;
						} else {
							venta_producto_requerimiento_adicional = [];
						}
						
						venta_producto.push({
							id_venta_producto: item3.id_venta_producto || null,
							venta_id_producto: item3.venta_id_producto || 1, // asignar el id de producto 1 pro default
							venta_producto: item3.venta_producto || null,
							venta_descripcion: item3.venta_descripcion || null,
							id_cat_grupo: item3.id_cat_grupo || null,
							venta_cantidad: item3.venta_cantidad || 0,
							venta_precio_unitario: item3.venta_precio_unitario || 0,
							venta_costo_adicional: item3.venta_costo_adicional || 0,
							venta_precio: item3.venta_precio || 0,
							venta_porcentaje_descuento: item3.venta_porcentaje_descuento || 0,
							venta_descuento: item3.venta_descuento || 0,
							venta_tipo_descuento: item3.venta_tipo_descuento || 0,
							venta_producto_tipo_requerimiento: venta_producto_tipo_requerimiento || null,
							venta_producto_requerimiento_adicional: venta_producto_requerimiento_adicional || null,
						});
					}
				}
				
				let id_cat_tipo_orden = null
				switch (form.factura_cotizacion_nota_sencilla_interno) {
					case "pre_cotizacion":
						id_cat_tipo_orden = 1
						break;
					case "cotizacion":
						id_cat_tipo_orden = 2
						break;
					case "factura":
						id_cat_tipo_orden = 3
						break;
					case "nota_sencilla":
						id_cat_tipo_orden = 4
						break;
					case "interno":
						id_cat_tipo_orden = 5
						break;
				}
				
				let params = {
					
					id_venta: form.id_venta || null,
					
					// id_cliente: form.id_cliente || '',
					// id_usuario: form.id_usuario || '',
					
					// id_cat_venta_status_proceso: form.id_cat_venta_status_proceso || '',
					// numero_pedido: form.numero_pedido || '',
					
					id_cliente: form.id_cliente || '',
					id_cat_tipo_orden: id_cat_tipo_orden,
					
					fecha_alta: fecha_alta || '',
					fecha_entrega: fecha_entrega || '',
					porcentaje_descuento: form.descuento_general || 0,
					tipo_descuento_general: form.tipo_descuento_general || 0,
					total: form.formpagos.total_venta || 0,
					descuento: form.formpagos.descuento_venta || 0,
					
					pre_cotizacion: form.factura_cotizacion_nota_sencilla_interno === 'pre_cotizacion' ? 1 : 0,
					cotizacion: form.factura_cotizacion_nota_sencilla_interno === 'cotizacion' ? 1 : 0,
					factura: form.factura_cotizacion_nota_sencilla_interno === 'factura' ? 1 : 0,
					nota_sencilla: form.factura_cotizacion_nota_sencilla_interno === 'nota_sencilla' ? 1 : 0,
					interno: form.factura_cotizacion_nota_sencilla_interno === 'interno' ? 1 : 0,
					por_hora: form.por_hora === true ? 1 : 0,
					
					// otros: form.otros || '',
					observaciones: form.observaciones || '',
					
					cliente_deja_anexo: form.cliente_deja_anexo ? 1 : 0,
					cliente_envia_muestra_por_correo: form.cliente_envia_muestra_por_correo ? 1 : 0,
					
					// total: form.total || '',
					// anticipo: form.anticipo || '',
					
					venta_como_te_enteraste: venta_como_te_enteraste,
					venta_producto: venta_producto,
					
					insumo: form.insumo,
					
					id_cat_tipo_mensajeria: form._id_cat_tipo_mensajeria,
					otra_mensajeria: form._otra_mensajeria,
					id_cat_envio_estatus: form._id_cat_envio_estatus,
					numero_guia: form._numero_guia,
					numero_rastreo: form._numero_rastreo,
					
					no_desglosar_iva: form.no_desglosar_iva || 0,
					desglosar_iva: form.desglosar_iva || 0,
					
				};
				
				let ws = '';
				
				if (form.id_venta) {
					ws = '_Orden_De_Venta_Modificar';
				} else {
					ws = '_Orden_De_Venta_Guardar';
				}
				
				HttpRequest.post(ws, params, null, 1500).then((response) => {
					resolve(response);
				}).catch((error) => {
					reject(error);
				});
				
			} catch (error) {
				reject(error);
			}
			
		});
	},
	OrdenDeVentaPagar: (id_venta, form, productos_anticipo, tab) => {
		let ids_productos_anticipo = [];
		let ids_productos_anticipo_total = [];
		for (let i = 0; i < productos_anticipo.length; i++) {
			if (productos_anticipo[i].cubrir_anticipo_checked) {
				ids_productos_anticipo.push(productos_anticipo[i].id_venta_producto)
			}
		}
		for (let i = 0; i < productos_anticipo.length; i++) {
			if (productos_anticipo[i].cubrir_total_checked) {
				ids_productos_anticipo_total.push(productos_anticipo[i].id_venta_producto)
			}
		}
		let params = {
			id_venta: id_venta,
			id_cat_forma_pago: form.id_cat_forma_pago,
			fecha_pago: form.fecha_pago,
			pago_con: form.pago_con,
			monto: form.monto,
			cambio: form.pago_con - form.monto,
			numero_cheque: form.numero_cheque,
			nombre_cuentahabiente: form.nombre_cuentahabiente,
			id_cat_banco: form.id_cat_banco,
			
			numero_referencia: form.numero_referencia,
			folio: form.folio,
			clave_rastreo: form.clave_rastreo,
			cuenta_origen: form.cuenta_origen,
			
			ids_productos_anticipo: ids_productos_anticipo,
			ids_productos_anticipo_total: ids_productos_anticipo_total,
			
			tab: Number(tab) === 1 ? 1 : 0
		};
		
		console.log(params);
		
		return new Promise((resolve, reject) => {
			// reject({
			// 	success: false,
			// 	codigo_api: 400,
			// 	mensaje: "N/A"
			// });
			HttpRequest.post('_Ventas_Realizar_Pago', params, null, 1500).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AprobarCotizacion: (id_venta, form, tipo_vista) => {
		let fecha_entrega = DateFormat.FormatSql(form.fecha_entrega);
		let params = {
			id_venta: id_venta,
			
			cotizacion: form.cotizacion ? 1 : 0,
			factura: form.factura ? 1 : 0,
			nota_sencilla: form.nota_sencilla ? 1 : 0,
			interno: form.interno ? 1 : 0,
			
			tipo_vista_es_cotizacion: ((tipo_vista === "cotizacion") ? 'si' : 'no'),
			fecha_entrega: ((tipo_vista === "cotizacion") ? fecha_entrega : null)
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Aprobar_Cotizacion', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AsignarCliente: (id_venta, id_cliente) => {
		let params = {
			id_venta: id_venta,
			id_cliente: id_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Asignar_Cliente', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
