import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const VentasService = {
	Listar: (filtro, paginacion, tipo_vista) => {
		let fecha_alta_inicio = (filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(filtro.fecha_alta_inicio) : null;
		let fecha_alta_fin = (filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(filtro.fecha_alta_fin) : null;
		let fecha_entrega_inicio = (filtro.fecha_entrega_inicio || null) ? DateFormat.FormatSql(filtro.fecha_entrega_inicio) : null;
		let fecha_entrega_fin = (filtro.fecha_entrega_fin || null) ? DateFormat.FormatSql(filtro.fecha_entrega_fin) : null;
		
		let params = {
			filtro: {
				folio: filtro.folio || null,
				numero_cotizacion: filtro.numero_cotizacion || null,
				numero_pedido: filtro.numero_pedido || null,
				cliente: filtro.cliente || null,
				fecha_alta_inicio: fecha_alta_inicio,
				fecha_alta_fin: fecha_alta_fin,
				fecha_entrega_inicio: fecha_entrega_inicio,
				fecha_entrega_fin: fecha_entrega_fin,
				status_pago: filtro.status_pago || null,
				status_proceso: filtro.status_proceso || null,
				status_tipo_pedido: filtro.status_tipo_pedido || null,
				id_usuario_disenador: filtro.id_usuario_disenador || null,
				ordenar_fecha_alta: filtro.ordenar_fecha_alta || null,
				ordenar_fecha_entrega: filtro.ordenar_fecha_entrega || null,
				id_cat_grupo: filtro.id_cat_grupo || null,
				tipo_vista: tipo_vista,
				producto: filtro.producto || null,
				id_cat_envio_estatus: filtro.id_cat_envio_estatus || null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: (filtro, item) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			id_proyecto: 2,
			id_cat_periodicidad: filtro.id_cat_periodicidad || null,
			timbrar: filtro.timbrar || null,
			data_timbrar: item || null,
			serie: filtro.serie || null,
			folio: filtro.folio || null,
			meses: filtro.meses || null,
			mes: filtro.mes || null,
			anio: filtro.anio || null,
			fecha: filtro.fecha || null,
			id_empresa_sucursal_serie: filtro.id_empresa_sucursal_serie || null,
			enviar_a: filtro.enviar_a || null,
			enviar_cc: filtro.enviar_cc || null
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Timbrado_General', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarArchivo: (form) => {
		let params = {
			id_venta: form.id_venta,
			archivos: form.archivos,
			xmls: form.jsonXML,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Agregar_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	VentasDatosDisenador: (id_venta, id_venta_producto) => {
		let params = {
			id_venta: id_venta,
			id_venta_producto: id_venta_producto || null,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Datos_Disenador', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	VentasAsignarDisenador: (id_venta, id_venta_producto, venta_producto_disenador_asignado) => {
		let ids_venta_producto_disenador_asignado = [];
		
		for (let i = 0; i < venta_producto_disenador_asignado.length; i++) {
			ids_venta_producto_disenador_asignado.push(venta_producto_disenador_asignado[i].id_usuario);
		}
		
		let params = {
			id_venta: id_venta,
			id_venta_producto: id_venta_producto,
			ids_venta_producto_disenador_asignado: ids_venta_producto_disenador_asignado,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Asignar_Disenador', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	VentasEviarProduccion: (id_venta, id_venta_producto) => {
		let params = {
			id_venta: id_venta,
			id_venta_producto: id_venta_producto,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Eviar_Produccion', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	VentasCancelarOrden: (item) => {
		let params = {
			id_venta: item.id_venta
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Cancelar_Orden', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	VentasActivarOrden: (item) => {
		let params = {
			id_venta: item.id_venta
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Activar_Orden', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	VentasProductoEntregarAlmacen: (id_venta, producto_entrega_almacen) => {
		let temp = [];
		for (let i = 0; i < producto_entrega_almacen.length; i++) {
			temp.push({
				id_venta_producto: producto_entrega_almacen[i].id_venta_producto,
				cantidad: producto_entrega_almacen[i].cantidad
			});
		}
		let params = {
			id_venta: id_venta,
			producto_entrega_almacen: temp,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Producto_Entrega_Almacen', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	VentasProductoEntregarCliente: (id_venta, producto_entrega_cliente) => {
		let temp = [];
		for (let i = 0; i < producto_entrega_cliente.length; i++) {
			temp.push({
				id_venta_producto: producto_entrega_cliente[i].id_venta_producto,
				cantidad: producto_entrega_cliente[i].cantidad
			});
		}
		let params = {
			id_venta: id_venta,
			producto_entrega_cliente: temp,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Producto_Entrega_Cliente', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	VentaGuardarHorasTrabajadas: (id_venta, id_venta_producto, horas) => {
		let params = {
			id_venta: id_venta,
			id_venta_producto: id_venta_producto,
			horas: horas,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Guardar_Horas_Trabajadas', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ActivarDesactivarFactura: (data) => {
		let params = {
			id_venta: data.id_venta,
			factura: data.factura
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Desactivar_Factura', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ActivarDesactivarNotaSencilla: (data) => {
		let params = {
			id_venta: data.id_venta,
			nota_sencilla: data.nota_sencilla
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Desactivar_Nota_Sencilla', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ActivarDesactivarInterno: (data) => {
		let params = {
			id_venta: data.id_venta,
			interno: data.interno
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Desactivar_Interno', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SolicitarAprobarEdicion: (data) => {
		let params = {
			id_venta: data.id_venta,
			edicion: data.edicion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Solicitar_Aprobar_Edicion', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Timbrado_CFDI: (data) => {
		let params = {
			id_venta: data.id_venta,
			id_cat_metodo_pago: data.id_cat_metodo_pago,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Timbrado_CFDI', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Timbrado_CFDI_Pago: (data) => {
		let params = {
			id_venta: data.id_venta,
			id_venta_pago: data.id_venta_pago,
			folio: data.folio,
			serie: data.serie,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Timbrado_CFDI_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ModificarPago: (data) => {
		let params = {
			id_venta: data.id_venta,
			id_venta_pago: data.id_venta_pago,
			fecha_pago: data.fecha_pago,
			monto: data.monto,
			id_cat_forma_pago: data.id_cat_forma_pago,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Modificar_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarInsumo: (data) => {
		let params = {
			id_compra_producto: data.id_compra_producto || '',
			id_cat_unidad_medida: data.id_cat_unidad_medida || '',
			cantidad: data.cantidad || '',
			ordenes_venta: data.ordenes_venta || []
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Ventas_Agregar_Insumo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
