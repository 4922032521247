import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import moment from "moment";
import {KeyboardDatePicker} from '@material-ui/pickers';
import {VentasService} from "../../../../services/VentasService/VentasService";


class ModalFormaPagoEdit extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
			id_cat_forma_pago: '',
			fecha_pago: null,
			monto: '',
			id_venta: '',
			id_venta_pago: '',
			
			lista_cat_forma_pago: []
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		let hc = new Promise((resolve, reject) => {
			FieldsJs.HandleChange(e, variable, campo, date, input, (r) => {
				this.setState({
					[r.name]: r.value,
				}, resolve(true));
			});
		});
		
		hc.then(r => {
			this.props.selectEnvio(this.state);
		}).catch(e => {
			console.log(e);
		});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const {item} = this.props;
		console.log(item);
		
		this.listFormaPago().then((response) => {
			this.setState({
				id_cat_forma_pago: item.id_cat_forma_pago || ''
			});
		});
		
		this.setState({
			modal: {
				open: true
			},
			id_venta: item.id_venta,
			id_venta_pago: item.id_venta_pago,
			monto: item.monto || '',
			fecha_pago: item.fecha_alta ? moment(new Date(item.fecha_alta)) : ''
		});
	};
	
	listFormaPago = () => {
		return new Promise((resolve, reject) => {
		
		CatService.ListFormaPago().then((response) => {
			this.setState({
				lista_cat_forma_pago: response.data
			});
			resolve(response);
		}).catch((error) => {
			this.setState({
				lista_cat_forma_pago: []
			});
		});
		});
	};
	
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		console.log(this.state);
		VentasService.ModificarPago(this.state).then((response) => {
			this.closeModal();
			this.props.closeModalPagos();
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		let {item} = this.props;
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogTitle>
						Editar Forma de Pago
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} className={'cotizador_fondo'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_forma_pago"
									label="Forma de Pago"
									value={this.state.id_cat_forma_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_forma_pago.map(option => (
										<option key={option.id_cat_forma_pago}
										        value={option.id_cat_forma_pago}>
											{option.forma_pago}
										</option>
									))}
								</TextField>
							</Grid>
							
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									margin="dense"
									fullWidth
									clearable
									required
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha Pago"
									value={this.state.fecha_pago}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_pago');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<TextField
									type={'text'}
									fullWidth
									variant={"filled"}
									margin={"none"}
									onChange={this.handleChange}
									name="monto"
									value={this.state.monto}
									label="Monto"
									onKeyPress={EnteroSolo}
								/>
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.save()} color="primary" style={{backgroundColor: "#3f50b6", color: 'white'}}>
									Actualizar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalFormaPagoEdit.propTypes = {
	componente: PropTypes.element.isRequired,
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	closeModalPagos: PropTypes.func.isRequired,
	
};

export default ModalFormaPagoEdit;
