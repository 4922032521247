import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {DateRangeOutlined, SearchOutlined, PersonOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";

import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import moment from "moment";

class FiltrosBalances extends Component {
	state = {};
	
	constructor() {
		super();
		this.state = {
			numero_compra: '',
			id_cat_tipo_gasto_compra: '',
			tipo_gasto_compra: '',
			fecha_inicio: new Date(moment().startOf('month')),
			fecha_fin: new Date(moment()),
			
			lista_cat_tipo_gasto_compra: [],
		};
		
		this.ListaTipoGastoCompra();
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.id_cat_tipo_gasto_compra = this.state.id_cat_tipo_gasto_compra || undefined;
		/*for (let i = 0; i < this.state.lista_cat_tipo_gasto_compra.length; i++) {
			if (Number(this.state.lista_cat_tipo_gasto_compra[i].id_cat_tipo_gasto_compra) === Number(this.state.id_cat_tipo_gasto_compra)) {
				filtro.tipo_gasto_compra = this.state.lista_cat_tipo_gasto_compra[i].tipo_gasto_compra.toUpperCase();
			}
		}*/
		filtro.fecha_inicio = this.state.fecha_inicio && this.state.fecha_fin ? this.state.fecha_inicio : undefined;
		filtro.fecha_fin = this.state.fecha_inicio && this.state.fecha_fin ? this.state.fecha_fin : undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	ListaTipoGastoCompra = () => {
		CatService.ListTipoGastoCompra().then(value => {
			this.setState({
				lista_cat_tipo_gasto_compra: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_tipo_gasto_compra: []
			});
		});
	};
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={6} sm={6} md={3} lg={3} xl={3} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha de alta"
									value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha de alta">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_inicio}
							endDate={this.state.fecha_fin}
							onChange={(range) => {
								this.setState({
									fecha_inicio: range.startDate,
									fecha_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_tipo_gasto_compra"
									label="Tipo gasto"
									value={this.state.id_cat_tipo_gasto_compra}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_tipo_gasto_compra.map(option => (
										<option key={option.id_cat_tipo_gasto_compra}
										        value={option.id_cat_tipo_gasto_compra}>
											{option.tipo_gasto_compra}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					
					
					<Grid item xs={6} sm={6} md={3} lg={2} xl={2} align={'left'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
					
				</Grid>
			</Fragment>
		);
	}
}

FiltrosBalances.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default FiltrosBalances;
