import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {CloudUpload, EditOutlined, ListAlt} from '@material-ui/icons';
import {FieldsJs} from "../../../../settings/General/General";
import ModalCotizador from "./ModalCotizador";
import ModalFichaTecnica from "./ModalFichaTecnica";
import ModalNube from "./ModalNube";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-mark'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'left'} className={'min-w-150-px padding-20-L padding-20-R'}>ID</TableCell>
							<TableCell component="th" align={'left'} className={'min-w-150-px padding-20-L padding-20-R'}>Producto</TableCell>
							<TableCell component="th" align={'left'} className={'min-w-400-px padding-20-L padding-20-R'}>Descripción</TableCell>
							<TableCell component="th" align={'left'}>Costo Unitario</TableCell>
							<TableCell component="th" align={'left'}>Precio Individual</TableCell>
							{/*<TableCell component="th" align={'left'}>Tipo Costo</TableCell>*/}
							<TableCell component="th" align={'left'}>Costo Mayoreo</TableCell>
							{/*<TableCell component="th" align={'left'}>Precio Sugerido Individual</TableCell>*/}
							{/*<TableCell component="th" align={'left'}>Precio Sugerido Mayoreo</TableCell>*/}
							<TableCell component="th" align={'left'}>Precio Mayoreo</TableCell>
							{/*<TableCell component="th" align={'left'}>Total</TableCell>*/}
							{/*<TableCell component="th" align={'center'}>Subir comprobante</TableCell>*/}
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
							return (
								<TableRow key={key}>
									<TableCell align={'left'} className={'padding-20-L padding-20-R h-35-px'}>{item.id_cotizador}</TableCell>
									<TableCell align={'left'} className={'padding-20-L padding-20-R h-35-px'}>{item.cotizador_producto}</TableCell>
									<TableCell align={'left'}>{item.descripcion}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.costo_unitario)}</TableCell>
									<TableCell align={'left'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.precio_individual)}</TableCell>
									{/*<TableCell align={'left'}>{item.tipo_costo} | {item.costo}</TableCell>*/}
									<TableCell align={'left'}>${FieldsJs.Currency(item.costo_mayoreo)}</TableCell>
									{/*<TableCell align={'left'}>{item.precio_sugerido_individual }</TableCell>*/}
									{/*<TableCell align={'left'}>{item.precio_sugerido_mayoreo }</TableCell>*/}
									<TableCell align={'left'} style={{fontWeight: 'bold'}}>${FieldsJs.Currency(item.precio_mayoreo)}</TableCell>
									
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										
										<div className={'w-auto vertical-inline'}>
											<div className={'v-center'}>
												<ModalFichaTecnica
													item={item}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ficha Técnica">
															<IconButton aria-label="Ficha Técnica" onClick={() => {
															}}>
																<ListAlt/>
															</IconButton>
														</Tooltip>
													}
												/>
											</div>
											
											<div className={'v-center'}>
												<ModalCotizador
													tipo={'edit'}
													item={item}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar Ficha Técnica">
															<IconButton aria-label="Editar Ficha Técnica" onClick={() => {
															}}>
																<EditOutlined/>
															</IconButton>
														</Tooltip>
													}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
											<div className={'v-center'}>
												<ModalNube
													tipo={'edit'}
													item={item}
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Guardar Archivos">
															<IconButton aria-label="Guardar Archivos" onClick={() => {
															}}>
																<CloudUpload/>
															</IconButton>
														</Tooltip>
													}
													RefreshList={this.props.RefreshList}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
										</div>
									
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	delete: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
