import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";

import {FieldsJs} from '../../../../settings/General/General';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {
	AccountBalanceOutlined,
	AttachMoneyOutlined,
	CallMadeOutlined,
	CancelOutlined,
	CheckCircleOutlineOutlined,
	CheckOutlined,
	EditOutlined,
	ForwardOutlined,
	HowToRegOutlined,
	ImageAspectRatioOutlined,
	LocalShipping,
	MailOutline,
	PictureAsPdfOutlined,
	PostAdd,
	SearchOutlined,
	WatchLaterOutlined,
	Web,
	PlayForWork,
	AttachMoney,
	MonetizationOn,
	MoreVert
} from "@material-ui/icons";

import $State from "../../../../settings/$State/$State";
import {withRouter} from "react-router-dom";
import Fab from "@material-ui/core/Fab";

import icon_mas from '../../../../assets/img/icons/expandir-mas.svg';
import icon_menos from '../../../../assets/img/icons/expandir-menos.svg';
import icon_p from '../../../../assets/img/icons/icon-pre-cotizacion.svg';
import icon_i from '../../../../assets/img/icons/icon-interno.svg';
import icon_f from '../../../../assets/img/icons/icon-factura.svg';
import icon_c from '../../../../assets/img/icons/icon-cotizacion.svg';
import icon_n from '../../../../assets/img/icons/icon-nota-sencilla.svg';
import circulo_a from '../../../../assets/img/icons/circulo-aprobado.svg';
import circulo_n from '../../../../assets/img/icons/circulo-negado.svg';
import Typography from "@material-ui/core/Typography";
import icon_ticket from "../../../../assets/img/icons/icon-ticket.svg";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import Grid from "@material-ui/core/Grid";
import OnContextMenu from "../../../Include/MiniComponents/OnContextMenu";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
		}
	}
	
	openModal = () => {
		this.setState({open: true});
	}
	/*openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	*/
	closeModal = () => {
		this.setState({
			open: false
		});
	};
	
	detalles = (item) => {
		$State.go(this.props, 'ordendeventa', {id_venta: item.id_venta})
	};
	
	editar = (item) => {
		$State.go(this.props, 'ordendeventa', {editar: 'editar', id_venta: item.id_venta})
	};
	
	cancelar = (item) => {
		this.props.cancelar(item);
	};
	
	activar = (item) => {
		this.props.activar(item);
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	ticket_de_entrega = (value) => {
		HttpRequest.export('texid', ['ticket'], {
			id_venta_producto: value.id_venta_producto
		}, true, 450, 680)
	};
	
	is_cotizacion = (item) => {
		return item.cotizacion === 1 && item.factura === 0 && item.nota_sencilla === 0 && item.interno === 0;
	};
	
	is_cancel = (item) => {
		return item.activo === 0;
	};
	
	is_edited = (item) => {
		return FieldsJs.inArray([1, 2], Number(item.id_cat_venta_status_proceso)) || (item.edicion === "Aprobado" || item.edicion === "Forzar edicion");
	};
	
	crear_array_list = (item) => {
		
		let item_list = [];
		
		if (item.factura === 1) {
			item_list.push({
				id: 1,
				name: 'desactivar_factura',
				label: 'Desactivar el cliente requiere una factura'
			});
		}
		
		if (item.nota_sencilla === 1) {
			item_list.push({
				id: 2,
				name: 'desactivar_nota_sencilla',
				label: 'Desactivar nota sencilla'
			});
		}
		
		if (item.interno === 1) {
			item_list.push({
				id: 3,
				name: 'desactivar_interno',
				label: 'Desactivar orden de tipo interno'
			});
		}
		
		if (FieldsJs.inArray([3, 4], Number(item.id_cat_venta_status_proceso)) && item.edicion === "Sin accion") {
			item_list.push({
				id: 4,
				name: 'solicitar_edicion',
				label: 'Solicitar edición'
			});
		}
		
		if (item.edicion === "Solicitar") {
			item_list.push({
				id: 6,
				name: 'cancelar_solicitud_de_edicion',
				label: 'Cancelar solicitud de edición'
			});
		}
		
		if (FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario)) {
			
			if (FieldsJs.inArray([3, 4], Number(item.id_cat_venta_status_proceso)) && item.edicion === "Solicitar") {
				item_list.push({
					id: 5,
					name: 'aprobar_edicion',
					label: 'Aprobar edición'
				});
			}
			
			if ((item.edicion === "Aprobado" || item.edicion === "Forzar edicion")) {
				item_list.push({
					id: 7,
					name: 'cancelar_edicion',
					label: 'Cancelar edición'
				});
			}
			
		}
		
		if ((item.edicion === "Aprobado" || item.edicion === "Forzar edicion")) {
			item_list.push({
				id: 7,
				name: 'finalizar_edicion',
				label: 'Finalizar edición'
			});
		}
		
		return item_list;
	};
	
	status_lista_click_derecho = (item) => {
		let status = [];
		if (item.factura === 1) {
			status.push(1)
		}
		if (item.nota_sencilla === 1) {
			status.push(2)
		}
		if (item.interno === 1) {
			status.push(3)
		}
		return status.length > 1;
	};
	
	render() {
		
		const textoClaseIcono = "bg-img-contain-x-center-y-center h-15-px w-15-px cursor-pointer margin-2-R margin-2-L";
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		const bg_p = {
			backgroundImage: `url(${icon_p})`
		};
		
		const bg_i = {
			backgroundImage: `url(${icon_i})`
		};
		
		const bg_f = {
			backgroundImage: `url(${icon_f})`
		};
		
		const bg_c = {
			backgroundImage: `url(${icon_c})`
		};
		
		const bg_n = {
			backgroundImage: `url(${icon_n})`
		};
		
		const bg_circulo_a = {
			backgroundImage: `url(${circulo_a})`
		};
		
		const bg_circulo_n = {
			backgroundImage: `url(${circulo_n})`
		};
		
		const bg_icon_ticket = {
			backgroundImage: `url(${icon_ticket})`
		};
		
		return (<Paper className={classes.root}>
			<Table className={classes.table + ' desing-acresco'}>
				<TableHead>
					<TableRow>
						<TableCell component="th" align={'center'} className={'w-20-px padding-20-L padding-20-R'}/>
						<TableCell component="th" align={'left'} className={'padding-10-L padding-10-R'}>
							Folio
						</TableCell>
						<TableCell component="th" align={'left'} className={'w-30-px padding-10-L padding-10-R'}>
							{this.props.tipo_vista === 'cotizacion' ? "No. cotización" : "No. Pedido"}
						</TableCell>
						{this.ver_root() ? (
							<TableCell component="th" align={'left'}>Usuario</TableCell>
						) : null}
						<TableCell component="th" align={'left'}>Cliente</TableCell>
						<TableCell component="th" align={'center'}>Fecha alta</TableCell>
						<TableCell component="th" align={'center'}>Fecha entrega</TableCell>
						<TableCell component="th" align={'center'}>Progreso</TableCell>
						<TableCell component="th" align={'right'}>Pagado</TableCell>
						<TableCell component="th" align={'right'}>Por pagar</TableCell>
						<TableCell component="th" align={'right'}>Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{lista.map((item, key) => (
						<Fragment key={key}>
							<TableRow key={key}>
								
								<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
									<div style={!!item.expandir ? bg_menos : bg_mas}
									     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
									     onClick={() => this.props.SubTabla(item, key)}
									/>
								</TableCell>
								
								<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
									<Typography style={{fontWeight: 'bold', textDecoration: 'underline wavy red'}}>
										{item.id_venta}
									</Typography>
								</TableCell>
								
								<TableCell align={'left'} className={'padding-10-L padding-10-R'}>
									
									<div className={'vertical-inline v-center'}>
										
										<OnContextMenu
											id={"factura_" + key}
											componente={
												<b className={'vertical-inline v-center cursor-pointer ff-arial ' + (this.is_cancel(item) ? 'text-tachado' : '')}
												   style={{
													   background: this.status_lista_click_derecho(item) ? "#da7300" : "#e4e4e4",
													   padding: '1px 5px',
													   borderRadius: '4px',
													   color: this.status_lista_click_derecho(item) ? "white" : "black"
												   }}>
													{this.props.tipo_vista === 'cotizacion' ? item.numero_cotizacion : item.numero_pedido}
												</b>
											}
											itemsPopover={this.crear_array_list(item)}
											onClick={(option) => {
												this.props.DesactivarFacturaNotaSencillaInterno(option, item);
											}}
										/>
										
										<div className={'w-20-px h-15-px text-center'}>
											{item.venta_envio && Number(item.venta_envio.id_cat_envio_estatus) > 0 ? (
												<Tooltip TransitionComponent={Zoom} placement={"left"} title={item.venta_envio.envio_estatus}>
													<LocalShipping style={{
														borderRadius: 10,
														padding: 2,
														fontSize: 12,
														color: 'white',
														backgroundColor: Number(item.venta_envio.id_cat_envio_estatus) === 1 ? '#b9996f' : Number(item.venta_envio.id_cat_envio_estatus) === 2 ? '#2884a5' : '#3fb459'
													}}/>
												</Tooltip>
											) : ''}
										</div>
										
										{/*{item.pre_cotizacion === 1 ? (
											<div style={bg_p} className={textoClaseIcono}/>
										) : ''}*/}
										{item.pre_cotizacion === 1 ? (
											<Tooltip TransitionComponent={Zoom} placement={"left"}
											         title={'Pre-Cotización: ' + item.numero_pre_cotizacion}>
												<div style={bg_p} className={textoClaseIcono}/>
											</Tooltip>
										) : ''}
										{item.cotizacion === 1 ? (
											<Tooltip TransitionComponent={Zoom} placement={"left"}
											         title={'Cotización: ' + item.numero_cotizacion}>
												<div style={bg_c} className={textoClaseIcono}/>
											</Tooltip>
										) : ''}
										{item.interno === 1 ? (
											<div style={bg_i} className={textoClaseIcono}/>
										) : ''}
										{item.nota_sencilla === 1 ? (
											<div style={bg_n} className={textoClaseIcono}/>
										) : ''}
										{item.factura === 1 ? (
											<div style={bg_f} className={textoClaseIcono}/>
										) : ''}
									
									</div>
									
									<div className={'vertical-inline v-center'}>
										{item.edicion === "Solicitar" ? (
											<div style={{
												paddingLeft: "5px",
												paddingRight: "5px",
												fontSize: "9px",
												borderRadius: "4px",
											}} className={"v-center bg-warning"}>
												Edición solicitado
											</div>
										) : (item.edicion === "Aprobado" ? (
											<div style={{
												paddingLeft: "5px",
												paddingRight: "5px",
												fontSize: "9px",
												borderRadius: "4px",
											}} className={"v-center bg-success"}>
												Edición aprobado
											</div>
										) : (item.edicion === "Forzar edicion" ? (
											<div style={{
												paddingLeft: "5px",
												paddingRight: "5px",
												fontSize: "9px",
												borderRadius: "4px",
											}} className={"v-center bg-info"}>
												Edición forzada aprobado
											</div>
										) : ""))}
									</div>
								
								</TableCell>
								
								{this.ver_root() ? (
									<TableCell align={'left'}
									           className={this.is_cancel(item) ? 'text-tachado' : ''}>
										{item.usuario_nombre_completo}
									</TableCell>
								) : null}
								
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									{item.cliente_nombre_completo}
								</TableCell>
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									{DateFormat.FormatText(item.fecha_alta)}
								</TableCell>
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									{DateFormat.FormatText(item.fecha_entrega)}
								</TableCell>
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<div className={'w-100 h-20-px bg-lightgray b-r-3'}>
										<div className={'h-20-px bg-green b-r-3'}
										     style={{width: (item.progreso || 0) + "%"}}/>
									</div>
									{item.progreso || 0}%
								</TableCell>
								
								<TableCell align={'right'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										${FieldsJs.Currency(item.pagado)}
									</p>
								</TableCell>
								
								<TableCell align={'right'}
								           className={(item.porpagar > 0 ? 'px-20 text-danger' : '') + ' ' + (this.is_cancel(item) ? 'text-tachado' : '')}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										${FieldsJs.Currency(item.porpagar)}
									</p>
								</TableCell>
								
								<TableCell align={'right'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<Fragment>
										<p className={'w-100 padding-0 margin-0 ff-arial'}>
											<b>${FieldsJs.Currency((item.total))}</b>
										</p>
									</Fragment>
									{/*{item.descuento > 0 ? (
										<Fragment>
											<p className={'w-100 padding-0 margin-0 px-10 ff-arial'}>
												${FieldsJs.Currency((item.total))}
											</p>
											<p className={'w-100 padding-0 margin-0 px-10 ff-arial'}>
												Des. - ${FieldsJs.Currency((item.descuento))}
											</p>
											<hr className={'w-100 padding-0 margin-0'}/>
											<p className={'w-100 padding-0 margin-0 ff-arial'}>
												<b>${FieldsJs.Currency((item.total - item.descuento))}</b>
											</p>
										</Fragment>
									) : (
										<Fragment>
											<p className={'w-100 padding-0 margin-0 ff-arial'}>
												<b>${FieldsJs.Currency((item.total))}</b>
											</p>
										</Fragment>
									)}*/}
								
								</TableCell>
							</TableRow>
							{!!item.expandir ? (
								<TableRow>
									<TableCell
										align={'center'}
										className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
										colSpan={this.ver_root() ? 12 : 11}
									>
										
										<div className={'bg-white card-2'}>
											
											<Grid container spacing={1}>
												
												<Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
												
												</Grid>
												<Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
													<Typography className={'px-14 text-center bg-white padding-10'}>
														LINEAS DE PRODUCCIÓN
													</Typography>
												</Grid>
											
											</Grid>
											
											<table width={'100%'} className={'bg-white'} style={{
												borderSpacing: "0px"
											}}>
												<thead>
												<tr>
													<th className={'text-left'}>Grupo</th>
													<th className={'text-left'}>Producto</th>
													<th className={'text-left'}>Descripción</th>
													<th>Anticipo</th>
													<th>Entregado a almacén</th>
													<th>Entregado al cliente</th>
													<th>Progreso</th>
												</tr>
												</thead>
												<tbody>
												{item.venta_producto.map((value, index) => (
													<tr key={index}>
														<td className={'text-left'}>{value.grupo || "-"}</td>
														<td className={'text-left'}>
															{(this.props.tipo_vista === 'cotizacion' ? item.numero_cotizacion : item.numero_pedido)}-{value.id_venta_producto} {value.venta_producto}
														</td>
														<td className={'text-left'}>{value.venta_descripcion}</td>
														<td align={'center'}>
															{(value.cubrir_anticipo === 1 || (item.interno === 1 || item.por_hora === 1)) ? (
																<div style={bg_circulo_a}
																     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}/>
															) : (
																<div style={bg_circulo_n}
																     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}/>
															)}
														</td>
														<td>{(value.venta_entregado_almacen || 0)}/{value.venta_cantidad}</td>
														<td>{(value.venta_entregado_cliente || 0)}/{value.venta_cantidad}</td>
														<td>
															<div className={'w-100 h-20-px bg-lightgray b-r-3'}>
																<div className={'h-20-px bg-green b-r-3'}
																     style={{width: (value.progreso || 0) + "%"}}/>
															</div>
															{value.progreso || 0}%
														</td>
													</tr>
												))}
												</tbody>
											</table>
										
										</div>
									
									</TableCell>
								</TableRow>
							) : null}
						</Fragment>
					))}
				</TableBody>
			</Table>
		</Paper>)
	}
}

ListaTabla.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	exportar: PropTypes.func.isRequired,
	exportarTicket: PropTypes.func.isRequired,
	modalDisenador: PropTypes.func.isRequired,
	// almacen: PropTypes.func.isRequired,
	// entregar: PropTypes.func.isRequired,
	cancelar: PropTypes.func.isRequired,
	activar: PropTypes.func.isRequired,
	produccion: PropTypes.func.isRequired,
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	
	watchHorasTrabajadas: PropTypes.func.isRequired,
	watchAsignarCliente: PropTypes.func.isRequired,
	watchEnviarTodoAlmacen: PropTypes.func.isRequired,
	watchEnviarTodoCliente: PropTypes.func.isRequired,
	watchEnviarAlmacen: PropTypes.func.isRequired,
	watchEnviarCliente: PropTypes.func.isRequired,
	watchAprobarCotizacion: PropTypes.func.isRequired,
	
	handleChange: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	DesactivarFacturaNotaSencillaInterno: PropTypes.func.isRequired,
	tipo_vista: PropTypes.string.isRequired,
	
	listDropbox: PropTypes.array
};

export default withStyles(styles)(withRouter(ListaTabla));
