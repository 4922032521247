import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {AcUnit, Favorite, PersonPin, Phone, PlaylistAddOutlined} from '@material-ui/icons';
import carrito_de_compra_1 from "../../../../assets/img/icons/carro-de-la-compra-1.svg";
import {withStyles} from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import Typography from "@material-ui/core/Typography";
import TabContext from "@material-ui/lab/TabContext";
import TextField from "@material-ui/core/TextField";
import AutoCompleteCliente from "../../OrdenDeVenta/Includes/AutoCompleteCliente";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createFilterOptions} from "@material-ui/lab";
import ModalProveedor from "../../Proveedor/Includes/ModalProveedor";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Cotizador from "../Cotizador";
import {CotizadorService} from "../../../../services/CotizadorService/CotizadorService";
import {CotizadorProductoService} from "../../../../services/CotizadorProductoService/CotizadorProductoService";


class ModalCotizadorProducto extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
			id_cotizador_producto: props.id_cotizador_producto,
			cotizador_producto: '',
			descripcion : '',
			activo: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		
		return new Promise((resolve, reject) => {
			if (this.props.id_cotizador_producto > 0) {
				CotizadorService.Detalles(this.props.id_cotizador_producto).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_compra = item.fecha_compra ? moment(item.fecha_compra) : null;
					this.setState({
						id_cotizador_producto: item.id_cotizador_producto,
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					
					id_cotizador_producto: '',
					
				});
				resolve(true);
			}
		});
	};
	
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cotizador_producto: '',
			cotizador_producto: '',
			descripcion : '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cotizador_producto: item.id_cotizador_producto || '',
			cotizador_producto: item.producto_cotizador || '',
			descripcion : item.descripcion || '',
			activo: (Number(item.activo) === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cotizador_producto: item.id_cotizador_producto || '',
			cotizador_producto: item.producto_cotizador || '',
			descripcion : item.descripcion || '',
			activo: (Number(item.activo) === 1),
		});
		this.open();
	};
	
	
	save = () => {
		try {
			/*if (!this.state.fecha_compra) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha de compra.'
				})
			}*/
			
			CotizadorProductoService.Agregar(this.state).then((response) => {
				this.closeModal();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	
	render() {
		const is_root = this.is_root();
		
		let func;
		
		switch (this.state.tipo) {
			case 'edit':
				func = this.edit;
				break;
			case 'add':
				func = this.add;
				break;
			case 'view':
				func = this.view;
				break;
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => func()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Agregar Producto
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									autoFocus
									margin="dense"
									name="cotizador_producto"
									label="Producto"
									type="text"
									fullWidth
									defaultValue={this.state.cotizador_producto}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="dense"
									name="descripcion"
									label="Descripción"
									type="text"
									fullWidth
									defaultValue={this.state.descripcion}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCotizadorProducto.propTypes = {
	id_cotizador_producto: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCotizadorProducto;
