import React, {Component, Fragment} from 'react';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {EstadoCuentasService} from "../../../../services/EstadoCuentasService/EstadoCuentasService";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import carrito_de_compra_1 from "../../../../assets/img/icons/carro-de-la-compra-1.svg";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import DialogActions from "@material-ui/core/DialogActions";
import * as PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import ModalUsuarios from "../../Usuarios/Includes/ModalUsuarios";

class ModalFondeoCuenta extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_fondeo_cuenta: (props.item || {}).id_fondeo_cuenta,
			importe: '',
			origen: '',
			observacion: '',
			activo: true,
			id_cat_tipo_ingreso_fondeo: '',
			id_cat_forma_pago_fondeo: '',
			id_cat_destino_fondeo: '',
			
			fecha_operacion: null,
			
			lista_cat_tipo_ingreso_fondeo: [],
			lista_cat_forma_pago_fondeo: [],
			lista_fondeos: []
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	ListaTipoIngresoFondeo = () => {
		CatService.ListTipoIngresoFondeo().then((response) => {
			this.setState({
				lista_cat_tipo_ingreso_fondeo: response.data
			});
		}).catch((error) => {
			this.setState({
				lista_cat_tipo_ingreso_fondeo: []
			});
			this.showSnackBars('error', error.mensaje);
		})
	};
	
	ListaFormaPagoFondeo = () => {
		CatService.ListFormaPagoFondeo().then((response) => {
			this.setState({
				lista_cat_forma_pago_fondeo: response.data
			});
		}).catch((error) => {
			this.setState({
				lista_cat_forma_pago_fondeo: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaEstadoCuentaFondeo = () => {
		EstadoCuentasService.ListarFondeo().then((response) => {
			this.setState({
				lista_fondeos: response.data
			});
		}).catch((error) => {
			this.setState({
				lista_fondeos: []
			});
			this.showSnackBars('error', error.mensaje);
		})
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			
			this.ListaEstadoCuentaFondeo();
			this.ListaTipoIngresoFondeo();
			this.ListaFormaPagoFondeo();
			
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_fondeo_cuenta > 0) {
			EstadoCuentasService.ModificarFondeo(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			EstadoCuentasService.AgregarFondeo(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_fondeo_cuenta: '',
			importe: '',
			origen: '',
			observacion: '',
			id_cat_tipo_ingreso_fondeo: '',
			id_cat_forma_pago_fondeo: '',
			id_cat_destino_fondeo: '',
			fecha_operacion: null,
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_fondeo_cuenta: item.id_fondeo_cuenta || '',
			importe: item.importe || '',
			origen: item.origen || '',
			observacion: item.observacion || '',
			id_cat_tipo_ingreso_fondeo: item.id_cat_tipo_ingreso_fondeo || '',
			id_cat_forma_pago_fondeo: item.id_cat_forma_pago_fondeo || '',
			id_cat_destino_fondeo: item.id_cat_destino_fondeo || '',
			fecha_operacion: item.fecha_operacion || null,
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_fondeo_cuenta: item.id_fondeo_cuenta || '',
			importe: item.importe || '',
			origen: item.origen || '',
			observacion: item.observacion || '',
			id_cat_tipo_ingreso_fondeo: item.id_cat_tipo_ingreso_fondeo || '',
			id_cat_forma_pago_fondeo: item.id_cat_forma_pago_fondeo || '',
			id_cat_destino_fondeo: item.id_cat_destino_fondeo || '',
			fecha_operacion: item.fecha_operacion || null,
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_fondeo_cuenta}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Fondeo Cuenta
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
								
								<KeyboardDatePicker
									format={'dd/MM/yyyy'}
									margin="dense"
									fullWidth
									clearable
									required
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha de la operación"
									value={this.state.fecha_operacion}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_operacion');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
								/>
							
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									required
									margin="dense"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_tipo_ingreso_fondeo"
									label="Tipo de ingreso"
									value={this.state.id_cat_tipo_ingreso_fondeo}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_tipo_ingreso_fondeo.map(option => (
										<option key={option.id_cat_tipo_ingreso_fondeo}
										        value={option.id_cat_tipo_ingreso_fondeo}>
											{option.tipo_ingreso_fondeo}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									required
									margin="dense"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_forma_pago_fondeo"
									label="Forma de pago"
									value={this.state.id_cat_forma_pago_fondeo}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_forma_pago_fondeo.map(option => (
										<option key={option.id_cat_forma_pago_fondeo}
										        value={option.id_cat_forma_pago_fondeo}>
											{option.forma_pago_fondeo}
										</option>
									))}
								</TextField>
							</Grid>
							
							{Number(this.state.id_cat_forma_pago_fondeo) === 1 ? (
								<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
									<TextField
										select
										fullWidth
										required
										margin="dense"
										onChange={this.handleChange}
										SelectProps={{
											native: true,
											MenuProps: {
												className: '',
											},
										}}
										name="id_cat_destino_fondeo"
										label="Destino"
										value={this.state.id_cat_destino_fondeo}
									>
										<option key={0} value={''}>&nbsp;</option>
										<option key={1} value={'1'}>Mostrador</option>
										<option key={2} value={'2'}>Bancos</option>
									</TextField>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								
								<TextField
									margin="dense"
									name="importe"
									label="Importe"
									type="text"
									fullWidth
									required
									onKeyPress={EnteroSolo}
									defaultValue={this.state.importe}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									margin="dense"
									name="origen"
									label="Origen del ingreso"
									type="text"
									fullWidth
									required
									defaultValue={this.state.origen}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									margin="dense"
									name="observacion"
									label="Observaciones"
									type="text"
									multiline
									rows={3}
									fullWidth
									defaultValue={this.state.observacion}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
						
						
						</Grid>
					
					</DialogContent>
					
					<br/>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={() => this.close()} color="primary">
								{'Cerrar'}
							</Button>
							<Button onClick={() => this.save()} color="primary">
								{'Guardar'}
							</Button>
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalFondeoCuenta.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalFondeoCuenta;
