import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';

import IconButton from "@material-ui/core/IconButton/index";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import {CloudUploadOutlined, DeleteOutlined, EditOutlined, MoneyOff, SearchOutlined} from '@material-ui/icons';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../../../settings/General/General";
import ModalUtilidad from "./ModalUtilidad";
import ModalComprobantes from "./ModalComprobantes";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTabla extends Component {
	
	
	render() {
		
		const {classes, lista} = this.props;
		
		return (
			<Paper className={classes.root}>
				<Table className={classes.table + ' desing-mark'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>No. Orden</TableCell>
							<TableCell component="th" align={'left'}>Cliente</TableCell>
							<TableCell component="th" align={'left'}>Insumos</TableCell>
							<TableCell component="th" align={'left'}>% Otros Gastos</TableCell>
							<TableCell component="th" align={'left'}>Costo</TableCell>
							<TableCell component="th" align={'left'}>Importe Venta</TableCell>
							<TableCell component="th" align={'left'}>Utilidad</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
							return (
								<TableRow key={key}>
									<TableCell align={'center'} className={'padding-20-L padding-20-R h-35-px'}>{item.numero_orden}</TableCell>
									<TableCell align={'left'}>{item.id_cat_tipo_usuario === 2 ? item.razon_social:item.cliente_nombre_completo}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.consumo)}</TableCell>
									<TableCell align={'left'}>{item.otros_gastos || 'Sin asignar'}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.costo)}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.precio_venta)}</TableCell>
									<TableCell align={'left'}>${FieldsJs.Currency(item.utilidad)}</TableCell>
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										
										<div className={'w-auto vertical-inline'}>
											<div className={'v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Agregar % otros gastos">
													<IconButton aria-label="Agregar % otros gastos"
													            onClick={() => {}}>
														<MoneyOff/>
													</IconButton>
												</Tooltip>
											</div>
										</div>
									
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	delete: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListaTabla);
