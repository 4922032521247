import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Fab from "@material-ui/core/Fab";
import {AddOutlined, CancelOutlined, DeleteOutlined, EditOutlined, PlaylistAddOutlined, SaveOutlined, ShoppingCart} from '@material-ui/icons';
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";

import carrito_de_compra_3 from '../../../../assets/img/icons/carro-de-la-compra-3.svg'
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import ModalProveedor from "../../Proveedor/Includes/ModalProveedor";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalTipoCompraProducto from "../../Cat/TipoCompraProducto/Includes/ModalTipoCompraProducto";
import ModalAnchoCompraProducto from "../../Cat/AnchoCompraProducto/Includes/ModalAnchoCompraProducto";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ModalVentas from "./ModalVentas";


class ModalCompras extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.typingTimer = null;                //timer identifier
		this.doneTypingInterval = 1000;
		
		this.state = {
			modal: {
				open: false
			},
			
			id_compra: props.id_compra,
			
			id_cat_tipo_comprobante: '',
			id_proveedor: '',
			id_cat_forma_pago: '',
			id_quien_recibio: '',
			quien_recibio: '',
			orden_venta: '',
			
			descripcion: '',
			fecha_compra: null,
			total: '',
			
			key: null,
			id_compra_producto: null,
			compra_producto: '',
			compra_descripcion: '',
			compra_cantidad: '',
			compra_precio_unitario: '',
			compra_total: '',
			iva_incluido: false,
			iva_aplicado: false,
			
			activo_lona_vinil: false,
			id_cat_grupo_compra: '',
			id_cat_tipo_compra: '',
			id_cat_medida_ancho_compra: '',
			id_cat_medida_largo_compra: '',
			id_cat_tipo_gasto_compra: '',
			
			lista_compra_producto: [],
			lista_proveedor: [],
			lista_cat_tipo_comprobante: [],
			lista_cat_forma_pago: [],
			lista_cat_grupo_compra: [],
			lista_cat_tipo_compra: [],
			lista_cat_medida_ancho_compra: [],
			lista_cat_medida_largo_compra: [],
			lista_cat_tipo_gasto_compra: [],
			lista_cat_usuario: [],
			lista_cat_compra_descripcion: [],
			lista_cat_compra_producto: [],
			lista_cat_compra_producto_descripcion: [],
			
			ordenes_venta: [],
			
			descuento_general: '',
			compra_descripcion_ac: '',
			compra_producto_ac: '',
			compra_producto_descripcion_ac: ''
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	handleChangeIvaIncluido = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		
		if (!this.state.iva_incluido)
			this.setState({
				iva_aplicado: false
			})
	};
	
	handleChangeIvaAplicado = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		
		if (!this.state.iva_aplicado)
			this.setState({
				iva_incluido: false
			})
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		
		console.log("Entroooooooo");
		
		this.ListaUsuario();
		this.ListaTipoGastoCompra();
		this.ListaProveedor();
		this.ListaTipoComprobante();
		this.ListaFormaPago();
		this.ListsGrupoCompra();
		
		return new Promise((resolve, reject) => {
			if (this.props.id_compra > 0) {
				ComprasService.Detalles(this.props.id_compra).then((response) => {
					let item = FieldsJs.Copy(response.data);
					let fecha_compra = item.fecha_compra ? moment(item.fecha_compra) : null;
					let descuento_general = '';
					for (let i = 0; i < item.compra_producto.length; i++) {
						if (Number(item.compra_producto[i].descuento_general) > 0) {
							descuento_general = Number(item.compra_producto[i].descuento_general);
						}
					}
					
					this.setState({
						id_compra: item.id_compra,
						
						id_cat_tipo_comprobante: item.id_cat_tipo_comprobante || '',
						id_proveedor: item.id_proveedor || '',
						id_cat_forma_pago: item.id_cat_forma_pago || '',
						quien_recibio: item.quien_recibio || '',
						id_quien_recibio: item.id_quien_recibio || '',
						id_cat_tipo_gasto_compra: item.id_cat_tipo_gasto_compra || '',
						
						descripcion: item.descripcion || '',
						compra_descripcion_ac: {descripcion: item.descripcion || ""},
						fecha_compra: fecha_compra,
						total: item.total || '',
						
						key: null,
						id_compra_producto: null,
						compra_producto: '',
						compra_descripcion: '',
						compra_cantidad: '',
						compra_precio_unitario: '',
						compra_total: '',
						iva_incluido: false,
						iva_aplicado: false,
						
						descuento: '',
						descuento_general: descuento_general || '',
						
						lista_compra_producto: item.compra_producto || []
						
					});
					resolve(true);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
					reject(false)
				});
			} else {
				this.setState({
					
					id_compra: null,
					
					id_cat_tipo_comprobante: '',
					id_proveedor: '',
					id_cat_forma_pago: '',
					id_cat_tipo_gasto_compra: '',
					quien_recibio: '',
					id_quien_recibio: '',
					
					descripcion: '',
					fecha_compra: null,
					total: '',
					
					key: null,
					id_compra_producto: null,
					compra_producto: '',
					compra_descripcion: '',
					compra_cantidad: '',
					compra_precio_unitario: '',
					compra_total: '',
					iva_incluido: false,
					iva_aplicado: false,
					
					descuento: '',
					descuento_general: '',
					
					lista_compra_producto: [],
				});
				resolve(true);
			}
		});
	};
	
	RefreshListProveedor = (id_proveedor) => {
		if (id_proveedor > 0) {
			this.setState({
				id_proveedor: id_proveedor
			})
		}
		this.ListaProveedor();
	};
	
	ListaTipoGastoCompra = () => {
		CatService.ListTipoGastoCompra().then(value => {
			this.setState({
				lista_cat_tipo_gasto_compra: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_tipo_gasto_compra: []
			});
		});
	};
	
	ListaUsuario = () => {
		CatService.ListUsuarioCompra().then(value => {
			this.setState({
				lista_cat_usuario: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_usuario: []
			});
		});
	};
	
	ListaProveedor = () => {
		CatService.ListProveedor().then(value => {
			this.setState({
				lista_proveedor: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_proveedor: []
			});
		});
	};
	
	ListaTipoComprobante = () => {
		CatService.ListTipoComprobante().then(value => {
			this.setState({
				lista_cat_tipo_comprobante: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_tipo_comprobante: []
			});
		});
	};
	
	ListaFormaPago = () => {
		CatService.ListFormaPago().then(value => {
			this.setState({
				lista_cat_forma_pago: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_forma_pago: []
			});
		});
	};
	
	ListsGrupoCompra = () => {
		CatService.ListGrupoCompra().then(value => {
			this.setState({
				lista_cat_grupo_compra: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_grupo_compra: []
			});
		});
	};
	
	RefreshListTipoCompra = (id_cat_tipo_compra) => {
		this.ListsTipoCompra(this.state.id_cat_grupo_compra);
		if (id_cat_tipo_compra > 0) {
			this.setState({
				id_cat_tipo_compra: id_cat_tipo_compra
			})
		}
	};
	
	RefreshListAnchoCompra = (id_cat_medida_ancho_compra) => {
		this.ListsMedidaAnchoCompra(this.state.id_cat_grupo_compra);
		if (id_cat_medida_ancho_compra > 0) {
			this.setState({
				id_cat_medida_ancho_compra: id_cat_medida_ancho_compra
			})
		}
	};
	
	ListsTipoCompra = (id_cat_tipo_compra) => {
		CatService.ListTipoCompra(false, id_cat_tipo_compra).then(value => {
			this.setState({
				lista_cat_tipo_compra: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_tipo_compra: []
			});
		});
		this.ListsMedidaAnchoCompra(id_cat_tipo_compra);
		//this.ListsMedidaLargoCompra(id_cat_tipo_compra);
	};
	
	ListsMedidaAnchoCompra = (id_cat_tipo_compra) => {
		CatService.ListMedidaAnchoCompra(false, id_cat_tipo_compra).then(value => {
			this.setState({
				lista_cat_medida_ancho_compra: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_medida_ancho_compra: []
			});
		});
	};
	
	ListsMedidaLargoCompra = (id_cat_tipo_compra) => {
		CatService.ListMedidaLargoCompra(false, id_cat_tipo_compra).then(value => {
			this.setState({
				lista_cat_medida_largo_compra: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_medida_largo_compra: []
			});
		});
	};

	
	activoLonaVinil = (text) => {
		let gc = FieldsJs.Copy(this.state.lista_cat_grupo_compra);
		for (let i = 0; i < gc.length; i++) {
			if (gc[i].grupo_compra.toLowerCase() === text.toLowerCase()) {
				
				this.ListsTipoCompra(gc[i].id_cat_grupo_compra);
				
				this.setState({
					activo_lona_vinil: true,
					id_cat_grupo_compra: gc[i].id_cat_grupo_compra
				});
			}
		}
	};
	
	add = () => {
		
		try {
			
			if (!this.state.compra_producto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Producto.'
				})
			}
			
			if (!(this.state.compra_cantidad > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!(this.state.compra_precio_unitario > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Precio Unitario.'
				})
			}
			
			if (!(this.state.compra_total > 0)) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Total.'
				})
			}
			
			let lista_compra_producto = FieldsJs.Copy(this.state.lista_compra_producto || []);
			
			if (this.is_edited_product()) {
				for (let i = 0; i < lista_compra_producto.length; i++) {
					let item = lista_compra_producto[i];
					if (this.state.key === i) {
						item.id_cat_grupo_compra = this.state.id_cat_grupo_compra || '';
						item.id_cat_tipo_compra = this.state.id_cat_tipo_compra || '';
						item.id_cat_medida_ancho_compra = this.state.id_cat_medida_ancho_compra || '';
						item.id_cat_medida_largo_compra = this.state.id_cat_medida_largo_compra || '';
						item.compra_producto = this.state.compra_producto || '';
						item.compra_producto_ac = {compra_producto: item.compra_producto || ""};
						item.compra_descripcion = this.state.compra_descripcion || '';
						item.compra_producto_descripcion_ac = {compra_descripcion: item.compra_descripcion || ""};
						item.compra_cantidad = this.state.compra_cantidad || 0;
						item.compra_precio_unitario = this.state.compra_precio_unitario || 0;
						item.compra_total = this.state.compra_total || 0;
						item.iva_incluido = this.state.iva_incluido ? 1 : 0;
						item.iva_aplicado = this.state.iva_aplicado ? 1 : 0;
						item.ordenes_venta = this.state.ordenes_venta || [];
						item.descuento = this.state.descuento;
						item.descuento_general = this.state.descuento_general;
					}
				}
			} else {
				let item;
				item = {
					id_cat_grupo_compra: this.state.id_cat_grupo_compra || '',
					id_cat_tipo_compra: this.state.id_cat_tipo_compra || '',
					id_cat_medida_ancho_compra: this.state.id_cat_medida_ancho_compra || '',
					id_cat_medida_largo_compra: this.state.id_cat_medida_largo_compra || '',
					
					id_compra_producto: null,
					id_compra: this.state.id_compra || null,
					compra_producto: this.state.compra_producto || '',
					compra_descripcion: this.state.compra_descripcion || '',
					compra_cantidad: this.state.compra_cantidad || 0,
					compra_precio_unitario: this.state.compra_precio_unitario || 0,
					compra_total: this.state.compra_total || 0,
					iva_incluido: this.state.iva_incluido ? 1 : 0,
					iva_aplicado: this.state.iva_aplicado ? 1 : 0,
					ordenes_venta: this.state.ordenes_venta || [],
					descuento: this.state.descuento,
					descuento_general: this.state.descuento_general,
				};
				lista_compra_producto.push(item);
			}
			
			this.setState({
				key: null,
				
				activo_lona_vinil: '',
				id_cat_grupo_compra: '',
				id_cat_tipo_compra: '',
				id_cat_medida_ancho_compra: '',
				id_cat_medida_largo_compra: '',
				
				compra_producto_ac: '',
				compra_producto_descripcion_ac: '',
				id_compra_producto: '',
				compra_producto: '',
				compra_descripcion: '',
				compra_cantidad: '',
				compra_precio_unitario: '',
				compra_total: '',
				descuento: '',
				descuento_general: '',
				iva_incluido: false,
				iva_aplicado: false,
				lista_compra_producto: lista_compra_producto,
				ordenes_venta: [],
				
			});
			console.log(this.state);
			this.calcularTotalCompra();
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	save = () => {
		console.log("STATE::: ", this.state);
		try {
			if (!this.state.fecha_compra) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha de compra.'
				})
			}
			
			if (!this.state.descripcion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descripción.'
				})
			}
			
			if (!this.state.id_cat_forma_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Forma de pago.'
				})
			}
			
			if (!this.state.total) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			
			try {
				ComprasService.Guardar(this.state).then((response) => {
					this.closeModal();
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
			}
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	edit = (value, key) => {
		try {
			
			this.setState({
				key: key,
				
				id_cat_grupo_compra: value.id_cat_grupo_compra,
				id_cat_tipo_compra: value.id_cat_tipo_compra,
				id_cat_medida_ancho_compra: value.id_cat_medida_ancho_compra,
				id_cat_medida_largo_compra: value.id_cat_medida_largo_compra,
				
				compra_producto: value.compra_producto,
				compra_producto_ac: {compra_producto: value.compra_producto},
				compra_descripcion: value.compra_descripcion,
				compra_producto_descripcion_ac: {compra_descripcion: value.compra_descripcion},
				compra_cantidad: value.compra_cantidad,
				compra_precio_unitario: value.compra_precio_unitario,
				compra_total: value.compra_total,
				iva_incluido: (value.iva_incluido === 1),
				iva_aplicado: (value.iva_aplicado === 1),
				ordenes_venta: value.ordenes_venta,
				descuento: value.descuento,
			});
			this.activoLonaVinil(value.compra_producto);
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_cat_grupo_compra: '',
			id_cat_tipo_compra: '',
			id_cat_medida_ancho_compra: '',
			id_cat_medida_largo_compra: '',
			
			compra_producto_ac: '',
			compra_producto_descripcion_ac: '',
			id_compra_producto: '',
			compra_producto: '',
			compra_descripcion: '',
			compra_cantidad: '',
			compra_precio_unitario: '',
			compra_total: '',
			iva_incluido: false,
			iva_aplicado: false,
			ordenes_venta: [],
			descuento: '',
		});
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar esta este producto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_compra_producto = FieldsJs.Copy(this.state.lista_compra_producto);
					
					lista_compra_producto.splice(key, 1);
					
					this.setState({
						lista_compra_producto: lista_compra_producto
					});
					
					this.calcularTotalCompra();
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	calcularTotalProducto = () => {
		setTimeout(() => {
			let compra_cantidad = this.state.compra_cantidad;
			let compra_precio_unitario = this.state.compra_precio_unitario;
			let compra_total = 0;
			
			if (compra_cantidad > 0 && compra_precio_unitario > 0) {
				compra_total = (compra_cantidad * compra_precio_unitario);
			}
			
			this.setState({
				compra_total: compra_total
			});
		});
	};
	
	calcularPrecioUnitarioProducto = () => {
		setTimeout(() => {
			let compra_cantidad = this.state.compra_cantidad || 1;
			let compra_precio_unitario = 0;
			let compra_total = this.state.compra_total;
			
			if (compra_cantidad > 0 && compra_total > 0) {
				if (this.state.iva_aplicado === 0) {
					compra_precio_unitario = (compra_total / compra_cantidad);
				}
			}
			
			this.setState({
				compra_cantidad: compra_cantidad,
				compra_precio_unitario: compra_precio_unitario
			});
		});
	};
	
	calcularTotalCompra = () => {
		setTimeout(() => {
			let lista_compra_producto = this.state.lista_compra_producto || [];
			let total = 0;
			for (let i = 0; i < lista_compra_producto.length; i++) {
				let item = lista_compra_producto[i];
				if (Number(item.compra_total) > 0) {
					total += this.calcularTotalConSinIVA(item);
				}
			}
			this.setState({
				total: total
			});
			
			this.calcularDescuentoGeneral(this.state.descuento_general);
		});
	};
	
	calcularSubTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return value.compra_precio_unitario / 1.16;
		} else {
			return value.compra_precio_unitario;
		}
	};
	
	calcularSubTotalConSinIVAPrecioUnitarioNew = (value) => {
		return (this.calcularSubTotalConSinIVAPrecioUnitario(value) - (value.descuento / value.compra_cantidad));
	};
	
	calcularIVAPrecioUnitario = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.compra_precio_unitario - (value.compra_precio_unitario / 1.16);
			} else {
				return value.compra_precio_unitario * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularIVAPrecioUnitarioNew = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return value.compra_precio_unitario - (value.compra_precio_unitario / 1.16);
		} else {
			return ((this.calcularSubTotalConSinIVAPrecioUnitario(value) - (value.descuento / value.compra_cantidad)) * 0.16);
		}
	};
	
	/*calcularTotalConSinIVAPrecioUnitario = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_precio_unitario;
		} else {
			return value.compra_precio_unitario * 1.16;
		}
	};*/
	
	calcularTotalConSinIVAPrecioUnitario = (value) => {
		return (this.calcularSubTotalConSinIVAPrecioUnitarioNew(value) + this.calcularIVAPrecioUnitarioNew(value));
	};
	
	/*calcularSubTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1) {
			return (value.compra_total / 1.16) - (FieldsJs.Field(this.state.descuento_general) ? value.descuento_general : value.descuento);
		} else {
			return value.compra_total - (FieldsJs.Field(this.state.descuento_general) ? value.descuento_general : value.descuento);
		}
	};*/
	
	calcularSubTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 && value.iva_aplicado === 0) {
			return ((value.compra_total / 1.16) - value.descuento);
		} else {
			return (value.compra_total);
		}
	};
	
	/*calcularIVA = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_total - (value.compra_total / 1.16);
		} else {
			return value.compra_total * 0.16;
		}
	};*/
	
	calcularIVA = (value) => {
		if (value.iva_aplicado === 0) {
			if (value.iva_incluido === 1) {
				return value.compra_total - (value.compra_total / 1.16);
				//return this.calcularSubTotalConSinIVA(value) * 0.16;
			} else {
				return this.calcularSubTotalConSinIVA(value) * 0.16;
			}
		} else {
			return 0;
		}
	};
	
	calcularDescuentoGeneral = (valor) => {
		let lista_compra_producto = this.state.lista_compra_producto || [];
		for (let i = 0; i < lista_compra_producto.length; i++) {
			let item = lista_compra_producto[i];
			if (i === 0) {
				item.descuento_general = Number(item.descuento) + Number(valor || 0);
			} else {
				item.descuento_general = 0;
			}
		}
		this.setState({
			lista_compra_producto: lista_compra_producto
		});
	};
	
	calcularDescuento = (value) => {
		return Number(value.descuento);
	};
	
	/*calcularTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1) {
			return value.compra_total;
		} else {
			return value.compra_total * 1.16;
		}
	};*/
	
	calcularTotalConSinIVASuma = (value) => {
		if (value.iva_incluido === 1) {
			return this.calcularSubTotalConSinIVA(value);
		} else {
			return value.compra_total;
		}
	};
	
	calcularTotalConSinIVA = (value) => {
		if (value.iva_incluido === 1 || value.iva_aplicado === 1) {
			return this.calcularSubTotalConSinIVA(value) + this.calcularIVA(value);
		} else {
			return this.calcularSubTotalConSinIVA(value) * 1.16;
		}
	};
	
	calcularDesgoseTotales = () => {
		let desglose = {
			suma: 0,
			subtotal: 0,
			descuento: 0,
			iva: 0,
			total: 0,
		};
		let lista_compra_producto = this.state.lista_compra_producto;
		for (let i = 0; i < lista_compra_producto.length; i++) {
			let item = lista_compra_producto[i];
			desglose.suma += this.calcularTotalConSinIVASuma(item);
			//desglose.subtotal += this.calcularSubTotalConSinIVA(item);
			desglose.descuento += this.calcularDescuento(item);
			desglose.iva += this.calcularIVA(item);
			desglose.total += this.calcularTotalConSinIVA(item);
		}
		desglose.descuento = desglose.descuento + Number(this.state.descuento_general);
		desglose.subtotal = desglose.suma - desglose.descuento;
		//desglose.iva = desglose.subtotal * 0.16;
		desglose.total = desglose.subtotal + desglose.iva;
		
		
		return desglose;
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	text_compra_descripcion = (text) => {
		let texto = '';
		texto = texto + text;
		this.setState({
			compra_descripcion: texto
		});
	};
	
	eliminar_producto_atributo = (key) => {
		let ordenes = FieldsJs.Copy(this.state.ordenes_venta);
		ordenes.splice(key, 1);
		this.setState({
			ordenes_venta: ordenes
		});
	};
	
	addOrdenVenta = (e) => {
		if (e.keyCode == 13) {
			let ordenes = FieldsJs.Copy(this.state.ordenes_venta);
			ordenes.push({"orden": this.state.orden_venta, "id_compra_producto_orden": null});
			this.setState({
				ordenes_venta: ordenes,
				orden_venta: ''
			});
		}
	};
	
	ListaCompraDescripcion = (valor) => {
		CatService.ListCompraDescripcion(valor).then(value => {
			this.setState({
				lista_cat_compra_descripcion: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_compra_descripcion: []
			});
		});
	};
	
	
	selectDescripcionCompra = (e, valor) => {
		console.log("SELECT:::: ",e, valor);
		clearTimeout(this.typingTimer);
		let val = FieldsJs.Copy(valor);

		if (valor) {
			this.setState({
				descripcion: val,
				compra_descripcion_ac: {descripcion:val}
			}, () => {
				this.typingTimer = setTimeout(() => {
					this.ListaCompraDescripcion(val)
				}, this.doneTypingInterval);
			});
		}
	}
	
	handleChangeDescripcionCompra = (e, valor) => {
		console.log("SELECT:::: handleChangeDescripcionCompra ",e, valor);
		
		let val = FieldsJs.Copy(valor);

		if (valor) {
			this.setState({
				descripcion: val.descripcion,
				compra_descripcion_ac: valor
			});
		}
	}
	
	ListaCompraProducto = (valor) => {
		CatService.ListCompraProducto(valor).then(value => {
			this.setState({
				lista_cat_compra_producto: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_compra_producto: []
			});
		});
	};
	
	selectCompraProducto = (e, valor) => {
		console.log("SELECT:::: ",e, valor);
		clearTimeout(this.typingTimer);
		let val = FieldsJs.Copy(valor);

		if (valor) {
			this.setState({
				compra_producto: val,
				compra_producto_ac: {compra_producto:val}
			}, () => {
				this.typingTimer = setTimeout(() => {
					this.ListaCompraProducto(val)
				}, this.doneTypingInterval);
			});
		}
	}
	
	handleChangeCompraProducto = (e, valor) => {
		console.log("SELECT:::: handleChangeDescripcionCompra ",e, valor);
		
		let val = FieldsJs.Copy(valor);

		if (valor) {
			this.setState({
				compra_producto: val.compra_producto,
				compra_producto_ac: valor
			});
		}
	}
	
	ListaCompraProductoDescripcion = (valor) => {
		CatService.ListCompraProductoDescripcion(valor).then(value => {
			this.setState({
				lista_cat_compra_producto_descripcion: value.data
			});
		}).catch(reason => {
			this.setState({
				lista_cat_compra_producto_descripcion: []
			});
		});
	};
	
	selectCompraProductoDescripcion = (e, valor) => {
		console.log("SELECT:::: ",e, valor);
		clearTimeout(this.typingTimer);
		let val = FieldsJs.Copy(valor);

		if (valor) {
			this.setState({
				compra_descripcion: val,
				compra_producto_descripcion_ac: {compra_descripcion:val}
			}, () => {
				this.typingTimer = setTimeout(() => {
					this.ListaCompraProductoDescripcion(val)
				}, this.doneTypingInterval);
			});
		}
	}
	
	handleChangeCompraProductoDescripcion = (e, valor) => {
		console.log("SELECT:::: handleChangeDescripcionCompra ",e, valor);
		
		let val = FieldsJs.Copy(valor);

		if (valor) {
			this.setState({
				compra_descripcion: val.compra_descripcion,
				compra_producto_descripcion_ac: valor
			});
		}
	}
	
	render() {
		
		const is_root = this.is_root();
		
		const desglose = this.calcularDesgoseTotales();
		
		const {classes} = this.props;
		
		return (
			<div>
				
				{this.props.icono ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.icono}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Compra / Gastos
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							
							<Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									disabled={this.is_view()}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_tipo_gasto_compra"
									label="Tipo gasto"
									value={this.state.id_cat_tipo_gasto_compra}
									required
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_tipo_gasto_compra.map(option => (
										<option key={option.id_cat_tipo_gasto_compra}
										        value={option.id_cat_tipo_gasto_compra}>
											{option.tipo_gasto_compra}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
								
								<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
									{!this.is_view() ? (
										<Grid item style={{width: '50px'}}>
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<ModalProveedor
													componente={(
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Agregar nuevo proveedor"
															children={(
																<Fab
																	color="secondary"
																	size="small"
																	aria-label="Agregar nuevo proveedor"
																	children={(
																		<PlaylistAddOutlined/>
																	)}
																/>
															)}
														/>
													)}
													id_proveedor={null}
													tipo={'add'}
													item={{}}
													RefreshList={this.RefreshListProveedor}
													showSnackBars={this.props.showSnackBars}
												/>
											) : ''}
										</Grid>
									) : null}
									<Grid item style={{width: 'calc(100% - 50px)'}}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_proveedor"
											label="Proveedor"
											value={this.state.id_proveedor}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_proveedor.map(option => (
												<option key={option.id_proveedor} value={option.id_proveedor}>
													{option.nombre_comercial}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {},
									}}
									name="id_quien_recibio"
									label="Quien recibió"
									value={this.state.id_quien_recibio}
									disabled={this.is_view()}
									required
								>
									<option value={''}>&nbsp;</option>
									{this.state.lista_cat_usuario.map(option => (
										<option key={option.id_usuario} value={option.id_usuario}>
											{option.nombre} {option.apellido_paterno} {option.apellido_materno}
										</option>
									))}
								</TextField>
								
								{/*<TextField
									margin="none"
									name="quien_recibio"
									label="Quien recibió"
									type="text"
									fullWidth
									value={this.state.quien_recibio}
									onChange={this.handleChange}
									required={true}
									disabled={this.is_view()}
								/>*/}
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									disabled={this.is_view()}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									required
									name="id_cat_tipo_comprobante"
									label="Tipo de comprobante"
									value={this.state.id_cat_tipo_comprobante}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_tipo_comprobante.map(option => (
										<option key={option.id_cat_tipo_comprobante}
										        value={option.id_cat_tipo_comprobante}>
											{option.tipo_comprobante}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
								{/*<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a" fullWidth required clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de compra',
									}}
									label="Fecha de compra"
									value={this.state.fecha_compra}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_compra');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
									disabled={this.is_view()}
								/>*/}
								<KeyboardDatePicker
									format={'dd/MM/yyyy'} fullWidth clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									label="Fecha compra"
									value={this.state.fecha_compra}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_compra');
									}}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									disabled={this.is_view()}
								/>
							</Grid>
							
							<Grid item xs={9} sm={9} md={4} lg={4} xl={4}>
								<Autocomplete
									id="autocomplete_compra_descripcion"
									options={this.state.lista_cat_compra_descripcion}
									value={this.state.compra_descripcion_ac}
									//inputValue={this.state.descripcion}
									getOptionLabel={(option) => option.descripcion}
									noOptionsText={'No se encontron datos.'}
									renderInput={(params) => <TextField {...params} label="Descripción *" margin="none"/>}
									onChange={(e, value) => {
										this.handleChangeDescripcionCompra(e, value);
									}}
									onInputChange={(e, value) => {
										this.selectDescripcionCompra(e, value)
									}}
									disabled={this.is_view()}
								
								/>
								
								{/*<TextField
									rows={4}
									multiline
									margin="none"
									name="descripcion"
									label="Descripción"
									type="text"
									fullWidth
									value={this.state.descripcion}
									onChange={this.handleChange}
									required={true}
									disabled={this.is_view()}
								/>*/}
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									disabled={this.is_view()}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									required={true}
									name="id_cat_forma_pago"
									label="Forma de pago"
									value={this.state.id_cat_forma_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_forma_pago.map(option => (
										<option key={option.id_cat_forma_pago}
										        value={option.id_cat_forma_pago}>
											{option.forma_pago}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"} alignContent={"flex-end"}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"}>
										<Typography component={'h2'} className={'margin-0 padding-0 px-14'}>
											Monto total:
										</Typography>
										<Typography component={'h2'} className={'margin-0 padding-0 px-25'}>
											${FieldsJs.Currency(this.state.total)}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							
							{!this.is_view() ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
											
											<Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
												<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
													<Grid item style={{width: '50px'}}>
														<ModalVentas
															tipo={'add'}
															item={{}}
															component={
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title="Lista Ventas"
																	children={(
																		<Fab
																			color="secondary"
																			size="small"
																			aria-label="Lista Ventas"
																			children={(
																				<ShoppingCart/>
																			)}
																		/>
																	)}
																/>
															}
															RefreshList={this.RefreshListTipoCompra}
															showSnackBars={this.props.showSnackBars}
														/>
													</Grid>
													<Grid item style={{width: 'calc(100% - 50px)'}}>
														<TextField
															margin="none"
															name="orden_venta"
															label="Órdenes"
															type="text"
															fullWidth
															value={this.state.orden_venta}
															onChange={this.handleChange}
															onKeyDown={this.addOrdenVenta}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
												<Autocomplete
													id="autocomplete_compra_producto"
													options={this.state.lista_cat_compra_producto}
													value={this.state.compra_producto_ac}
													//inputValue={this.state.descripcion}
													getOptionLabel={(option) => option.compra_producto}
													noOptionsText={'No se encontron datos.'}
													renderInput={(params) => <TextField {...params} label="Producto *" margin="none"/>}
													onChange={(e, value) => {
														this.handleChangeCompraProducto(e, value);
													}}
													onInputChange={(e, value) => {
														this.selectCompraProducto(e, value)
													}}
													required
												/>
												
												{/*<TextField
													margin="none"
													name="compra_producto"
													label="Producto"
													type="text"
													fullWidth
													value={this.state.compra_producto}
													onChange={(e) => {
														this.handleChange(e);
														this.activoLonaVinil(e.target.value);
													}}
													required={true}
												/>*/}
											</Grid>
											
											{this.state.activo_lona_vinil ?
												<Fragment>
													{/*<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<TextField
															select
															fullWidth
															margin="none"
															onChange={(e) => {
																this.handleChange(e);
																this.ListsTipoCompra(e.target.value);
																this.setState({
																	id_tipo: '',
																	id_medida_largo: '',
																	id_medida_ancho: ''
																});
																//this.text_compra_descripcion(e.target.value);
															}}
															disabled={this.is_view()}
															SelectProps={{
																native: true,
																MenuProps: {
																	className: '',
																},
															}}
															name="id_grupo"
															label="Grupo"
															value={this.state.id_grupo}
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.lista_cat_grupo_compra.map(option => (
																<option key={option.id_cat_grupo_compra}
																        value={option.id_cat_grupo_compra}>
																	{option.grupo_compra}
																</option>
															))}
														</TextField>
													</Grid>*/}
													
													<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
														<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
															<Grid item style={{width: '50px'}}>
																<ModalTipoCompraProducto
																	tipo={'add'}
																	item={{}}
																	componente={
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Agregar nuevo tipo"
																			children={(
																				<Fab
																					color="secondary"
																					size="small"
																					aria-label="Agregar nuevo tipo"
																					children={(
																						<PlaylistAddOutlined/>
																					)}
																				/>
																			)}
																		/>
																	}
																	RefreshList={this.RefreshListTipoCompra}
																	showSnackBars={this.props.showSnackBars}/>
															</Grid>
															<Grid item style={{width: 'calc(100% - 50px)'}}>
																<TextField
																	select
																	fullWidth
																	margin="none"
																	onChange={this.handleChange}
																	disabled={this.is_view() || !this.state.id_cat_grupo_compra}
																	SelectProps={{
																		native: true,
																		MenuProps: {
																			className: '',
																		},
																	}}
																	name="id_cat_tipo_compra"
																	label="Tipo"
																	value={this.state.id_cat_tipo_compra}
																>
																	<option key={0} value={''}>&nbsp;</option>
																	{this.state.lista_cat_tipo_compra.map(option => (
																		<option key={option.id_cat_tipo_compra}
																		        value={option.id_cat_tipo_compra}>
																			{option.tipo_compra}
																		</option>
																	))}
																</TextField>
															</Grid>
														</Grid>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
														<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
															<Grid item style={{width: '50px'}}>
																<ModalAnchoCompraProducto
																	tipo={'add'}
																	item={{}}
																	componente={
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Agregar medida ancho"
																			children={(
																				<Fab
																					color="secondary"
																					size="small"
																					aria-label="Agregar medida ancho"
																					children={(
																						<PlaylistAddOutlined/>
																					)}
																				/>
																			)}
																		/>
																	}
																	RefreshList={this.RefreshListAnchoCompra}
																	showSnackBars={this.props.showSnackBars}/>
															</Grid>
															<Grid item style={{width: 'calc(100% - 50px)'}}>
																
																<TextField
																	select
																	fullWidth
																	margin="none"
																	onChange={this.handleChange}
																	disabled={this.is_view() || !this.state.id_cat_grupo_compra || !this.state.id_cat_tipo_compra}
																	SelectProps={{
																		native: true,
																		MenuProps: {
																			className: '',
																		},
																	}}
																	name="id_cat_medida_ancho_compra"
																	label="Medida Ancho"
																	value={this.state.id_cat_medida_ancho_compra}
																>
																	<option key={0} value={''}>&nbsp;</option>
																	{this.state.lista_cat_medida_ancho_compra.map(option => (
																		<option key={option.id_cat_medida_ancho_compra}
																		        value={option.id_cat_medida_ancho_compra}>
																			{option.medida_ancho_compra}
																		</option>
																	))}
																</TextField>
															</Grid>
														</Grid>
													</Grid>
													
													<Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
														{/*<TextField
															select
															fullWidth
															margin="none"
															onChange={this.handleChange}
															disabled={this.is_view() || !this.state.id_cat_grupo_compra || !this.state.id_cat_tipo_compra}
															SelectProps={{
																native: true,
																MenuProps: {
																	className: '',
																},
															}}
															name="id_cat_medida_largo_compra"
															label="Medida Largo"
															value={this.state.id_cat_medida_largo_compra}
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.lista_cat_medida_largo_compra.map(option => (
																<option key={option.id_cat_medida_largo_compra}
																        value={option.id_cat_medida_largo_compra}>
																	{option.medida_largo_compra}
																</option>
															))}
														</TextField>*/}
														<TextField
															margin="none"
															name="id_cat_medida_largo_compra"
															label="Medida Largo (Metros)"
															type="text"
															fullWidth
															value={this.state.id_cat_medida_largo_compra}
															onChange={this.handleChange}
															onKeyPress={EnteroSolo}
															inputProps={{maxLength: 15}}
															/>
													</Grid>
												</Fragment>
												: null}
											
											<Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
												<Autocomplete
													id="autocomplete_compra_producto_descripcion"
													options={this.state.lista_cat_compra_producto_descripcion}
													value={this.state.compra_producto_descripcion_ac}
													//inputValue={this.state.descripcion}
													getOptionLabel={(option) => option.compra_descripcion}
													noOptionsText={'No se encontron datos.'}
													renderInput={(params) => <TextField {...params} label="Descripción" margin="none"/>}
													onChange={(e, value) => {
														this.handleChangeCompraProductoDescripcion(e, value);
													}}
													onInputChange={(e, value) => {
														this.selectCompraProductoDescripcion(e, value)
													}}
												/>
												
												{/*<TextField
													margin="none"
													name="compra_descripcion"
													label="Descripción"
													type="text"
													fullWidth
													value={this.state.compra_descripcion}
													onChange={this.handleChange}
													required={false}
												/>*/}
											</Grid>
											
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
												<TextField
													margin="none"
													name="compra_cantidad"
													label="Cantidad"
													type="text"
													fullWidth
													value={this.state.compra_cantidad}
													onChange={(e) => {
														this.handleChange(e);
														this.calcularTotalProducto();
													}}
													onKeyPress={EnteroSolo}
													inputProps={{maxLength: 15}}
													required={true}
												/>
											</Grid>
											
											<Grid item xs={2} sm={3} md={2} lg={2} xl={2}>
												<TextField
													margin="none"
													name="compra_precio_unitario"
													label="Precio Unitario"
													type="text"
													fullWidth
													value={this.state.compra_precio_unitario}
													onChange={(e) => {
														this.handleChange(e);
														this.calcularTotalProducto();
													}}
													onKeyPress={EnteroSolo}
													inputProps={{maxLength: 50}}
													required={true}
												/>
											</Grid>
											
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
												<TextField
													margin="none"
													name="compra_total"
													label="Importe"
													type="text"
													fullWidth
													value={this.state.compra_total}
													onChange={(e) => {
														this.handleChange(e);
														this.calcularPrecioUnitarioProducto();
													}}
													onKeyPress={EnteroSolo}
													inputProps={{maxLength: 50}}
													required={true}
												/>
											</Grid>
											
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
												<TextField
													margin="none"
													name="descuento"
													label="$ Descuento"
													type="text"
													fullWidth
													value={this.state.descuento}
													onChange={(e) => {
														this.handleChange(e);
														//this.calcularDescuento();
													}}
													onKeyPress={EnteroSolo}
													inputProps={{maxLength: 50}}
													disabled={this.props.tipo === 'view'}
												/>
											</Grid>
											
											<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
												<FormGroup row>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																name='iva_incluido'
																checked={this.state.iva_incluido}
																onChange={this.handleChange}
																value="iva_incluido"
																color="primary"
																disabled={this.props.tipo === 'view'}
															/>
														}
														label={'iva incluido'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
												<FormGroup row>
													<FormControlLabel
														control={
															<Checkbox
																type="checkbox"
																name='iva_aplicado'
																checked={this.state.iva_aplicado}
																onChange={this.handleChangeIvaAplicado}
																value="iva_aplicado"
																color="primary"
																disabled={this.props.tipo === 'view'}
															/>
														}
														label={'No aplica IVA'}
													/>
												</FormGroup>
											</Grid>
											
											<Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={"right"}>
												<Tooltip
													TransitionComponent={Zoom}
													placement={"top"}
													title="Cancelar edición"
													children={(
														<span>
																<Fab size="small" color="secondary"
																     aria-label="Cancelar edición"
																     onClick={() => this.edit_cancel()}
																     style={{marginLeft: "15px"}}
																     disabled={!this.is_edited_product()}>
																	<CancelOutlined/>
																</Fab>
															</span>
													)}
												/>
												<Tooltip
													TransitionComponent={Zoom}
													placement={"top"}
													title={this.is_edited_product() ? "Actualizar" : "Agregar"}
													children={(
														<Fab size="small" color="secondary"
														     aria-label={this.is_edited_product() ? "Actualizar" : "Agregar"}
														     onClick={() => this.add()}
														     style={{marginLeft: "15px"}}>
															{this.is_edited_product() ? (
																<SaveOutlined/>
															) : (
																<AddOutlined/>
															)}
														</Fab>
													)}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Fragment>
							) : null}
							
							{this.state.ordenes_venta.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography variant={'h5'} className={'px-13 text-left'}>
										Ordene(s):
									</Typography>
									{(this.state.ordenes_venta || []).map((itemTR, key) => (
										<div key={key} className={'vertical-inline b-r-20 margin-5 bg-danger'}>
											<div className={'vertical-inline px-14 padding-3 padding-10-L padding-10-R v-center text-white'}>
												{itemTR.orden || 'N/A'}
											</div>
											<div className={'vertical-inline padding-3 padding-10-R v-center text-white'}>
												<DeleteOutlined className={'px-16 cursor-pointer'} onClick={() => this.eliminar_producto_atributo(key)}/>
											</div>
										</div>
									))}
								</Grid>
							) : null}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<table className={'desing-mark'}>
									<thead>
									<tr>
										<th>Órdenes</th>
										<th>Producto</th>
										<th>Descripción</th>
										{is_root ? (
											<th align={"center"} style={{color: '#00a8ff'}}>IVA incluido</th>
										) : null}
										<th align={"center"}>Cantidad</th>
										<th align={"right"} style={{color: '#00a8ff'}}>Descuento</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe P. U.</th>
										) : null}
										<th align={"right"}>
											{is_root ? 'Subtotal P. U.' : 'Precio Unitario'}
										</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>IVA P. U.</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Precio Unitario</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe Total</th>
										) : null}
										<th align={"right"}>
											{is_root ? 'Subtotal' : 'Total'}
										</th>
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>IVA</th>
										) : null}
										{is_root ? (
											<th align={"right"} style={{color: '#00a8ff'}}>Importe</th>
										) : null}
										{!this.is_view() ? (
											<th align={'right'} width={'110px'}>Acciones</th>
										) : null}
									</tr>
									</thead>
									<tbody>
									{this.state.lista_compra_producto.map((value, index) => (
										<tr key={index}>
											<td>{value.ordenes_venta.length > 0 ? (
												<Fragment>
													{(value.ordenes_venta || []).map((itemTR, key) => (
														<div key={key} className={'vertical-inline b-r-20 margin-5 bg-gray'}>
															<div className={'vertical-inline px-14 padding-3 padding-10-L padding-10-R v-center text-white'}>
																{itemTR.orden || 'N/A'}
															</div>
														</div>
													))}
												</Fragment>
											) : 'Sin registrar'}</td>
											<td>{value.compra_producto}</td>
											<td>{value.compra_descripcion}</td>
											{is_root ? (
												<td align={"center"}>
													{value.iva_incluido === 1 ? (
														<div style={{
															width: "15px",
															color: "white",
															borderRadius: "3px",
															padding: "1px 7px",
															background: "#3F51B5",
															display: "inline-grid",
															fontSize: '12px',
														}}>Si</div>
													) : (
														<div style={{
															width: "15px",
															color: "white",
															borderRadius: "3px",
															padding: "1px 7px",
															background: "#FF5722",
															display: "inline-grid",
															fontSize: '12px',
														}}>No</div>
													)}
												</td>
											) : null}
											<td align={"center"}>
												{value.compra_cantidad}
											</td>
											<td align={"center"}>
												${FieldsJs.Currency(value.descuento)}
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													${FieldsJs.Currency(value.compra_precio_unitario)}
												</td>
											) : null}
											<td align={"right"}>
												<b>
													${FieldsJs.Currency(this.calcularSubTotalConSinIVAPrecioUnitarioNew(value))}
												</b>
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularIVAPrecioUnitarioNew(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularTotalConSinIVAPrecioUnitario(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													${FieldsJs.Currency(value.compra_total)}
												</td>
											) : null}
											<td align={"right"}>
												<b>
													${FieldsJs.Currency(this.calcularSubTotalConSinIVA(value))}
												</b>
											</td>
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularIVA(value))}
													</b>
												</td>
											) : null}
											{is_root ? (
												<td align={"right"} style={{color: '#00a8ff'}}>
													<b>
														${FieldsJs.Currency(this.calcularTotalConSinIVA(value))}
													</b>
												</td>
											) : null}
											{!this.is_view() ? (
												<td align={'right'}>
													<Tooltip
														TransitionComponent={Zoom}
														placement={"top"}
														title="Eliminar"
														children={(
															<Fab
																size="small"
																aria-label="Eliminar"
																onClick={() => this.delete(value, index)}
																style={{
																	marginLeft: "10px",
																	backgroundColor: "transparent",
																	color: "#808080",
																	boxShadow: "none",
																}}
																children={(
																	<DeleteOutlined/>
																)}
															/>
														)}
													/>
													{this.state.key === index ? (
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Producto en edición"
															children={(
																<Fab
																	size="small"
																	aria-label="Editar"
																	onClick={() => this.edit(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "#f50057",
																		color: "white",
																	}}
																	children={(
																		<EditOutlined/>
																	)}
																/>
															)}
														/>
													) : (
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Editar producto"
															children={(
																<Fab
																	size="small"
																	aria-label="Editar"
																	onClick={() => this.edit(value, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<EditOutlined/>
																	)}
																/>
															)}
														/>
													)}
												</td>
											) : null}
										</tr>
									))}
									{!(this.state.lista_compra_producto.length > 0) ? (
										<tr>
											<td colSpan={!this.is_view() ? (is_root ? 14 : 7) : (is_root ? 13 : 6)} align={'center'}>
												<VistaVacia
													numero={null}
													mensaje={'Ningún producto agregado.'}
													image={carrito_de_compra_3}
													padding={'20px'}
													paddingText={'20px'}
													height={'80px'}
													width={'80px'}
												/>
											</td>
											<td/>
										</tr>
									) : null}
									<tr>
										<td colSpan={(is_root ? 10 : 4)}/>
										<td colSpan={2}>
											<TextField
												margin="none"
												name="descuento_general"
												type="text"
												fullWidth
												value={this.state.descuento_general}
												onChange={(e) => {
													this.handleChange(e);
													this.calcularDescuentoGeneral(e.target.value);
												}}
												inputProps={{
													placeholder: "Descuento general",
													maxLength: 50
												}}
												onKeyPress={EnteroSolo}
												disabled={this.props.tipo === 'view'}
											/>
										</td>
										<td align={"right"}>Suma</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.suma)}</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={(is_root ? 12 : 6)}/>
										<td align={"right"}>Descuento</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.descuento)}</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={(is_root ? 12 : 6)}/>
										<td align={"right"}>Subtotal</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.subtotal)}</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={(is_root ? 12 : 6)}/>
										<td align={"right"}>IVA</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.iva)}</td>
										<td/>
									</tr>
									<tr>
										<td colSpan={(is_root ? 12 : 6)}/>
										<td align={"right"}>Total</td>
										<td align={"right"}>${FieldsJs.Currency(desglose.total)}</td>
										<td/>
									</tr>
									</tbody>
								</table>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : 'Agregar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCompras.propTypes = {
	id_compra: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	icono: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCompras;
