import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';

export const MonitorService = {
	Datos: (spinner) => {
		let params = {
			filtro: {
				numero_cotizacion: null,
				numero_pedido: null,
				cliente: null,
				fecha_alta_inicio: null,
				fecha_alta_fin: null,
				fecha_entrega_inicio: null,
				fecha_entrega_fin: null,
				status_pago: 'all',
				status_proceso: 'todos_excepto_entregados',
				status_tipo_pedido: 'all',
				id_usuario_disenador: null,
				ordenar_fecha_alta: null,
				ordenar_fecha_entrega: 1,
				tipo_vista: 'nota_sencilla|factura|interna'
			},
			paginacion: {
				total: null,
				page: null,
				limit: null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Monitor_Datos', params, {spinner: spinner}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};

