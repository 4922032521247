import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import SVGDescargar from '../../../../assets/img/icons/orden-venta-descargar.svg';
import SVGVer from '../../../../assets/img/icons/orden-venta-ver.svg';
import SVGImprimir from '../../../../assets/img/icons/orden-venta-imprimir.svg';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import {Send} from "@material-ui/icons";
import {FieldsJs} from "../../../../settings/General/General";


class ModalAccionPDF extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.state = {
			correo_electronico: ""
		}
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	exportar = (tipo, sendmail) => {
		let cliente = this.props.cliente || {};
		let correo_electronico = this.state.correo_electronico || cliente.correo_electronico;
		let params = {
			id_venta: this.props.id_venta,
			tipo: tipo,
			sendmail: sendmail ? 1 : 0,
			correo_electronico: correo_electronico,
		};
		if (sendmail) {
			HttpRequest.export('vxid', ['orden', 'venta'], params, !(tipo === 3), false, false, false, (response) => {
				this.props.showSnackBars('success', response.message);
				this.setState({
					correo_electronico: "",
				});
			}, (error) => {
				this.props.showSnackBars('error', error.message);
			});
		} else {
			HttpRequest.export('vxid', ['orden', 'venta'], params, !(tipo === 3))
		}
	};
	
	render() {
		console.log(this.props.cliente);
		let cliente = this.props.cliente || {};
		let correo_electronico = this.state.correo_electronico || cliente.correo_electronico;
		const estilo = {
			height: "150px",
			width: "150px",
			borderRadius: "100%",
			cursor: "pointer",
			marginBottom: "15px",
			marginTop: "15px",
		};
		const configuracion = {
			imprimir: false,
			ver: true,
			descargar: false,
			sendmail: true,
		};
		return (
			<div>
				<Dialog open={this.props.open} onClose={() => this.props.closeModal()} transition={
					<Slide direction="up"/>
				} disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} fullWidth={true}>
					
					{/*<DialogTitle>*/}
					{/*	/!*{'Orden de venta generada con exito'}*!/*/}
					{/*</DialogTitle>*/}
					
					<DialogContent>
						
						{/*<DialogContentText>*/}
						{/*</DialogContentText>*/}
						
						<div className={'padding-40'}>
							<Grid container spacing={5}>
								
								{configuracion.imprimir ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<img
											src={SVGImprimir}
											alt=""
											style={estilo}
											onClick={() => this.exportar(1, false)}
										/>
										<Typography variant="h6" className={'l-h-20 px-16'}>
											Imprimir orden de venta
										</Typography>
									</Grid>
								) : null}
								
								{configuracion.ver ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<img
											src={SVGVer}
											alt=""
											style={estilo}
											onClick={() => this.exportar(2, false)}
										/>
										<Typography variant="h6" className={'l-h-20 px-16'}>
											Ver e imprimir
										</Typography>
									</Grid>
								) : null}
								
								{configuracion.descargar ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
										<img
											src={SVGDescargar}
											alt=""
											style={estilo}
											onClick={() => this.exportar(3, false)}
										/>
										<Typography variant="h6" className={'l-h-20 px-16'}>
											Descargar
										</Typography>
									</Grid>
								) : null}
								
								{configuracion.sendmail ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Typography variant="h5" align={'center'}>
													{this.props.cliente.razon_social || this.props.cliente.nombre_completo || "No especificado..."}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={2} md={2} lg={2} xl={2}/>
											<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
												<TextField
													fullWidth
													required
													label={"Correo electrónico"}
													name={"correo_electronico"}
													value={correo_electronico}
													margin={"none"}
													onChange={this.handleChange}
												/>
											</Grid>
											<Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
												<Fab variant="extended" size="small" color="primary"
												     aria-label="Enviar cotización" onClick={() => this.exportar(2, true)}>
													<Send className={'margin-5-R px-14'}/> enviar
												</Fab>
											</Grid>
											<Grid item xs={12} sm={2} md={2} lg={2} xl={2}/>
										</Grid>
									</Grid>
								) : null}
							
							</Grid>
						</div>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Button onClick={() => this.props.closeModal()} color="primary">Cerrar</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			</div>
		);
	}
}

ModalAccionPDF.propTypes = {
	id_venta: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	cliente: PropTypes.object,
	showSnackBars: PropTypes.func.isRequired,
};


export default ModalAccionPDF;
