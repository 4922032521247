import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import {DropzoneArea} from "material-ui-dropzone";
import {CotizadorService} from "../../../../services/CotizadorService/CotizadorService";
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from "dropbox";


class ModalNube extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
			id_cotizador: '',
			files: []
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const {item} = this.props;
		this.setState({
			modal: {
				open: true
			},
			id_cotizador: item.id_cotizador,
			archivo: ''
		});
		console.log(this.state, item);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		}, () => {
			console.log("CONTENT:::: ", files);
			
			/*const data = new FormData();
			data.append('file', this.state.files);
			console.warn(this.state.files, data);
			
			CotizadorService.Nube(data).then((response) => {
				this.closeModal();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});*/
		});
		
		//this.createImage(files[0]);
	};
	
	createImage(file) {
		let reader = new FileReader();
		reader.onload = (e) => {
			this.setState({
				image: e.target.result
			})
		};
		reader.readAsDataURL(file);
	}
	
	save = () => {
		let cont_files = this.state.files.length - 1;
		for (let i = 0; i < this.state.files.length; i++) {
			this.getBase64(this.state.files[i], true).then(response => {
				this.setState({
					cotizador_archivo: response.path_display
				});
				CotizadorService.AgregarArchivo(this.state).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					console.log(i, cont_files, i >= cont_files);
					if (i >= cont_files) {
						this.closeModal();
						this.props.RefreshList();
					}
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			});
			
		}
		
		/*const formData = {file: this.state.image};
		
		CotizadorService.Nube(formData).then((response) => {
			this.closeModal();
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});*/
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve) => {
			const accessToken = CONFIG.DROPBOX_TOKEN;
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			const dbx = new Dropbox({
				accessToken,
				fetch
			});
			
			console.log("DROPBOX: ", '/COTIZADOR/' + this.state.id_cotizador + '/' + files.name);
			dbx.filesUpload({path: '/COTIZADOR/' + this.state.id_cotizador + '/' + files.name, contents: files})
				.then(function (response) {
					console.log("DROPBOX UPLOAD: ", response);
					resolve(response);
				})
				.catch(function (error) {
					console.error(error);
				});
		});
	};
	
	render() {
		const is_root = this.is_root();
		let {item} = this.props;
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogTitle>
						Ficha Técnica - Archivos
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} className={'cotizador_fondo'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<p className={'margin-0'}>
									Sube a la nube archivos para resguardar el trabajo realizado del producto.
								</p>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100'}>
									<DropzoneArea
										onChange={this.handleChangeDropzoneArea.bind(this)}
										open={this.state.open}
										filesLimit={50}
										dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
										acceptedFiles={[]}
										showPreviews={false}
										maxFileSize={5000000}
										showAlerts={false}
										showFileNames={true}
										showFileNamesInPreview={true}
									/>
								</div>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.save()} color="primary">
									Guardar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalNube.propTypes = {
	item: PropTypes.object.isRequired,
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalNube;
