import {CONFIG} from '../Config/Config';
import {FieldsJs, hideSpinner, showSpinner} from '../General/General';
import {ReactLocalStorageService} from '../ReactLocalStorageService/ReactLocalStorageService';
import {Dropbox} from "dropbox";


const imprimir = (strung, obj, type) => {
	let dato = [];
	if (type) {
		dato = [
			"%c%s%c%s\n",
			"color: white; background: gray; font-size: 12px;font-weight: bold;letter-spacing: 10px;",
			" " + strung,
			"color: #30568C; background: #FAFAFA; font-size: 12px;font-weight: bold;",
			obj
		];
		window.console.log(
			dato[0],
			dato[1],
			dato[2],
			dato[3],
			JSON.stringify(dato[4], null, 2)
		);
	} else {
		dato = [
			"%c%s%c\n",
			"color: white; background: gray; font-size: 12px;font-weight: bold;letter-spacing: 10px;",
			" " + strung,
			"color: #30568C; background: #FAFAFA; font-size: 12px;font-weight: bold;",
			obj
		];
		window.console.log(
			dato[0],
			dato[1],
			dato[2],
			dato[3],
			dato[4]
		);
	}
};

export const DropboxApi = {
	
	List: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken, fetch});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		
		return new Promise((resolve, reject) => {
			
			dbx.filesListFolder({
				path: path
			}).then(response => {
				console.log("DROPBOX::: ", response);
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				
				resolve(response);
			}).catch((error) => {
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
	
	Upload: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken, fetch});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		/*if (setting.spinner) {
			showSpinner('spinner');
		}*/
		
		return new Promise((resolve, reject) => {
			dbx.filesUpload({
				path: path,
				contents: contents,
				mode: mode ? mode : 'overwrite'
			}).then(function (response) {
				/*if (setting.spinner) {
					hideSpinner('spinner', time);
				}*/
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				resolve(response);
			}).catch(function (error) {
				
				/*if (setting.spinner) {
					hideSpinner('spinner', time);
				}*/
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
	
	Download: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken, fetch});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		const arc = path.split('.');
		const nom = arc[arc.length - 2].split('/');
		
		return new Promise((resolve, reject) => {
			dbx.filesDownload({
				path: path
			}).then(function (response) {
				console.log("DROPBOX::: ", response);
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				
				const url = URL.createObjectURL(response.fileBlob);
				const a = document.createElement('a');
				a.href = url;
				a.download =  nom[nom.length - 1] + '.' + arc[arc.length - 1] || 'download';
				const clickHandler = () => {
					setTimeout(() => {
						URL.revokeObjectURL(url);
					}, 150);
				};
				a.addEventListener('click', clickHandler, false);
				a.click();
				
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				
				resolve(response);
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
	
	Open: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken, fetch});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		const arc = path.split('.');
		let typeDoc='';
		if (arc[arc.length - 1] === 'pdf') {
			typeDoc = 'application/pdf';
		} else if (arc[arc.length - 1] === 'xml') {
			typeDoc = 'text/plain';
		}
		
		return new Promise((resolve, reject) => {
			dbx.filesDownload({
				path: path
			}).then(function (response) {
				console.log("DROPBOX::: ", response);
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				
				//const url = URL.createObjectURL(response.fileBlob);
				var file = new Blob([response.fileBlob], {type: typeDoc, endings: 'native' });
				var fileURL = URL.createObjectURL(file);
				//
				// var pom = document.createElement('a');
				// var filename = "file.xml";
				// var bb = new Blob([response.fileBlob], {type: typeDoc});
				// pom.setAttribute('href', window.URL.createObjectURL(bb));
				// pom.setAttribute('download', filename);
				// pom.dataset.downloadurl = [typeDoc, pom.download, pom.href].join(':');
				// pom.draggable = true;
				// pom.classList.add('dragout');
				//pom.click();
				//window.open(pom, '', 'width=900, height=600');
				
				window.open(fileURL, '', 'width=900, height=600');
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				
				resolve(response);
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
	
	Delete: (path, contents, mode, setting, time) => {
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken, fetch});
		
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		
		if (!(time > 0)) {
			time = 500;
		}
		
		if (!FieldsJs.Array(setting)) {
			setting = {};
		}
		
		setting.authentication = (setting.authentication === true || setting.authentication === undefined || setting.authentication === null);
		setting.spinner = (setting.spinner === true || setting.spinner === undefined || setting.spinner === null);
		
		
		if (CONFIG.debug) {
			console.log("W E B S E R V I C E   &   D A T A [DROPBOX POST]:\n\n" + path + "\n");
		}
		
		if (setting.spinner) {
			showSpinner('spinner');
		}
		const arc = path.split('.');
		
		return new Promise((resolve, reject) => {
			dbx.filesDelete ({
				path: path
			}).then(function (response) {
				console.log("DROPBOX::: ", response);
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX RESPONSE::" + path + "]:\n", response, false);
				}
				
				resolve(response);
			}).catch(function (error) {
				
				if (setting.spinner) {
					hideSpinner('spinner', time);
				}
				
				if (CONFIG.debug) {
					imprimir("R E S P O N S E [DROPBOX ERROR::" + path + "]:\n", error, false);
				}
				
				reject(error);
			});
		});
	},
};
