import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const TipoRequerimientoService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Requerimiento_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_grupo: form.id_cat_grupo,
			tipo_requerimiento: form.tipo_requerimiento,
			con_costo_adicional: form.con_costo_adicional ? 1 : 0,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Requerimiento_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_tipo_requerimiento: form.id_cat_tipo_requerimiento,
			id_cat_grupo: form.id_cat_grupo,
			tipo_requerimiento: form.tipo_requerimiento,
			con_costo_adicional: form.con_costo_adicional ? 1 : 0,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Requerimiento_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_tipo_requerimiento) => {
		let params = {
			id_cat_tipo_requerimiento: id_cat_tipo_requerimiento
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Tipo_Requerimiento_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
