import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ComprasService} from "../../../../services/ComprasService/ComprasService";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {AddOutlined, CardTravel, Check, DeleteForever, LocalAtm, LocalParking, MonetizationOn, PlaylistAddOutlined} from '@material-ui/icons';
import carrito_de_compra_1 from "../../../../assets/img/icons/carro-de-la-compra-1.svg";
import {withStyles} from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createFilterOptions} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import ModalCotizadorProducto from "./ModalCotizadorProducto";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {CotizadorProductoService} from "../../../../services/CotizadorProductoService/CotizadorProductoService";
import {CotizadorService} from "../../../../services/CotizadorService/CotizadorService";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";


class ModalCotizador extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			id_cotizador: (props.item || '').id_cotizador,
			tipo: props.tipo,
			
			steps: [
				{id: 0, title: 'PRODUCTO', activo: 0},
				{id: 1, title: 'INSUMOS', activo: 0},
				{id: 2, title: 'COSTOS', activo: 0},
				{id: 3, title: 'PRECIOS SUGERIDOS', activo: 0}
			],
			activeStep: '',
			
			lista_cotizador_producto: [],
			insumos: [],
			
			id_cotizador_producto: '',
			select_cotizador_producto: {},
			insumo: '',
			insumo_cantidad: '',
			insumo_unidad_medida: '',
			precio_unitario: '',
			porcentaje: '',
			importe: '',
			costo: '',
			total: '',
			porcentaje_individual: '',
			importe_individual: '',
			porcentaje_mayoreo: '',
			importe_mayoreo: '',
			total_individual: '',
			total_mayoreo: '',
			check_costo: '',
			check_individual: '',
			check_mayoreo: '',
			
			cantidad: '',
			cantidad_individual: '',
			cantidad_mayoreo: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => {
			this.setState({
				[r.name]: r.value
			})
		});
		
		/*let steps = FieldsJs.Copy(this.state.steps);
		setTimeout(() => {
			if (this.state.cantidad_individual && this.state.cantidad_mayoreo) {
				steps[3].activo = 1;
			} else {
				steps[3].activo = 0;
			}
			if (this.state.cantidad) {
				steps[2].activo = 1;
			} else {
				steps[2].activo = 0;
			}
			this.setState({
				steps: steps
			});
		}, 200);*/
		
	};
	
	handleChangeTab = (event, value) => {
		console.log("value", value);
		this.setState({
			tabvalue: value
		});
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		if (this.state.activeStep > 0) {
			let steps = FieldsJs.Copy(this.state.steps);
			steps[this.state.activeStep ? this.state.activeStep:0].activo = 0;
			
			this.setState({
				activeStep: Number(this.state.activeStep) - 1,
				steps: steps
			});
		} else {
			this.closeModal();
		}
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
				resolve(true);
		});
	};
	
	RefreshList = () => {
		this.listaCotizadorProducto();
	};
	
	listaCotizadorProducto = (id_cotizador_producto) => {
		try {
			CotizadorProductoService.Listar().then((response) => {
				let producto='';
				if (id_cotizador_producto > 0) {
					response.data.map((row) => {
						if (Number(id_cotizador_producto) === Number(row.id_cotizador_producto)) {
							producto = row;
						}
					});
				}
				this.setState({
					lista_cotizador_producto: response.data,
					select_cotizador_producto: producto
				});
			}).catch((error) => {
				this.setState({
					lista_cotizador_producto: []
				});
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	save = () => {
		if (Number(this.state.id_cotizador) > 0) {
			CotizadorService.Modificar(this.state).then((response) => {
				this.closeModal();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			if (this.state.activeStep < 3) {
				let steps = FieldsJs.Copy(this.state.steps);
				steps[this.state.activeStep ? this.state.activeStep:0].activo = 1;
				
				this.setState({
					activeStep: Number(this.state.activeStep) + 1,
					steps: steps
				});
			} else {
				try {
					console.log(this.state);
					/*if (!this.state.fecha_compra) {
						throw Object({
							status: false, mensaje: 'Campo requerido: Fecha de compra.'
						})
					}*/
					
					CotizadorService.Agregar(this.state).then((response) => {
						this.closeModal();
						this.props.RefreshList();
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
					
				} catch (e) {
					console.error(e);
					this.props.showSnackBars('error', e.mensaje);
				}
			}
		}
	};
	
	
	add = () => {
		console.log(this.props);
		this.listaCotizadorProducto();
		this.setState({
			id_cotizador: '',
			id_cotizador_producto: '',
			select_cotizador_producto: {},
			insumo: '',
			insumo_cantidad: '',
			insumo_unidad_medida: '',
			precio_unitario: '',
			porcentaje: '',
			importe: '',
			costo: '',
			porcentaje_individual: '',
			importe_individual: '',
			porcentaje_mayoreo: '',
			importe_mayoreo: '',
			total_individual: '',
			total_mayoreo: '',
			check_costo: '1',
			check_individual: '1',
			check_mayoreo: '1',
			
			insumos: [],
			total: '',
			cantidad: '',
			cantidad_individual: '',
			cantidad_mayoreo: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log("EDIT::: ", this.props);
		
		let steps = FieldsJs.Copy(this.state.steps);
		steps.map((row) => {
			row.activo = 1;
		});
		this.listaCotizadorProducto(item.id_cotizador_producto);
		this.setState({
			id_cotizador: item.id_cotizador || '',
			id_cotizador_producto: item.id_cotizador_producto || '',
			//select_cotizador_producto: {},
			insumo: item.insumo || '',
			precio_unitario: item.precio_unitario || '',
			porcentaje: item.porcentaje || '',
			importe: item.importe || '',
			costo: item.costo || '',
			porcentaje_individual: item.porcentaje_individual || '',
			importe_individual: item.importe_individual || '',
			porcentaje_mayoreo: item.porcentaje_mayoreo || '',
			importe_mayoreo: item.importe_mayoreo || '',
			total_individual: item.total_individual || '',
			total_mayoreo: item.total_mayoreo || '',
			check_costo: Number(item.porcentaje) === 1 ? '1':'2',
			check_individual: Number(item.porcentaje_individual) === 1 ? '1':'2',
			check_mayoreo: Number(item.porcentaje_mayoreo) === 1 ? '1':'2',
			
			total: item.total || '',
			cantidad: item.cantidad || '',
			cantidad_individual: item.cantidad_individual || '',
			cantidad_mayoreo: item.cantidad_mayoreo || '',
			activo: (Number(item.activo) === 1),
			
			insumos: item.insumos || '',
			steps: steps,
		});
		
		setTimeout(() => {
			this.porcentajeImporte();
		}, 150);
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		/*this.setState({
			id_cat_sexo: item.id_cat_sexo || '',
			sexo: item.sexo || '',
			abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});*/
		this.open();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	addInsumo = () => {
		console.log();
		
		try {
			let insumos = FieldsJs.Copy(this.state.insumos);
			let steps = FieldsJs.Copy(this.state.steps);
			steps[1].activo = 1;
			let total = 0;
			console.log(this.state);
			if (!this.state.insumo || !this.state.precio_unitario) {
				throw Object({
					status: false, mensaje: !this.state.insumo ? 'Campo requerido: Insumo.' : 'Campo requerido: Precio Unitario.'
				})
			}
			
			insumos.push({insumo: this.state.insumo, cantidad: this.state.insumo_cantidad, unidad_medida: this.state.insumo_unidad_medida, precio_unitario: this.state.precio_unitario});
			
			insumos.map((item) => {
				total = Number(total) + Number(item.precio_unitario)
			});
			
			this.setState({
				insumos: insumos,
				insumo: '',
				insumo_cantidad: '',
				insumo_unidad_medida: '',
				precio_unitario: '',
				total: total,
				steps: steps
			});
			
			this.porcentajeImporte();
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	deleteInsumo = (key) => {
		let insumos = FieldsJs.Copy(this.state.insumos);
		let steps = FieldsJs.Copy(this.state.steps);
		let total = 0;
		insumos.splice(key, 1);
		
		if (insumos.length > 0) {
			insumos.map((item) => {
				total = Number(total) + Number(item.precio_unitario)
			});
		} else {
			steps[1].activo = 0;
		}
		
		this.setState({
			insumos: insumos,
			total: total,
			steps: steps
		});
		
		this.porcentajeImporte();
		
	};
	
	onTagsChange = (e, value) => {
		/*let steps = FieldsJs.Copy(this.state.steps);
		console.log("E.TARGET.VALUE", value, e.value);
		if (value) {
			steps[0].activo = 1;
		} else {
			steps[0].activo = 0;
		}*/
		
		
		this.setState({
			id_cotizador_producto: value ? value.id_cotizador_producto : '',
			select_cotizador_producto: value ? value : '',
			//steps: steps
		});
	};
	
	porcentajeImporte = () => {
		setTimeout(() => {
			let costo = 0;
			let total_individual = 0;
			let total_mayoreo = 0;
			if (Number(this.state.total > 0)) {
				if (this.state.check_costo === '1') {
					costo = Number(this.state.total) - ((Number(this.state.total) * Number(this.state.cantidad)) / 100);
				} else {
					if (Number(this.state.total) < Number(this.state.cantidad)) {
						this.props.showSnackBars('error', "Cantidad de costos, no puede ser mayor al precio unitario.");
					} else {
						costo = this.state.total - this.state.cantidad;
					}
				}
			}
			
			if (Number(this.state.total > 0) && Number(this.state.cantidad_individual > 0)) {
				if (this.state.check_individual === '1') {
					total_individual = Number(this.state.total) + ((Number(this.state.total) * Number(this.state.cantidad_individual)) / 100);
				} else {
					if (Number(this.state.total) < Number(this.state.cantidad_individual)) {
						this.props.showSnackBars('error', "Cantidad de costos, no puede ser mayor al precio unitario.");
					} else {
						total_individual = Number(this.state.total) + Number(this.state.cantidad_individual);
					}
				}
			}
			
			if (Number(this.state.total) > 0 && Number(this.state.cantidad_mayoreo > 0)) {
				if (this.state.check_mayoreo === '1') {
					total_mayoreo = Number(this.state.costo) + ((Number(this.state.costo) * Number(this.state.cantidad_mayoreo)) / 100);
				} else {
					if (Number(this.state.total) < Number(this.state.cantidad_mayoreo)) {
						this.props.showSnackBars('error', "Cantidad de costos, no puede ser mayor al precio unitario.");
					} else {
						total_mayoreo = Number(this.state.costo) + Number(this.state.cantidad_mayoreo);
					}
				}
			}
			
			
			this.setState({
				costo: costo,
				total_individual: total_individual,
				total_mayoreo: total_mayoreo,
			})
		}, 150)
		
	};
	
	
	render() {
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		const carrito = {
			backgroundImage: `url(${carrito_de_compra_1})`,
			height: '30px',
			width: '30px',
		};
		
		const carrito2 = {
			backgroundImage: `url(${carrito_de_compra_1})`,
			height: '30px',
			width: '30px',
			opacity: 0.4
		};
		
		
		function QontoStepIcon(stepper) {
			console.log("QONTOSTEPICON::: ", stepper);
			return (
				<div>
					{stepper.activo ? <Check/> :
						stepper.icon === 1 ? <LocalParking/> :
							stepper.icon === 2 ? <CardTravel/> :
								stepper.icon === 3 ? <MonetizationOn/> : <LocalAtm/>
					}
				</div>
			);
		}
		
		const ColorlibConnector = withStyles({
			alternativeLabel: {
				//top: 22,
			},
			active: {
				'& $line': {
					backgroundImage:
						'linear-gradient( 95deg,rgb(221, 227, 233) 0%,rgb(25, 75, 95) 50%,rgb(0, 75, 255) 100%)',
				},
			},
			completed: {
				'& $line': {
					backgroundImage:
						'linear-gradient( 95deg,rgb(221, 227, 233) 0%,rgb(25, 75, 95) 50%,rgb(0, 75, 255) 100%)',
				},
			},
			line: {
				height: 4,
				border: 0,
				backgroundColor: '#eaeaf0',
				borderRadius: 1,
			},
		})(StepConnector);
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.cotizador_producto,
		});
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogTitle>
						Cotizador
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={0} className={'cotizador_fondo'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'px-10'}>
										<Stepper className={'padding-0-T padding-10-B'} alternativeLabel activeStep={this.state.activeStep} connector={<ColorlibConnector/>}>
											{this.state.steps.map((item, key) => (
												
												<Step key={item.id}>
													{/*<StepLabel>{item.title}</StepLabel>
													<StepContent>
													
													</StepContent>*/}
													{/*<StepLabel StepIconComponent={QontoStepIcon}>{item.title}</StepLabel>*/}
													<StepLabel completed={item.activo}>{item.title}</StepLabel>
												</Step>
											
											))}
										</Stepper>
									</Grid>
									
									{Number(this.state.activeStep) === 0 || this.is_edit() ?
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'} style={{backgroundColor: 'rgb(0, 75, 182, 0.4)', opacity: 0.4, marginTop: '15px'}}>
												<p className={'margin-0 text-black'}>PRODUCTO</p>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-end">
													<Grid item style={{width: '50px'}}>
														<div className={'vertical-inline h-50-px'}>
															<div className={'v-center'}>
																<ModalCotizadorProducto
																	componente={(
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Agregar nuevo producto"
																			children={(
																				<Fab
																					color="secondary"
																					size="small"
																					aria-label="Agregar nuevo producto"
																					children={(
																						<PlaylistAddOutlined/>
																					)}
																				/>
																			)}
																		/>
																	)}
																	id_proveedor={null}
																	tipo={'add'}
																	item={{}}
																	RefreshList={this.RefreshList}
																	showSnackBars={this.props.showSnackBars}
																/>
															</div>
														</div>
													</Grid>
													
													<Grid item style={{width: 'calc(100% - 50px)'}}>
														<Autocomplete
															id="filter-demo"
															className={'margin-0'}
															options={this.state.lista_cotizador_producto}
															getOptionLabel={option => option.cotizador_producto}
															filterOptions={filterOptions}
															onChange={this.onTagsChange}
															name="select_cotizador_producto"
															renderInput={params =>
																<TextField
																	{...params}
																	label="Producto"
																	fullWidth
																	margin="dense"
																	disabled={this.state.tipo === 'view'}
																	variant="outlined"/>
															}
															value={this.state.select_cotizador_producto || null}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Fragment>
										: null }
									
									
									{Number(this.state.activeStep) === 1 || this.is_edit() ?
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'} style={{backgroundColor: 'rgb(254, 78, 29, 0.4)', opacity: 0.4, marginTop: '15px'}}>
												<p className={'margin-0 text-black'}>INSUMOS</p>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-end">
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														
														<TextField
															margin="none"
															name="insumo"
															label="Insumo"
															type="text"
															fullWidth
															value={this.state.insumo}
															onChange={this.handleChange}
															required={true}
															disabled={this.is_view()}
														/>
													</Grid>
													
													<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
														
														<TextField
															margin="none"
															name="insumo_cantidad"
															label="Cantidad"
															type="text"
															fullWidth
															value={this.state.insumo_cantidad}
															onChange={this.handleChange}
															required={true}
															disabled={this.is_view()}
														/>
													</Grid>
													
													<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
														
														<TextField
															margin="none"
															name="insumo_unidad_medida"
															label="Unidad Medida"
															type="text"
															fullWidth
															value={this.state.insumo_unidad_medida}
															onChange={this.handleChange}
															required={true}
															disabled={this.is_view()}
														/>
													</Grid>
													
													<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
														
														<TextField
															margin="none"
															name="precio_unitario"
															label="Precio Unitario"
															type="text"
															fullWidth
															value={this.state.precio_unitario}
															onChange={this.handleChange}
															required={true}
															disabled={this.is_view()}
														/>
													</Grid>
													
													<Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
														
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Agregar Insumo"
															onClick={this.addInsumo}
															children={(
																<Fab
																	color="primary"
																	size="small"
																	aria-label="Agregar Insumo"
																	children={(
																		<AddOutlined/>
																	)}
																/>
															)}
														/>
													</Grid>
													
													{this.state.insumos.length > 0 || this.is_edit() ? (
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<div className={'w-100 vertical-inline'}>
																<div className={'v-center w-60'}>
																	<p className={'margin-0 px-12'}>Insumo</p>
																</div>
																<div className={'v-center w-15'}>
																	<p className={'margin-0 px-12'}>Cantidad</p>
																</div>
																<div className={'v-center w-20'}>
																	<p className={'margin-0 px-12'}>Precio Unitario</p>
																</div>
															</div>
															
															{this.state.insumos.map((item, key) => (
																<Fragment>
																	<div className={'w-100 vertical-inline'}>
																		<div className={'v-center w-5'}>
																			<DeleteForever
																				className={'cursor-pointer'}
																				onClick={() => this.deleteInsumo(key)}
																				color="secondary"
																			/>
																		</div>
																		<div className={'v-center w-60'}>
																			<p className={'margin-0'}>{item.insumo}</p>
																		</div>
																		<div className={'v-center w-15'}>
																			<span>{item.cantidad}</span>
																		</div>
																		<div className={'v-center w-20'}>
																			<span>${FieldsJs.Currency(item.precio_unitario)}</span>
																		</div>
																	</div>
																	<Divider/>
																</Fragment>
															))}
														</Grid>
													) : null}
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<p className={'margin-0'}>Total: <span style={{fontWeight: 'bold'}}>${FieldsJs.Currency(this.state.total)}</span></p>
													</Grid>
												</Grid>
											</Grid>
										</Fragment>
										: null }
									
									{Number(this.state.activeStep) === 2 || this.is_edit() ?
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'} style={{backgroundColor: 'rgb(254, 230, 43, 0.4)', opacity: 0.4, marginTop: '15px'}}>
												<p className={'margin-0 text-black'}>COSTOS</p>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-end">
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<p style={{fontWeight: 'bold'}} className={'px-14 text-center margin-0-L margin-0-R margin-5-T margin-0-B'}>Descuento por mayoreo</p>
													</Grid>
													
													
													<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
														
														<RadioGroup row aria-label="position"
														            name="check_costo"
														            value={this.state.check_costo}
														            onChange={(e) => {
															            this.handleChange(e);
															            this.porcentajeImporte();
														            }}>
															<FormControlLabel
																className={'px-12'}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value="1"
																label="Porcentaje"
																// disabled={disabledInputVenta}
															/>
															<FormControlLabel
																className={'px-12'}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value="2"
																label="Importe"
																// disabled={disabledInputVenta}
															/>
														</RadioGroup>
													
													</Grid>
													
													<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
														<TextField
															margin="none"
															name="cantidad"
															label="Cantidad"
															type="text"
															value={this.state.cantidad}
															onChange={(e) => {
																this.handleChange(e);
																this.porcentajeImporte();
															}}
															onKeyPress={EnteroSolo}
															required={true}
															disabled={this.is_view()}
														/>
														
														{/*{this.state.check_costo === '1' ?
													<TextField
														margin="none"
														name="porcentaje"
														label="Porcentaje"
														type="text"
														fullWidth
														value={this.state.porcentaje}
														onChange={this.handleChange}
														required={true}
														disabled={this.is_view()}
													/>
													:
													<TextField
														margin="none"
														name="importe"
														label="Importe"
														type="text"
														fullWidth
														value={this.state.importe}
														onChange={this.handleChange}
														required={true}
														disabled={this.is_view()}
													/>
												}*/}
													
													</Grid>
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<p className={'margin-0'}>Costo Unitario: <span style={{fontWeight: 'bold'}}>${FieldsJs.Currency(this.state.total)}</span></p>
													</Grid>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<p className={'margin-0'}>Costo Mayoreo: <span style={{fontWeight: 'bold'}}>${FieldsJs.Currency(this.state.costo)}</span></p>
													</Grid>
												</Grid>
											</Grid>
										
										</Fragment>
										: null }
									
										{Number(this.state.activeStep) === 3 || this.is_edit() ?
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'} style={{backgroundColor: 'rgb(0, 97, 65, 0.4)', opacity: 0.4, marginTop: '15px'}}>
												<p className={'margin-0 text-black'}>PRECIOS SUGERIDOS</p>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-end">
													<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
														
														<RadioGroup row aria-label="position"
														            name="check_individual"
														            value={this.state.check_individual}
														            onChange={(e) => {
															            this.handleChange(e);
															            this.porcentajeImporte();
														            }}>
															<FormControlLabel
																className={'px-12'}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value="1"
																label="Porcentaje Individual"
																// disabled={disabledInputVenta}
															/>
															<FormControlLabel
																className={'px-12'}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value="2"
																label="Importe Individual"
																// disabled={disabledInputVenta}
															/>
														</RadioGroup>
													
													</Grid>
													
													<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
														
														<TextField
															margin="none"
															name="cantidad_individual"
															label="Cantidad"
															type="text"
															value={this.state.cantidad_individual}
															onChange={(e) => {
																this.handleChange(e);
																this.porcentajeImporte();
															}}
															onKeyPress={EnteroSolo}
															required={true}
															disabled={this.is_view()}
														/>
													
													</Grid>
													
													<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
														
														<RadioGroup row aria-label="position"
														            name="check_mayoreo"
														            value={this.state.check_mayoreo}
														            onChange={(e) => {
															            this.handleChange(e);
															            this.porcentajeImporte();
														            }}>
															<FormControlLabel
																className={'px-12'}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value="1"
																label="Porcentaje Mayoreo"
																// disabled={disabledInputVenta}
															/>
															<FormControlLabel
																className={'px-12'}
																labelPlacement="end"
																control={<Radio color="primary"/>}
																value="2"
																label="Importe Mayoreo"
																// disabled={disabledInputVenta}
															/>
														</RadioGroup>
													
													</Grid>
													
													<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
														
														<TextField
															margin="none"
															name="cantidad_mayoreo"
															label="Cantidad"
															type="text"
															value={this.state.cantidad_mayoreo}
															onChange={(e) => {
																this.handleChange(e);
																this.porcentajeImporte();
															}}
															onKeyPress={EnteroSolo}
															required={true}
															disabled={this.is_view()}
														/>
													
													</Grid>
													
													
													{/*<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												
												<TextField
													margin="none"
													name="porcentaje"
													label="Porcentaje Individual"
													type="text"
													fullWidth
													value={this.state.porcentaje_individual}
													onChange={this.handleChange}
													required={true}
													disabled={this.is_view()}
												/>
											</Grid>
											
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												
												<TextField
													margin="none"
													name="importe"
													label="Importe Individual"
													type="text"
													fullWidth
													value={this.state.importe_individual}
													onChange={this.handleChange}
													required={true}
													disabled={this.is_view()}
												/>
											</Grid>
											
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												
												<TextField
													margin="none"
													name="porcentaje"
													label="Porcentaje Mayoreo"
													type="text"
													fullWidth
													value={this.state.porcentaje_mayoreo}
													onChange={this.handleChange}
													required={true}
													disabled={this.is_view()}
												/>
											</Grid>
											
											<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
												
												<TextField
													margin="none"
													name="importe"
													label="Importe Mayoreo"
													type="text"
													fullWidth
													value={this.state.importe_mayoreo}
													onChange={this.handleChange}
													required={true}
													disabled={this.is_view()}
												/>
											</Grid>*/}
													
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<p className={'margin-0'}>Precio Individual: <span style={{fontWeight: 'bold'}}>${FieldsJs.Currency(this.state.total_individual)}</span></p>
													</Grid>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<p className={'margin-0'}>Precio Mayoreo: <span style={{fontWeight: 'bold'}}>${FieldsJs.Currency(this.state.total_mayoreo)}</span></p>
													</Grid>
												
												</Grid>
											</Grid>
										</Fragment>
										: null }
									
									
									
									
								</Grid>
							</Grid>
							
							{/*<Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<TabContext value={this.state.tabvalue.toString()}>
											<Tabs value={this.state.tabvalue}
											      onChange={this.handleChangeTab}
											      variant="fullWidth"
											      indicatorColor="secondary"
											      textColor="secondary"
											      aria-label="icon label tabs example"
											>
												<Tab icon={<Phone/>} label="PRODUCTO" value="0"/>
												<Tab icon={<Favorite/>} label="INSUMOS" value="1"/>
												<Tab icon={<PersonPin/>} label="COSTOS " value="2"/>
												<Tab icon={<AcUnit/>} label="PRECIOS SUGERIDOS" value="3"/>
											</Tabs>
											
											<Typography id="demo-a11y-tabs-manual-label">
												Algun texto de intro para cada tipo
											</Typography>
											
											<TabPanel value="0">
												Los productos tipo 1
											</TabPanel>
											<TabPanel value="1">
												Los productos tipo 2
											</TabPanel>
											<TabPanel value="2">
												Los productos tipo 3
											</TabPanel>
											<TabPanel value="3">
												Los productos tipo 4
											</TabPanel>
										</TabContext>
									</Grid>
									
									
									<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
										
										<TextField
											margin="none"
											name="quien_recibio"
											label="Quien recibio"
											type="text"
											fullWidth
											value={this.state.quien_recibio}
											onChange={this.handleChange}
											required={true}
											disabled={this.is_view()}
										/>
									</Grid>
									
									<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
										<TextField
											select
											fullWidth
											margin="none"
											onChange={this.handleChange}
											disabled={this.is_view()}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											
											name="id_cat_tipo_comprobante"
											label="Tipo de comprobante"
											value={this.state.id_cat_tipo_comprobante}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.lista_cat_tipo_comprobante.map(option => (
										<option key={option.id_cat_tipo_comprobante}
										        value={option.id_cat_tipo_comprobante}>
											{option.tipo_comprobante}
										</option>
									))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>*/}
						
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									{!this.state.activeStep ? 'Cerrar' : 'Anterior'}
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								{!this.is_view() ? (
									<Button onClick={() => this.save()} color="primary">
										{this.is_edit() > 0 ? 'Actualizar' : this.state.activeStep < 3 && !this.is_edit()? 'Siguiente' : 'Guardar'}
									</Button>
								) : null}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCotizador.propTypes = {
	id_compra: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.oneOf([null]).isRequired,
	]),
	item: PropTypes.object.isRequired,
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCotizador;
