import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../settings/General/General";

export const CotizadorService = {
	Listar: (filtro, paginacion) => {
				let params = {
			filtro: {
				producto: filtro.producto || null,
				descripcion: filtro.descripcion || null,
				costo_unitario: filtro.costo_unitario || null,
				precio_individual: filtro.precio_individual || null,
				costo_mayoreo: filtro.costo_mayoreo || null,
				precio_mayoreo: filtro.precio_mayoreo || null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cotizador: form.id_cotizador,
			id_cotizador_producto: form.id_cotizador_producto,
			cantidad: form.cantidad,
			cantidad_individual: form.cantidad_individual,
			cantidad_mayoreo: form.cantidad_mayoreo,
			check_costo: form.check_costo,
			check_individual: form.check_individual,
			check_mayoreo: form.check_mayoreo,
			insumos: form.insumos,
			costo: form.costo,
			total: form.total,
			total_individual: form.total_individual,
			total_mayoreo: form.total_mayoreo,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarArchivo: (form) => {
		let params = {
			id_cotizador: form.id_cotizador,
			id_cotizador_archivo: form.id_cotizador_archivo,
			cotizador_archivo: form.cotizador_archivo,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Guardar_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cotizador: form.id_cotizador,
			id_cotizador_producto: form.id_cotizador_producto,
			cantidad: form.cantidad,
			cantidad_individual: form.cantidad_individual,
			cantidad_mayoreo: form.cantidad_mayoreo,
			check_costo: form.check_costo,
			check_individual: form.check_individual,
			check_mayoreo: form.check_mayoreo,
			insumos: form.insumos,
			costo: form.costo,
			total: form.total,
			total_individual: form.total_individual,
			total_mayoreo: form.total_mayoreo,
			activo: form.activo ? 1 : 0,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cotizador) => {
		let params = {
			id_cotizador: id_cotizador
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizador_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Nube: (data) => {
		/*let params = {
			files: form.archivo
		};
		console.log("RESPONSE::", params);*/
		console.log("RESPONSE::", data);
		
		return new Promise((resolve, reject) => {
			HttpRequest.dropboxUpload('_Cotizador_Guardar_Nube', data).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
