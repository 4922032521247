import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {DateRangeOutlined, PersonOutlined, SearchOutlined, GroupOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";

import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {CatService} from "../../../../services/_Cat/CatService/CatService";


class FiltrosPreCotizacion extends Component {
	state = {};
	
	constructor() {
		super();
		this.state = {
			folio: '',
			numero_cotizacion: '',
			numero_pedido: '',
			cliente: '',
			fecha_alta_inicio: null,
			fecha_alta_fin: null,
			fecha_entrega_inicio: null,
			fecha_entrega_fin: null,
			status_pago: 'all',
			status_proceso: 'all',
			status_tipo_pedido: 'all',
			id_usuario_disenador: '',
			id_cat_grupo: '',
			ordenar_fecha_alta: false,
			ordenar_fecha_entrega: false,
			
			filtro_cat_usuario_disenador: [],
			filtro_cat_grupo: [],
		};
		
		this.ListaUsuarioDisenador();
		this.ListaGrupo();
		
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.folio = this.state.folio || undefined;
		filtro.numero_cotizacion = this.state.numero_cotizacion || undefined;
		filtro.numero_pedido = this.state.numero_pedido || undefined;
		filtro.cliente = this.state.cliente || undefined;
		filtro.fecha_alta_inicio = this.state.fecha_alta_inicio && this.state.fecha_alta_fin ? this.state.fecha_alta_inicio : undefined;
		filtro.fecha_alta_fin = this.state.fecha_alta_inicio && this.state.fecha_alta_fin ? this.state.fecha_alta_fin : undefined;
		filtro.fecha_entrega_inicio = this.state.fecha_entrega_inicio && this.state.fecha_entrega_fin ? this.state.fecha_entrega_inicio : undefined;
		filtro.fecha_entrega_fin = this.state.fecha_entrega_inicio && this.state.fecha_entrega_fin ? this.state.fecha_entrega_fin : undefined;
		filtro.status_pago = this.state.status_pago || undefined;
		filtro.status_proceso = this.state.status_proceso || undefined;
		filtro.status_tipo_pedido = this.state.status_tipo_pedido || undefined;
		filtro.id_usuario_disenador = this.state.id_usuario_disenador || undefined;
		filtro.id_cat_grupo = this.state.id_cat_grupo || undefined;
		filtro.ordenar_fecha_alta = this.state.ordenar_fecha_alta ? 1 : undefined;
		filtro.ordenar_fecha_entrega = this.state.ordenar_fecha_entrega ? 1 : undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	ListaUsuarioDisenador = () => {
		CatService.ListUsuarioDisenador().then((cat) => {
			this.setState({
				filtro_cat_usuario_disenador: cat.data
			})
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListaGrupo = () => {
		CatService.ListGrupo().then((cat) => {
			this.setState({
				filtro_cat_grupo: cat.data
			})
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	render() {
		return (
			<Fragment>
				
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					<Grid item className={'text-left'} xs={6} sm={6} md={1} lg={1} xl={1}>
						<TextField
							type={'text'}
							fullWidth
							name="folio"
							onChange={this.handleChange}
							label="Folio"
							autoComplete={'off'}
							value={this.state.folio}
						/>
					</Grid>
					{this.props.tipo_vista === "cotizacion" ? (
						<Grid item className={'text-left'} xs={6} sm={6} md={1} lg={1} xl={1}>
							<TextField
								type={'text'}
								fullWidth
								name="numero_cotizacion"
								onChange={this.handleChange}
								label="No. cotización"
								autoComplete={'off'}
								value={this.state.numero_cotizacion}
							/>
						</Grid>
					) : (
						<Grid item className={'text-left'} xs={6} sm={6} md={1} lg={1} xl={1}>
							<TextField
								type={'text'}
								fullWidth
								name="numero_pedido"
								onChange={this.handleChange}
								label="No. orden"
								autoComplete={'off'}
								value={this.state.numero_pedido}
							/>
						</Grid>
					)}
					
					<Grid item className={'text-left'} xs={6} sm={6} md={1} lg={1} xl={1}>
						<TextField
							select
							fullWidth
							onChange={this.handleChange}
							SelectProps={{
								native: true,
								MenuProps: {},
							}}
							
							name="id_cat_grupo"
							label="Grupo"
							value={this.state.id_cat_grupo}
						>
							<option key={0} value={''}>&nbsp;</option>
							{this.state.filtro_cat_grupo.map(option => (
								<option key={option.id_cat_grupo} value={option.id_cat_grupo}>
									{option.grupo}
								</option>
							))}
						</TextField>
					</Grid>
					
					<Grid item className={'text-left'} xs={6} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="cliente"
									onChange={this.handleChange}
									label="Cliente"
									autoComplete={'off'}
									value={this.state.cliente}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha de alta"
									value={(this.state.fecha_alta_inicio && this.state.fecha_alta_fin) ? (DateFormat.FormatText(this.state.fecha_alta_inicio) + " al " + DateFormat.FormatText(this.state.fecha_alta_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha de alta">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_alta_inicio}
							endDate={this.state.fecha_alta_fin}
							onChange={(range) => {
								this.setState({
									fecha_alta_inicio: range.startDate,
									fecha_alta_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha de entrega"
									value={(this.state.fecha_entrega_inicio && this.state.fecha_entrega_fin) ? (DateFormat.FormatText(this.state.fecha_entrega_inicio) + " al " + DateFormat.FormatText(this.state.fecha_entrega_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha de entrega">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_entrega_inicio}
							endDate={this.state.fecha_entrega_fin}
							onChange={(range) => {
								this.setState({
									fecha_entrega_inicio: range.startDate,
									fecha_entrega_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<TextField
							select
							fullWidth
							onChange={this.handleChange}
							SelectProps={{
								native: true,
								MenuProps: {},
							}}
							
							name="id_usuario_disenador"
							label="Diseñador asignado"
							value={this.state.id_usuario_disenador}
							disabled={this.props.tipo_vista === "cotizacion"}
						>
							<option key={0} value={''}>&nbsp;</option>
							{this.state.filtro_cat_usuario_disenador.map(option => (
								<option key={option.id_usuario} value={option.id_usuario}>
									{option.nombre} {option.apellido_paterno} {option.apellido_materno}
								</option>
							))}
						</TextField>
					</Grid>
					
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<TextField
							select
							fullWidth
							onChange={this.handleChange}
							SelectProps={{
								native: true,
								MenuProps: {},
							}}
							
							name="status_pago"
							label="Estatus de pagos"
							value={this.state.status_pago}
							disabled={this.props.tipo_vista === "cotizacion"}
						>
							<option value={'all'} defaultValue>{'Todos'}</option>
							<option value={1}>{'Ningún pago'}</option>
							<option value={2}>{'Anticipo < a 50%'}</option>
							<option value={3}>{'Anticipo >= a 50%'}</option>
							<option value={4}>{'Pagado'}</option>
							<option value={'por_pagar'}>{'Por pagar'}</option>
						
						</TextField>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<TextField
							select
							fullWidth
							onChange={this.handleChange}
							SelectProps={{
								native: true,
								MenuProps: {},
							}}
							
							name="status_proceso"
							label="Estatus proceso"
							value={this.state.status_proceso}
							disabled={this.props.tipo_vista === "cotizacion"}
						>
							<option value={'all'} defaultValue>{'Todos'}</option>
							<option value={1}>{'Orden creado'}</option>
							<option value={2}>{'Diseñador asignado'}</option>
							<option value={3}>{'En producción'}</option>
							<option value={4}>{'En almacén'}</option>
							<option value={5}>{'Entregado'}</option>
							<option value={'todos_excepto_entregados'}>{'Todos excepto entregados'}</option>
						
						</TextField>
					</Grid>
					
					<Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
						<TextField
							select
							fullWidth
							onChange={this.handleChange}
							SelectProps={{
								native: true,
								MenuProps: {},
							}}
							
							name="status_tipo_pedido"
							label="Tipo de pedido"
							value={this.state.status_tipo_pedido}
							disabled={this.props.tipo_vista === "cotizacion"}
						>
							<option value={'all'} defaultValue>Todos</option>
							<option value={'all_excepto_interno'}>Todos excepto internos</option>
							<option value={'nota_sencilla'}>Nota sencilla</option>
							<option value={'factura'}>Solicitud de factura</option>
							<option value={'interno'}>Interno</option>
							<option value={'cotizacion'}>Cotización inicial</option>
						</TextField>
					</Grid>
					
					<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
						
						<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
							
							<Grid item xs={6} sm={6} md={5} lg={5} xl={5} align={'left'}>
								<FormControlLabel
									control={
										<Checkbox
											type="checkbox" name='ordenar_fecha_alta'
											checked={this.state.ordenar_fecha_alta}
											onChange={this.handleChange} value="activo" color="primary"
										/>
									}
									label={'Ordenar por fecha de alta'}
								/>
							</Grid>
							
							<Grid item xs={6} sm={6} md={5} lg={5} xl={5} align={'left'}>
								<FormControlLabel
									control={
										<Checkbox
											type="checkbox" name='ordenar_fecha_entrega'
											checked={this.state.ordenar_fecha_entrega}
											onChange={this.handleChange} value="activo" color="primary"
										/>
									}
									label={'Ordenar por fecha de entrega'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2} align={'right'}>
								<Fab variant="extended" size="small" color="default" aria-label="Add"
								     onClick={this.AplicarFiltro}>
									<SearchOutlined className={'margin-5-R px-14'}/> Buscar
								</Fab>
							</Grid>
							
						</Grid>
					
					</Grid>
				
				</Grid>
			
			</Fragment>
		);
	}
}

FiltrosPreCotizacion.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	tipo_vista: PropTypes.string.isRequired,
};

export default FiltrosPreCotizacion;
