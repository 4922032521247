import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SexoService} from "../../../../services/_Cat/SexoService/SexoService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import Typography from "@material-ui/core/Typography";
import {DeleteOutlined} from "@material-ui/icons";
import {InventarioService} from "../../../../services/InventarioService/InventarioService";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {VentasService} from "../../../../services/VentasService/VentasService";

class ModalInsumos extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: props.open || false
			},
			
			tipo: props.tipo,
			
			id_compra_producto: (props.item || {}).id_compra_producto,
			id_unidad_medida: '',
			cantidad: '',
			ordene_venta: '',
			ordenes_venta: [],
			
			lista_insumos: [],
			lista_cat_unidad_medida: [],
			
			paginacion: {},
			
			activo: true,
		};
		
		if (props.open) {
			this.add();
		}
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.props.selectCloseModal();
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		this.ListarInsumos();
		this.ListUnidadMedida();
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	
	ListarInsumos = () => {
		InventarioService.ListarAll().then((response) => {
			
			this.setState({
				lista_insumos: response.data,
			});
			
		}).catch((error) => {
			
			this.setState({lista_insumos: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	ListUnidadMedida = () => {
		CatService.ListUnidadMedida().then((response) => {
			
			this.setState({
				lista_cat_unidad_medida: response.data,
			});
			
		}).catch((error) => {
			
			this.setState({lista_cat_unidad_medida: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	save = () => {
		VentasService.AgregarInsumo(this.state).then((response) => {
			this.close();
			this.props.RefreshList();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		console.log(this.props);
		const {item} = this.props;
		let ordenprincipal = [item.numero_pedido];
		this.setState({
			id_compra_producto: '',
			id_unidad_medida: '',
			cantidad: '',
			ordenes_venta: ordenprincipal,
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_compra_producto: '',
			id_unidad_medida: '',
			cantidad: '',
			ordenes_venta: [],
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_compra_producto: '',
			id_unidad_medida: '',
			cantidad: '',
			ordenes_venta: [],
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edit = () => {
		return this.props.tipo === 'edit';
	};
	
	is_add = () => {
		return this.props.tipo === 'add';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	disabledInputVentaEditar = (key) => {
		if (key > 0) {
			return true;
		} else {
			return false;
		}
	};
	
	eliminar_producto_atributo = (key) => {
		let ordenes = FieldsJs.Copy(this.state.ordenes_venta);
		ordenes.splice(key, 1);
		this.setState({
			ordenes_venta: ordenes
		});
	};
	
	addOrdenVenta = (e) => {
		if (e.keyCode == 13) {
			let ordenes = FieldsJs.Copy(this.state.ordenes_venta);
			ordenes.push(this.state.orden_venta);
			this.setState({
				ordenes_venta: ordenes,
				orden_venta: ''
			});
		}
	};
	
	obtenerCantidadCuadrada = () => {
		let list = FieldsJs.Copy(this.state.lista_insumos);
		let cantidad = this.state.cantidad || 0;
		for (let i = 0; i < list.length; i++) {
			if (Number(list[i].id_compra) === Number(this.state.id_compra)) {
				cantidad = cantidad * list[i].medida_ancho_compra;
			}
		}
		return cantidad;
	};
	
	render() {
		
		/*let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}*/
		
		return (
			<div>
				
				{/*{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}*/}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Insumo'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} className={'borde-punteado-gray-1'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									disabled={this.is_view()}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_compra_producto"
									label="Selecciona Insumo"
									value={this.state.id_compra_producto}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_insumos.map(option => (
										<option key={option.id_compra_producto}
										        value={option.id_compra_producto}>
											{option.numero_compra + ' - ' + option.grupo_compra + ' - ' + option.tipo_compra + ' - ' + option.medida_largo_compra + ' | ' + option.medida_ancho_compra}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									disabled={this.is_view()}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_unidad_medida"
									label="U. Medida"
									value={this.state.id_cat_unidad_medida}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.lista_cat_unidad_medida.map(option => (
										<option key={option.id_cat_unidad_medida}
										        value={option.id_cat_unidad_medida}>
											{option.unidad_medida}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
								<TextField
									margin="none"
									name="cantidad"
									label="Cantidad en metros"
									type="text"
									fullWidth
									value={this.state.cantidad}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
									inputProps={{maxLength: 5}}
									required={true}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
								<TextField
									margin="none"
									name="orden_venta"
									label="Otras Órdenes de Venta"
									type="text"
									fullWidth
									value={this.state.orden_venta}
									onChange={this.handleChange}
									onKeyDown={this.addOrdenVenta}
								/>
							</Grid>
							
							{this.state.id_cat_unidad_medida === '1' ?
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography variant={'h5'} className={'px-13 text-left'}>
										La cantidad de m² es: {this.obtenerCantidadCuadrada()}
									</Typography>
								</Grid>
								: null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography variant={'h5'} className={'px-13 text-left'}>
									Ordene(s) de venta:
								</Typography>
								{(this.state.ordenes_venta || []).map((itemTR, key) => (
									<div key={key} className={'vertical-inline b-r-20 margin-5 bg-danger'}>
										<div className={'vertical-inline px-14 padding-3 padding-10-L padding-10-R v-center text-white'}>
											{itemTR || 'N/A'}
										</div>
										<div className={'vertical-inline padding-3 padding-10-R v-center text-white'}>
											{this.disabledInputVentaEditar(key) ? (
												<DeleteOutlined className={'px-16 cursor-pointer'} onClick={() => this.eliminar_producto_atributo(key)}/>
											) : ''}
										</div>
									</div>
								))}
							</Grid>
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>*/}
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_venta_insumo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalInsumos.propTypes = {
	componente: PropTypes.element,
	open: PropTypes.bool,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func,
	showSnackBars: PropTypes.func.isRequired,
	selectCloseModal: PropTypes.func.isRequired,
};

export default ModalInsumos;
