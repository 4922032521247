import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {DropzoneArea} from 'material-ui-dropzone';
import {CONFIG} from "../../../../settings/Config/Config";
import {Dropbox} from 'dropbox';
import {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CargaCFDIService} from "../../../../services/CargaCFDIService/CargaCFDIService";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";


class ModalCargaMasivoPDFCFDI extends Component {
	
	state = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			id_venta: (props.item || '').id_venta,
			files: [],
			
			archivos: [],
			
			listar_cfdi: [],
			
			jsonXML: [],
			jsonXMLError: [],
			
			pdf: false,
			
			response_content: {
				correctos: [],
				repetidos: [],
				erroneos: [],
				faltantes: []
			}
			
		};
	}
	
	handleChange(files) {
		
		this.setState({
			files: files[0]
		});
	}
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		});
		
		let xmls = [];
		let xmlerror = [];
		
		if (files.length > 0) {
			showSpinner('spinner');
			
			for (let f = 0; f < files.length; f++) {
				xmls.push(files[f].name);
				
				if (f >= (files.length - 1)) {
					console.log("JSONXML :::: ", xmls);
					hideSpinner('spinner');
				}
			}
			this.setState({
				jsonXML: xmls
			});
		}
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
			pdf: false
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			//this.ListaCFDI();
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	guardarDocTimbradoDbx = () => {
		let ftar = this.state.jsonXML;
		console.log("1 FTAR::: ", ftar);
		
		const accessToken = CONFIG.DROPBOX_TOKEN;
		const dbx = new Dropbox({accessToken, fetch});
		let files;
		let ruta;
		
		return new Promise((resolve, reject) => {
			let band = 0;
			let band2 = 0;
			band = ftar.length;
			
			for (let i = 0; i < ftar.length; i++) {
				console.log("2 FTAR::: ", ftar);
				files = ftar[i].file;
				ruta = ftar[i].ruta + ftar[i].file.name;
				
				console.log("RUTA:::", ruta);
				
				let displayDropbox = new Promise((resol, reje) => {
					DropboxApi.Upload(ruta, files).then(function (response) {
						
						console.log("DROPBOX UPLOAD RESPONSE: ", response, band, (i + 1));
						
						resol(response.path_display);
						
					}).catch(function (error) {
						console.log("DROPBOX UPLOAD ERROR: ", error);
						reje(null);
					});
					
				});
				
				displayDropbox.then((response) => {
					this.state.jsonXML[i].archivo_pdf = response;
					band2++;
					if (band2 >= band) {
						resolve(true);
						//hideSpinner('spinner');
					}
				}).catch((error) => {
					band2++;
					this.state.jsonXML[i].archivo_pdf = null;
					if (band2 >= band) {
						resolve(true);
						//hideSpinner('spinner');
					}
				});
				
			}
		});
	};
	
	save = () => {
		console.log("THIS.STATE: ", this.state);
		
		try {
			
			if (this.state.files.length <= 0 || this.state.jsonXML.length <= 0) {
				throw Object({
					status: false, mensaje: 'Agrega un archivo.'
				})
			}
			
			if (this.state.pdf) {
				let guardar = new Promise((resolve, reject) => {
					showSpinner('spinner');
					
					this.guardarDocTimbradoDbx().then(response => {
						console.log("GUARDARFICHATECNICAARCHIVOELABORACIONPROPUESTADBX::: ", response);
						this.setState({
							archivos: response
						});
						resolve(response);
						console.log("DROPBOX UPLOAD: ", this.state);
						
					}).catch(error => {
						reject(error);
					})
				});
				
				guardar.then(response => {
					hideSpinner('spinner');
					console.log("ENTRO AQUIIIIIIIII");
					CargaCFDIService.GuardarPDF(this.state).then((response) => {
						this.setState({
							response_content: response.data.data,
							pdf: true
						});
						if (response.data.success) {
							this.closeModal();
							this.props.RefreshList();
						}
						this.props.showSnackBars('success', response.mensaje);
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				})
			} else {
				CargaCFDIService.GuardarPDF(this.state).then((response) => {
					this.setState({
						response_content: response.data.data,
						pdf: true
					});
					
					this.pdfCorrectos(response.data.correctos);
					console.log("CARGACFDISERVICE.GUARDARPDF::: ", response);
					//this.closeModal();
					//this.props.RefreshList();
					//this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	pdfCorrectos = (pdfc) => {
		showSpinner('spinner');
		
		let archivos = [];
		for (let i = 0; i < this.state.files.length; i++) {
			for (let j = 0; j < pdfc.length; j++) {
				if (pdfc[j].archivo_pdf === this.state.files[i].name) {
					archivos.push({id_facturacion_cfdi: pdfc[j].id_facturacion_cfdi, ruta: pdfc[j].ruta, file: this.state.files[i], name: this.state.files[i].name});
				}
			}
			if (i >= (this.state.files.length - 1)) {
				hideSpinner('spinner');
			}
		}
		this.setState({
			jsonXML: archivos
		});
		
		console.log("THIS.STATE.JSONXML::: ", archivos, this.state.jsonXML);
	};
	
	add = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: true,
			jsonXML: '',
			id_venta: item.id_venta || ''
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			//abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	onTagsChange = (event, value) => {
		let select = FieldsJs.Copy(value);
		
		if (select) {
			try {
				
				this.setState({
					select_cliente: select,
					nombre_comercial: select.nombre_comercial,
					razon_social: select.razon_social,
					rfc: select.rfc,
					correo_electronico: select.correo_electronico,
					telefono: select.telefono,
					id_cat_giro_cliente: select.id_cat_giro_cliente,
				}, () => console.log("STATE", select, this.state));
				
			} catch (error) {
				this.showSnackBars('error', error.mensaje);
			}
		} else {
			try {
				
				this.setState({
					select_cliente: '',
					nombre_comercial: '',
					razon_social: '',
					rfc: '',
					correo_electronico: '',
					telefono: '',
					id_cat_giro_cliente: '',
				});
				
			} catch (error) {
				this.showSnackBars('error', error.mensaje);
			}
		}
	};
	
	render() {
		
		const filterOptions = createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.rfc,
		});
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Carga de CFDI´s'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							{!this.state.pdf ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Typography className={'margin-15-B'} style={{color: 'gray', opacity: '0.8'}}>
											Selecciona los archivos PDF´s que desea registrar (Debe coincidir con los nombres de los archivos XML´s).
										</Typography>
									</Grid>
									
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<DropzoneArea
											onChange={this.handleChangeDropzoneArea.bind(this)}
											open={this.state.open}
											filesLimit={100}
											dropzoneText={'Arrastre y suelte un archivo aquí o haga clic'}
											acceptedFiles={['application/pdf']}
											showPreviews={false}
											maxFileSize={5000000}
											showAlerts={false}
											showFileNames={true}
											showFileNamesInPreview={true}
										/>
									</Grid>
									
									{this.state.jsonXMLError.length > 0 ? (
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#901C30', fontSize: 'bold'}}>
													PDF´s no válidos:
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Table className={'desing-acresco'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'}>Archivo</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														
														{this.state.jsonXMLError.map((item, key) => (
															<Fragment key={key}>
																<TableRow>
																	<TableCell align={'left'}>{item.nombre_archivo}</TableCell>
																</TableRow>
															</Fragment>
														))
														}
													</TableBody>
												</Table>
											</Grid>
										</Fragment>
									) : null}
								
								</Fragment>
							) : (
								<Fragment>
									{this.state.response_content.faltantes.length === 0 ? (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<div className={'vertical-inline w-100'}>
												<Typography className={'v-center px-14 text-center bg-white padding-5 padding-20-R'} style={{color: '#901C30', fontSize: 'bold'}}>
													Sin conincidencias: {this.state.response_content.erroneos.length}
												</Typography>
												<Typography className={'v-center px-14 text-center bg-white padding-5 padding-20-R'} style={{color: '#073290', fontSize: 'bold'}}>
													Repetidos: {this.state.response_content.repetidos.length}
												</Typography>
												<Typography className={'v-center px-14 text-center bg-white padding-5'} style={{color: '#009018', fontSize: 'bold'}}>
													Nuevos: {this.state.response_content.correctos.length}
												</Typography>
											</div>
											<Divider/>
										</Grid>
									) : null}
									
									{this.state.response_content.faltantes.length > 0 ? (
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#901C30', fontSize: 'bold'}}>
													Los siguientes PDF´s no se pudieron registrar correctamente, favor de intentar nuevamente:
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Table className={'desing-acresco'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'}>Archivo</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														
														{this.state.response_content.faltantes.map((item, key) => (
															<Fragment key={key}>
																<TableRow>
																	<TableCell align={'left'}>{item}</TableCell>
																</TableRow>
															</Fragment>
														))
														}
													</TableBody>
												</Table>
											</Grid>
										</Fragment>
									) : null}
									
									{this.state.response_content.correctos.length > 0 ? (
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#009018', fontSize: 'bold'}}>
													Los siguientes PDF´s pueden registrarse correctamente:
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Table className={'desing-acresco'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'}>Archivo</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														
														{this.state.response_content.correctos.map((item, key) => (
															<Fragment key={key}>
																<TableRow>
																	<TableCell align={'left'}>{item.archivo_pdf}</TableCell>
																</TableRow>
															</Fragment>
														))
														}
													</TableBody>
												</Table>
											</Grid>
										</Fragment>
									) : null}
									
									{this.state.response_content.erroneos.length > 0 ? (
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#901C30', fontSize: 'bold'}}>
													Los siguientes PDF´s no podran registrarse ya que no cuentan con alguna coincidencia de los registros XML´s:
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Table className={'desing-acresco'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'}>Archivo</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														
														{this.state.response_content.erroneos.map((item, key) => (
															<Fragment key={key}>
																<TableRow>
																	<TableCell align={'left'}>{item}</TableCell>
																</TableRow>
															</Fragment>
														))
														}
													</TableBody>
												</Table>
											</Grid>
										</Fragment>
									) : null}
									
									{this.state.response_content.repetidos.length > 0 ? (
										<Fragment>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Typography className={'px-14 text-center bg-white padding-5'} style={{color: '#073290', fontSize: 'bold'}}>
													Los siguientes PDF´s ya se encuentran registrados:
												</Typography>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Table className={'desing-acresco'}>
													<TableHead>
														<TableRow>
															<TableCell component="th" align={'left'}>Archivo</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														
														{this.state.response_content.repetidos.map((item, key) => (
															<Fragment key={key}>
																<TableRow>
																	<TableCell align={'left'}>{item}</TableCell>
																</TableRow>
															</Fragment>
														))
														}
													</TableBody>
												</Table>
											</Grid>
										</Fragment>
									) : null}
								
								</Fragment>
							)}
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Fragment>
							<Button onClick={this.close} color="primary">
								Cancelar
							</Button>
							<Button onClick={this.save} color="primary">
								{this.state.pdf ? 'Guardar' : 'Verificar'}
							</Button>
						
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCargaMasivoPDFCFDI.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCargaMasivoPDFCFDI;
